import React, { useState } from "react"
import { Row, Col, Collapse } from "reactstrap"
import CredentialTab from "./CredentialTab"

const DmCredentials = ({ verticalActiveTab, type, projectDetail }) => {
  return (
    <React.Fragment>
      <div className=" mb-4">
        <Row>
          <Col md={"12"} className={`z-1 pe-0 `}>
            <CredentialTab
              verticalActiveTab={verticalActiveTab}
              type={type}
              projectDetail={projectDetail}
            />
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default DmCredentials
