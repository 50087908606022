import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import "../../../assets/css/Staff/Staff.css"
import "../../../assets/css/Settings/digitalMarketing.css"

import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import PackageDm from "./PackageDm"
import ActivityDm from "./ActivityDm"
import PackageDmIndex from "./PackageDmIndex"
import Loader from "pages/Separate/Loader"
import secureLocalStorage from "react-secure-storage"
import { fetchData } from "utils/common-fetchData"

// components

const DigitalMarketingSeting = () => {
  const [verticalActiveTab, setverticalActiveTab] = useState("1")

  document.title = "Digital Marketing | Zithas Crm"

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  const [fixedSideTab, setFixedSideTab] = useState(false)

  document.addEventListener("scroll", () => {
    if (window.scrollY > 68) {
      setFixedSideTab(true)
    } else {
      setFixedSideTab(false)
    }
  })
  const [activities, setActivities] = useState([])
  const [location, setLocation] = useState([])
  const [packages, setPackages] = useState([])
  const [loading, setLoading] = useState(false)
  const toggleLoading = (loading) => {
    setLoading(loading)
  }

  const getActivity = async () => {
    const auth = secureLocalStorage.getItem('auth_id')
    const response = await fetchData(toggleLoading, "digitalPackages", auth)
    if (response.success) {
      setActivities(response?.data?.activities)
      setLocation(response?.data?.country?.map((obj) => ({
        label: obj?.location,
        value: obj?.id,
      })))
      setPackages(response?.data?.packages)
    }
  }

  useEffect(() => {
    getActivity()
  }, [verticalActiveTab])


  return (
    <React.Fragment>
      {loading && (<Loader />)}
      <div className="page-content mb-4">
        <Container fluid>
          <Breadcrumbs title="Setting" breadcrumbItem="Digital Marketing" />

          <Row className="gx-4 justify-content-between di-flex align-items-top">
            <Col md="2" className="px-0 side-digital-tab">
              <Nav
                pills
                className={`side-tab flex-column px-2 py-3 bg-white  ${fixedSideTab ? "fixed-side-tab" : ""
                  } h-100`}
              >
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleVertical("1")
                    }}
                  >
                    Package
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "2",
                    })}
                    onClick={() => {
                      toggleVertical("2")
                    }}
                  >
                    Activity
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col md="10" className="pe-0 side-digital-list">
              <div className="card p-4 h-100">
                <TabContent
                  activeTab={verticalActiveTab}
                  className="text-muted mt-4 mt-md-0"
                >
                  <TabPane tabId="1">
                    <PackageDmIndex activity={activities} country={location} packages={packages} />
                  </TabPane>
                  <TabPane tabId="2">
                    <ActivityDm activity={activities} />
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DigitalMarketingSeting
