import configure from "configure"
import moment from "moment"
import React, { useState } from "react"
import { Link } from "react-router-dom"
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap"
import sideImg from "../../../assets/images/profile.png"
import { projectStatus } from "utils/common-options"

export const DigitalProjectsColumns = (verticalActiveTab) => [
    {
        Header: "ID",
        accessor: "id",
        isInfoIcon: false,
        isShortIcon: true,
        className: "text-center project_id",
    },
    {
        Header: "Project Name",
        title: "Project Name",
        accessor: "project_name",
        isInfoIcon: false,
        isShortIcon: true,
        className: "project_name_box",
    },
    {
        Header: "Members",
        title: "Members",
        accessor: "project_members",
        isInfoIcon: false,
        isShortIcon: true,
        className: "project_members_box",
        Cell: cellProps => {
            return (
                <div className="d-flex justify-content-center align-items-center gap-2">
                    {cellProps?.row?.original?.members?.map((user, i) => (
                        <img src={`${configure.imgUrl}${user?.avatar}`} className="img-fluid" style={{ width: "25px", borderRadius: "50%", height: "25px" }} title={user?.alias_name} key={i}></img>
                    ))}
                </div>
            )
        }
    },
    {
        Header: "Start Date",
        title: "Start Date",
        accessor: "start_date",
        isInfoIcon: false,
        isShortIcon: true,
        className: "start_date_box text-center",
        Cell: cellProps => {
            return (
                <>{moment(cellProps.row.original.startdate).format("DD-MM-YYYY")}</>
            )
        },
    },
    {
        Header: "End Date",
        title: "Pending Working Days",
        accessor: "due_date",
        isInfoIcon: false,
        isShortIcon: true,
        className: "start_date_box text-center",
        Cell: cellProps => {
            return (
                <>{moment(cellProps.row.original.end_date).format("DD-MM-YYYY")}</>
            )
        },
    },
    {
        Header: "Allc. Hrs",
        title: "Pending Working Days",
        accessor: "allocate_hrs",
        isInfoIcon: false,
        isShortIcon: true,
        className: "project_action text-center",
    },
    {
        Header: "Status",
        title: "Status",
        accessor: "status",
        isInfoIcon: false,
        isShortIcon: true,
        className: "project_status_box text-center",
        Cell: cellProps => {
            return (
                <div>
                    {projectStatus?.find((obj) => obj?.value == cellProps?.row?.original?.project_status)?.label}
                </div>
            )
        }
    },
    {
        Header: "Action",
        accessor: "action",
        className: "project_action text-center",
        Cell: cellProps => {
            const data = cellProps?.row?.original
            return (
                <div className="d-flex justify-content-center">
                    <Link to={`${configure.appUrl}initial-project-plan/${data?.id}/${verticalActiveTab}`}
                        className="btn btn-sm btn-outline-secondary me-1"
                        title="Plan Project"
                    >
                        <i className="fas fa-clipboard-list "></i>
                    </Link>

                    <Link to={`${configure.appUrl}digital_marketing/edit_project/${data?.id}`}
                        className="btn btn-sm btn-outline-secondary me-1"
                        title="Edit Project"
                    >
                        <i className="fas fa-pen "></i>
                    </Link>

                    <UncontrolledDropdown
                        className="dropdown"
                        style={{ width: "29px" }}
                    >
                        <DropdownToggle
                            className="btn btn-sm btn-outline-secondary w-100"
                            color="white"
                        >
                            <i className="mdi mdi-dots-horizontal"></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                            <a
                                title="Delete Task"
                                className="dropdown-item"
                            >
                                <i className="fas fa-trash-alt text-danger pr-10"></i>
                                Delete Project
                            </a>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
            )
        },
    },
]
export const DigitalWeekPlanColumns = () => [
    {
        Header: "ID",
        accessor: "id",
        isInfoIcon: false,
        isShortIcon: true,
        className: "text-center project_id",
    },
    {
        Header: "Project Name",
        title: "Project Name",
        accessor: "project_name",
        isInfoIcon: false,
        isShortIcon: true,
        className: "project_name_box",
    },
    {
        Header: "Customer",
        title: "Customer",
        accessor: "customer",
        isInfoIcon: false,
        isShortIcon: true,
        className: "customer_name_box",
    },
    {
        Header: "Start Date",
        title: "Start Date",
        accessor: "start_date",
        isInfoIcon: false,
        isShortIcon: true,
        className: "start_date_box text-center",
        Cell: cellProps => {
            return (
                <>{moment(cellProps.row.original.startdate).format("DD-MM-YYYY")}</>
            )
        },
    },
    {
        Header: "End Date",
        title: "due_date",
        accessor: "due_date",
        isInfoIcon: false,
        isShortIcon: true,
        className: "start_date_box text-center",
    },
    {
        Header: "Days",
        title: "Last Updated Days",
        accessor: "days",
        isInfoIcon: false,
        isShortIcon: true,
        className: "projects_days_box text-center",
    },
    {
        Header: "Status",
        title: "Status",
        accessor: "status",
        isInfoIcon: false,
        isShortIcon: true,
        className: "project_status_box text-center",
        Cell: cellProps => {
            return (
                <div className="" style={{ width: "100%" }}>
                    <select
                        className={`form-select custom-select `}
                        value={cellProps.row.original.status}
                    >
                        <option value='not-started' className="">Not Started</option>
                        <option value='in-progress' className="">In Progress</option>
                        <option value='on-hold' className="">On Hold</option>
                        <option value='cancelled' className="">Cancelled</option>
                        <option value='finished' className="">Finished</option>
                    </select>
                </div>
            )
        },
    },
    {
        Header: "Action",
        accessor: "action",
        className: "project_action text-center",
        Cell: cellProps => {
            return (
                <div className="d-flex justify-content-center">
                    <Link to={`${configure.appUrl}weekly-plan/digital-marketing/${cellProps?.row?.original?.id}`}
                        className="btn btn-sm btn-outline-secondary me-1"
                        title="View Project"
                    >
                        <i className="fas fa-clipboard-list "></i>
                    </Link>

                    <a
                        className="btn btn-sm btn-outline-secondary me-1"
                        title="Edit Project"
                    >
                        <i className="fas fa-pen "></i>
                    </a>

                    <UncontrolledDropdown
                        className="dropdown"
                        style={{ width: "29px" }}
                    >
                        <DropdownToggle
                            className="btn btn-sm btn-outline-secondary w-100"
                            color="white"
                        >
                            <i className="mdi mdi-dots-horizontal"></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                            <a
                                title="Add Task"
                                className="dropdown-item"
                            >
                                <i className="bi bi-list-check pr-10"></i> Add Task
                            </a>
                            <a
                                title="Delete Task"
                                className="dropdown-item"
                            >
                                <i className="fas fa-trash-alt text-danger pr-10"></i>{" "}
                                Delete Project
                            </a>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
            )
        },
    },
]

export const ApprovalDigitalColumns = (onEditHrs) => [
    {
        Header: "ID",
        accessor: "id",
        isInfoIcon: false,
        isShortIcon: true,
        className: "com_id",
    },
    {
        Header: "Activity Name",
        accessor: "name",
        isInfoIcon: false,
        isShortIcon: true,
        className: "a_name",
    },
    {
        Header: "Quantity",
        accessor: "quantity",
        isInfoIcon: false,
        isShortIcon: true,
        className: "a_kam_hrs",
        Cell: cellProps => {
            return (
                <div>{cellProps?.row?.original?.quantity} - (<span className="text-purple">{cellProps?.row?.original?.est_time}</span>)</div>
            )
        }
    },
    {
        Header: "Assign",
        accessor: "a_assign",
        isInfoIcon: false,
        isShortIcon: true,
        className: "a_kam_hrs",
        Cell: cellProps => {
            return (
                <div className=" d-flex gap-2 flex-wrap justify-content-center align-items-center">
                    <img
                        src={`${cellProps?.row?.original?.avatar != null ? `${configure.imgUrl}${cellProps?.row?.original?.avatar}` : sideImg}`}
                        alt=""
                        className="img-fluid"
                        style={{ borderRadius: "100%", width: "25px", height: "25px" }}
                        title={cellProps?.row?.original?.user_alias}
                    />
                </div>
            )
        },
    },
    {
        Header: "Total Est. Hrs",
        accessor: "est_hrs",
        isInfoIcon: false,
        isShortIcon: true,
        className: "a_kam_hrs",
        Cell: cellProps => {
            const data = cellProps.row.original
            return (
                <div>{data?.est_hrs} </div>
            )
        }
    },
    {
        Header: "Resource Hrs",
        accessor: "a_final_days",
        isInfoIcon: false,
        isShortIcon: true,
        className: "a_kam_hrs",
        Cell: cellProps => {
            const data = cellProps?.row?.original
            const [devHrs, setdevHrs] = useState(data?.status == 1 && data?.est_hrs != null ? data?.dev_hrs : data?.est_hrs);
            return (
                <div className="d-flex gap-2">
                    <input type="text" className={`form-control text-center ${data?.dev_hrs != null ? "text-success" : ''}`}
                        value={devHrs}
                        onChange={(e) => setdevHrs(e.target.value)}
                        required
                    />
                    <button className="p-2 copyBtn col-4 btn btn-primary  btn-sm rounded "
                        onClick={(e) => {
                            e.preventDefault()
                            onEditHrs(data?.id, devHrs)
                        }} >
                        <i className="fas fa-save text-white"></i>
                    </button>
                </div>
            )
        },
    },
]

export const CompleteDigitalColumns = () => [
    {
        Header: "ID",
        accessor: "id",
        isInfoIcon: false,
        isShortIcon: true,
        className: "com_id",
    },
    {
        Header: "Activity Name",
        accessor: "name",
        isInfoIcon: false,
        isShortIcon: true,
        className: "a_name",
    },
    {
        Header: "Quantity",
        accessor: "quantity",
        isInfoIcon: false,
        isShortIcon: true,
        className: "a_kam_hrs",
        Cell: cellProps => {
            return (
                <div>{cellProps?.row?.original?.quantity} - (<span className="text-purple">{cellProps?.row?.original?.est_time}</span>)</div>
            )
        }
    },
    {
        Header: "Assign",
        accessor: "a_assign",
        isInfoIcon: false,
        isShortIcon: true,
        className: "a_kam_hrs",
        Cell: cellProps => {
            return (
                <div className=" d-flex gap-2 flex-wrap justify-content-center align-items-center">
                    <img
                        src={`${cellProps?.row?.original?.avatar != null ? `${configure.imgUrl}${cellProps?.row?.original?.avatar}` : sideImg}`}
                        alt=""
                        className="img-fluid"
                        style={{ borderRadius: "100%", width: "25px", height: "25px" }}
                        title={cellProps?.row?.original?.user_alias}
                    />
                </div>
            )
        },
    },
    {
        Header: "Total Est. Hrs",
        accessor: "est_hrs",
        isInfoIcon: false,
        isShortIcon: true,
        className: "a_kam_hrs",
        Cell: cellProps => {
            const data = cellProps.row.original
            return (
                <div>{data?.est_hrs} </div>
            )
        }
    },
]

export const DMReportsColumns = (onViewTime) => [
    {
        Header: "ID",
        accessor: "a_id",
        isInfoIcon: false,
        isShortIcon: true,
        className: "report_id",
    },
    {
        Header: "Activity Name",
        accessor: "a_name",
        isInfoIcon: false,
        isShortIcon: true,
        className: "a_name",
    },
    {
        Header: "Quantity",
        accessor: "quantity",
        isInfoIcon: false,
        isShortIcon: true,
        className: "a_kam_hrs",
    },
    {
        Header: "Assign",
        accessor: "a_assign",
        isInfoIcon: false,
        isShortIcon: true,
        className: "a_kam_hrs",
        Cell: cellProps => {
            return (
                <div className=" d-flex gap-2 flex-wrap justify-content-center align-items-center">
                    <img
                        src={sideImg}
                        alt=""
                        className="img-fluid"
                        width='30'
                        style={{ borderRadius: "100%" }}
                        title="User Name"
                    />
                </div>
            )
        },
    },
    {
        Header: "Status",
        accessor: "status",
        isInfoIcon: false,
        isShortIcon: true,
        className: "a_kam_hrs",
    },
    {
        Header: "Allocated Hrs",
        accessor: "allocated_hrs",
        isInfoIcon: false,
        isShortIcon: true,
        className: "a_kam_hrs",
    },
    {
        Header: "Actual Hrs",
        accessor: "actual_hrs",
        isInfoIcon: false,
        isShortIcon: true,
        className: "a_kam_hrs",
        Cell: cellProps => {
            return (
                <div className="d-flex align-items-center gap-2">{cellProps?.row?.original?.actual_hrs}
                    <a onClick={() => onViewTime(cellProps.row?.original?.id)}><i className="fas fa fa-eye"></i></a>
                </div>
            )
        }
    },
]