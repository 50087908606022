import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";
//Dasboard job
import Roles from "./Roles/reducer";
import location from "./locations/reducer";
import Elements from "./elements/reducer";
import GeneralData from "./generalData/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  location,
  // ecommerce,
  // calendar,
  // chat,
  // mails,
  // crypto,
  // invoices,
  // JobReducer,
  // projects,
  // tasks,
  // contacts,
  // Dashboard,
  // DashboardSaas,
  // DashboardCrypto,
  // DashboardBlog,
  // DashboardJob,
  Roles,
  Elements,
  GeneralData
});

export default rootReducer;
