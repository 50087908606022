import Breadcrumb from "components/Common/Breadcrumb"
import configure from "configure"
import moment from "moment"
import Loader from "pages/Separate/Loader"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import secureLocalStorage from "react-secure-storage"
import { Card, CardBody, Container } from "reactstrap"
import AccessDenied from "routes/AccessDenied"
import { FetchPostData } from "utils/common-fetchData"
import { getDecryptedData } from "utils/common-helpers"

const DmSharedNotes = () => {
  const [data, setData] = useState([])
  const params = useParams()
  const [loading, setLoading] = useState(false)
  const [isShared, setIsShared] = useState(null)
  const toggleLoading = loading => {
    setLoading(loading)
  }
  useEffect(() => {
    const getData = async () => {
      const authToken = secureLocalStorage.getItem("auth_token")
      const formEntries = {
        user_id: localStorage.getItem("auth_id"),
        note_id: getDecryptedData(params.noteId),
      }
      const response = await FetchPostData(
        toggleLoading,
        "showDigitalProjectNotes",
        authToken,
        formEntries
      )
      if (response?.data?.success) {
        const ids = response?.data?.data[0]?.share_users?.split(",")
        const isPresent = ids?.includes(localStorage.getItem("auth_id"))
        setIsShared(isPresent)
        if (isPresent) {
          setData(response?.data?.data)
        }
      }
    }
    getData()
  }, [])
  console.log(isShared)
  return (
    <div>
      {isShared ? (
        <>
          {loading && <Loader />}
          <React.Fragment>
            <div className="page-content mb-4">
              <Container fluid>
                <Breadcrumb title={"Work"} breadcrumbItem={"Project Notes"} />
                <Card>
                  <CardBody>
                    <div className="mt-4">
                      <ul className="verti-timeline list-unstyled notes-box">
                        <div>
                          {data.map(note => (
                            <li
                              className="event-list position-relative"
                              key={note.id}
                            >
                              <div className="event-timeline-dot">
                                <img
                                  src={`${configure.imgUrl}${note?.avatar}`}
                                  className="userImg me-3 "
                                  alt=""
                                />
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1 ms-4 note py-2 rounded ps-3 pr-10">
                                  <div className=" d-flex justify-content-between ">
                                    <div>
                                      <p className="text-muted ">
                                        <h6 className="d-inline me-2">
                                          By {note?.name}
                                        </h6>{" "}
                                        At {note?.created_at}
                                        <span className="pl-15">
                                          Got in touch with this Project at{" "}
                                          {moment(note?.date).format(
                                            "DD-MM-YYYY HH:mm:ss"
                                          )}
                                        </span>
                                      </p>
                                    </div>
                                    <div className="me-4">
                                      <a className="btn btn-sm edit-delete-btn text-success me-2">
                                        <i
                                          className={` ${
                                            note.pinned == 1
                                              ? "bi bi-pin-angle-fill"
                                              : " bi bi-pin-angle"
                                          }`}
                                          title="Pin Note"
                                        ></i>
                                      </a>
                                    </div>
                                  </div>
                                  <i className="bi bi-triangle-fill note-icon"></i>

                                  <div className="Features">
                                    <p
                                      className="mb-2"
                                      dangerouslySetInnerHTML={{
                                        __html: note.decription,
                                      }}
                                    ></p>
                                    <div />
                                    <div>
                                      <div>
                                        Attachment:
                                        {note?.file != null ? (
                                          <>
                                            {JSON.parse(note?.file)?.map(
                                              (obj, i) => {
                                                return (
                                                  <a
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="activity-log-btn text-info"
                                                    href={obj}
                                                    key={i}
                                                  >
                                                    <i className="fas fa-eye pr-10"></i>
                                                    View
                                                  </a>
                                                )
                                              }
                                            )}
                                          </>
                                        ) : (
                                          <small className="text-dark ms-2">
                                            No Files Available
                                          </small>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </div>
                      </ul>
                      {data.length == 0 ? <p>Note is Not Addded</p> : null}
                    </div>
                  </CardBody>
                </Card>
              </Container>
            </div>
          </React.Fragment>
        </>
      ) : isShared == false ? (
        <AccessDenied />
      ) : (
        <Loader />
      )}
    </div>
  )
}

export default DmSharedNotes
