import Breadcrumb from "components/Common/Breadcrumb"
import NewPagination from "components/Common/Newpagination"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import {
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
} from "react-table"
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    Input,
    Modal,
    Row,
    Table,
} from "reactstrap"
import { ReportColumns } from "utils/columns/Reports/reports-columns"
import Select from "react-select"
import { ResizableBox } from "react-resizable"
import {
    ChanceOptions,
    RelatedToOption,
    StaffOption,
} from "utils/common-options"
import ZithasLogo from "../../../assets/images/zithasLogo.png"
import ZithasSignature from "../../../assets/images/zithas-signature.png"
import ReactSelect from "react-select"
import Draggable from "react-draggable"
import Swal from "sweetalert2"
import html2pdf from "html2pdf.js"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { fetchData, FetchPostData } from "utils/common-fetchData"
import secureLocalStorage from "react-secure-storage"
import Loader from "pages/Separate/Loader"
import { useSelector } from "react-redux"
import { filterDiscrepanciesData } from "utils/common-helpers"

function TableContainer({ columns, datas, generalData, allDatas }) {
    const [fixedHeader, setFixedHeader] = useState(false)
    const [fixedContent, setFixedContent] = useState(false)
    const [data, setData] = useState(datas)
    const [allData, setAllData] = useState(allDatas)
    useEffect(() => {
        setData(datas)
        setAllData(allDatas)
    }, [datas, allDatas])

    document.addEventListener("scroll", () => {
        const scrollThreshold = window.innerHeight * 0.25
        if (window.scrollY > scrollThreshold) {
            setFixedContent(true)
        } else {
            setFixedContent(false)
        }
    })
    const navigate = useNavigate()
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        state,
        previousPage,
        nextPage,
        canPreviousPage,
        canNextPage,
        gotoPage,
        setPageSize,
        pageOptions,
        setGlobalFilter,
        pageCount,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: [
                    {
                        id: "created_at",
                        desc: true,
                    },
                ],
            },
        },

        useGlobalFilter,
        useSortBy,
        usePagination
    )

    const { pageIndex, pageSize, globalFilter } = state

    useEffect(() => setPageSize(50), [])

    document.addEventListener("scroll", () => {
        if (window.scrollY > 165) {
            setFixedHeader(true)
        } else {
            setFixedHeader(false)
        }
    })
    const [reportFilter, setReportFilter] = useState(false)
    const [modal_fullscreen, setmodal_fullscreen] = useState(false)

    const generateReport = (data) => {
        let combinedContent = `
              <div className='Features document-content'>
                <h5 className='text-center mb-3 mt-3'>Staff Performance Report</h5>
                <p>
                  <i className='bi bi-arrow-right'></i> 
                  As part of our commitment to transparency and continuous improvement, this report provides a comprehensive overview of issues encountered by you throughout. 
                  It highlights specific challenges, tracks recurring issues, and documents the steps taken to address them. 
                  This report serves as a valuable tool for performance assessment, internal feedback, and enhanced team support.
                </p>
                <h6 className='mb-3'><strong>Reasons</strong></h6>
            `;

        // Loop through the data and add each title and reason as part of the report
        data.forEach(item => {
            combinedContent += `
                <p>
                  <strong>${item.title}:</strong> <br/> ${item.reasons}
                </p>
              `;
        });

        combinedContent += `</div>`; // Close the div

        return combinedContent;
    };




    const pdfRef = useRef()
    const [editMode, setEditMode] = useState(false)
    const [description, setDescription] = useState('')
    const [fromDate, setfromDate] = useState('')
    const [toDate, setToDate] = useState('')
    const [staffId, setStaffId] = useState('')
    const [reported, setReported] = useState('')
    const [chance, setChance] = useState(false)
    const [solved, setSolved] = useState(false)

    const generatePDF = async () => {
        setEditMode(true)
        setTimeout(() => {
            const content = document.getElementById("report-content")
            if (content) {
                const opt = {
                    margin: 0.38,
                    filename: "Staff_Issue_Report.pdf",
                    image: { type: "jpeg", quality: 0.98 },
                    html2canvas: { dpi: 192, letterRendering: true, scale: 2 },
                    jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
                }

                html2pdf().from(content).set(opt).save()
            } else {
                console.error("Element with the provided id not found.")
            }
            setEditMode(false)
        }, 2000)
    }
    const filters = { fromDate: fromDate, toDate: toDate, staffId: staffId, reported: reported, chance: chance, solved: solved }

    return (
        <div>
            <div
                className={`flex-column fixed-buttons pt-2 ${fixedContent ? "d-flex" : "d-none"
                    }`}
            >
                <Link className="btn btn-save  fix-btn btn-label pe-0">
                    <i className="fas fa-plus me-2 icon-size label-icon"></i>
                    <span>Staff</span>
                </Link>
                <button
                    onClick={() => navigate(-1)}
                    className="btn btn-red  fix-btn btn-label pe-0"
                    type="button"
                >
                    <i className="dripicons-cross me-2 icon-center label-icon"></i>
                    <span>Cancel</span>
                </button>
            </div>
            <Row className="mb-2">
                <Col md={3}>
                    <div>
                        <select
                            className=" form-select"
                            style={{ width: "100px" }}
                            value={pageSize}
                            onChange={e => setPageSize(e.target.value)}
                        >
                            {[10, 25, 50, 100].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </select>
                    </div>
                </Col>
                <Col md={9} className="d-flex justify-content-end">
                    <div className="me-2 d-flex">
                        <div className="mb-2">
                            <button
                                onClick={() => setReportFilter(!reportFilter)}
                                className="btn btn-outline-secondary  me-3"
                            >
                                <i className="fas fa-filter"></i>
                            </button>
                        </div>
                        <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
                            <div className="position-relative">
                                <label htmlFor="search-bar-0" className="search-label">
                                    <span id="search-bar-0-label" className="sr-only">
                                        Search this table
                                    </span>
                                    <input
                                        onChange={e => setGlobalFilter(e.target.value)}
                                        id="search-bar-0"
                                        type="text"
                                        className=" form-control rounded h-100"
                                        placeholder={` Search...`}
                                        value={globalFilter || ""}
                                    />
                                </label>
                                <i className="bx bx-search-alt search-icon"></i>
                            </div>
                        </div>
                    </div>
                    <div className="text-md-end ">
                        <div className="d-flex gap-2">
                            <a
                                className=" btn btn-label export-btn tex-white credit-card-btn"
                                onClick={() => {
                                    setmodal_fullscreen(true)
                                    setDescription(generateReport(data))
                                }}
                            >
                                <i className="fas fa-download me-2 label-icon"></i>Download PDF
                            </a>
                            <a
                                onClick={() => navigate(-1)}
                                className=" btn ms-md-2 btn-red btn-label"
                                type="button"
                            >
                                <i className="dripicons-cross me-2 icon-center label-icon"></i>
                                CANCEL
                            </a>
                        </div>
                    </div>
                </Col>
            </Row>
            <Table className="table table-bordered mt-4 shadow" {...getTableProps()}>
                <thead
                    className={`${fixedHeader && page.length >= 10 ? "fix-staffs-headers" : ""
                        } fix-staff-headers`}
                >
                    {headerGroups.map((headerGroup, i) => (
                        <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column, index) => (
                                <th
                                    key={index}
                                    className={`t-col-${index + 1} ${column.className
                                        } customShortIcon`}
                                    {...column.getHeaderProps()}
                                >
                                    {column.render("Header")}
                                    {column.isShortIcon && (
                                        <span
                                            onClick={e => column.getSortByToggleProps().onClick(e)}
                                            className="ms-2 cursor-pointer"
                                        >
                                            <i
                                                title="Ascending"
                                                className={`bi bi-arrow-up info-icon ${column.isSorted && !column.isSortedDesc
                                                    ? "text-dark"
                                                    : ""
                                                    }`}
                                            ></i>
                                            <i
                                                title="Descending"
                                                className={`bi bi-arrow-down info-icon ${column.isSorted && column.isSortedDesc
                                                    ? "text-dark"
                                                    : ""
                                                    }`}
                                            ></i>
                                        </span>
                                    )}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>

                <tbody className={`fix-staff-headers`} {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr
                                className={`row-hover ${row?.original?.time != null ? "bg-orange" : ""
                                    }`}
                                {...row.getRowProps()}
                                key={i}
                            >
                                {row.cells.map((cell, index) => {
                                    return (
                                        <td
                                            key={index}
                                            className={`t-col-${index + 1}`}
                                            {...cell.getCellProps()}
                                        >
                                            {cell.render("Cell")}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </Table>

            {/* Pagination */}
            <Row className="justify-content-md-end justify-content-center align-items-center">
                <NewPagination
                    gotoPage={gotoPage}
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    pageIndex={pageIndex}
                    pageOptions={pageOptions}
                    pageSize={pageSize}
                    page={page}
                    data={data}
                />
            </Row>

            {reportFilter ? (
                <div className="report-filter" >
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        setData(filterDiscrepanciesData(allDatas, filters))
                    }}>
                        <Row className="align-items-end">
                            <Row className="d-flex flex-wrap ">
                                <div className="col-2 mt-2">
                                    <label htmlFor="">From Date</label>
                                    <Input
                                        type="date"
                                        className="form-control"
                                        id="formrow-firstname-Input"
                                        placeholder="Enter Task Name"
                                        value={fromDate}
                                        onChange={(e) => setfromDate(e.target.value)}
                                    />
                                </div>
                                <div className="col-2 mt-2">
                                    <label htmlFor="">To Date</label>
                                    <Input
                                        type="date"
                                        className="form-control"
                                        id="formrow-firstname-Input"
                                        placeholder="Enter Task Name"
                                        value={toDate}
                                        onChange={(e) => setToDate(e.target.value)}
                                    />
                                </div>
                                <div className="col-2 mt-2">
                                    <label htmlFor="">Staff</label>
                                    <Select
                                        placeholder="Select Staff"
                                        className="basic-multi-select "
                                        classNamePrefix="select"
                                        closeMenuOnSelect={true}
                                        options={generalData?.staff}
                                        value={staffId}
                                        onChange={(e) => setStaffId(e)}
                                    />
                                </div>
                                <div className="col-2 mt-2">
                                    <label htmlFor="">Reported By</label>
                                    <Select
                                        placeholder="Select Reported By"
                                        className="basic-multi-select "
                                        classNamePrefix="select"
                                        closeMenuOnSelect={true}
                                        options={generalData?.users}
                                        value={reported}
                                        onChange={(e) => setReported(e)}
                                    />
                                </div>
                                <div className="col-1 mt-2 pe-0 ps-0">
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <label htmlFor="">Chance</label>
                                            <div className="form-switch form-switch-md mt-2 ps-4 ms-3    ">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customSwitchsizemd"
                                                    onChange={(e) => setChance(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="">Solved</label>
                                            <div className="form-switch form-switch-md mt-2 ps-4 ms-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customSwitchsizemd"
                                                    onChange={(e) => setSolved(e.target.checked)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 d-flex justify-content-end align-items-end mb-2 pe-0 ps-0">
                                    <a
                                        className="btn  btn-danger me-1"
                                        style={{
                                            fontSize: "14px",
                                            backgroundColor: "#fed36d",
                                            borderColor: "#fed36d",
                                            color: "black",
                                        }}
                                        onClick={() => {
                                            setfromDate('')
                                            setToDate('')
                                            setStaffId([])
                                            setReported([])
                                            setChance(false)
                                            setSolved(false)
                                            setData(datas)
                                        }}
                                    >
                                        Clear Filters
                                    </a>
                                    <button className="btn  btn-save btn-label me-1" >
                                        <i className="fas fa-filter search-icon label-icon"></i>{" "}
                                        Filter
                                    </button>
                                    <a
                                        className="btn btn-red btn-label me-1"
                                        onClick={() => {
                                            setReportFilter(false)

                                        }}
                                    >
                                        <i className="dripicons-cross label-icon"></i> Close
                                    </a>
                                </div>
                            </Row>
                        </Row>
                    </form>
                </div>
            ) : null}

            <Modal
                size="xl"
                isOpen={modal_fullscreen}
                toggle={() => {
                    setmodal_fullscreen(false)
                }}
            >
                <div className="modal-header mb-0">
                    <div
                        className="d-flex align-items-center justify-content-between"
                        style={{ width: "100%" }}
                    >
                        <h5 className="col-6 modal-title mt-0">Download PDF</h5>
                    </div>

                    <button className="btn btn-purple" onClick={() => generatePDF()}>
                        <i className="fas fa-download"></i>
                    </button>
                    <button
                        type="button"
                        onClick={() => {

                            setmodal_fullscreen(false)
                        }}
                        className="ms-4"
                        style={{ border: "none", backgroundColor: "transparent" }}
                    >
                        <span
                            aria-hidden="true"
                            style={{ color: "#bf1e30", fontSize: "28px", lineHeight: "1px" }}
                        >
                            {" "}
                            &times;{" "}
                        </span>
                    </button>
                </div>
                <div className="modal-body hide-bottom-scrollbar">
                    <div className="">
                        <div id="report-content" ref={pdfRef} className="">
                            <div className="pb-2 text-center">
                                <img src={ZithasLogo} height="60" alt="zithasLogo" />
                            </div>
                            <div>
                                {editMode ? (
                                    <>
                                        <div dangerouslySetInnerHTML={{ __html: description }} />
                                    </>
                                ) : (
                                    <div className="lead-desc">
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={description}
                                            onChange={(event, editor) => {
                                                const data = editor.getData()
                                                setDescription(data)
                                            }}
                                        />
                                    </div>
                                )}
                                <h5 className="fw-bold text-decoration-underline mt-5">
                                    Date : 09-11-2023
                                </h5>
                                <div className="mt-4 justify-space-between row align-items-end">
                                    <div className="col-4">
                                        <h5>Staff Signature</h5>
                                    </div>
                                    <div className="col-4">
                                        <img width="120" src={ZithasSignature} />
                                        <h5>Zithas Signature</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

const index = () => {
    const [isLoading, setLoading] = useState(false)
    const [change, setChange] = useState(false)
    const [boxWidth, setBoxWidth] = useState(0)
    const [notesSidebar, setNotesSidebar] = useState(false)
    const token = secureLocalStorage.getItem("auth_token")
    const [related, setRelated] = useState([])
    const [reportId, setReportId] = useState("")
    const [reason, setReason] = useState("")
    const [title, setTitle] = useState("")
    const [relatedto_option, setRelatedToOption] = useState(
        RelatedToOption?.find(obj => obj.value == "work")?.related
    )
    const [relatedto, setRelatedTo] = useState({
        label: "Development Project",
        value: "development-project",
    })
    const [relatedIdOption, setRelatedIdOption] = useState([])
    const [staffId, setStaffId] = useState(null)
    const [relatedId, setRelatedId] = useState(null)
    const [file, setFile] = useState("")
    const generalData = useSelector(state => state?.GeneralData?.generalData)
    const [formRows, setFormRows] = useState([{}])
    const [data, setData] = useState([])
    const [allData, setAllData] = useState([])
    useEffect(() => {
        const updateWidth = () => {
            const viewportWidth = window.innerWidth
            setBoxWidth(viewportWidth * 0.25)
        }
        updateWidth()
        window.addEventListener("resize", updateWidth)
        return () => window.removeEventListener("resize", updateWidth)
    }, [])

    const onGiveChance = (id) => {
        setChange(true)
        setReportId(id);


    }
    const onDeleteReport = id => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to remove this Report !",
            icon: "warning",
            showCancelButton: true,
        }).then(async isDelete => {
            if (isDelete?.isConfirmed) {
                const res = await fetchData(
                    toggleLoading,
                    `deleteImproveReport/${id}`,
                    token
                )
                if (res?.success) {
                    Swal.fire({
                        icon: "success",
                        text: "Flaws & Gap Deleted Successfully",
                    }).then(data => {
                        setData(
                            res?.data?.original?.data?.filter(obj => obj?.status != 1)
                        )
                        setAllData(res?.data?.original?.data)
                    })
                }
            }
        })
    }
    const onEditReport = (id, data) => {
        setNotesSidebar(true)
        setReportId(id)
        setStaffId(generalData?.staff?.find((staff) => staff.value == data?.staff_id))
        setReason(data?.reasons)
        setTitle(data?.title)
        setRelated(RelatedToOption?.find((obj) => obj?.value == data?.related))
        const RelatedToOptions = RelatedToOption?.find((obj) => obj?.value == data?.related)?.related
        setRelatedToOption(RelatedToOptions)
        const relateTos = RelatedToOptions?.find((obj) => obj?.value == data?.related_to)
        setRelatedTo(
            RelatedToOptions?.find((obj) => obj?.value == data?.related_to)
        )
        const relateIds = relateTos?.value == "lead"
            ? generalData?.leads
            : relateTos?.value == "proposal"
                ? generalData?.proposal
                : relateTos?.value == "digital-project"
                    ? generalData?.digital_projects
                    : relateTos?.value == "development-project"
                        ? generalData?.projects
                        : relateTos?.value == "introducer"
                            ? generalData.introducer
                            : relateTos?.value == "staff"
                                ? generalData.staff
                                : []
        setRelatedIdOption(relateIds)
        setRelatedId(relateIds?.find((obj) => obj?.value == data?.related_id))

    }
    const onCompleteIssue = async id => {
        const formEntries = {
            report_id: id,
            status: 1,
        }
        const res = await FetchPostData(
            toggleLoading,
            "changeImproveReportStatus",
            token,
            formEntries
        )
        if (res?.data?.success) {
            Swal.fire({
                icon: "success",
                text: "Flaws & Gap Solved Successfully",
            }).then(data => {
                setData(
                    res?.data?.data?.original?.data?.filter(obj => obj?.status != 1)
                )
                setAllData(res?.data?.data?.original?.data)
            })
        }
    }
    const columns = useMemo(
        () =>
            ReportColumns(
                onGiveChance,
                onDeleteReport,
                onEditReport,
                onCompleteIssue
            ),
        []
    )
    const [time, setTime] = useState(null)
    const toggleLoading = loading => {
        setLoading(loading)
    }
    const onGiveTime = async e => {
        e.preventDefault()
        if (time != null && time?.length != 0) {
            const formEntries = {
                report_id: reportId,
                time: time?.value,
            }
            const res = await FetchPostData(
                toggleLoading,
                "editImproveReport",
                token,
                formEntries
            )
            if (res?.data?.success) {
                Swal.fire({
                    icon: "success",
                    text: "Chance has been Given Successfully",
                }).then(data => {
                    setData(
                        res?.data?.data?.original?.data?.filter(obj => obj?.status != 1)
                    )
                    setAllData(res?.data?.data?.original?.data)
                })
                setChange(false)
                setTime(null)
            }
        } else {
            Swal.fire({
                icon: "warning",
                text: "Please Select Time",
            })
        }
    }

    const handleEditReport = async e => {
        e.preventDefault()
        if (staffId != null && staffId?.length != 0) {
            const formEntries = {
                report_id: reportId,
                staff_id: staffId?.value,
                related: related?.value,
                related_to: relatedto?.value,
                related_id: relatedId?.value,
                reason: reason,
                title: title,
                // file: file,
            }
            const token = secureLocalStorage.getItem("auth_token")
            const res = await FetchPostData(
                toggleLoading,
                "editImproveReport",
                token,
                formEntries
            )
            if (res?.data?.success) {
                Swal.fire({
                    icon: "success",
                    text: "Flaws & Gap Added Successfully",
                }).then(data => {
                    setData(
                        res?.data?.data?.original?.data?.filter(obj => obj?.status != 1)
                    )
                    setAllData(res?.data?.data?.original?.data)
                    setNotesSidebar(false)
                })
            }
        } else {
            Swal.fire({
                icon: "warning",
                text: "Please Select Staff",
            })
        }
    }
    useEffect(() => {
        const fetchReports = async () => {
            const token = secureLocalStorage.getItem("auth_token")
            const res = await fetchData(toggleLoading, "improveReport", token)
            if (res?.success) {
                setData(res?.data?.filter((obj) => obj?.status != 1))
                setAllData(res?.data)
            }
        }
        fetchReports()
    }, [])

    return (
        <div>
            {isLoading && <Loader />}
            <React.Fragment>
                <div className="page-content" id="staff">
                    <Container fluid>
                        <Breadcrumb title={"Dashboard"} breadcrumbItem={"Flaws & Gap"} />
                        <Row>
                            <Col lg={12} className="p-0">
                                <Card>
                                    <CardBody>
                                        <div className="responsive-table">
                                            <TableContainer
                                                columns={columns}
                                                datas={data}
                                                setLoading={setLoading}
                                                generalData={generalData}
                                                allDatas={allData}
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>

            <div className={`note-sticky-form ${change ? "d-block" : "d-none"}`}>
                <Draggable>
                    <ResizableBox
                        width={boxWidth}
                        style={{
                            position: "fixed",
                            top: "10%",
                            right: "18%",
                            zIndex: "1055",
                            backgroundColor: "#fff",
                        }}
                    >
                        <div className="modal-header pe-4">
                            <h5 className="modal-title mt-0 mb-3">Give Chance</h5>
                        </div>

                        <div className="modal-body pe-4">
                            <form
                                onSubmit={e => onGiveTime(e)}
                                className="row d-flex justify-content-center align-items-center"
                            >
                                <div className="col-md-12 d-flex flex-column justify-space-between  gap-5 mb-3">
                                    <div className="col-12 d-flex justify-space-between align-items-center">
                                        <div className="col-12">
                                            <label htmlFor="">
                                                Select Time <span className="text-danger">*</span>
                                            </label>
                                            <ReactSelect
                                                placeholder="Select Time"
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                options={ChanceOptions}
                                                value={time}
                                                onChange={e => setTime(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="text-end mt-2">
                                        <button className=" btn btn-save btn-label">
                                            <i className="fas fa-save me-2 label-icon"></i>
                                            Save
                                        </button>
                                        <a
                                            onClick={() => {
                                                setChange(false)
                                            }}
                                            className=" btn ms-md-2 btn-red btn-label"
                                        >
                                            <i className="dripicons-cross me-2 icon-center label-icon"></i>
                                            CANCEL
                                        </a>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </ResizableBox>
                </Draggable>
            </div>

            <div
                className={`notes-sidebar ${notesSidebar ? "show-notes-sidebar report-width" : ""
                    }`}
            >
                <form onSubmit={handleEditReport}>
                    <Row className="align-items-center ">
                        <div className="col-12 d-flex justify-content-between">
                            <h5>Edit Flaws & Gap</h5>
                            <div className="text-end">
                                <button className="btn btn-purple  me-2">
                                    <i className="fas fa-plus me-2"></i>Submit
                                </button>
                                <a
                                    onClick={() => setNotesSidebar(false)}
                                    className="btn btn-red text-uppercase "
                                >
                                    <i className="dripicons-cross me-1 icon-center"></i>Close
                                </a>
                            </div>
                        </div>
                        <div className="col-md-3 mt-4">
                            <label htmlFor="">Staff</label>
                            <Select
                                placeholder="Select"
                                className="basic-multi-select"
                                classNamePrefix=" select"
                                options={generalData?.staff}
                                value={staffId}
                                onChange={e => setStaffId(e)}
                            />
                        </div>
                        <div className="col-md-3 mt-4">
                            <label htmlFor="">Related</label>
                            <Select
                                placeholder="Select"
                                className="basic-multi-select"
                                classNamePrefix=" select"
                                options={RelatedToOption}
                                value={related}
                                onChange={e => {
                                    setRelated(e)
                                    setRelatedToOption(e?.related)
                                }}
                            />
                        </div>
                        {related?.length != 0 && (
                            <div className="col-md-3 mt-4">
                                <label htmlFor="">{related?.label}</label>
                                <Select
                                    placeholder="Select"
                                    className="basic-multi-select"
                                    classNamePrefix=" select"
                                    options={relatedto_option}
                                    value={relatedto}
                                    onChange={selectedOption => {
                                        setRelatedTo(selectedOption)
                                        setRelatedIdOption(
                                            selectedOption?.value == "lead"
                                                ? generalData?.leads
                                                : selectedOption?.value == "proposal"
                                                    ? generalData?.proposal
                                                    : selectedOption?.value == "digital-project"
                                                        ? generalData?.digital_projects
                                                        : selectedOption?.value == "development-project"
                                                            ? generalData?.projects
                                                            : selectedOption?.value == "introducer"
                                                                ? generalData.introducer
                                                                : selectedOption?.value == "staff"
                                                                    ? generalData.staff
                                                                    : []
                                        )
                                    }}
                                />
                            </div>
                        )}
                        {relatedIdOption?.length != 0 && (
                            <div className="col-md-3 mt-4">
                                <label htmlFor="">{relatedto?.label}</label>
                                <Select
                                    placeholder="Select"
                                    className="basic-multi-select"
                                    classNamePrefix=" select"
                                    options={relatedIdOption}
                                    value={relatedId}
                                    onChange={e => setRelatedId(e)}
                                />
                            </div>
                        )}

                        <div className="mt-4 report-height">
                            <label htmlFor="">Reasons</label>
                            <div className={"mt-3"}>
                                <div className="col-12 d-flex justify-content-start align-items-center my-2">
                                    <div className="contact-column edit-contact-column mb-md-0 w-100">
                                        <Input
                                            type="text"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            placeholder="Enter Title"
                                            name="title"
                                            value={title}
                                            onChange={e => setTitle(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="contact-column edit-contact-column mb-md-0 mt-2 w-100">
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        id="formrow-firstname-Input"
                                        placeholder="Enter Incident"
                                        name="incident"
                                        value={reason}
                                        onChange={e => setReason(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-md-6 mt-3">
                            <label htmlFor="">Upload File</label>
                            <Input
                                type="file"
                                className="form-control"
                                id="formrow-firstname-Input"
                                placeholder="Enter Reasons"
                                name="task_name"
                                onChange={e => setFile(e.target.files)}
                            />
                        </div> */}
                    </Row>
                </form>
            </div>
        </div>
    )
}

export default index
