// reducers.js
const initialState = {
  generalData: [],
};

const generalReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_GENERAL_DATA':
      return {
        ...state,
        generalData: action.payload,
      };
    default:
      return state;
  }
};

export default generalReducer;
