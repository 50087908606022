// reducers.js
const initialState = {
    element: [],
  };

  const elementReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_ELEMENT_DATA':
        return {
          ...state,
          element: action.payload,
        };
      default:
        return state;
    }
  };

  export default elementReducer;
