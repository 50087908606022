import React, { useEffect, useState } from "react";
import { Container, Form, Label, Input } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useMemo } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import Select from "react-select";
import ReactSelect from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import NewPagination from "components/Common/Newpagination";
import "../../../../assets/css/Sales/Sales.css";
import "../../../../assets/css/Sales/Proposal.css";
import "../../../../assets/css/Work/projects.css";
import InfoGif from "../../../../assets/images/GIF/question-mark.gif";
import axios from "axios";
import Loader from "pages/Separate/Loader";
import configure from "configure";
import AccessDeniedPopup from "routes/AccessDeniedPopup";
import Draggable from "react-draggable";
import { ResizableBox } from "react-resizable";
import Swal from "sweetalert2";
import secureLocalStorage from "react-secure-storage";
import DmViewBasicProjectDetails from "../ViewProject/DmViewBasicProjectDetails";
import DmViewNotes from "../ViewProject/DmViewNotes";
import DmViewActivityLogs from "../ViewProject/DmViewActivityLogs";
import { digitalColumns } from "utils/columns/Digital Marketing/digitalColumns";
import { FetchPostData } from "utils/common-fetchData";
import { checkPermissionsByModule } from "utils/common-helpers";
import { useSelector } from "react-redux";
import {
  DigitalServiceOptions,
  DigitalServiceOptionsDrop,
} from "utils/common-options";
import DmTasks from "../EditProject/DmTasks";
import DmCredentials from "../EditProject/DmCredentials";

function TableContainer({
  columns,
  data,
  setData,
  allData,
  roles,
  setAccessDenied,
}) {
  const [fixedContent, setFixedContent] = useState(false);
  const [name_modal, setNameModal] = useState(false);
  const [title, setTitle] = useState("");
  const [status, setStatus] = useState("all");
  const [viewData, setViewData] = useState(false);
  const navigate = useNavigate();
  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedContent(true);
    } else {
      setFixedContent(false);
    }
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "id",
            desc: true,
          },
        ],
      },
    },

    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { pageIndex, pageSize, globalFilter } = state;

  useEffect(() => setPageSize(50), []);

  const info = [
    {
      title: "Assigned",
      mean: " <p> <strong>Meaning :</strong> In the Mars System's Lead Module, the <strong>Assigned </strong> column designates the specific team member or individual responsible for overseeing and taking action on a lead or potential business opportunity. </p>",
      purpose:
        " <p> <strong>Purpose :</strong> The purpose of the <strong>Assigned</strong> column in the Lead Module is to assign clear accountability for each lead. By identifying who is responsible for nurturing and converting leads into customers, it streamlines lead management, ensures follow-up, and enhances the chances of successful lead conversion. This fosters a more efficient and organized approach to lead handling within the Mars System.</p>",
    },
    {
      title: "Name",
      mean: "<p><strong>Meaning :</strong> In the Mars System's Proposal Module, the <strong>Name</strong> column represents the name of the customer or organization for whom the proposal is being prepared.</p>",
      purpose:
        "<p> <strong>Purpose :</strong> The purpose of the <strong>Name</strong>  column is to provide specific identification of the recipient of the proposal. It ensures that each proposal is customized and addressed to the correct customer or organization, creating a personalized and professional impression. This column is crucial for accurate proposal documentation and effective communication within the Mars System, helping to build strong customer relationships and tailor proposals to individual needs.</p>",
    },
    {
      title: "Type",
      mean: "<p><strong>Meaning :</strong> In the Mars System's Proposal Module, the <strong>Type</strong>  column categorizes proposals into distinct types, such as <strong>General</strong>, <strong>Website</strong>, <strong>SEO </strong>, <strong>Digital Marketing</strong>, or <strong>Dedicated Resource.</strong> </p>",
      purpose:
        "</strong> <p> <strong>Purpose :</strong> The purpose of the <strong>Type</strong> column is to provide specific information about the person linked to the lead. It allows for personalized communication and relationship-building with leads. By knowing the names of potential clients or contacts, the Mars System's users can establish a more personal and targeted approach to lead engagement, which can significantly improve lead conversion rates and customer satisfaction</p>",
    },
    {
      title: "Currency",
      mean: "<p><strong>Meaning :</strong> In the Mars System's Proposal Module, the  <strong>Currency</strong> column specifies the currency in which the proposal's pricing and financial details are presented.</p>",
      purpose:
        "<p><strong>Purpose :</strong> The purpose of the <strong>Currency</strong> column is to provide clarity and transparency regarding the currency in which the proposal's costs and pricing are expressed. This ensures that both your team and the client understand the financial terms of the proposal. By clearly indicating the currency, you facilitate accurate pricing and financial communication, especially in international or multi-currency transactions within the Mars System.</p>",
    },
    {
      title: "Price",
      mean: "<p><strong>Meaning :</strong>  In the Mars System's Proposal Module, the <strong>Price</strong> column denotes the total cost or pricing details associated with the proposal, which may include itemized costs for services, products, or any other expenses.</p>",
      purpose:
        "<p><strong>Purpose :</strong> The purpose of the <strong>Price</strong>  column is to provide a clear and itemized breakdown of the costs associated with the proposal. It allows both your team and the client to understand the financial aspects of the proposal, making it easier to review and negotiate terms. This column ensures transparency in pricing and supports informed decision-making within the Mars System, helping to reach agreements that align with both parties' expectations.</p>",
    },
    {
      title: "Created At",
      mean: " <p><strong>Meaning :</strong> In the Mars System's Proposal Module, the <strong>Created At</strong> column records the date and time when the proposal was initially created or entered into the system.</p>",
      purpose:
        "<p><strong>Purpose :</strong> The purpose of the <strong>Created At</strong> column is to track and document the proposal's creation timeline. It provides valuable information about the proposal's age and origin, allowing users to gauge its recency and relevance. This column aids in organizing proposals chronologically, assessing the efficiency of the proposal creation process, and ensuring that proposals are up-to-date and timely managed within the Mars System.</p>",
    },
    {
      title: "Open Till",
      mean: "<p><strong>Meaning :</strong>  In the Mars System's Proposal Module, the <strong> Open Till</strong>column signifies the date until which the proposal remains valid and open for consideration by the client.</p>",
      purpose:
        "<p><strong>Purpose :</strong>  The purpose of the  <strong> Open Till</strong> column is to establish a clear expiration date for the proposal's validity. It ensures that both your team and the client are aware of the timeframe within which the proposal can be accepted or negotiated. This column helps manage proposal timelines, avoid misunderstandings, and maintain accurate records of proposal status within the Mars System.</p>",
    },
    {
      title: "Status",
      mean: "<p><strong>Meaning :</strong>  In the Mars System's Proposal Module, the <strong>Status</strong> column categorizes proposals into different stages, including <strong>In Progress </strong>, <strong>On Hold </strong>, <strong>Lost </strong>, or <strong>Won </strong>.</p>",
      purpose:
        "<p><strong>Purpose :</strong>  The purpose of the  <strong>Status</strong> column is to provide a quick overview of the current state of each proposal. It helps users track and manage the progression of proposals, from creation to closure. By categorizing proposals into these stages, the Mars System facilitates efficient proposal management, allowing teams to focus their efforts on active proposals, prioritize follow-ups, and accurately report on the status of each proposal. This column aids in effective decision-making and resource allocation.</p>",
    },
  ];

  const onCheckPermission = async () => {
    const permission = checkPermissionsByModule(roles, 101, 3);
    console.log(permission, roles);
    // if (permission) {

    // } else {
    //   setAccessDenied(true)
    // }
    navigate(`${configure.appUrl}digital_marketing/add_project`);
  };

  return (
    <div>
      <div id="support-header" className="mb-4 align-items-center row">
        <div className="pr-0 support-status-part general-status-part col-md-9">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-start w-100 text-center align-items-center gap-3">
              <a
                className={`text-green pt-2 px-0 rounded work-status general-task-status ${
                  status == "all" ? "activeProjectTab" : ""
                }`}
                onClick={() => {
                  setStatus("all");
                  setData(allData);
                }}
              >
                <h4>{allData?.length}</h4>
                <h4>All</h4>
              </a>
              <a
                className={`text-green pt-2 px-0 rounded work-status general-task-status ${
                  status == "not" ? "activeProjectTab" : ""
                }`}
                onClick={() => {
                  setStatus("not");
                  setData(allData?.filter((obj) => obj.project_status == "0"));
                }}
              >
                <h4>
                  {allData?.filter((obj) => obj.project_status == "0")?.length}
                </h4>
                <h4>Not Started</h4>
              </a>
              <a
                className={`text-green pt-2 px-2 rounded work-status general-task-status ${
                  status == "in-progress" ? "activeProjectTab" : ""
                }`}
                onClick={() => {
                  setStatus("in-progress");
                  setData(allData?.filter((obj) => obj.project_status == "1"));
                }}
              >
                <h4>
                  {allData?.filter((obj) => obj.project_status == "1")?.length}
                </h4>
                <h4>In Progress</h4>
              </a>
              <a
                className={`text-green pt-2 px-2 rounded work-status general-task-status ${
                  status == "hold" ? "activeProjectTab" : ""
                }`}
                onClick={() => {
                  setStatus("hold");
                  setData(allData?.filter((obj) => obj.project_status == "2"));
                }}
              >
                <h4>
                  {allData?.filter((obj) => obj.project_status == "2")?.length}
                </h4>
                <h4>On Hold</h4>
              </a>
              <a
                className={`text-green pt-2 px-2 rounded work-status general-task-status ${
                  status == "cancel" ? "activeProjectTab" : ""
                }`}
                onClick={() => {
                  setStatus("cancel");
                  setData(allData?.filter((obj) => obj.project_status == "3"));
                }}
              >
                <h4>
                  {allData?.filter((obj) => obj.project_status == "3")?.length}
                </h4>
                <h4>Cancelled</h4>
              </a>
              <a
                className={`text-green pt-2 px-2 rounded work-status general-task-status ${
                  status == "finish" ? "activeProjectTab" : ""
                }`}
                onClick={() => {
                  setStatus("finish");
                  setData(allData?.filter((obj) => obj.project_status == "4"));
                }}
              >
                <h4>
                  {allData?.filter((obj) => obj.project_status == "4")?.length}
                </h4>
                <h4>Finished</h4>
              </a>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-end pl-0 support-action-part col-md-3">
          <div className="text-md-end">
            <a
              onClick={onCheckPermission}
              className="s btn text-white me-2 btn-save btn-label"
            >
              <i className="fas fa-plus me-2 icon-size label-icon"></i>Project
            </a>
            <button
              className="s btn btn-red btn-label"
              onClick={() => navigate(-1)}
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>
              CANCEL
            </button>
          </div>
        </div>
      </div>
      <Row>
        <div className="col-md-12 my-4 d-flex">
          <div className="col-md-6 d-flex justify-content-start align-items-center">
            <div className="col-md-2 d-flex justify-content-start align-items-center">
              <select
                className="s me-4 form-select"
                style={{ width: "100px" }}
                value={pageSize}
                onChange={(e) => setPageSize(e.target.value)}
              >
                {[10, 25, 50, 100].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
            <div className="d-flex gap-3">
              {DigitalServiceOptions?.map((obj, i) => {
                return (
                  <a key={i} className={`fs-6 ${obj?.text}-color`}>
                    <i className={`${obj?.icon} ${obj?.text}-color`}></i> -{" "}
                    {obj?.text}
                  </a>
                );
              })}
            </div>
          </div>

          <div className="col-md-6 d-flex justify-content-end align-items-center">
            <div className="search-box">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={(e) => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className="s form-control rounded "
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
        </div>
      </Row>
      <div className="position-relative">
        <Table className="table table-bordered" {...getTableProps()}>
          <thead
            className={`${
              fixedContent ? "fix-fixed-project-header" : "fix-table-header"
            } `}
          >
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => {
                  const classNames = column.className;
                  return (
                    <th
                      key={index}
                      className={` col-t-${index + 1} ${classNames}`}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <div className="col-12 p-0 d-flex flex-wrap">
                        <div
                          className={`${
                            index == 0 ? "col-6" : "col-10"
                          } d-flex ${
                            index == 0
                              ? "justify-content-end"
                              : "justify-content-start"
                          }`}
                        >
                          {column.render("Header")}
                        </div>
                        <div
                          className={`${
                            index == 0 ? "col-6" : "col-2"
                          } d-flex justify-content-end`}
                        >
                          {column.isShortIcon ? (
                            <span
                              onClick={() =>
                                column.getSortByToggleProps().onClick()
                              }
                              style={{
                                position: index != 0 ? "" : "",
                                right: index != 0 ? "0" : "",
                              }}
                              className="d-flex"
                            >
                              <i
                                title="Ascending"
                                className={`bi bi-arrow-up ms-1 info-icon ${
                                  column.isSorted && !column.isSortedDesc
                                    ? "text-dark"
                                    : ""
                                }`}
                              ></i>
                              <i
                                title="Descending"
                                className={`bi bi-arrow-down info-icon ${
                                  column.isSorted && column.isSortedDesc
                                    ? "text-dark"
                                    : ""
                                }`}
                              ></i>
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>

          <tbody className="fix-table-body" {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={i}
                  className={
                    row?.original?.freeze_status == 1 ? "bg-pause-project" : ""
                  }
                >
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        key={index}
                        className={`t-col-${index + 1} ${
                          cell.column.className
                        }  `}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {page.length == 0 && (
              <tr style={{ backgroundColor: "unset" }}>
                <td className="text-center" colSpan={10}>
                  No Data Found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <NewPagination
          gotoPage={gotoPage}
          canPreviousPag={canPreviousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          page={page}
          data={data}
        />
      </Row>

      <Modal
        isOpen={name_modal}
        toggle={() => {
          tog_center();
        }}
        className="bd-info-popup"
        centered
      >
        <div className="modal-header bd-head-info">
          <h5 className="modal-title mt-0">
            <i className="dripicons-information bd-transform-icon pr-10 text-red"></i>
            {title}
          </h5>
        </div>
        <div className="modal-body pb-0">
          <div className="d-flex">
            <div className="col-5 pr-30">
              <div>
                <img src={InfoGif} className="w-100" />
              </div>
            </div>
            <div className="mt-20 ">
              <div>
                {info.map((item, i) => {
                  if (item.title == title) {
                    return (
                      <div key={i}>
                        <span>
                          <span
                            dangerouslySetInnerHTML={{ __html: item.mean }}
                          ></span>
                        </span>
                        <div
                          dangerouslySetInnerHTML={{ __html: item.purpose }}
                        ></div>
                      </div>
                    );
                  }
                })}
                <div></div>
                <div className="text-center mt-70">
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={() => setNameModal(false)}
                  >
                    I Understood
                    <i className="dripicons-thumbs-up pl-10"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal size="md" centered isOpen={viewData} className="activity-log">
        <div className="modal-header">
          <h5 className="modal-title mt-0">Log Details</h5>
          <button
            type="button"
            onClick={() => {
              setViewData(false);
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <div className="table-responsive">
            <table className="table mb-0 table">
              <thead className="table-light">
                <tr>
                  <th>Currency</th>
                  <th>All</th>
                  <th>Sent</th>
                  <th>Lost</th>
                  <th>Won</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
        </div>
      </Modal>
    </div>
  );
}

const SeoIndex = () => {
  const [verticalActiveTab, setverticalActiveTab] = useState("1");
  const [modal_fullscreen, setmodal_fullscreen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState([]);
  const [modal_Access, setAccessDenied] = useState(false);
  const navigate = useNavigate();

  //meta title
  document.title = "Digital Marketing | Zithas Technologies";

  const toggleVertical = (tab) => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab);
    }
  };

  document.addEventListener("keydown", (e) => {
    if (e.key === "Escape") setmodal_fullscreen(false);
  });

  const [projectDetail, setProjectDetail] = useState({});
  const roles = useSelector((state) => state?.Roles?.apiData);
  const [serviceTask, setServiceTask] = useState([]);

  const onChangeStatusProject = async (id, type, status) => {
    const permission = checkPermissionsByModule(roles, 101, 4);
    if (permission) {
      Swal.fire({
        icon: "warning",
        title: "Are you sure?",
        text: `${
          type == 1
            ? `You want to ${status == 0 ? "Play Project" : "Pause Project"}`
            : "You want to change status."
        }`,
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);
          const formEntries = {
            user_id: localStorage.getItem("auth_id"),
            role_id: secureLocalStorage.getItem("auth_role"),
            project_id: id,
            status: status,
            type: type,
          };
          const response = await FetchPostData(
            toggleLoading,
            "changeDigitalProjectStatus",
            "",
            formEntries
          );
          if (response.data?.success) {
            Swal.fire({
              icon: "success",
              title: "Project Status Updated Successfully",
            }).then((data) => {
              setData(response?.data?.data);
              setAllData(response?.data?.data);
            });
          }
        }
      });
    } else {
      setAccessDenied(true);
    }
  };

  // On Delete project
  const onDelete = async (id) => {
    const permission = checkPermissionsByModule(roles, 101, 5);
    if (permission) {
      Swal.fire({
        title: "Are you sure?",
        text: "you want to delete this Project!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const formDatas = {
            project_id: id,
            user_id: localStorage?.getItem("auth_id"),
          };
          const response = await FetchPostData(
            toggleLoading,
            "deleteDigitalProjects",
            secureLocalStorage.getItem("auth_token"),
            formDatas
          );
          if (response?.data?.success) {
            Swal.fire({
              icon: "success",
              text: "Project deleted successfully",
            });
            setData(response?.data?.data);
          }
        }
      });
    } else {
      setAccessDenied(true);
    }
  };

  const onEditPermission = async (id) => {
    const permission = checkPermissionsByModule(roles, 101, 4);
    if (permission) {
      navigate(`${configure.appUrl}digital_marketing/edit_project/${id}`);
    } else {
      setAccessDenied(true);
    }
  };
  const onViewPermission = (obj) => {
    setProjectDetail(obj);
    const ids = obj?.project_type?.split(",");
    setServiceTask(
      DigitalServiceOptionsDrop?.filter((ser) => ids?.includes(ser?.value))
    );
    setmodal_fullscreen(true);
  };

  const columns = useMemo(
    () =>
      digitalColumns(
        onDelete,
        onEditPermission,
        onViewPermission,
        onChangeStatusProject
      ),
    []
  );

  const toggleLoading = (loading) => {
    setLoading(loading);
  };
  const fatchAllTabData = async () => {
    setLoading(true);
    const authToken = secureLocalStorage.getItem("auth_token");
    const formEntries = {
      role_id: secureLocalStorage.getItem("auth_role"),
      authId: localStorage.getItem("auth_id"),
    };
    const response = await FetchPostData(
      toggleLoading,
      "digitalProjects",
      authToken,
      formEntries
    );
    if (response?.data?.success) {
      setData(response?.data?.data);
      setAllData(response?.data?.data);
    }
  };

  useEffect(() => {
    fatchAllTabData();
  }, []);

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="">
        <Container fluid>
          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <div className="responsive-table">
                    <TableContainer
                      columns={columns}
                      data={data}
                      setData={setData}
                      setAccessDenied={setAccessDenied}
                      allData={allData}
                      roles={roles}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal size="xl" isOpen={modal_fullscreen} className="modal-fullscreen">
        <div className="modal-header mb-0">
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ width: "100%" }}
          >
            <div className="col-6 d-flex align-items-center">
              <h5 className=" modal-title mt-0">View Project Details</h5>
            </div>
          </div>

          <button
            type="button"
            onClick={() => {
              setmodal_fullscreen(false);
            }}
            className="ms-4"
            style={{ border: "none", backgroundColor: "transparent" }}
          >
            <span
              aria-hidden="true"
              style={{ color: "#bf1e30", fontSize: "28px", lineHeight: "1px" }}
            >
              {" "}
              &times;{" "}
            </span>
          </button>
        </div>

        <div className="modal-body hide-bottom-scrollbar ps-3 pe-0 pt-0">
          <Row className="gx-4 justify-content-between">
            <Col md="2" className="side-tab h-100 py-3 bd-fixed-sidetab">
              <Nav pills className="flex-column">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleVertical("1");
                    }}
                  >
                    Basic Project Details
                  </NavLink>
                </NavItem>
                {serviceTask?.map((obj, i) => {
                  return (
                    <NavItem key={i}>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "mb-2": true,
                          active: verticalActiveTab === obj?.tabId,
                        })}
                        onClick={() => {
                          toggleVertical(obj?.tabId);
                        }}
                      >
                        Task - {obj?.label}
                      </NavLink>
                    </NavItem>
                  );
                })}
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "4",
                    })}
                    onClick={() => {
                      toggleVertical("4");
                    }}
                  >
                    Notes
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "6",
                    })}
                    onClick={() => {
                      toggleVertical("6");
                    }}
                  >
                    Credential
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "5",
                    })}
                    onClick={() => {
                      toggleVertical("5");
                    }}
                  >
                    Activity Log
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col md="10" className="bd-right-side-tab">
              <div className="px-4 h-100 pl-0">
                <TabContent
                  activeTab={verticalActiveTab}
                  className="text-muted mt-4 mt-md-0"
                >
                  <TabPane tabId="1">
                    <DmViewBasicProjectDetails
                      verticalActiveTab={verticalActiveTab}
                      projectDetail={projectDetail}
                    />
                  </TabPane>

                  <TabPane tabId="4">
                    <DmViewNotes
                      verticalActiveTab={verticalActiveTab}
                      projectDetail={projectDetail}
                    />
                  </TabPane>
                  <TabPane tabId="3">
                    <DmTasks
                      verticalActiveTab={verticalActiveTab}
                      type="edit"
                      projectDetail={projectDetail}
                    />
                  </TabPane>
                  <TabPane tabId="7">
                    <DmTasks
                      verticalActiveTab={verticalActiveTab}
                      type="edit"
                      projectDetail={projectDetail}
                    />
                  </TabPane>
                  <TabPane tabId="8">
                    <DmTasks
                      verticalActiveTab={verticalActiveTab}
                      type="edit"
                      projectDetail={projectDetail}
                    />
                  </TabPane>
                  <TabPane tabId="9">
                    <DmTasks
                      verticalActiveTab={verticalActiveTab}
                      type="edit"
                      projectDetail={projectDetail}
                    />
                  </TabPane>
                  <TabPane tabId="6">
                    <DmCredentials
                      verticalActiveTab={verticalActiveTab}
                      type="edit"
                      projectDetail={projectDetail}
                    />
                  </TabPane>
                  <TabPane tabId="5">
                    <DmViewActivityLogs
                      verticalActiveTab={verticalActiveTab}
                      type="edit"
                      projectDetail={projectDetail}
                    />
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>

      {/* Access Denied */}
      <AccessDeniedPopup
        modal_Access={modal_Access}
        setAccessDenied={setAccessDenied}
      />
    </React.Fragment>
  );
};

export default SeoIndex;
