import React, { useState, useEffect, useRef } from "react";
import { Col, Container, Form, Input, Label, Modal, Row } from "reactstrap";
import { useDispatch } from "react-redux";
import { setApiData } from "../../store/Roles/actions";
import axios from "axios";
import Loader from "pages/Separate/Loader";
import configure from "configure";
import secureLocalStorage from "react-secure-storage";
import ReactGridLayout from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import WelcomeComp from "./WelcomeComp";
import FavoriteLead from "./FavoriteLead";
import Absentees from "pages/Elements/Dashboard/Absentees";
import GeneralTask from "./GeneralTask";
import ActivityComponent from "pages/Elements/Dashboard/ActivityComponent";
import SwipeAndIssues from "pages/Elements/Dashboard/SwipeAndIssues";
import StaffDocApprovel from "./StaffDocApprovel";
import addGsImg from "../../assets/images/settingImg/addGs.jpg";
import AccessDeniedPopup from "routes/AccessDeniedPopup";
import IndiaFlag from "../../assets/images/flags/india.png";
import UkFlag from "../../assets/images/flags/uk.png";
import creditSafeImg from "../../assets/images/creditsafe/icon-2.png";
import CanadaFlag from "../../assets/images/flags/canadian_flag.jpg";
import WaitImg from "../../assets/images/Hourglass.gif";
import DashImg from "../../assets/images/Dash-gif.gif";
import moment from "moment";
import { fetchData, FetchPostData } from "utils/common-fetchData";
import {
  allElements,
  calculateLayout,
  layoutConfig,
} from "utils/elements/element-config";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import DeveloperGit from "pages/Elements/Dashboard/DeveloperGit";
import WorkDuration from "pages/Elements/Dashboard/WorkDuration";
import AppliedLeaves from "pages/Elements/Dashboard/AppliedLeaves";
import LoginClear from "pages/Elements/Dashboard/LoginClear";
import FlawsGap from "pages/Elements/Human Resource/FlawsGap";
import { shiftIdToEnd } from "utils/common-helpers";
import Notification from "pages/Elements/Notification/Notification";
import { setElementData } from "store/elements/actions";
import FlowsList from "pages/Elements/Human Resource/FlowsList";

const DragDashboard = () => {
  const dispatch = useDispatch();
  const assignElement = useSelector((state) => state?.Elements?.element);
  const allowedIds =
    assignElement?.length != 0 ? assignElement?.split(",") : [];
  document.title = "Dashboard | Zithas Technologies";
  const [isLoading, setLoading] = useState(false);
  const [isLeadAccess, setLeadAccess] = useState(false);
  const [isLeadEdit, setLeadEdit] = useState(false);
  const [modal_Access, setAccessDenied] = useState(false);
  const [addTab, setAddTab] = useState(false);
  const [editTab, setEditTab] = useState(false);
  const [Elements, setElements] = useState(allElements);
  const [isEmpty, setIsEmpty] = useState(null);
  useEffect(() => {
    LeadData();
  }, []);


  const [potential_data, setPotentialData] = useState([]);
  const [tab_name, setTabName] = useState("");


  const LeadData = async (e) => {
    const formData = new FormData();
    setLoading(true);
    try {
      const bearerToken = secureLocalStorage.getItem("auth_token");
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/get_lead_potential`,
        headers: {
          Authorization: "Bearer " + bearerToken,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      }).then((response) => {
        setPotentialData(response.data.data);
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const findLeadAccess = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("user_id", localStorage.getItem("auth_id"));
    formData.append("module_id", 17);
    const response = await axios({
      method: "post",
      url: `${configure.apiUrl}/earth_permission`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    });
    if (response) {
      setLoading(false);
      if (response.data.data.india[2] == 1 || response.data.data.uk[2] == 1) {
        setLeadAccess(true);
      }
      if (response.data.data.india[4] == 1 || response.data.data.uk[4] == 1) {
        setLeadEdit(true);
      }
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    findLeadAccess();
  }, []);
  const [tabsName, setTabsName] = useState("Default Dashboard");
  const [currentTab, setCurrentTab] = useState("1");

  const onChangeTabs = (tabs, name) => {
    setTabsName(name);
    setCurrentTab(tabs);
    const tab = allTabs?.find((tab) => tab?.id == tabs);
    if (!tab) return;
    const selectedIds =
      tab?.component != undefined
        ? tab?.component?.map((item) => Number(item.id))
        : [];
    const updatedArray = allElements.map((group) => ({
      ...group,
      inner: group.inner.map((item) => ({
        ...item,
        checked: selectedIds.includes(item.id) ? true : item.checked,
      })),
    }));
    setElements(updatedArray);
  };

  const [layoutBoxWidth, setlayoutBoxWidth] = useState("");

  useEffect(() => {
    const updateWidth = () => {
      const viewportWidth = window.innerWidth;

      let multiplier = 0.93;
      if (viewportWidth >= 1440 && viewportWidth <= 1600) {
        multiplier = 0.94;
      }

      setlayoutBoxWidth(viewportWidth * multiplier);
    };

    updateWidth();
    window.addEventListener("resize", updateWidth);

    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  const [classname, setClassname] = useState(true);
  const renderComponents = (item) => {
    const { id, x, y, w, h, minW, minH, maxH } = item;
    switch (id) {
      case 1:
        return (
          <div
            key="welcomeComp"
            className="single_comp p-0"
            data-grid={{ i: "welcomeComp", x, y, w, h, minW, minH, maxH }}
          >
            <div className="moveIcon">
              <i
                className="bi bi-arrows-move cursor-pointer drag-handle"
                title="Drag Me"
              ></i>
            </div>
            <div className="inner_component">
              <WelcomeComp />
            </div>
          </div>
        );
      case 2:
        return (
          <div
            key="favoriteLead"
            className="single_comp p-0"
            data-grid={{ i: "welcomeComp", x, y, w, h, minW, minH, maxH }}
          >
            <div className="moveIcon">
              <i
                className="bi bi-arrows-move cursor-pointer drag-handle"
                title="Drag Me"
              ></i>
            </div>
            <div className="inner_component">
              {isLeadAccess && (
                <FavoriteLead isLeadEdit={isLeadEdit} cls={classname} />
              )}
            </div>
          </div>
        );
      case 3:
        return (
          <div
            key="absentees"
            className="single_comp p-0"
            data-grid={{ i: "welcomeComp", x, y, w, h, minW, minH, maxH }}
          >
            <div className="moveIcon mb-1">
              <i
                className="bi bi-arrows-move cursor-pointer drag-handle"
                title="Drag Me"
              ></i>
            </div>
            <div className="inner_component">
              <Absentees />
            </div>
          </div>
        );
      case 10:
        return (
          <div
            key="generalTask"
            className="single_comp p-0"
            data-grid={{ i: "welcomeComp", x, y, w, h, minW, minH, maxH }}
          >
            <div className="moveIcon">
              <i
                className="bi bi-arrows-move cursor-pointer drag-handle"
                title="Drag Me"
              ></i>
            </div>
            <div className="inner_component">
              <GeneralTask />
            </div>
          </div>
        );
      case 5:
        return (
          <div
            key="activityComponent"
            className="single_comp p-0"
            data-grid={{ i: "welcomeComp", x, y, w, h, minW, minH, maxH }}
          >
            <div className="moveIcon">
              <i
                className="bi bi-arrows-move cursor-pointer drag-handle"
                title="Drag Me"
              ></i>
            </div>
            <div className="inner_component">
              <ActivityComponent />
            </div>
          </div>
        );
      case 6:
        return (
          <div
            key="swipeAndIssues"
            className="single_comp p-0"
            data-grid={{ i: "welcomeComp", x, y, w, h, minW, minH, maxH }}
          >
            <div className="moveIcon">
              <i
                className="bi bi-arrows-move cursor-pointer drag-handle"
                title="Drag Me"
              ></i>
            </div>
            <div className="inner_component">
              <SwipeAndIssues />
            </div>
          </div>
        );
      case 8:
        return (
          <div
            key="leadPotential"
            className="single_comp p-0"
            data-grid={{ i: "welcomeComp", x, y, w, h, minW, minH, maxH }}
          >
            <div className="moveIcon">
              <i
                className="bi bi-arrows-move cursor-pointer drag-handle"
                title="Drag Me"
              ></i>
            </div>
            <div className="inner_component">
              <section>
                {localStorage.getItem("auth_id") == 32 ||
                  localStorage.getItem("auth_id") == 4 ||
                  localStorage.getItem("auth_id") == 1 ||
                  localStorage.getItem("auth_id") == 140 ? (
                  <div className="mt-2">
                    <div className="card">
                      <div className="card-body ">
                        <div className="mb-3 text-purple fs-5 mb-0 fw-bold">
                          Lead Potentials
                        </div>
                        <div className="table-responsive">
                          <table className="table table-bordered mb-0 table">
                            <thead>
                              <tr>
                                <th>Lead From</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th style={{ width: "3%" }}>Mobile No.</th>
                                <th>Industry</th>
                                <th>Service</th>
                                <th style={{ width: "13%" }}>Message</th>
                                <th style={{ width: "5%" }}>Date</th>
                                <th style={{ width: "5%" }}>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {potential_data?.map((items, i) => {
                                let backColor;
                                if (items.lead_type == "Hot") {
                                  backColor = "bd-hot-follow";
                                } else if (items.lead_type == "Cold") {
                                  backColor = "bd-cold";
                                } else if (items.lead_type == "Warm") {
                                  backColor = "bd-warm";
                                }

                                const onChangeStatus = async (e) => {
                                  swal({
                                    title: "Are you sure?",
                                    text: "You Want to Remove Lead from list !",
                                    icon: "warning",
                                    buttons: true,
                                    dangerMode: true,
                                  }).then(async (willDelete) => {
                                    if (willDelete) {
                                      setLoading(true);
                                      const formData = new FormData();
                                      formData.append("client_id", items.id);
                                      try {
                                        const response = await axios({
                                          method: "post",
                                          url: `${configure.apiUrl}/change_status`,
                                          headers: {
                                            Accept: "application/json",
                                            "Content-Type":
                                              "multipart/form-data",
                                          },
                                          data: formData,
                                        }).then((response) => {
                                          setPotentialData(response.data.data);
                                          setLoading(false);
                                        });
                                      } catch (err) {
                                        console.log(err);
                                        setLoading(false);
                                      }
                                    }
                                  });
                                };

                                let LeadFrom;
                                if (items.location == 1) {
                                  LeadFrom = (
                                    <div className="d-flex align-items-center justify-content-center">
                                      <img
                                        src={IndiaFlag}
                                        alt="No Img"
                                        className="me-2"
                                        width="20"
                                        height="15"
                                      />
                                      <i
                                        className="fas fa-globe"
                                        style={{
                                          fontSize: "17px",
                                          color: "black",
                                        }}
                                      ></i>
                                    </div>
                                  );
                                } else if (items.location == 2) {
                                  LeadFrom = (
                                    <div className="d-flex align-items-center justify-content-center">
                                      <img
                                        src={UkFlag}
                                        alt="No Img"
                                        className="me-2"
                                        width="20"
                                        height="15"
                                      />
                                      <i
                                        className="fas fa-globe"
                                        style={{
                                          fontSize: "17px",
                                          color: "black",
                                        }}
                                      ></i>
                                    </div>
                                  );
                                } else if (items.location == 3) {
                                  LeadFrom = (
                                    <div className="d-flex align-items-center justify-content-center">
                                      <img
                                        src={CanadaFlag}
                                        alt="No Img"
                                        className="me-2"
                                        width="20"
                                        height="15"
                                      />
                                      <i
                                        className="fas fa-globe"
                                        style={{
                                          fontSize: "17px",
                                          color: "black",
                                        }}
                                      ></i>
                                    </div>
                                  );
                                } else if (items.location == 11) {
                                  LeadFrom = (
                                    <div className="d-flex align-items-center justify-content-center">
                                      <i
                                        className="fas fa-utensils text-success me-2"
                                        style={{ fontSize: "17px" }}
                                      ></i>
                                      <i
                                        className="fas fa-globe"
                                        style={{
                                          fontSize: "17px",
                                          color: "black",
                                        }}
                                      ></i>
                                    </div>
                                  );
                                } else if (items.location == 12) {
                                  LeadFrom = (
                                    <div className="d-flex align-items-center justify-content-center">
                                      <img
                                        src={UkFlag}
                                        alt="No Img"
                                        className="me-2"
                                        width="20"
                                        height="15"
                                      />
                                      <img
                                        src={creditSafeImg}
                                        title="creditSafe"
                                        alt="No Img"
                                        className=""
                                        width="20"
                                        height="15"
                                      />
                                    </div>
                                  );
                                }

                                if (localStorage.getItem("auth_id") == 140) {
                                  if (items.location == 11) {
                                    return (
                                      <tr key={i} className={backColor}>
                                        <td>{LeadFrom}</td>
                                        <td scope="row">{items.name}</td>
                                        <td>{items.email}</td>
                                        <td>{items.contact}</td>
                                        <td>
                                          {items.industry != null
                                            ? items.industry
                                            : "-"}
                                        </td>
                                        <td>
                                          {items.service != null
                                            ? items.service
                                            : "-"}
                                        </td>
                                        <td>
                                          {items.message != null
                                            ? items.message
                                            : "-"}
                                        </td>
                                        <td>
                                          {moment(items.created_at).format(
                                            "DD-MM-YYYY hh:mm:ss"
                                          )}
                                        </td>
                                        <td className="text-center">
                                          <a
                                            title="view lead"
                                            className="btn btn-sm btn-outline-secondary me-1"
                                            onClick={onChangeStatus}
                                          >
                                            <i className="fas fa-check"></i>
                                          </a>
                                        </td>
                                      </tr>
                                    );
                                  }
                                } else {
                                  return (
                                    <tr key={i} className={backColor}>
                                      <td>{LeadFrom}</td>
                                      <td scope="row">{items.name}</td>
                                      <td>{items.email}</td>
                                      <td>{items.contact}</td>
                                      <td>
                                        {items.industry != null
                                          ? items.industry
                                          : "-"}
                                      </td>
                                      <td>
                                        {items.service != null
                                          ? items.service
                                          : "-"}
                                      </td>
                                      <td>
                                        {items.message != null
                                          ? items.message
                                          : "-"}
                                      </td>
                                      <td>
                                        {moment(items.created_at).format(
                                          "DD-MM-YYYY hh:mm:ss"
                                        )}
                                      </td>
                                      <td className="text-center">
                                        <a
                                          title="view lead"
                                          className="btn btn-sm btn-outline-secondary me-1"
                                          onClick={onChangeStatus}
                                        >
                                          <i className="fas fa-check"></i>
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                }
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </section>
            </div>
          </div>
        );
      case 9:
        return (
          <div
            key="documentApproval"
            className="single_comp p-0"
            data-grid={{ i: "welcomeComp", x, y, w, h, minW, minH, maxH }}
          >
            {secureLocalStorage.getItem("auth_role") == 4 ||
              secureLocalStorage.getItem("auth_role") == 1 ||
              secureLocalStorage.getItem("auth_role") == 11 ||
              localStorage.getItem('auth_id') == 4 ? (
              <div className="inner_component">
                <section>
                  <div className="moveIcon">
                    <i className="bi bi-arrows-move cursor-pointer drag-handle"></i>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <StaffDocApprovel />
                    </div>
                  </div>
                </section>
              </div>
            ) : (
              ""
            )}
          </div>
        );
      case 11:
        return (
          <div
            key="appliedLeaves"
            className="single_comp p-0"
            data-grid={{ i: "appliedLeaves", x, y, w, h, minW, minH, maxH }}
          >
            <div className="moveIcon">
              <i
                className="bi bi-arrows-move cursor-pointer drag-handle"
                title="Drag Me"
              ></i>
            </div>
            <div className="inner_component">
              <AppliedLeaves />
            </div>
          </div>
        )
      case 12:
        return (
          <div
            key="workduration"
            className="single_comp p-0"
            data-grid={{ i: "workduration", x, y, w, h, minW, minH, maxH }}
          >
            <div className="moveIcon mb-1">
              <i
                className="bi bi-arrows-move cursor-pointer drag-handle"
                title="Drag Me"
              ></i>
            </div>
            <div className="inner_component">
              <WorkDuration />
            </div>
          </div>
        )
      case 13:
        return (
          <div
            key="gitPush"
            className="single_comp p-0"
            data-grid={{ i: "gitPush", x, y, w, h, minW, minH, maxH }}
          >
            <div className="moveIcon mb-1">
              <i
                className="bi bi-arrows-move cursor-pointer drag-handle"
                title="Drag Me"
              ></i>
            </div>
            <div className="inner_component">
              <DeveloperGit />
            </div>
          </div>
        )
      case 14:
        return (
          <div
            key="clearlogin"
            className="single_comp p-0"
            data-grid={{ i: "clearlogin", x, y, w, h, minW, minH, maxH }}
          >
            <div className="moveIcon mb-1">
              <i
                className="bi bi-arrows-move cursor-pointer drag-handle"
                title="Drag Me"
              ></i>
            </div>
            <div className="inner_component">
              <LoginClear />
            </div>
          </div>
        )
      case 15:
        return (
          <div
            key="flawGap"
            className="single_comp p-0"
            data-grid={{ i: "flawGap", x, y, w, h, minW, minH, maxH }}
          >
            <div className="moveIcon mb-1">
              <i
                className="bi bi-arrows-move cursor-pointer drag-handle"
                title="Drag Me"
              ></i>
            </div>
            <div className="inner_component">
              <FlawsGap />
            </div>
          </div>
        )
      case 16:
        return (
          <div
            key="notify"
            className="single_comp p-0"
            data-grid={{ i: "notify", x, y, w, h, minW, minH }}
          >
            <div className="moveIcon mb-1">
              <i
                className="bi bi-arrows-move cursor-pointer drag-handle"
                title="Drag Me"
              ></i>
            </div>
            <div className="inner_component">
              <Notification />
            </div>
          </div>
        )
        case 17:
        return (
          <div
            key="flawList"
            className="single_comp p-0"
            data-grid={{ i: "flawList", x, y, w, h, minW, minH }}
          >
            <div className="moveIcon mb-1">
              <i
                className="bi bi-arrows-move cursor-pointer drag-handle"
                title="Drag Me"
              ></i>
            </div>
            <div className="inner_component">
              <FlowsList />
            </div>
          </div>
        )
      default:
        return null;
    }
  };

  const [tabsShows, setTabShows] = useState([]);
  const [currentTabData, setCurrentTabData] = useState([]);
  const [isSaving, setIsSaving] = useState(null);

  useEffect(() => {
    if (tabsShows?.length != 0) {
      setCurrentTabData(tabsShows.find((tab) => tab.id == currentTab));
    } else {
      setCurrentTabData(null);
    }
  }, [tabsShows, currentTab]);

  const onLayoutChange = (newLayout) => {
    const updatedTabsData = tabsShows.map((tab) => {
      if (tab.id == currentTab) {
        const updatedComponents = tab?.component?.map((component) => {
          const matchingLayoutItem = newLayout.find(
            (layoutItem) => layoutItem.i === component.i
          );
          if (matchingLayoutItem) {
            return {
              ...component,
              x: matchingLayoutItem.x,
              y: matchingLayoutItem.y,
              w: matchingLayoutItem.w,
              h: matchingLayoutItem.h,
            };
          }
          return component;
        });
        return {
          ...tab,
          component: updatedComponents,
        };
      }
      return tab;
    });
    setTabShows(updatedTabsData);
  };
  const toggleLoading = (loading) => {
    setLoading(loading);
  };

  useEffect(() => {
    const onGetDashboardTab = async () => {
      const token = secureLocalStorage.getItem("auth_token");
      const user_id = localStorage.getItem("auth_id");
      const res = await fetchData(
        toggleLoading,
        `getDashboardPositions/${user_id}`,
        token
      );
      if (res.success) {
        const dashboardLayout = JSON.parse(res.data?.positions);
        setTabShows(dashboardLayout);
        setAllTabs(dashboardLayout);
        const updatedArray = Elements.map((tab) => {
          const matchedOutput = dashboardLayout.find((o) =>
            o.component.some((comp) =>
              tab.inner.some((innerItem) => innerItem.id == comp.id)
            )
          );
          if (matchedOutput) {
            const updatedInner = tab.inner.map((innerItem) => {
              const isChecked = matchedOutput.component.some(
                (comp) => comp.id == innerItem.id
              );
              return {
                ...innerItem,
                checked: isChecked,
              };
            });
            return {
              ...tab,
              inner: updatedInner,
            };
          }
          return tab;
        });
        setElements(updatedArray);
      } else {
        setIsEmpty(true);
        setTabShows([
          {
            id: 1,
            name: "Default Dashboard",
            elements: [],
            component: [],
          },
        ]);
        setAllTabs([
          {
            id: 1,
            name: "Default Dashboard",
            elements: [],
            component: [],
          },
        ]);
      }
    };
    onGetDashboardTab();
  }, []);

  const toggleSaving = (save) => {
    setIsSaving(save);
    if (save == false) {
      setTimeout(function () {
        setIsSaving(null);
      }, 2000);
    }
  };
  const onSaveDashboards = async (e) => {
    e.preventDefault();
    Swal.fire({
      icon: "warning",
      title: "Are You Sure?",
      text: "You want to save dashboard?",
      showCancelButton: true,
      confirmButtonColor: "#752a97",
    }).then(async (add) => {
      if (add.isConfirmed) {
        const token = secureLocalStorage.getItem("auth_token");
        const formEntries = {
          user_id: localStorage.getItem("auth_id"),
          dashboard_data: JSON.stringify(shiftIdToEnd(tabsShows)),
        };
        const res = await FetchPostData(
          toggleSaving,
          "addDashboardPositions",
          token,
          formEntries
        );
      }
    });
  };

  const [allTabs, setAllTabs] = useState([]);
  const addElementsToTab = (tabId) => {
    const selectedIds = Elements.reduce((acc, group) => {
      const uncheckedIds = group.inner
        .filter((item) => item.checked)
        .map((item) => item.id);

      const filteredIds = uncheckedIds.filter(
        (id) =>
          !allTabs.some(
            (tab) =>
              tab.id == tabId &&
              tab.component?.some((component) => component.id == id)
          )
      );
      return [...acc, ...filteredIds];
    }, []);

    const selectedElements = Elements.flatMap((category) =>
      category.inner.filter((item) => item.checked)
    );
    const filteredLayout = layoutConfig.filter((item) =>
      selectedIds.includes(item.id)
    );
    const updatedLayouts = calculateLayout(filteredLayout);

    const updateTabs = (tabs) =>
      tabs.map((tab) =>
        tab.id == tabId
          ? {
            ...tab,
            name: tab_name,
            elements: selectedElements,
            component: [
              ...(tab.component || []).filter((component) =>
                selectedElements.some(
                  (element) => element.id == component.id && element.checked
                )
              ),
              ...updatedLayouts.filter((layout) =>
                selectedElements.some(
                  (element) => element.id == layout.id && element.checked
                )
              ),
            ],
          }
          : tab
      );

    const resetElements = Elements.map((category) => ({
      ...category,
      inner: category.inner.map((item) => ({
        ...item,
        checked: selectedElements?.some((i) => i?.id == item.id) ? true : false,
      })),
    }));
    setAllTabs(updateTabs);
    setTabShows(updateTabs);
    setElements(resetElements);
    setEditTab(false);
  };

  const handleElmSelection = (groupId, elmId, isChecked) => {
    setElements((prev) =>
      prev.map((group) =>
        group.id == groupId
          ? {
            ...group,
            inner: group.inner.map((element) =>
              element.id == elmId
                ? { ...element, checked: isChecked }
                : element
            ),
          }
          : group
      )
    );
  };

  // Drag & Drop Feature

  const dragItem = useRef();
  const dragOverItem = useRef();

  const dragStart = (e, position) => {
    if (position === 0) return;
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    if (position === 0) return;
    dragOverItem.current = position;
  };

  const drop = (e) => {
    if (dragItem.current === null || dragOverItem.current === null) return;
    const copyListItems = [...allTabs];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setAllTabs(copyListItems);
  };
  const onRemoveTab = (e) => {
    Swal.fire({
      icon: "warning",
      title: "Are You Sure?",
      text: "You want to remove this tab?",
      showCancelButton: true,
      confirmButtonColor: "#752a97",
    }).then(async (confirm) => {
      if (confirm.isConfirmed) {
        const token = secureLocalStorage.getItem("auth_token");
        const formEntries = {
          user_id: localStorage.getItem("auth_id"),
          dashboard_data: JSON.stringify(
            shiftIdToEnd(allTabs.filter((tab) => tab.id != currentTab))
          ),
        };
        const res = await FetchPostData(
          toggleLoading,
          "addDashboardPositions",
          token,
          formEntries
        );
        if (res?.data?.success) {
          setCurrentTab(1);
          setEditTab(false);
          setAllTabs(allTabs.filter((tab) => tab.id != currentTab));
          setTabShows(tabsShows.filter((tab) => tab.id != currentTab))
        }
      }
    });
  };

  const onAddNewTab = async (e) => {
    e.preventDefault()
    const alltabShows = [
      ...allTabs,
      {
        id: parseFloat(allTabs?.slice(-1)[0]?.id) + 1,
        name: tab_name,
        component: [],
        elements: []
      },
    ]
    const ShowTabs = [
      ...tabsShows,
      {
        id: parseFloat(allTabs?.slice(-1)[0]?.id) + 1,
        name: tab_name,
        component: [],
        elements: []
      },
    ]
    const token = secureLocalStorage.getItem('auth_token')
    const formEntries = {
      user_id: localStorage.getItem('auth_id'),
      dashboard_data: JSON.stringify(shiftIdToEnd(ShowTabs))
    }
    const res = await FetchPostData(toggleLoading, "addDashboardPositions", token, formEntries);
    setTabShows(ShowTabs)
    setAllTabs(alltabShows)
    setAddTab(false)
  }

  // To update the user Data

  useEffect(() => {
    const newToggle = () => { }
    const getUpdateData = async () => {
      const userId = localStorage.getItem('auth_id');
      const role = secureLocalStorage.getItem("auth_role");
      const token = secureLocalStorage.getItem("auth_token");
      const res = await fetchData(newToggle, `userUpdatedData/${userId}/${role}`, token);
      if (res?.success) {
        dispatch(setApiData(res?.data?.permission))
        dispatch(setElementData(res.data?.components))
      }
    }
    getUpdateData()
  }, [])

  return (
    <div>
      <React.Fragment>
        {isLoading && <Loader />}
        <div className="page-content drag_drop_comp ">
          <Container fluid>
            <div className="d-flex gap-3 justify-content-between align-items-center w-100 mb-3">
              <div className="d-flex gap-3 dashboard-tabs">
                {allTabs?.map((obj, i) => {
                  return (
                    <div
                      className={`tabs ${currentTab == obj.id ? "active" : " "
                        } added-tabs`}
                      key={i}
                      onDragStart={(e) => dragStart(e, i)}
                      onDragEnter={(e) => dragEnter(e, i)}
                      onDragEnd={drop}
                      draggable={i !== 0}
                    >
                      <a
                        className="d-flex align-items-center justify-content-between gap-3"
                        onClick={(e) => {
                          setCurrentTab(obj.id);
                          onChangeTabs(obj?.id, obj?.name);
                        }}
                      >
                        {obj?.name}
                        <a
                          className="edit-dashboard"
                          onClick={() => {
                            setTabName(obj.name);
                            setEditTab(true);
                          }}
                          title="Edit Elements"
                        >
                          <i className="fas fa-pen "></i>
                        </a>
                      </a>
                    </div>
                  );
                })}
              </div>
              <div className="add-dahsboard-tab ">
                <div className="text-center">
                  <a
                    className="tabs active"
                    title="Add Dashboard"
                    onClick={() => setAddTab(true)}
                  >
                    <i className="fas fa-plus "></i>
                  </a>
                </div>
                <div className="text-center ms-3">
                  <a
                    className={`loading-button tabs active position-relative 
                  ${isSaving
                        ? "loading"
                        : isSaving == false
                          ? "success  bg-success"
                          : ""
                      }`}
                    onClick={onSaveDashboards}
                    title="Save Dashboards"
                  >
                    <span>
                      <i className="fas fa-save"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </Container>
          <Container fluid className="drag-width">
            {currentTabData?.component?.length !== 0 &&
              currentTabData?.component != undefined ? (
              <ReactGridLayout
                className="layout custom_layout_cls"
                onLayoutChange={onLayoutChange}
                cols={12}
                rowHeight={30}
                width={layoutBoxWidth}
                draggableHandle=".drag-handle"
                style={{ padding: "0px" }}
              >
                {/* {currentTabData?.component?.map(item =>  renderComponents(item) )} */}
                {currentTabData?.component?.map((item) => {
                  if (allowedIds.includes(String(item?.id))) {
                    return renderComponents(item);
                  }
                  return null;
                })}
              </ReactGridLayout>
            ) : isEmpty || currentTabData?.component?.length == 0 ? (
              <Col className="mt-5 pt-5">
                <div className="d-flex align-items-center justify-content-center p-5 col-md-6 margin-auto shadow-lg ">
                  <img src={DashImg} alt="No Img" />
                  <div>
                    <h4
                      className="mb-3 text-purple text-center"
                      style={{ lineHeight: "1.4", fontSize: "16px" }}
                    >
                      Woohoo! 🎉 Your new tab is Added!
                    </h4>
                    <h4
                      className="mb-3 text-purple text-center"
                      style={{ lineHeight: "1.4", fontSize: "16px" }}
                    >
                      Customize your dashboard with the elements you need to do
                      your best work. 💼
                    </h4>
                    <h4
                      className="mb-0 text-purple text-center"
                      style={{ lineHeight: "1.4", fontSize: "16px" }}
                    >
                      Ready to create your perfect view?
                    </h4>
                  </div>
                </div>
              </Col>
            ) : currentTabData == undefined ? (
              <Col className="mt-5 pt-5">
                <div className="d-flex align-items-center justify-content-center p-5 col-md-5 margin-auto shadow-lg ">
                  <img src={WaitImg} alt="No Img" />
                  <h4
                    className="mb-0 text-purple text-center lh-lg"
                    style={{ fontSize: "17px" }}
                  >
                    We’re preparing your dashboard elements. <br />
                    Please wait while we load your selections!
                  </h4>
                </div>
              </Col>
            ) : null}
          </Container>
        </div>

        {/* Add Dashboard Tabs */}
        <Modal
          isOpen={addTab}
          toggle={() => {
            tog_center();
          }}
          centered
          size="lg"
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Add New Dashboard Tabs</h5>
            <button
              type="button"
              onClick={() => {
                setAddTab(false);
              }}
              className="btn-modal-close"
            >
              <span aria-hidden="true" className="btn-modal-span">
                &times;
              </span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={(e) => {
                onAddNewTab(e);
              }}
            >
              <Row className="align-items-center">
                <div className="col-4">
                  <img src={addGsImg} className="img-fluid" />
                </div>
                <div className="col-8 ">
                  <div className="mb-3 col-12">
                    <Label htmlFor="formrow-firstname-Input">
                      Dashboard Tab Name
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="formrow-firstname-Input"
                      placeholder="Enter Dashboard Tab Name"
                      required
                      onChange={(e) => {
                        setTabName(e.target.value);
                      }}
                    />
                  </div>
                  <div className="text-end pt-3">
                    <button className=" btn btn-purple btn-label">
                      <i className="fas fa-save me-2 icon-size label-icon"></i>
                      Save
                    </button>
                    <button
                      type="button"
                      onClick={() => setAddTab(false)}
                      className=" btn ms-md-2 btn-red btn-label"
                    >
                      <i className="dripicons-cross me-2 icon-center label-icon"></i>
                      CANCEL
                    </button>
                  </div>
                </div>
              </Row>
            </Form>
          </div>
        </Modal>

        {/* Edit Dashboard Tabs  */}
        <Modal
          isOpen={editTab}
          toggle={() => {
            tog_center();
          }}
          centered
          size="xl"
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              Display Elements for Current Dashboard Tab
            </h5>
            <button
              type="button"
              onClick={() => {
                setEditTab(false);
              }}
              className="btn-modal-close"
            >
              <span aria-hidden="true" className="btn-modal-span">
                &times;
              </span>
            </button>
          </div>
          <div className="modal-body">
            <Form>
              <Row>
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <div className="mb-3 col-5">
                    <Label htmlFor="formrow-firstname-Input">
                      Dashboard Tab Name
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="formrow-firstname-Input"
                      placeholder="Enter Dashboard Tab Name"
                      value={tab_name}
                      onChange={(e) => setTabName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="text-end">
                    <button
                      className=" btn btn-purple btn-label"
                      onClick={(e) => {
                        e.preventDefault();
                        addElementsToTab(currentTab);
                      }}
                    >
                      <i className="fas fa-save me-2 icon-size label-icon"></i>
                      Save
                    </button>
                    {currentTab != 1 ? (
                      <button
                        type="button"
                        className=" btn ms-md-2 btn-red btn-label"
                        onClick={onRemoveTab}
                      >
                        <i className="dripicons-cross me-2 icon-center label-icon"></i>
                        Remove Tab
                      </button>
                    ) : null}
                  </div>
                </div>
                <div>
                  <Row>
                    <div className="col-md-6">
                      {Elements?.slice(0, 2)?.map((obj, i) => {
                        const filteredElements = obj?.inner?.filter((element) =>
                          allowedIds?.includes(String(element?.id))
                        );
                        if (filteredElements?.length > 0) {
                          return (
                            <div key={i} className="mb-4 col-md-10">
                              <h5>{obj?.name}</h5>
                              {obj?.inner
                                ?.filter((element) =>
                                  allowedIds?.includes(String(element?.id))
                                )
                                ?.map((element) => {
                                  return (
                                    <div
                                      className="d-flex justify-space-between align-items-center gap-2 mb-2"
                                      key={element?.id}
                                    >
                                      <span>
                                        <i className="bi bi-arrow-right pe-2"></i>
                                        {element?.name}
                                      </span>
                                      <div className="form-switch form-switch-md">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          checked={element?.checked}
                                          onChange={(e) =>
                                            handleElmSelection(
                                              obj.id,
                                              element.id,
                                              e.target.checked
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          );
                        }
                      })}
                    </div>
                    <div className="col-md-6">
                      {Elements?.slice(2, 6)?.map((obj, i) => {
                        const filteredElements = obj?.inner?.filter((element) =>
                          allowedIds?.includes(String(element?.id))
                        );
                        if (filteredElements?.length > 0) {
                          return (
                            <div key={i} className="mb-4 col-md-10">
                              <h5>{obj?.name}</h5>
                              {obj?.inner
                                ?.filter((element) =>
                                  allowedIds?.includes(String(element?.id))
                                )
                                ?.map((element, id) => {
                                  return (
                                    <div
                                      className="d-flex justify-space-between align-items-center gap-2 mb-2"
                                      key={id}
                                    >
                                      <span>
                                        <i className="bi bi-arrow-right pe-2"></i>{" "}
                                        {element?.name}
                                      </span>
                                      <div className="form-switch form-switch-md">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="customSwitchsizemd"
                                          checked={element?.checked}
                                          onChange={(e) =>
                                            handleElmSelection(
                                              obj.id,
                                              element.id,
                                              e.target.checked
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          );
                        }
                      })}
                    </div>
                  </Row>
                </div>
              </Row>
            </Form>
          </div>
        </Modal>

        <AccessDeniedPopup
          modal_Access={modal_Access}
          setAccessDenied={setAccessDenied}
        />
      </React.Fragment>
    </div>
  );
};

export default DragDashboard;
