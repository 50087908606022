import React, { useEffect, useState } from "react"
import { Card, CardBody, Table } from "reactstrap"
import { fetchData } from "utils/common-fetchData"
import zithas from "../../../assets/images/main-zithas.png"
// import { Tooltip } from "antd"
import { convertSecondsToTime } from "utils/common-helpers"
import Loader from "pages/Separate/Loader"
import configure from "configure"

const WorkDuration = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const toggleLoading = loading => {
    setLoading(loading)
  }
  useEffect(() => {
    const getAbsentees = async () => {
      const response = await fetchData(toggleLoading, "dashboardData/1", "")
      if (response?.success) {
        console.log(response)
        setData(response?.data?.timer_user)
      }
    }
    getAbsentees()
  }, [])
  function getPreviousDate() {
    const today = new Date() // Get the current date
    today.setDate(today.getDate() - 1) // Subtract 1 day from today's date
    return today
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div>
        <Card>
          <CardBody>
            <div className="mb-4 d-flex justify-content-between">
              <p className="text-purple fs-5 mb-0 fw-bold">Work Duration</p>
              <div className="text-purple absent-length">{data?.length}</div>
            </div>
            <div className="responsive-table mb-4">
              <Table className="table table-bordered mb-0">
                <thead>
                  <tr>
                    <th className="header col-2">User</th>
                    <th className="header col-5">Date</th>
                    <th className="text-center header col-4">Hours</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((obj, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          {/* <Tooltip color={"#556de6"} title={obj?.name}> */}
                            <img
                              src={`${
                                obj?.avatar != null
                                  ? `${configure.imgUrl}${obj?.avatar}`
                                  : zithas
                              } `}
                              alt="user Image"
                              className="img-fluid cursor-pointer object-fit-cover"
                              style={{
                                borderRadius: "50px",
                                width: "30px",
                                height: "30px",
                              }}
                              title={obj?.name}
                            />
                          {/* </Tooltip> */}
                        </td>
                        <td>
                          {" "}
                          {getPreviousDate().toISOString().split("T")[0]}
                        </td>
                        <td className="text-end">
                          {convertSecondsToTime(Number(obj?.total_time))}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
              {data?.length == 0 ? (
                <p className="text-center text-purple fw-bold mt-3">
                  🎉 Everyone's working hard! No durations under 5 hours! 💪
                </p>
              ) : null}
            </div>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}

export default WorkDuration
