import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Dropzone from "react-dropzone"
import { Row, Form, Modal, Card, Col, Input, Label } from "reactstrap"
import Select from "react-select"
// editor
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
// assets
import Loader from "pages/Separate/Loader"
import "../../../../assets/css/Sales/Sales.css"
import userImg from "../../../../assets/images/users/avatar-1.jpg"
import axios from "axios"
import swal from "sweetalert"
import moment from "moment"
import configure from "configure"
import Swal from "sweetalert2"
import AddMeeting from "../AddMeeting"
import Draggable, { DraggableCore } from "react-draggable"
import { Resizable, ResizableBox } from "react-resizable"
import AccessDeniedPopup from "routes/AccessDeniedPopup"
import { priorityOption } from "utils/common-options"

const Notes = ({ noteData, assigned, verticalActiveTab, subtasks }) => {
  const [modal_Access, setAccessDenied] = useState(false)
  const [boxWidth, setBoxWidth] = useState(0)
  const [formPopup, setFormPopup] = useState(false)
  const [filterForm, setFilterForm] = useState(false)
  const [visibleCount, setVisibleCount] = useState(5)
  const [fStartDate, setFStartDate] = useState("")
  const [fendDate, setFendDate] = useState("")
  const [fDescription, setFDescription] = useState("")

  const [fixButtons, setFixButtons] = useState(false)
  // const [notesSidebar, setNotesSidebar] = useState(false)
  const [notesSidebar, setNotesSidebar] = useState(false)

  const [edit_status, setEditStatus] = useState(false)
  const [viewFollowUp, setViewFollowUp] = useState(false)
  const [editNotesSidebar, setEditNotesSidebar] = useState(false)
  const [noDrag, setNoDrag] = useState(false)

  const [selectedFiles1, setselectedFiles1] = useState([])
  const [loading, setLoading] = useState(false)
  const [data, setDatas] = useState([])
  const [allData, setAllData] = useState([])
  const navigate = useNavigate()
  const params = useParams()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })
  const [assign_option, setAssignOption] = useState([])
  const minVisibleCount = 15

  useEffect(() => {
    setDatas(noteData)
    setAllData(noteData)
    setAssignOption(
      assigned?.map(obj => ({
        label: obj.name,
        value: obj.id,
      }))
    )
  }, [noteData])


  useEffect(() => {
    if (verticalActiveTab !== "2") {
      setFilterForm(false)
      setFStartDate("")
      setFendDate("")
      setFDescription("")
      setDatas(noteData)
      setAllData(noteData)
      setFormPopup(false)
    }
  }, [verticalActiveTab])

  const onClearFilter = () => {
    if (fStartDate || fendDate || fDescription) {
      setFilterForm(false)
      setFStartDate("")
      setFendDate("")
      setFDescription("")
      setDatas(noteData)
      setAllData(noteData)
      Swal.fire({
        icon: "success",
        text: "Filters cleared successfully!",
      })
    } else {
      Swal.fire({
        icon: "info",
        text: "No filters to clear.",
      }).then(() => {
        setDatas(noteData)
      })
    }
  }

  const handleClose = () => {
    if (fStartDate || fendDate || fDescription) {
      setFilterForm(false)
      setFStartDate("")
      setFendDate("")
      setFDescription("")
      setDatas(noteData)
      setFormPopup(false)
      Swal.fire({
        icon: "success",
        text: "Filter cleared and popup closed.",
      })
    } else {
      setFormPopup(false)
    }
  }

  const [currency_option, setCurrencyOption] = useState([])

  useEffect(() => {
    const fetchCurrency = async () => {
      try {
        const response = await axios({
          method: "get",
          url: `${configure.apiUrl}/currency`,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(response => {
          setCurrencyOption(
            response.data.data.currency?.map(item => ({
              label: item.currency,
              value: item.id,
            }))
          )
        })
      } catch (err) {
        console.log(err)
      }
    }
    fetchCurrency()
  }, [])

  const [currency, setCurrency] = useState("")
  const curentDateTime = moment().format("YYYY-MM-DDTHH:mm")
  const [notedate, setNotedate] = useState(curentDateTime)
  const [amount, setAmount] = useState("")
  const [ckedata, setCkedata] = useState("")
  const [task, setTask] = useState('')
  const [taskDepends, setTaskDepends] = useState(null)
  const [priority, setPriority] = useState(null)
  const [assignee, setAssignee] = useState(null)
  const [generateTask, setGenerateTask] = useState(false)

  const onAddPermission = async () => {
    setNotesSidebar(true)
  }

  const addNotes = async e => {
    e.preventDefault()
    if (ckedata != "") {
      setLoading(true)
      const formData = new FormData()
      formData.append("lead_id", params.id)
      formData.append("user_id", localStorage.getItem("auth_id"))
      formData.append("currency", currency?.value || 0)
      formData.append("date", notedate)
      formData.append("amount", amount)
      formData.append("description", ckedata)
      formData.append("general_status", generateTask ? 1 : 0)
      formData.append("title", taskDepends?.value == "other" ? task : taskDepends?.value)
      formData.append("priority", priority?.value)
      formData.append("assigned", assignee?.map(obj => obj.value)?.join(","))
      formData.append("related", 'sales')
      formData.append("related_to", 'lead')
      formData.append("related_id", params?.id)
      for (let i = 0; i < selectedFiles1.length; i++) {
        formData.append("files[]", selectedFiles1[i])
      }
      try {
        const response = await axios({
          method: "post",
          url: `${configure.apiUrl}/add_lead_note`,
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(response => {
          setLoading(false)
          Swal.fire({
            icon: "success",
            text: "Note Added Successfully",
          }).then(data => {
            setCkedata("")
            setCurrency("")
            setAmount("")
            setDatas(response.data.data)
            setAllData(response.data.data)
            setNotesSidebar(false)
          })
        })
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "Please fill out all mandatory fields.",
      })
    }
  }

  const [tempid, setTempid] = useState("")

  const deleteNote = async (e, note_id) => {
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("module_id", 3)
    try {
      await axios({
        method: "post",
        url: `${configure.apiUrl}/user_permission`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        if (res.data.data.india[5] == 1 || res.data.data.uk[5] == 1) {
          swal({
            title: "Are you sure?",
            text: "You want to Delete this note !",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then(async willdelete => {
            if (willdelete) {
              e.preventDefault()
              setLoading(true)
              const formData = new FormData()
              formData.append("user_id", localStorage.getItem("auth_id"))
              formData.append("lead_id", params.id)
              formData.append("note_id", note_id)
              try {
                await axios({
                  method: "post",
                  url: `${configure.apiUrl}/delete_lead_note`,
                  data: formData,
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                  },
                }).then(res => {
                  setDatas(res.data.data)
                  setAllData(res.data.data)
                  swal({
                    icon: "success",
                    text: "Note Deleted Successfully",
                  }).then(data => {
                    setLoading(false)
                  })
                })
              } catch (error) {
                console.log(error)
                setLoading(false)
              }
            }
          })
        } else {
          setAccessDenied(true)
        }
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  function handleAcceptedFiles1(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles1(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  // code for follow up
  const [followUpAssigned, setFollowUpAssigned] = useState()
  const [followUpPurpose, setFollowUpPurpose] = useState()
  const [followUpDate, setFollowUpDate] = useState()

  const addFollowUp = async e => {
    e.preventDefault()
    const result = followUpAssigned?.map(obj => obj.value).join(",")
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("purpose", followUpPurpose)
    formData.append("assigned", result)
    formData.append("follow_date", followUpDate)
    formData.append("lead_id", params.id)
    formData.append("note_id", tempid)
    try {
      const res = await axios({
        method: "post",
        url: `${configure.apiUrl}/add_lead_followup`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setLoading(false)
        setDatas(res.data.data)
        setAllData(res.data.data)
        swal({
          icon: "success",
          text: "Follow Up Added Successfully",
        }).then(data => {
          setEditStatus(false)
        })
      })
    } catch (error) {
      setLoading(false)
    }
  }

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 50,
    }),
    menu: (provided, state) => ({
      ...provided,
      top: "auto", // Reset the top position
      bottom: "100%", // Position the menu above the select input
    }),
  }

  const [edit_description, setEditDescription] = useState("")
  const [edit_currency, setEditCurrency] = useState([])
  const [edit_amount, setEditAmount] = useState("")
  const [edit_date, setEditDate] = useState("")
  const [note_id, setNoteId] = useState("")
  // console.log(edit_date)

  const editNotes = async e => {
    e.preventDefault()
    if (edit_description != "") {
      setLoading(true)
      const formData = new FormData()
      formData.append("lead_id", params.id)
      formData.append("user_id", localStorage.getItem("auth_id"))
      formData.append("note_id", note_id)
      if (edit_currency != undefined || edit_currency?.value != undefined) {
        formData.append("currency", edit_currency.value)
      } else {
        formData.append("currency", "")
      }
      formData.append("date", edit_date)
      formData.append("amount", edit_amount)
      formData.append("description", edit_description)
      try {
        const response = await axios({
          method: "post",
          url: `${configure.apiUrl}/edit_lead_note`,
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(response => {
          setLoading(false)
          swal({
            icon: "success",
            text: "Note Edited Successfully",
          }).then(data => {
            setEditNotesSidebar(false)
            setDatas(response.data.data)
            setAllData(response.data.data)
          })
        })
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "Please fill out all mandatory fields.",
      })
    }
  }

  const [editFollowdate, setEditFollowDate] = useState("")
  const [editFollowdesc, setEditFollowDesc] = useState("")
  const [editFollowassign, setEditFollowAssign] = useState([])

  const onEditFollowup = async e => {
    e.preventDefault()
    const result = editFollowassign?.map(obj => obj.value).join(",")
    setLoading(true)
    const formData = new FormData()
    formData.append("lead_id", params.id)
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("note_id", note_id)
    formData.append("follow_date", editFollowdate)
    formData.append("purpose", editFollowdesc)
    formData.append("assigned", result)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/edit_lead_followup`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setLoading(false)
        swal({
          icon: "success",
          text: "FollowUp Edited Successfully",
        }).then(data => {
          setViewFollowUp(false)
          setDatas(response.data.data)
          setAllData(response.data.data)
        })
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }
  const normalizeString = str => {
    return str?.replace(/\s+/g, "").toLowerCase() || ""
  }
  const onFilter = e => {
    e.preventDefault()
    // if(fStartDate || fendDate)
    if (fStartDate <= fendDate || fendDate == "" || fStartDate == "") {
      try {
        if (fStartDate || fendDate || fDescription) {
          const filteredByStartDate = fStartDate
            ? allData?.filter(item =>
              moment(item.created_at).isAfter(moment(fStartDate))
            )
            : allData
          const filteredByEndDate = fendDate
            ? filteredByStartDate.filter(item =>
              moment(item.created_at).isBefore(moment(fendDate))
            )
            : filteredByStartDate

          const filteredByDescription = fDescription
            ? filteredByEndDate.filter(item => {
              const normalizedDescription = normalizeString(item.description)
              const normalizedSearchTerm = normalizeString(fDescription)
              return normalizedDescription.includes(normalizedSearchTerm)
            })
            : filteredByEndDate
          // Swal.fire({
          //   icon: "success",
          //   text: "Filter applied successfully!",
          // }).then(() => {
          setFilterForm(true)
          setVisibleCount(15)
          const ascending = filteredByDescription?.sort(
            (a, b) => new Date(a.created_at) - new Date(b.created_at)
          )
          setDatas(ascending)
          // setFormPopup(!formPopup)
          // })
        } else {
          Swal.fire({
            icon: "warning",
            text: "Please enter at least one filter criterion to proceed.",
          })
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "Invalid Date Range",
        text: "End date must be after the start date.",
      })
    }
  }
  const handleSeeMore = () => {
    setVisibleCount(prevCount => {
      const newCount = prevCount + 15
      return Math.min(newCount, data.length)
    })
  }
  const handleSeeLess = () => {
    setVisibleCount(prevCount => {
      const newCount = prevCount - 15
      return Math.max(newCount, minVisibleCount)
    })
  }

  useEffect(() => {
    const updateWidth = () => {
      const viewportWidth = window.innerWidth
      setBoxWidth(viewportWidth * 0.72)
    }
    updateWidth()
    window.addEventListener("resize", updateWidth)
    return () => window.removeEventListener("resize", updateWidth)
  }, [])

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="mb-3">
        <div className="px-3 py-4 bg-white">
          {/* Fixed Buttons */}
          <div
            className={`flex-column fixed-buttons ${fixButtons ? "d-flex" : "d-none"
              }`}
          >
            <a
              onClick={() => onAddPermission()}
              className="btn btn-purple  fix-btn"
            >
              <i className="fas fa-plus me-2"></i>
              <span>Note</span>
            </a>
            <a onClick={() => navigate(-1)} className="btn btn-red  fix-btn">
              <i className="dripicons-cross me-2 icon-center"></i>
              <span>Cancel</span>
            </a>
          </div>
          <div className="d-flex justify-content-between position-relative note-filter">
            <h5 className="text-dark">Add Note</h5>
            <div className="text-end">
              {/* <AddMeeting /> */}
              <button
                onClick={() => setFormPopup(!formPopup)}
                className="btn btn-outline-secondary  me-3"
              >
                <i className="fas fa-filter"></i>
              </button>
              <a
                className="btn btn-save  me-2 btn-label"
                type="button"
                // onClick={() => {
                //   redirectCalender()
                // }}
                href={`${configure.appUrl}calender/lead-${params.id}`}
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-users me-2 label-icon"></i>
                Add Meeting
              </a>
              <a
                onClick={() => onAddPermission()}
                className=" btn btn-save btn-label"
              >
                <i className="fas fa-plus me-2 icon-size label-icon"></i>Add
                Note
              </a>
              <a
                onClick={() => navigate(-1)}
                className=" btn ms-md-2 btn-red btn-label"
              >
                <i className="dripicons-cross me-2 icon-center label-icon"></i>
                CANCEL
              </a>
            </div>

            {formPopup ? (
              <Draggable>
                <ResizableBox
                  width={boxWidth}
                  style={{
                    position: "fixed",
                    top: "28%",
                    right: "6%",
                    zIndex: "1055",
                    backgroundColor: "#fff",
                  }}
                >
                  <div>
                    <Form onSubmit={onFilter}>
                      <Row className="align-items-end">
                        <Row
                          className="d-flex flex-wrap pe-0 me-0 row"
                        // style={{ width: "100%" }}
                        >
                          <div className="col-md-2 mb-2">
                            <label htmlFor="startDate">From Date</label>
                            <input
                              required={fendDate ? true : false}
                              type="date"
                              id="startDate"
                              value={fStartDate}
                              onChange={e => setFStartDate(e.target.value)}
                              className="form-control"
                            />
                          </div>

                          <div className="col-md-2 mb-2">
                            <label htmlFor="endDate">To Date</label>
                            <input
                              type="date"
                              id="endDate"
                              value={fendDate}
                              onChange={e => setFendDate(e.target.value)}
                              className="form-control"
                            />
                          </div>

                          <div className="col-md-4 mb-2">
                            <label htmlFor="">Description</label>
                            <input
                              type="text"
                              id="endDate"
                              value={fDescription}
                              onChange={e => setFDescription(e.target.value)}
                              className="form-control"
                              placeholder="Enter Description"
                            />
                          </div>

                          <div className="col-md-4 mb-2 d-flex flex-wrap justify-content-end">
                            <div className="d-flex align-item-center mt-3">
                              {/* <label htmlFor="">Clear Filters</label> */}
                              <div className="">
                                <button className="btn  btn-purple mr-10 my-1">
                                  <i className="fas fa-search search-icon me-1 icon-center"></i>{" "}
                                  Search
                                </button>
                                <a
                                  className="btn  btn-danger mr-10 my-1"
                                  onClick={onClearFilter}
                                  style={{
                                    fontSize: "11px",
                                    backgroundColor: "#fed36d",
                                    borderColor: "#fed36d",
                                    color: "black",
                                  }}
                                >
                                  Clear Filters
                                </a>

                                <a
                                  className="btn  btn-red my-1"
                                  onClick={e => {
                                    handleClose()
                                  }}
                                >
                                  <i className="dripicons-cross me-1 icon-center"></i>{" "}
                                  Close
                                </a>
                              </div>
                            </div>
                          </div>
                        </Row>
                      </Row>
                    </Form>
                    <i className="bi bi-triangle-fill pop-icon"></i>
                  </div>
                </ResizableBox>
              </Draggable>
            ) : null}
          </div>

          <div
            className={`note-sticky-form ${notesSidebar ? "d-block" : "d-none"
              }`}
          >
            <Draggable
              handle={`${noDrag ? ".modal-header" : ""}`}
              cancel={`${noDrag ? ".modal-body" : ""}`}
            >
              <ResizableBox
                width={800}
                style={{
                  position: "fixed",
                  top: "5%",
                  right: "2%",
                  zIndex: "1055",
                  backgroundColor: "rgb(255, 255, 255)",
                }}
              >
                <div>
                  <form onSubmit={addNotes}>
                    <Row className="align-items-center ">
                      <div className="col-12 d-flex justify-content-between">
                        <h5>Add New Note</h5>
                        <div className="text-end">
                          <button className="btn btn-purple  me-2 btn-lable">
                            <i className="fas fa-plus me-2 label-icon"></i>Add
                            Note
                          </button>
                          <a
                            onClick={() => setNotesSidebar(false)}
                            className="btn btn-red text-uppercase btn-lable"
                          >
                            <i className="dripicons-cross me-1 icon-center label-icon"></i>
                            Close
                          </a>
                        </div>
                      </div>
                      <div className="col-12 ">
                        <label htmlFor="">
                          Description <span className="text-danger">*</span>
                        </label>
                        <div
                          className="no-drag"
                          onDoubleClick={() => setNoDrag(true)}
                          onMouseLeave={() => setNoDrag(false)}
                          onMouseEnter={() => setNoDrag(true)}
                        >
                          <CKEditor
                            editor={ClassicEditor}
                            data={ckedata}
                            onReady={editor => { }}
                            onChange={(event, editor) => {
                              const data = editor.getData()
                              setCkedata(data)
                            }}
                          />
                        </div>
                      </div>


                      <div className="col-md-2 mt-3">
                        <label htmlFor="">Currency</label>
                        <Select
                          onChange={e => setCurrency(e)}
                          placeholder="Select"
                          className="basic-multi-select"
                          classNamePrefix=" select"
                          styles={colourStyles}
                          options={currency_option}
                          value={currency}
                        />
                      </div>
                      <div className="col-md-2 mt-3   ">
                        <label htmlFor="">Amount</label>
                        <input
                          type="number"
                          value={amount}
                          onChange={e => setAmount(e.target.value)}
                          placeholder="Enter Amount"
                          className="form-control "
                          disabled={currency.length == 0}
                        />
                      </div>
                      <div className="col-md-5 mt-3 notes">
                        <label htmlFor="touch">
                          Got in touch with this lead{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="datetime-local"
                          value={notedate}
                          onChange={e => setNotedate(e.target.value)}
                          className="form-control "
                          required
                        />
                      </div>

                      <div className="col-md-3 mt-3">
                        <label htmlFor="">Attachment</label>
                        {/* custom file upload */}
                        <Dropzone
                          onDrop={acceptedFiles => {
                            handleAcceptedFiles1(acceptedFiles)
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div
                              className="dz-message needsclick p-0"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="d-flex px-2 custom-file-upload align-items-center">
                                <div className="">
                                  <i className="display-6 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h5 className="mb-0 ms-2">Upload Files</h5>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      </div>
                      {/* file display here */}
                      <div
                        className={`col-md-4 mt-3 dropzone-previews ${selectedFiles1.length > 0 ? "d-block" : "d-none"
                          }`}
                        id="file-previews"
                      >
                        <label className="mb-1">Uploaded Files</label>
                        <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                          <div className="p-1">
                            <Row className="align-items-center justify-content-evenly">
                              {selectedFiles1?.map((f, i) => {
                                return (
                                  <Col key={i} className="col-auto">
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-sm rounded bg-light"
                                      alt={f.name}
                                      src={f.preview}
                                    />
                                  </Col>
                                )
                              })}
                            </Row>
                          </div>
                        </Card>
                      </div>

                      {/* Generate Task */}
                      <div className="col-md-2 ps-2 mt-3">
                        <Label htmlFor="formrow-firstname-Input">Generate Task</Label>
                        <div className="form-switch form-switch-md mb-2">
                          <input
                            title="Intial Days"
                            type="checkbox"
                            className="form-check-input"
                            id="customSwitchsizemd"
                            onChange={e => setGenerateTask(e.target.checked)}
                            value={generateTask}
                          />
                        </div>
                      </div>
                      <div className="col-md-8"></div>
                      {generateTask ? (
                        <>
                          <div className="col-md-9 mt-3">
                            <label htmlFor="">
                              Task Name <span className="text-danger">*</span>
                            </label>
                            <Select
                              placeholder="Select Task"
                              className="basic-multi-select "
                              classNamePrefix="select"
                              options={subtasks}
                              value={taskDepends}
                              onChange={e => setTaskDepends(e)}
                            />
                          </div>
                          <div className="col-md-3 mt-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Priority <span className="text-danger">*</span>
                            </Label>
                            <Select
                              placeholder="Select Priority"
                              className="basic-multi-select "
                              classNamePrefix="select"
                              options={priorityOption}
                              value={priority}
                              onChange={e => setPriority(e)}
                            />
                          </div>
                          {taskDepends?.value == "other" ? (
                            <div className="col-md-12 mt-3">
                              <label htmlFor="">
                                Task Name <span className="text-danger">*</span>
                              </label>
                              <Input
                                type="text"
                                className="form-control"
                                placeholder="Enter Task Name"
                                // required
                                onChange={(e) => setTask(e.target.value)}
                                value={task}
                              />
                            </div>
                          ) : null}
                          <div className="col-md-9  mt-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Assignee <span className="text-danger">*</span>
                            </Label>
                            <Select
                              placeholder="Select Member"
                              className="basic-multi-select "
                              classNamePrefix="select"
                              options={assign_option}
                              closeMenuOnSelect={false}
                              isMulti
                              value={assignee}
                              onChange={e => setAssignee(e)}
                            />
                          </div>
                        </>
                      ) : null}
                    </Row>
                  </form>
                </div>
              </ResizableBox>
            </Draggable>
          </div>

          <div className="mt-4">
            {filterForm ? (
              <ul className="verti-timeline list-unstyled notes-box">
                {data?.slice(0, visibleCount)?.map((note, key) => {
                  return (
                    <li key={key} className="event-list position-relative">
                      <div className="event-timeline-dot">
                        <img
                          src={`https://mars.zithas.com/application/public/${note.addedby_avatar}`}
                          className="userImg me-3 "
                          alt=""
                        />
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1 ms-4 note py-2 rounded ps-3 pr-10">
                          <div className=" d-flex justify-content-between ">
                            <div>
                              <p className="text-muted ">
                                <h6 className="d-inline me-2">
                                  By {note.added_by}
                                </h6>{" "}
                                {moment(note.created_at).format(
                                  "DD-MM-YYYY hh:mm:ss"
                                )}
                                <span className="pl-15">
                                  Got in touch with this lead at{" "}
                                  {moment(note.date).format('dddd')}{" "}
                                  {moment(note.date).format(
                                    "DD-MM-YYYY hh:mm a"
                                  )}
                                </span>
                              </p>
                            </div>
                            <div className="me-4">
                              {note.type == 1 ? (
                                <span
                                  onClick={() => {
                                    setViewFollowUp(true)
                                    setNoteId(note.id)
                                    setEditFollowDate(
                                      note?.lead_followup[0]?.date_time
                                    )
                                    setEditFollowDesc(
                                      note?.lead_followup[0]?.description
                                    )
                                    const idArray =
                                      note?.lead_followup[0]?.assign_user
                                        .split(",")
                                        .map(Number)
                                    setEditFollowAssign(
                                      assign_option.filter(obj =>
                                        idArray.includes(obj.value)
                                      )
                                    )
                                  }}
                                  className="me-2 cursor-pointer badge-soft-success badge"
                                >
                                  follow up
                                </span>
                              ) : null}

                              {note.type == 0 ? (
                                <span
                                  onClick={e => {
                                    setEditStatus(true)
                                    setTempid(note.id)
                                  }}
                                  className="me-2 cursor-pointer badge-soft-success badge"
                                >
                                  Add follow up
                                </span>
                              ) : null}
                              <a
                                onClick={() => {
                                  setEditNotesSidebar(true)
                                  setNoteId(note.id)
                                  setEditDescription(
                                    note.description ? note.description : ""
                                  )
                                  setEditCurrency(
                                    currency_option.filter(
                                      obj => obj.value == note.currency
                                    )[0]
                                  )
                                  setEditAmount(note.amount)
                                  setEditDate(note.date)
                                }}
                                className="btn btn-sm edit-delete-btn text-success me-2"
                              >
                                <i className="fas fa-edit" title="Edit"></i>
                              </a>
                              <button
                                onClick={e => {
                                  e.preventDefault()
                                  deleteNote(e, note.id)
                                }}
                                className="btn btn-sm edit-delete-btn text-danger"
                              >
                                <i
                                  className="fas fa-trash-alt"
                                  title="Delete"
                                ></i>
                              </button>
                            </div>
                          </div>
                          <i className="bi bi-triangle-fill note-icon"></i>

                          <div
                            className="Features"
                            dangerouslySetInnerHTML={{
                              __html: note.description,
                            }}
                          />
                          <p className="mb-2">
                            Amount : {note.currency_symbol} {note.amount}{" "}
                          </p>
                          {note.link != null ? (
                            <p className="mb-1">
                              Attachment :{" "}
                              <a
                                target="_blank "
                                className="btn btn-sm edit-delete-btn text-secondary ml-10"
                                rel="noreferrer"
                                type="button"
                                href={note.link}
                              // onClick={() =>
                              //   downloadImage({ imagelink: note.link })
                              // }
                              >
                                <i className="fas fa-eye"></i> View
                              </a>
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </li>
                  )
                })}
                <div className="d-flex justify-content-center mt-3 gap-3">
                  {visibleCount < data.length && (
                    <button
                      onClick={handleSeeMore}
                      className="btn btn-outline-primary"
                    >
                      See More <i className="bi bi-arrow-down"></i>
                    </button>
                  )}
                  {visibleCount > minVisibleCount && (
                    <button
                      onClick={handleSeeLess}
                      className="btn btn-outline-secondary"
                    >
                      See Less <i className="bi bi-arrow-up"></i>
                    </button>
                  )}
                </div>
              </ul>
            ) : (
              <ul className="verti-timeline list-unstyled notes-box">
                {data?.map((note, key) => {
                  return (
                    <li key={key} className="event-list position-relative">
                      <div className="event-timeline-dot">
                        <img
                          src={`https://mars.zithas.com/application/public/${note.addedby_avatar}`}
                          className="userImg me-3 "
                          alt=""
                        />
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1 ms-4 note py-2 rounded ps-3 pr-10">
                          <div className=" d-flex justify-content-between ">
                            <div>
                              <p className="text-muted ">
                                <h6 className="d-inline me-2">
                                  By {note.added_by}
                                </h6>{" "}
                                {moment(note.created_at).format(
                                  "DD-MM-YYYY hh:mm:ss"
                                )}
                                <span className="pl-15">
                                  Got in touch with this lead at{" "}
                                  {moment(note.date).format('dddd')}{" "}
                                  {moment(note.date).format(
                                    "DD-MM-YYYY hh:mm a"
                                  )}
                                </span>
                              </p>
                            </div>
                            <div className="me-4">
                              {note.type == 1 ? (
                                <span
                                  onClick={() => {
                                    setViewFollowUp(true)
                                    setNoteId(note.id)
                                    setEditFollowDate(
                                      note?.lead_followup[0]?.date_time
                                    )
                                    setEditFollowDesc(
                                      note?.lead_followup[0]?.description
                                    )
                                    const idArray =
                                      note?.lead_followup[0]?.assign_user
                                        .split(",")
                                        .map(Number)
                                    setEditFollowAssign(
                                      assign_option.filter(obj =>
                                        idArray.includes(obj.value)
                                      )
                                    )
                                  }}
                                  className="me-2 cursor-pointer badge-soft-success badge"
                                >
                                  follow up
                                </span>
                              ) : null}

                              {note.type == 0 ? (
                                <span
                                  onClick={e => {
                                    setEditStatus(true)
                                    setTempid(note.id)
                                  }}
                                  className="me-2 cursor-pointer badge-soft-success badge"
                                >
                                  Add follow up
                                </span>
                              ) : null}
                              <a
                                onClick={() => {
                                  setEditNotesSidebar(true)
                                  setNoteId(note.id)
                                  setEditDescription(
                                    note.description ? note.description : ""
                                  )
                                  setEditCurrency(
                                    currency_option.filter(
                                      obj => obj.value == note.currency
                                    )[0]
                                  )
                                  setEditAmount(note.amount)
                                  setEditDate(note.date)
                                }}
                                className="btn btn-sm edit-delete-btn text-success me-2"
                              >
                                <i className="fas fa-edit" title="Edit"></i>
                              </a>
                              <button
                                onClick={e => {
                                  e.preventDefault()
                                  deleteNote(e, note.id)
                                }}
                                className="btn btn-sm edit-delete-btn text-danger"
                              >
                                <i
                                  className="fas fa-trash-alt"
                                  title="Delete"
                                ></i>
                              </button>
                            </div>
                          </div>
                          <i className="bi bi-triangle-fill note-icon"></i>

                          <div
                            className="Features"
                            dangerouslySetInnerHTML={{
                              __html: note.description,
                            }}
                          />
                          <p className="mb-2">
                            Amount : {note.currency_symbol} {note.amount}{" "}
                          </p>
                          {note.link != null ? (
                            <p className="mb-1">
                              Attachment :{" "}
                              <a
                                target="_blank "
                                className="btn btn-sm edit-delete-btn text-secondary ml-10"
                                rel="noreferrer"
                                type="button"
                                href={note.link}
                              // onClick={() =>
                              //   downloadImage({ imagelink: note.link })
                              // }
                              >
                                <i className="fas fa-eye"></i> View
                              </a>
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </li>
                  )
                })}
              </ul>
            )}
            {data.length == 0 && filterForm == false ? (
              <p>Note is Not Addded</p>
            ) : null}
            {data.length == 0 && filterForm == true ? (
              <p>Note is Not found</p>
            ) : null}
          </div>

          {/* Edit Notes Sidebar */}

          <div
            className={`note-sticky-form ${editNotesSidebar ? "d-block" : "d-none"
              }`}
          >
            <Draggable
              handle={`${noDrag ? ".modal-header" : ""}`}
              cancel={`${noDrag ? ".modal-body" : ""}`}
            >
              <ResizableBox
                width={800}
                style={{
                  position: "fixed",
                  top: "20%",
                  right: "2%",
                  zIndex: "1055",
                  backgroundColor: "#fff",
                }}
              >
                <div>
                  <form onSubmit={editNotes}>
                    <Row className="align-items-center justify-content-between">
                      <div className="col-12 d-flex justify-content-between">
                        <h5>Edit Note</h5>
                        <div className="text-end">
                          <button className="btn btn-purple  me-2">
                            <i className="fas fa-edit me-2"></i>Edit Note
                          </button>
                          <a
                            onClick={() => setEditNotesSidebar(false)}
                            className="btn btn-red text-uppercase "
                          >
                            <i className="dripicons-cross me-1 icon-center"></i>
                            Close
                          </a>
                        </div>
                      </div>
                      <div className="col-12 ">
                        <label htmlFor="">
                          Description <span className="text-danger">*</span>{" "}
                        </label>
                        <div
                          className="no-drag"
                          onDoubleClick={() => setNoDrag(true)}
                          onMouseLeave={() => setNoDrag(false)}
                          onMouseEnter={() => setNoDrag(true)}
                        >
                          <CKEditor
                            editor={ClassicEditor}
                            data={edit_description}
                            onReady={editor => {
                              // You can store the "editor" and use when it is needed.
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData()
                              setEditDescription(data)
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-2 mt-3">
                        <label htmlFor="">Currency</label>
                        <Select
                          onChange={e => setEditCurrency(e)}
                          className="basic-multi-select"
                          classNamePrefix=" select"
                          styles={colourStyles}
                          options={currency_option}
                          value={edit_currency}
                        />
                      </div>
                      <div className="col-md-2 mt-3   ">
                        <label htmlFor="">Amount</label>
                        <input
                          type="number"
                          onChange={e => setEditAmount(e.target.value)}
                          placeholder="Enter amount"
                          value={edit_amount}
                          className="form-control "
                          disabled={edit_amount == null}
                        />
                      </div>
                      <div className="col-md-4 mt-3 notes">
                        <label htmlFor="touch">
                          Got in touch with this lead{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="datetime-local"
                          onChange={e => setEditDate(e.target.value)}
                          value={edit_date}
                          required
                          className="form-control "
                        />
                      </div>
                      <div className="col-md-4 mt-3 text-end"></div>
                    </Row>
                  </form>
                </div>
              </ResizableBox>
            </Draggable>
          </div>
        </div>
      </div>

      {/* Follow Up Modal Add  */}
      <Modal isOpen={edit_status} size="sm" className="bd-custom-md-3" centered>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Follow Up</h5>
          <button
            type="button"
            onClick={() => {
              setEditStatus(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <Form className="row" onSubmit={addFollowUp}>
            <div className="mb-3 col-12">
              <label htmlFor="formrow-firstname-Input">Date</label>
              <input
                onChange={e => setFollowUpDate(e.target.value)}
                type="date"
                className="form-control "
              />
            </div>
            <div className="mb-3 col-12">
              <label htmlFor="">Purpose</label>
              <input
                onChange={e => setFollowUpPurpose(e.target.value)}
                type="Purpose"
                className="form-control "
                placeholder="Enter Purpose"
              />
            </div>
            <div className="mb-3 col-12">
              <label htmlFor="">Assigned</label>
              <Select
                onChange={e => setFollowUpAssigned(e)}
                placeholder="Select Assigned"
                className="basic-multi-select"
                classNamePrefix=" select"
                isMulti
                styles={colourStyles}
                options={assign_option}
              />
            </div>
            <div className="mt-2 text-end">
              <button className=" btn btn-purple">Submit</button>
            </div>
          </Form>
        </div>
      </Modal>
      {/* -------------- */}

      {/* View Follow Up Modal */}
      <Modal
        isOpen={viewFollowUp}
        size="sm"
        className="bd-custom-md-3"
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">View Follow Up</h5>
          <button
            type="button"
            onClick={() => {
              setViewFollowUp(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <Form className="row bd-span-input" onSubmit={onEditFollowup}>
            <>
              <div className="mb-3 col-12">
                <label htmlFor="formrow-firstname-Input">Date <span className="text-danger">*</span></label>
                <input
                  onChange={e => setEditFollowDate(e.target.value)}
                  type="date"
                  required
                  className="form-control "
                  value={editFollowdate}
                />
              </div>
              <div className="mb-3 col-12">
                <label htmlFor="">Purpose</label>
                <input
                  onChange={e => setEditFollowDesc(e.target.value)}
                  type="text"
                  className="form-control "
                  value={editFollowdesc}
                />
              </div>
              <div className="mb-3 col-12">
                <label htmlFor="">Assigned</label>
                <Select
                  onChange={e => setEditFollowAssign(e)}
                  placeholder="Select Assigned"
                  className="basic-multi-select"
                  classNamePrefix=" select"
                  isMulti
                  styles={colourStyles}
                  options={assign_option}
                  value={editFollowassign}
                />
              </div>
              <div className="mt-2 text-end">
                <button className=" btn btn-purple">Submit</button>
              </div>
            </>
          </Form>
        </div>
      </Modal>
      {/* -------------- */}

      {/* Access Denied */}

      <AccessDeniedPopup
        modal_Access={modal_Access}
        setAccessDenied={setAccessDenied}
      />
    </React.Fragment>
  )
}

export default Notes
