import React, { useEffect, useState } from "react"
import { Container } from "reactstrap"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useMemo } from "react"
// import { useTable, usePagination, useGlobalFilter } from "react-table"
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table"
import { Table, Row, Col, Card, CardBody, Modal } from "reactstrap"
import sideImg from "../../../../assets/images/profile.png"
import Loader from "pages/Separate/Loader"
import configure from "configure"
import AccessDeniedPopup from "routes/AccessDeniedPopup"
import NewPagination from "components/Common/Newpagination"
import { fetchData } from "utils/common-fetchData"
import { DigitalServiceOptionsDrop } from "utils/common-options"
import secureLocalStorage from "react-secure-storage"
import moment from "moment"

function TableContainer({ columns, data, setAccessDenied, setEditTask }) {
  const [fixedContent, setFixedContent] = useState(false)

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "id", // Set the ID of the first column for default sorting
            desc: true, // `true` for descending, `false` for ascending
          },
        ],
      },
    },

    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state
  useEffect(() => setPageSize(50), [])

  const [viewData, setViewData] = useState(false)
  const [isLoading, setLoading] = useState(false)

  return (
    <div>
      {isLoading && <Loader />}
      <Row className="mb-4 align-items-center">
        <Col md={9} className="pr-0">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-start w-100 text-center align-items-center gap-3">
              <a
                href="#"
                className="text-green pt-2 px-2 rounded proposal-status"
              >
                <h4>{data?.length}</h4>
                <h4>All</h4>
              </a>
              <a
                href="#"
                className="text-success pt-2 px-2 rounded proposal-status"
              >
                <h4>{data.filter(obj => obj.status_name == "Sent")?.length}</h4>
                <h4>Done</h4>
              </a>
              <a
                href="#"
                className="text-danger pt-2 px-2 rounded proposal-status"
              >
                <h4>
                  {" "}
                  {data.filter(obj => obj.status_name == "Sent")?.length}
                </h4>
                <h4>Pending</h4>
              </a>
            </div>
          </div>
        </Col>
        <Col
          md={3}
          className="d-flex align-items-center justify-content-end pl-0"
        ></Col>
      </Row>
      <Row>
        <div className="col-md-12 my-4 d-flex">
          <div className="col-md-3 d-flex justify-content-start align-items-center">
            <select
              className="s me-4 form-select"
              style={{ width: "100px" }}
              value={pageSize}
              onChange={e => setPageSize(e.target.value)}
            >
              {[10, 25, 50, 100].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-9 d-flex justify-content-end align-items-center">
            <div className="search-box">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className="s form-control rounded "
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
        </div>
      </Row>
      <div className="position-relative">
        <Table className="table table-bordered" {...getTableProps()}>
          <thead
            className={`${
              fixedContent ? "fix-task-headers" : ""
            } fix-project-header`}
          >
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    key={index}
                    className={`t-col-${index + 1} ${
                      column.className
                    } customShortIcon`}
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                    {column.isShortIcon ? (
                      <span
                        style={{
                          display: index == 0 ? "none" : "block",
                        }}
                        onClick={() => column.getSortByToggleProps().onClick()}
                      >
                        <i
                          title="Ascending"
                          className={`bi bi-arrow-up ms-1 info-icon ${
                            column.isSorted && !column.isSortedDesc
                              ? "text-dark"
                              : ""
                          }`}
                        ></i>
                        <i
                          title="Descending"
                          className={`bi bi-arrow-down info-icon ${
                            column.isSorted && column.isSortedDesc
                              ? "text-dark"
                              : ""
                          }`}
                        ></i>
                      </span>
                    ) : null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody className="fix-project-header" {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              let rowColor
              switch (row.original.month) {
                case "February 2024":
                  rowColor = "bg-red"
                  break
                case "March 2024":
                  rowColor = "bg-red"
                  break
              }
              return (
                <tr
                  className={`row-hover ${rowColor}`}
                  {...row.getRowProps()}
                  key={i}
                >
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        key={index}
                        className={`t-col-${index + 1} ${
                          cell.column.className
                        }`}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-space-between align-items-center">
        <NewPagination
          gotoPage={gotoPage}
          canPreviousPag={canPreviousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          page={page}
          data={data}
        />
      </Row>
    </div>
  )
}

const DmTasks = ({ verticalActiveTab, type, projectDetail }) => {
  const params = useParams()
  const [isLoading, setLoading] = useState(false)
  const token = secureLocalStorage.getItem("auth_token")
  const [data, setData] = useState([])
  const toggleLoading = load => {
    setLoading(load)
  }
  useEffect(() => {
    if (
      verticalActiveTab == 3 ||
      verticalActiveTab == 7 ||
      verticalActiveTab == 8 ||
      verticalActiveTab == 9
    ) {
      fetch()
    }
  }, [verticalActiveTab])

  const fetch = async () => {
    const typeSeo = DigitalServiceOptionsDrop?.find(
      obj => obj?.tabId == verticalActiveTab
    )?.value
    const res = await fetchData(
      toggleLoading,
      `digitalProjectTasks/${
        type == "edit" ? projectDetail?.id : params?.id
      }/${typeSeo}`,
      token
    )
    if (res?.success) {
      setData(res?.data?.filter(obj => obj?.status != 1))
    }
  }

  const [modal_Access, setAccessDenied] = useState(false)

  const columns = useMemo(
    () => [
      {
        Header: "Sr No.",
        accessor: "id",
        title: "ID",
        isInfoIcon: false,
        isShortIcon: true,
        className: "text-center dm_task_id",
      },
      {
        Header: "Assign",
        title: "assign",
        accessor: "assign_name",
        isInfoIcon: false,
        isShortIcon: true,
        className: "dm_task_id",
        Cell: cellProps => {
          return (
            <div className=" d-flex gap-2 flex-wrap justify-content-center align-items-center">
              <img
                src={`${
                  cellProps?.row?.original?.avatar != null
                    ? `${configure.imgUrl}${cellProps?.row?.original?.avatar}`
                    : sideImg
                }`}
                alt=""
                className="img-fluid"
                style={{ borderRadius: "100%", width: "25px", height: "25px" }}
                title={cellProps?.row?.original?.user_alias}
              />
            </div>
          )
        },
      },
      {
        Header: "Task Name",
        title: "Task Name",
        accessor: "name",
        isInfoIcon: false,
        isShortIcon: true,

        className: "dm_task_name_box",
      },
      {
        Header: "Quantity",
        title: "Quantity",
        accessor: "quantity",
        isInfoIcon: false,
        isShortIcon: true,

        className: "dm_quantity_box text-center",
      },
      {
        Header: "Date",
        title: "Month",
        accessor: "month",
        isInfoIcon: false,
        isShortIcon: true,
        className: "dm_month_box text-center",
        Cell: cellProps => {
          return (
            <div>
              {moment(cellProps?.row?.original?.date).format("DD-MM-YYYY")}
            </div>
          )
        },
      },
      {
        Header: "Est. Time",
        title: "Last Updated Days",
        accessor: "est_hrs",
        isInfoIcon: false,
        isShortIcon: true,

        className: "days_box text-center",
      },
    ],
    []
  )

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="">
        <Container fluid>
          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <div className="responsive-table">
                    <TableContainer
                      columns={columns}
                      data={data}
                      setAccessDenied={setAccessDenied}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <AccessDeniedPopup
        modal_Access={modal_Access}
        setAccessDenied={setAccessDenied}
      />
    </React.Fragment>
  )
}

export default DmTasks
