import React, { useEffect, useState } from "react"
import { Container } from "reactstrap"
import { useMemo } from "react"
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table"
import { Table, Row, Col, Card, CardBody, Modal } from "reactstrap"
import NewPagination from "components/Common/Newpagination"
import moment from "moment"
import Loader from "pages/Separate/Loader"
import { fetchData } from "utils/common-fetchData"
import { useParams } from "react-router-dom"
import secureLocalStorage from "react-secure-storage"

function TableContainer({ columns, data }) {
  const [fixedContent, setFixedContent] = useState(false)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "id",
            desc: true,
          },
        ],
      },
    },

    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state

  useEffect(() => setPageSize(50), [])
  const [isLoading, setLoading] = useState(false)

  return (
    <div>
      {isLoading && <Loader />}
      <div className="position-relative">
        <Table className="table table-bordered" {...getTableProps()}>
          <thead
            className={`${
              fixedContent && page.length >= 5
                ? "fixed-general-task-header"
                : "fix-table-header"
            } `}
          >
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => {
                  const classNames = column.className
                  return (
                    <th
                      key={index}
                      className={` col-t-${
                        index + 1
                      } ${classNames} customShortIcon custom-text`}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}

                      {column.isInfoIcon ? (
                        <a
                          onClick={() => {
                            // if(column)
                            if (column.Header == "General Task") {
                              setViewModal(true)
                            } else if (column.Header == "Action") {
                              setActionModal(true)
                            }
                          }}
                        >
                          <i
                            title="info"
                            className="bx bx-info-circle ms-1 info-icon"
                          ></i>
                        </a>
                      ) : null}

                      {column.isShortIcon && (
                        <span
                          onClick={() =>
                            column.getSortByToggleProps().onClick()
                          }
                          className="ms-2 cursor-pointer customIcon"
                        >
                          <i
                            title="Ascending"
                            className={`bi bi-arrow-up info-icon ${
                              column.isSorted && !column.isSortedDesc
                                ? "text-dark"
                                : ""
                            }`}
                          ></i>
                          <i
                            title="Descending"
                            className={`bi bi-arrow-down info-icon ${
                              column.isSorted && column.isSortedDesc
                                ? "text-dark"
                                : ""
                            }`}
                          ></i>
                        </span>
                      )}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>

          <tbody className="fix-table-body" {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              const startDate = moment()
              const deliveryDateBefore = moment(row.original.deliverydatebefore)
              const diffInDays = deliveryDateBefore.diff(startDate, "days")
              const totalDays = row.original.estimatedhours
              const deliveryDate = new Date(row.original.deliverydatebefore)
              const today = new Date()
              const differenceInDays = Math.ceil(
                (deliveryDate - today) / (1000 * 60 * 60 * 24)
              )
              let percentage
              if (differenceInDays >= totalDays) {
                percentage = 100
              } else if (differenceInDays <= 0) {
                percentage = 0
              } else {
                const percentComplete =
                  ((totalDays - differenceInDays) / totalDays) * 100
                percentage = Math.min(Math.max(percentComplete, 0), 100)
              }
              const conditionClass =
                percentage >= 0 && percentage < 25
                  ? "condition-25"
                  : percentage >= 25 && percentage < 50
                  ? "condition-50"
                  : percentage >= 50 && percentage < 75
                  ? "condition-75"
                  : percentage >= 75 && percentage <= 100
                  ? "condition-100"
                  : ""

              const isConditiondid = row.original.pwd >= 0 && diffInDays < 0
              const isbothCondition = row.original.pwd < 0 && diffInDays < 0
              const ispwdCondition = row.original.pwd < 0 && diffInDays > 0
              return (
                <tr
                  {...row.getRowProps()}
                  key={i}
                  // className={`${isConditiondid && conditionClass} ${
                  //   isbothCondition && "condition-100"
                  // }`}
                  className=""
                >
                  {row.cells.map((cell, index) => (
                    <td
                      key={index}
                      className={`t-col-${index + 1} ${cell.column.className}`}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              )
            })}
            {page.length == 0 && (
              <tr style={{ backgroundColor: "unset" }}>
                <td className="text-center" colSpan={10}>
                  No Data Found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <NewPagination
          gotoPage={gotoPage}
          canPreviousPag={canPreviousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          page={page}
          data={data}
        />
      </Row>
    </div>
  )
}

const CredentialTab = ({ verticalActiveTab, type, projectDetail }) => {
  const [isLoading, setLoading] = useState(false)
  const [allData, setAllData] = useState([])
  const [data, setData] = useState([])
  const [fields, setFields] = useState([])
  const [tableFields, setTablefields] = useState([])
  const [viewCred, setViewCred] = useState(false)
  const [credObj, setCredObj] = useState("")
  const params = useParams()
  const token = secureLocalStorage.getItem("auth_token")
  document.addEventListener("keydown", e => {
    if (e.key === "Escape") {
    }
  })
  const toggleLoading = load => {
    setLoading(load)
  }

  useEffect(() => {
    const fetchCredentials = async () => {
      const res = await fetchData(
        toggleLoading,
        `digitalCredentials/${type == "edit" ? projectDetail?.id :params?.id  }`,
        token
      )
      if (res?.success) {
        setTablefields(res?.data?.active_fields)
        setData(res?.data?.data)
        setAllData(res?.data?.data)
        setFields(res?.data?.tab_fields)
      }
    }
    if (verticalActiveTab == 6) {
      fetchCredentials()
    }
  }, [verticalActiveTab])

  const credentailColumns = [
    ...tableFields.map(field => ({
      Header: field.field_label,
      accessor: field.field_name,
      className: "cred_name",
    })),
    {
      Header: "Action",
      accessor: "action",
      className: "cred_action text-center",
      Cell: cellProps => {
        return (
          <div className="d-flex justify-content-center">
            <a
              className="btn btn-sm btn-outline-secondary me-1"
              title="View Project"
              onClick={() => {
                setCredObj(cellProps?.row?.original)
                setViewCred(true)
              }}
            >
              <i className="fas fa-eye"></i>
            </a>
          </div>
        )
      },
    },
  ]

  // Memoize the columns
  const columns1 = useMemo(() => credentailColumns, [tableFields])

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="">
        <Container fluid>
          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <div className="responsive-table">
                    <TableContainer columns={columns1} data={data} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal
        isOpen={viewCred}
        size="xl"
        className=""
        style={{ maxWidth: "80vw", width: "100%" }}
        centered
      >
        <div className="modal-header pe-4">
          <h5 className="modal-title mt-0">View Credentials</h5>
          <button
            type="button"
            onClick={() => {
              setViewCred(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>

        <div
          className="modal-body"
          style={{ paddingTop: "40px", paddingBottom: "40px" }}
        >
          <div className="col-12 pe-3">
            <div className="col-12 row justify-content-start align-items-center mb-4">
              {fields?.map((obj, i) => {
                return (
                  <div className="col-4 mb-4" key={i}>
                    <label htmlFor="">{obj?.field_name}</label>
                    <input
                      disabled
                      type="text"
                      value={credObj[obj?.field_name]}
                      className="form-control "
                    />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default CredentialTab
