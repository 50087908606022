import React, { useEffect, useRef, useState } from "react"
import { Container } from "reactstrap"
import { useNavigate, useParams } from "react-router-dom"
import JsPDF, { jsPDF } from "jspdf"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Row, Modal, Form, Canvas } from "reactstrap"

// canvas
import SignatureCanvas from "react-signature-canvas"

// images
import zithasLogo from "../../../assets/images/zithas-dark-logo.png"
import sign from "../../../assets/images/zithas-signature.png"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import moment from "moment"
import html2canvas from "html2canvas"
import configure from "configure"
import swal from "sweetalert"
import html2pdf from "html2pdf.js"

const ViewContract = ({ view, id }) => {
  const [fixedRightSec, setFixedRightSec] = useState(false)
  const [modal_center, setmodal_center] = useState(false)

  document.addEventListener("scroll", () => {
    if (window.scrollY > 48) {
      setFixedRightSec(true)
    } else {
      setFixedRightSec(false)
    }
  })

  const printContract = () => {
    let printContents = document.getElementById("zithasInvoicePrint").innerHTML
    let originalContents = document.body.innerHTML
    document.body.innerHTML = printContents
    window.print()
    document.body.innerHTML = originalContents
  }

  const [isLoading, setLoading] = useState(false)
  const [contract_type, setContractType] = useState([])
  const [contract_value, setContractValue] = useState("")
  const [description, setDescription] = useState("")
  const [start_date, setStartDate] = useState("")
  const [end_date, setEndDate] = useState("")
  const [note, setNote] = useState("")
  const [date, setDate] = useState("")
  const [sign_date, setSignDate] = useState("")
  const [symbol, setCurrencySymbol] = useState("")
  const [signature, setSignature] = useState("")
  const [contact, setContact] = useState("")
  const [approve, setApprove] = useState("")

  useEffect(() => {
    FetchContractData()
  }, [])

  const params = useParams()

  const navigate = useNavigate()

  const FetchContractData = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("contract_id", view ? id : params.id)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/view_contract`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(async response => {
        setContractValue(response.data.data[0]?.value)
        setStartDate(response.data.data[0]?.startDate)
        setEndDate(response.data.data[0]?.endDate)
        setNote(response.data.data[0]?.subject)
        setDescription(response.data.data[0]?.template_formate)
        setDate(response.data.data[0]?.created_at)
        setSignDate(response.data.data[0]?.Date_signed)
        setCurrencySymbol(response.data.data[0]?.currency_symbol)
        setContractType(response.data.data[0]?.contract_type)
        setSignature(response.data.data[0]?.signature)
        setContact(response.data.data[0]?.contact_person)
        setApprove(response.data.data[0]?.approve)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const pdfRef = useRef()

  const generatePDF = async () => {
    if (signature == null || approve == 0) {
      swal("", "Cannot Download Unsigned OR Unapprove Contract!", "warning")
    } else {
      setLoading(true)

      // Select the content to be converted to PDF
      const content = pdfRef.current

      const opt = {
        margin: 0.38,
        filename: "Contract-Document.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true, scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      }

      setTimeout(() => setLoading(false), 2000)
      html2pdf().from(content).set(opt).save()
    }
  }

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className={view ? "" : "page-content mb-4"}>
        <Container fluid>
          <Row>
            <div className="col-md-9 ps-0">
              <div
                id="zithasInvoicePrint"
                ref={pdfRef}
                className="bg-white p-3 shadow document"
              >
                <div className="pb-2 text-center">
                  <img src={zithasLogo} height="60" alt="zithasLogo" />
                </div>
                <div>
                  <div>
                    <div
                      className="Features document-content"
                      dangerouslySetInnerHTML={{
                        __html: description
                          .replaceAll("{", "")
                          .replaceAll("}", ""),
                      }}
                    />
                  </div>

                  <h5 className="fw-bold text-decoration-underline">
                    Date : {moment(date).format("DD-MM-YYYY")}
                  </h5>
                  <Row className="mt-4 justify-space-between">
                    <div className="col-4">
                      {signature == null ? (
                        <div className="sign-box">Signature</div>
                      ) : (
                        <img src={sign} height="50" className="mb-2" />
                      )}
                      <h5>Zithas Signature</h5>
                    </div>

                    <div className="col-4">
                      {signature != null ? (
                        <>
                          <img src={signature} height="50" className="mb-2" />
                          <h5>{contact != "undefined" && contact} Signature</h5>
                        </>
                      ) : null}
                    </div>
                  </Row>
                </div>
              </div>
            </div>

            <div className="col-md-3 pe-0 ">
              <div className={`${fixedRightSec ? "fixed-right-section" : ""}`}>
                <div className="col-12  ">
                  <button
                    onClick={() => printContract()}
                    className="btn btn-sm btn-success border-radius me-1"
                  >
                    <i className="fas fa-print me-1"></i> Print
                  </button>

                  <button
                    onClick={generatePDF}
                    className="btn btn-sm btn-secondary border-radius me-1"
                  >
                    <i className="fas fa-file-download me-1"></i> Downloads
                  </button>

                  <button
                    onClick={() => navigate(-1)}
                    className="btn btn-sm btn-red border-radius"
                  >
                    <i className="fas fa-times fs-6 me-1"></i> Cancel
                  </button>
                </div>

                <div className="bg-white p-3 mt-4 shadow">
                  <h5 className="mb-4 text-dark">
                    Contract Value : {symbol} {contract_value}
                  </h5>

                  <h6 className="mb-3">
                    Start Date: {moment(start_date).format("DD-MM-YYYY")}
                  </h6>
                  <h6 className="mb-3">
                    End Date: {moment(end_date).format("DD-MM-YYYY")}
                  </h6>
                  <h6 className="mb-3">Contract Type: {contract_type}</h6>
                  <h6 className="mb-3">
                    Date Signed:{" "}
                    {sign_date ? moment(sign_date).format("DD-MM-YYYY") : " -"}
                  </h6>
                  <h6>Note: {note != "null" ? note : " -"}</h6>
                </div>

                {/* <div className="bg-white p-3 mt-4 shadow ">
                  <p className="text-danger">
                    * If you face any difficulty while Signing contract upload
                    from here
                  </p>

                  <div>
                    <label htmlFor="">Attachment</label>
                    <Row className="align-items-center">
                      <div className="col-10 pe-0">
                        <input
                          type="file"
                          className="form-control border-radius"
                        />
                      </div>
                      <div className="col-2 ps-1">
                        <button
                          title="upload"
                          className="btn btn-secondary border-radius"
                        >
                          <i className="fas fa-upload"></i>
                        </button>
                      </div>
                    </Row>
                  </div>
                </div> */}
              </div>
            </div>
          </Row>
        </Container>
      </div>

      {/* Signature Modal */}
      <Modal
        isOpen={modal_center}
        toggle={() => {
          tog_center()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            Signature & Confirmation Of Identity
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center(false)
            }}
            className="btn-modal-close"
          >
            <span className="btn-modal-span">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <Row>
              <div className="col-6 mb-3">
                <label htmlFor="">Name</label>
                <input
                  type="text"
                  placeholder="Enter Name"
                  className="form-control border-radius"
                />
              </div>
              <div className="col-6 mb-3">
                <label htmlFor="">Email Address </label>
                <input
                  type="email"
                  placeholder="Enter Email"
                  className="form-control border-radius"
                />
              </div>
              <div className="mb-3">
                <SignatureCanvas
                  penColor="black"
                  canvasProps={{
                    width: 465,
                    height: 200,
                    className: "sigCanvas border ",
                  }}
                />
              </div>
              <div>
                <input type="checkbox" id="auto" className="me-2" />
                <label htmlFor="auto"> Auto Renewal </label>
              </div>
            </Row>
            <div className="border-bottom my-3"></div>
            <div className="text-end">
              <button className="btn btn-red border-radius me-2">
                Clear Signature
              </button>
              <button className="btn btn-purple border-radius">Submit</button>
            </div>
          </Form>
        </div>
      </Modal>
      {/* ------------ */}
    </React.Fragment>
  )
}

export default ViewContract
