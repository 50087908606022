import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Dropzone from "react-dropzone"
import { Row, Form, Modal, Card, Col } from "reactstrap"
// assets
import Loader from "pages/Separate/Loader"
import "../../../../assets/css/Sales/Sales.css"
import swal from "sweetalert"
import Swal from "sweetalert2"
import Switch from "react-switch"
import Draggable from "react-draggable"
import { ResizableBox } from "react-resizable"
import viewNotes from "../../../../assets/images/Project/viewNotes.jpg"
import profile from "../../../../assets/images/profile.png"
import shareNotesImg from "../../../../assets/images/Digital Marketing/shareNotes.jpg"
import { FetchPostData } from "utils/common-fetchData"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import { getEncryptedData, toolbarOptions } from "utils/common-helpers"
import moment from "moment"
import Select from "react-select"
import configure from "configure"
import axios from "axios"

export function copyToClipBoard({ e, setCopy, id }) {
  if (e !== null) {
    window.navigator.clipboard.writeText(e)
    window.navigator.clipboard.writeText(e)
    setCopy({ type: id })
    setTimeout(() => {
      setCopy({
        value: "",
        type: null,
      })
    }, 1000)
  }
}

const DmNotes = ({ verticalActiveTab }) => {
  const [copy, setCopy] = useState({
    type: null,
  })
  const [fixButtons, setFixButtons] = useState(false)
  const [notesSidebar, setNotesSidebar] = useState(false)
  const [editNotesSidebar, setEditNotesSidebar] = useState(false)
  const [viewNote, setViewNote] = useState(false)
  const [selectedFiles1, setselectedFiles1] = useState([])
  const [loading, setLoading] = useState(false)
  const [data, setDatas] = useState([])
  const [allData, setAllDatas] = useState([])
  const navigate = useNavigate()
  const params = useParams()
  const [boxWidth, setBoxWidth] = useState(0)
  const [boxHeight, setBoxHeight] = useState(0)
  const [note_id, setNoteId] = useState("")
  const [isShared, setIsShared] = useState(false)
  const [customSwitch, setCustomSwitch] = useState(true)
  const authToken = sessionStorage.getItem("auth_token")
  const [notedate, setNotedate] = useState("")
  const [editNoteDate, setEditNoteDate] = useState("")
  const [description, setDescription] = useState("")
  const [editDescription, setEditDescription] = useState("")
  const [viewDescription, setViewDescription] = useState("")
  const [viewFiles, setViewFiles] = useState([])
  const [assignMember, setAssignMember] = useState([])
  const [decriptId, setDecriptId] = useState("")
  const [staffOption, setStaffOption] = useState([])

  useEffect(() => {
    const updateHeight = () => {
      const viewportHeight = window.innerHeight
      setBoxHeight(viewportHeight * 72)
    }
    const updateWidth = () => {
      const viewportWidth = window.innerWidth
      setBoxWidth(viewportWidth * 0.72)
      // setFilterBoxWidth(viewportWidth * 72)
    }
    updateWidth()
    updateHeight()
    window.addEventListener("resize", updateWidth)
    return () => window.removeEventListener("resize", updateWidth, updateHeight)
  }, [])

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })

  document.addEventListener("keydown", e => {
    if (e.key === "Escape")
      setNotesSidebar(false), setEditNotesSidebar(false), setViewNote(false)
  })

  useEffect(() => {
    const fetchNotes = async () => {
      const formEntries = {
        project_id: params?.id,
        user_id: localStorage.getItem("auth_id"),
      }
      const response = await FetchPostData(
        toggleLoading,
        "digitalProjectNotes",
        authToken,
        formEntries
      )
      if (response?.data?.success) {
        setDatas(response.data.data?.notes)
        setAllDatas(response.data.data?.notes)
        setStaffOption(
          response.data.data?.staff?.map(obj => ({
            value: obj.id,
            label: obj.name,
          }))
        )
      }
    }
    if (verticalActiveTab == "4") {
      fetchNotes()
    }
  }, [verticalActiveTab])

  const deleteNote = async note_id => {
    swal({
      title: "Are you sure?",
      text: "You want to Delete this note !",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async willdelete => {
      if (willdelete) {
        const formEntries = {
          user_id: localStorage.getItem("auth_id"),
          project_id: params.id,
          note_id: note_id,
        }
        const response = await FetchPostData(
          toggleLoading,
          "deleteDigitalProjectNotes",
          authToken,
          formEntries
        )
        if (response.data?.success) {
          Swal.fire({
            icon: "success",
            text: "Note deleted successfully",
          }).then(() => {
            setDatas(response.data.data)
            setAllDatas(response.data.data)
          })
        }
      }
    })
  }

  function handleAcceptedFiles1(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles1(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const toggleLoading = loading => {
    setLoading(loading)
  }

  const addNotes = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("project_id", params.id)
    formData.append("description", description)
    formData.append("notedate", notedate)
    for (let i = 0; i < selectedFiles1.length; i++) {
      formData.append("file[]", selectedFiles1[i])
    }
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/addDigitalProjectNotes`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      setLoading(false)
      if (response?.data?.success) {
        Swal.fire({
          icon: "success",
          title: "Notes Added successfully.",
        }).then(() => {
          setNotesSidebar(false)
          setDescription("")
          setselectedFiles1([])
          setNotedate("")
          setDatas(response?.data?.data)
          setAllDatas(response?.data?.data)
        })
      }
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }
  const EditNotes = async e => {
    e.preventDefault()
    const formData = new FormData()
    setLoading(true)
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("project_id", params.id)
    formData.append("description", editDescription)
    formData.append("notedate", editNoteDate)
    formData.append("note_id", note_id)
    if (selectedFiles1?.length != 0) {
      for (let i = 0; i < selectedFiles1.length; i++) {
        formData.append("file[]", selectedFiles1[i])
      }
    }
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/editDigitalProjectNotes`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      setLoading(false)
      if (response?.data?.success) {
        Swal.fire({
          icon: "success",
          title: "Notes Edited successfully.",
        }).then(() => {
          setEditNotesSidebar(false)
          setDescription("")
          setselectedFiles1([])
          setNotedate("")
          setDatas(response?.data?.data)
          setAllDatas(response?.data?.data)
        })
      }
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 14,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    )
  }

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 14,
          color: "#fff",
          paddingRight: 2,
          paddingLeft: 4,
        }}
      >
        {" "}
        Yes
      </div>
    )
  }

  const pinnedNote = async (id, pinned) => {
    const date = new Date()
    const formEntries = {
      user_id: localStorage.getItem("auth_id"),
      project_id: params?.id,
      note_id: id,
      pin: pinned,
      pinned_date: moment(date)?.format("yyyy-MM-DD HH:mm:ss"),
    }
    const response = await FetchPostData(
      toggleLoading,
      "pinnedDigitalProjectNotes",
      authToken,
      formEntries
    )
    if (response?.data?.success) {
      setDatas(response.data.data)
      setAllDatas(response.data.data)
    }
  }

  const shareNotes = async e => {
    e.preventDefault()
    const formEntries = {
      user_id: localStorage.getItem("auth_id"),
      project_id: params?.id,
      note_id: note_id,
      share: isShared ? 1 : 0,
      staff: assignMember?.map(obj => obj?.value)?.join(","),
    }
    const response = await FetchPostData(
      toggleLoading,
      "shareDigitalProjectNotes",
      authToken,
      formEntries
    )
    if (response?.data?.success) {
      Swal.fire({
        icon: "success",
        title: "Notes Shared successfully.",
      }).then(() => {
        setAssignMember([])
        setDatas(response?.data?.data)
        setAllDatas(response?.data?.data)
        setIsShared(false)
      })
    }
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="mb-3">
        <div className="px-3 py-4 bg-white">
          <div
            className={`flex-column fixed-buttons ${
              fixButtons ? "d-flex" : "d-none"
            }`}
          >
            <a
              onClick={() => setNotesSidebar(true)}
              className="btn btn-purple  fix-btn"
            >
              <i className="fas fa-plus me-2"></i>
              <span>Note</span>
            </a>

            <a onClick={() => navigate(-1)} className="btn btn-red  fix-btn">
              <i className="dripicons-cross me-2 icon-center"></i>
              <span>Cancel</span>
            </a>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="text-dark">Add Note</h5>
            <div className="text-end d-flex justify-content-center align-items-center gap-2">
              <div className="">
                <div className="search-box me-xxl-2 my-xxl-0 d-inline-block pt-3">
                  <div className="position-relative">
                    <label htmlFor="search-bar-0" className="search-label">
                      <span id="search-bar-0-label" className="sr-only">
                        Search this table
                      </span>
                      <input
                        id="search-bar-0"
                        type="text"
                        className=" form-control rounded h-100"
                        placeholder={` Search...`}
                        onChange={e => {
                          setDatas(
                            allData.filter(
                              item =>
                                item.decription
                                  ?.toLowerCase()
                                  ?.includes(e.target.value?.toLowerCase()) ||
                                item?.date?.includes(e.target.value)
                            )
                          )
                        }}
                      />
                    </label>
                    <i className="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
              </div>
              <a
                onClick={() => setNotesSidebar(true)}
                className=" btn btn-save btn-label"
              >
                <i className="fas fa-plus me-2 icon-size label-icon"></i>Add
                Note
              </a>
            </div>
          </div>

          <div
            className={`note-sticky-form ${
              notesSidebar ? "d-block" : "d-none"
            }`}
          >
            <Draggable>
              <ResizableBox
                width={boxWidth}
                style={{
                  position: "fixed",
                  top: "20%",
                  right: "2%",
                  zIndex: "1055",
                  backgroundColor: "#fff",
                }}
              >
                <form onSubmit={addNotes}>
                  <Row className="align-items-center ">
                    <div className="col-12 d-flex justify-content-between">
                      <h5>Add New Note</h5>
                      <div className="text-end">
                        <button className="btn btn-purple  me-2">
                          <i className="fas fa-plus me-2"></i>Add Note
                        </button>
                        <a
                          onClick={() => setNotesSidebar(false)}
                          className="btn btn-red text-uppercase"
                        >
                          <i className="dripicons-cross me-1 icon-center"></i>
                          Close
                        </a>
                      </div>
                    </div>
                    <div className="col-12 ">
                      <label htmlFor="">Description</label>
                      <ReactQuill
                        theme="snow"
                        value={description}
                        onChange={setDescription}
                        modules={{
                          toolbar: toolbarOptions,
                        }}
                        className="bd-custome-editor"
                        style={{ height: "400px" }}
                        placeholder="Add Notes Description ..."
                      />
                    </div>

                    <div className="col-md-5 mt-3 notes">
                      <label htmlFor="touch">
                        Got in touch with this Project
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="datetime-local"
                        value={notedate}
                        onChange={e => setNotedate(e.target.value)}
                        className="form-control "
                        required
                      />
                    </div>

                    <div className="col-md-3 mt-3">
                      <label htmlFor="">Attachment</label>

                      <Dropzone
                        onDrop={acceptedFiles => {
                          handleAcceptedFiles1(acceptedFiles)
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div
                            className="dz-message needsclick p-0"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="d-flex px-2 custom-file-upload align-items-center">
                              <div className="">
                                <i className="display-6 text-muted bx bxs-cloud-upload" />
                              </div>
                              <h5 className="mb-0 ms-2">File</h5>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </div>
                    <div
                      className={`col-md-4 mt-3 dropzone-previews ${
                        selectedFiles1.length > 0 ? "d-block" : "d-none"
                      }`}
                      id="file-previews"
                    >
                      <label className="mb-1">File Name</label>
                      <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                        <div className="p-1">
                          <Row className="align-items-center justify-content-evenly">
                            {selectedFiles1?.map((f, i) => {
                              return (
                                <Col key={i} className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                  />
                                </Col>
                              )
                            })}
                          </Row>
                        </div>
                      </Card>
                    </div>
                  </Row>
                </form>
              </ResizableBox>
            </Draggable>
          </div>

          <div className="mt-4">
            <ul className="verti-timeline list-unstyled notes-box">
              <div>
                {data.map(note => (
                  <li className="event-list position-relative" key={note.id}>
                    <div className="event-timeline-dot">
                      <img
                        src={`${configure.imgUrl}${note?.avatar}`}
                        className="userImg me-3 "
                        alt=""
                      />
                    </div>
                    <div className="d-flex">
                      <div className="flex-grow-1 ms-4 note py-2 rounded ps-3 pr-10">
                        <div className=" d-flex justify-content-between ">
                          <div>
                            <p className="text-muted ">
                              <h6 className="d-inline me-2">By {note?.name}</h6>{" "}
                              At {note?.created_at}
                              <span className="pl-15">
                                Got in touch with this Project at{" "}
                                {moment(note?.date).format(
                                  "DD-MM-YYYY HH:mm:ss"
                                )}
                              </span>
                            </p>
                          </div>
                          <div className="me-4">
                            <a
                              className="btn btn-sm edit-delete-btn text-success me-2"
                              onClick={() => {
                                pinnedNote(note?.id, note?.pinned == 0 ? 1 : 0)
                              }}
                            >
                              <i
                                className={` ${
                                  note.pinned == 1
                                    ? "bi bi-pin-angle-fill"
                                    : " bi bi-pin-angle"
                                }`}
                                title="Pin Note"
                              ></i>
                            </a>
                            <a
                              className="btn btn-sm edit-delete-btn text-success me-2"
                              onClick={() => {
                                setNoteId(note?.id)
                                setDecriptId(
                                  getEncryptedData(note?.id),
                                  note?.id
                                )
                                setCustomSwitch(
                                  note?.share_users != null ? true : false
                                )
                                const ids = note?.share_users?.split(",")
                                setAssignMember(
                                  staffOption?.filter(obj =>
                                    ids?.includes(obj.value?.toString())
                                  )
                                )
                                setIsShared(true)
                              }}
                            >
                              <i
                                className="bi bi-share-fill"
                                title="Share Note"
                              ></i>
                            </a>
                            <a
                              className="btn btn-sm edit-delete-btn text-success me-2"
                              onClick={() => {
                                setEditDescription(note?.decription)
                                setNoteId(note?.id)
                                setEditNoteDate(note?.date)
                                setEditNotesSidebar(true)
                              }}
                            >
                              <i className="fas fa-edit" title="Edit Note"></i>
                            </a>
                            <a
                              className="btn btn-sm edit-delete-btn text-success me-2"
                              onClick={() => {
                                setViewDescription(note.decription)
                                setViewFiles(
                                  note?.file != null
                                    ? JSON.parse(note.file)
                                    : []
                                )
                                setViewNote(true)
                              }}
                            >
                              <i className="fas fa-eye" title="View Note"></i>
                            </a>
                            <button
                              className="btn btn-sm edit-delete-btn text-danger"
                              onClick={() => deleteNote(note?.id)}
                            >
                              <i
                                className="fas fa-trash-alt"
                                title="Delete Note"
                              ></i>
                            </button>
                          </div>
                        </div>
                        <i className="bi bi-triangle-fill note-icon"></i>

                        <div className="Features">
                          <p
                            className="mb-2"
                            dangerouslySetInnerHTML={{
                              __html: note.decription,
                            }}
                          ></p>
                          <div />
                          <div>
                            <div>
                              Attachment:
                              {note?.file != null ? (
                                <>
                                  {JSON.parse(note?.file)?.map((obj, i) => {
                                    return (
                                      <a
                                        target="_blank"
                                        rel="noreferrer"
                                        className="activity-log-btn text-info"
                                        href={obj}
                                        key={i}
                                      >
                                        <i className="fas fa-eye pr-10"></i>View
                                      </a>
                                    )
                                  })}
                                </>
                              ) : (
                                <small className="text-dark ms-2">
                                  No Files Available
                                </small>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </div>
            </ul>
            {data.length == 0 ? <p>Note is Not Addded</p> : null}
          </div>

          {/* Share Notes popup  */}
          <div
            className={`note-sticky-form ${isShared ? "d-block" : "d-none"}`}
          >
            <Draggable>
              <ResizableBox
                width={1000}
                style={{
                  position: "fixed",
                  top: "16%",
                  right: "18%",
                  zIndex: "1055",
                  backgroundColor: "#fff",
                }}
              >
                <div className="modal-header pe-4">
                  <h5 className="modal-title mt-0">Share Note</h5>
                </div>

                <div className="modal-body pe-4">
                  <Form
                    className="row d-flex justify-content-center align-items-center"
                    onSubmit={shareNotes}
                  >
                    <div className="col-md-5 d-flex justify-content-end align-items-center ps-3">
                      <div className="text-center">
                        <img
                          src={shareNotesImg}
                          alt="Addepe"
                          style={{ width: "90%" }}
                        />
                      </div>
                    </div>

                    <div className="col-md-7 d-flex flex-column justify-space-between  gap-2">
                      <div className="col-12 d-flex  flex-wrap gap-3">
                        <h5>Do you want to share this note</h5>
                        <Switch
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          className="me-1 mb-sm-12 mb-2"
                          onColor="#762b98"
                          onChange={() => {
                            setCustomSwitch(!customSwitch)
                          }}
                          checked={customSwitch}
                        />
                      </div>
                      {customSwitch && (
                        <div className="col-md-12 mb-3 mb-md-0">
                          <label htmlFor="">
                            Select Staff
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            placeholder="Select Staff"
                            className="basic-multi-select"
                            classNamePrefix=" select"
                            options={staffOption}
                            value={assignMember}
                            onChange={e => setAssignMember(e)}
                            closeMenuOnSelect={false}
                            isMulti
                          />
                        </div>
                      )}
                      <div className="col-12 d-flex flex-column  mt-3 mb-4">
                        {customSwitch && (
                          <div>
                            <a
                              href={
                                configure.websiteUrl +
                                "/work/digital_marketing/notes/" +
                                decriptId
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {configure.websiteUrl +
                                "/work/digital_marketing/notes/" +
                                decriptId}
                            </a>
                            {copy.type == 1 && (
                              <div className="position-absolute DMcopyMsg">
                                Copied
                              </div>
                            )}
                            <i
                              className="bi bi-clipboard ms-2 position-relative cursor-pointer"
                              onClick={e =>
                                copyToClipBoard({
                                  e: `${
                                    configure.websiteUrl +
                                    "/work/digital_marketing/notes/" +
                                    decriptId
                                  }`,
                                  setCopy: setCopy,
                                  id: 1,
                                })
                              }
                              value="https://www.google.com"
                            ></i>
                          </div>
                        )}
                      </div>

                      <div className="text-end mt-4">
                        <button className=" btn btn-save btn-label">
                          <i className="fas fa-save me-2 label-icon"></i>
                          Save
                        </button>
                        <a
                          onClick={() => setIsShared(false)}
                          className=" btn ms-md-2 btn-red btn-label"
                        >
                          <i className="dripicons-cross me-2 icon-center label-icon"></i>
                          CANCEL
                        </a>
                      </div>
                    </div>
                  </Form>
                </div>
              </ResizableBox>
            </Draggable>
          </div>
          {/* Edit Notes  */}
          <div
            className={`note-sticky-form ${
              editNotesSidebar ? "d-block" : "d-none"
            }`}
          >
            <Draggable>
              <ResizableBox
                width={boxWidth}
                style={{
                  position: "fixed",
                  top: "20%",
                  right: "2%",
                  zIndex: "1055",
                  backgroundColor: "#fff",
                }}
              >
                <form onSubmit={EditNotes}>
                  <Row className="align-items-center ">
                    <div className="col-12 d-flex justify-content-between">
                      <h5>Edit Note</h5>
                      <div className="text-end">
                        <button className="btn btn-purple  me-2">
                          <i className="fas fa-plus me-2"></i>Edit Note
                        </button>
                        <a
                          onClick={() => setEditNotesSidebar(false)}
                          className="btn btn-red text-uppercase "
                        >
                          <i className="dripicons-cross me-1 icon-center"></i>
                          Close
                        </a>
                      </div>
                    </div>
                    <div className="col-12 ">
                      <label htmlFor="">Description</label>
                      <ReactQuill
                        theme="snow"
                        value={editDescription}
                        onChange={setEditDescription}
                        modules={{
                          toolbar: toolbarOptions,
                        }}
                        className="bd-custome-editor"
                        style={{ height: "400px" }}
                        placeholder="Add Notes Description ..."
                      />
                    </div>

                    <div className="col-md-5 mt-3 notes">
                      <label htmlFor="touch">
                        Got in touch with this Project{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="datetime-local"
                        value={editNoteDate}
                        onChange={e => setEditNoteDate(e.target.value)}
                        className="form-control "
                        required
                      />
                    </div>

                    <div className="col-md-3 mt-3">
                      <label htmlFor="">Attachment</label>
                      <Dropzone
                        onDrop={acceptedFiles => {
                          handleAcceptedFiles1(acceptedFiles)
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div
                            className="dz-message needsclick p-0"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="d-flex px-2 custom-file-upload align-items-center">
                              <div className="">
                                <i className="display-6 text-muted bx bxs-cloud-upload" />
                              </div>
                              <h5 className="mb-0 ms-2">File</h5>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </div>
                    <div
                      className={`col-md-4 mt-3 dropzone-previews ${
                        selectedFiles1.length > 0 ? "d-block" : "d-none"
                      }`}
                      id="file-previews"
                    >
                      <label className="mb-1">File Name</label>
                      <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                        <div className="p-1">
                          <Row className="align-items-center justify-content-evenly">
                            {selectedFiles1?.map((f, i) => {
                              return (
                                <Col key={i} className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                  />
                                </Col>
                              )
                            })}
                          </Row>
                        </div>
                      </Card>
                    </div>
                  </Row>
                </form>
              </ResizableBox>
            </Draggable>
          </div>
        </div>
      </div>
      <Modal Modal isOpen={viewNote} size="xl" className="" centered>
        <div className="modal-header pe-4">
          <h5 className="modal-title mt-0">View Notes</h5>
          <button
            type="button"
            onClick={() => {
              setViewNote(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>

        <div className="modal-body pe-4">
          <Form className="row d-flex justify-content-center align-items-center">
            <div className="col-md-4 d-flex justify-content-end align-items-center ps-3">
              <div className="text-center">
                <img src={viewNotes} alt="Addepe" style={{ width: "90%" }} />
              </div>
            </div>

            <div className="col-md-7 d-flex flex-column ">
              <div className="col-12 mb-3">
                <label htmlFor="">Description</label>
                <div className="bd-span-input">
                  <div className="input-span input p-3">
                    <p
                      dangerouslySetInnerHTML={{ __html: viewDescription }}
                    ></p>
                  </div>
                </div>
              </div>

              <div className={`col-md-6 mt-3 mb-3`} id="file-previews">
                <label className="mb-1">Uploaded Files</label>
                <Card className="mt-1 mb-0 ">
                  <div className="p-1">
                    <Row className="align-items-center gap-2">
                      {viewFiles?.map((obj, i) => {
                        return (
                          <a
                            target="_blank"
                            rel="noreferrer"
                            className="activity-log-btn text-info col-md-3"
                            href={obj}
                            key={i}
                          >
                            <i className="fas fa-eye pr-10"></i>View
                          </a>
                        )
                      })}
                    </Row>
                    {viewFiles?.length == 0 ? (
                      <span className="text-dark">No files are Available</span>
                    ) : null}
                  </div>
                </Card>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default DmNotes
