import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../assets/css/RolesResponse/Roles.css"
import Select from "react-select"
import InfoGif from "../../assets/images/GIF/question-mark.gif"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import warningIcon from "../../assets/images/exclamation_14025462.png"
import {
  Container,
  Table,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
} from "reactstrap"
import classnames from "classnames"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import configure from "configure"
import Swal from "sweetalert2"
import secureLocalStorage from "react-secure-storage"
import { allElements } from "utils/elements/element-config"
import { DashboardElement } from "utils/common-options"

const AddRoles = () => {
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const [permissionActiveTab, setPermissionActiveTab] = useState({
    i: 0,
    countryId: null,
  })
  const [fixedContent, setFixedContent] = useState(false)
  const [fixedSideTab, setFixedSideTab] = useState(false)
  const [role_name, setRoleName] = useState("")
  const [roleId, setRoleId] = useState(null)
  const [dashboardElem, setDashboardElem] = useState([])
  const [description, setDescription] = useState("")
  const [error, setError] = useState(null)
  const [error1, setError1] = useState(null)
  const [show_module, setShowModule] = useState([])
  const [global_modal, setGlobalModal] = useState(false)
  const [view_modal, setViewModal] = useState(false)
  const [add_modal, setAddModal] = useState(false)
  const [edit_modal, setEditModal] = useState(false)
  const [delete_modal, setDeleteModal] = useState(false)
  const [warningTab, setWarningTab] = useState(false)
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const checkAllSubmodule = ({ e, name, count, globe }) => {
    console.log("check ....")
    let idName = name
    if (name.includes(" ")) {
      idName = name?.trim().replace(/ /g, "_")
    }
    const checkboxes = document.getElementsByClassName(`${idName}${count}`)
    let allUnchecked = true

    for (let i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked) {
        allUnchecked = false
      }
    }
    const checkbox = document.getElementById(`${idName}${globe}`)
    if (checkbox) {
      checkbox.checked = !allUnchecked
    }
  }

  const allGlobChecked = ({ e, name }) => {
    if (e.target.checked) {
      if (name.includes(" ")) {
        let idName = name.replace(/ /g, "_")
        const checkboxes = document.getElementsByClassName(`${idName}one`)
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = true
        }
      } else {
        const checkboxes = document.getElementsByClassName(`${name}one`)
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = true
        }
      }
    } else if (!e.target.checked) {
      if (name.includes(" ")) {
        let idName = name.replace(/ /g, "_")
        const checkboxes = document.getElementsByClassName(`${idName}one`)
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = false
        }
      } else {
        const checkboxes = document.getElementsByClassName(`${name}one`)
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = false
        }
      }
    }
  }

  const allViewChecked = ({ e, name }) => {
    if (e.target.checked) {
      if (name.includes(" ")) {
        let idName = name.replace(/ /g, "_")
        const checkboxes = document.getElementsByClassName(`${idName}two`)
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = true
        }
      } else {
        const checkboxes = document.getElementsByClassName(`${name}two`)
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = true
        }
      }
    } else if (!e.target.checked) {
      if (name.includes(" ")) {
        let idName = name.replace(/ /g, "_")
        const checkboxes = document.getElementsByClassName(`${idName}two`)
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = false
        }
      } else {
        const checkboxes = document.getElementsByClassName(`${name}two`)
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = false
        }
      }
    }
  }
  const allAddChecked = ({ e, name, isCheck }) => {
    if (e.target.checked) {
      if (name.includes(" ")) {
        let idName = name.replace(/ /g, "_")
        const checkboxes = document.getElementsByClassName(`${idName}three`)
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = true
        }
      } else {
        const checkboxes = document.getElementsByClassName(`${name}three`)
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = true
        }
      }
    } else if (!e.target.checked) {
      if (name.includes(" ")) {
        let idName = name.replace(/ /g, "_")
        const checkboxes = document.getElementsByClassName(`${idName}three`)
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = false
        }
      } else {
        const checkboxes = document.getElementsByClassName(`${name}three`)
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = false
        }
      }
    }
  }

  const allUpdateChecked = ({ e, name, isCheck }) => {
    if (e.target.checked) {
      if (name.includes(" ")) {
        let idName = name.replace(/ /g, "_")
        const checkboxes = document.getElementsByClassName(`${idName}four`)
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = true
        }
      } else {
        const checkboxes = document.getElementsByClassName(`${name}four`)
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = true
        }
      }
    } else if (!e.target.checked) {
      if (name.includes(" ")) {
        let idName = name.replace(/ /g, "_")
        const checkboxes = document.getElementsByClassName(`${idName}four`)
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = false
        }
      } else {
        const checkboxes = document.getElementsByClassName(`${name}four`)
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = false
        }
      }
    }
  }
  const allDeleteChecked = ({ e, name, isCheck }) => {
    if (e.target.checked) {
      if (name.includes(" ")) {
        let idName = name.replace(/ /g, "_")
        const checkboxes = document.getElementsByClassName(`${idName}five`)
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = true
        }
      } else {
        const checkboxes = document.getElementsByClassName(`${name}five`)
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = true
        }
      }
    } else if (!e.target.checked) {
      if (name.includes(" ")) {
        let idName = name.replace(/ /g, "_")
        const checkboxes = document.getElementsByClassName(`${idName}five`)
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = false
        }
      } else {
        const checkboxes = document.getElementsByClassName(`${name}five`)
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = false
        }
      }
    }
  }

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 1200,
    }),
  }

  function tog_center() {
    setGlobalModal(false)
    setViewModal(false)
    setAddModal(false)
    setEditModal(false)
    setDeleteModal(false)
    removeBodyCss()
  }
  const toggleWarningTab = () => {
    setWarningTab(!warningTab)
  }

  document.addEventListener("scroll", () => {
    if (window.scrollY > 68) {
      setFixedSideTab(true)
    } else {
      setFixedSideTab(false)
    }
  })

  document.addEventListener("scroll", () => {
    if (window.scrollY > 230) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  const navigate = useNavigate()

  // title
  document.title = "Roles & Permission  | Zithas Crm"

  useEffect(() => {
    fetch2()
    fetch3()
  }, [])

  const [all_module, setModules] = useState([])
  const [locations, setLocations] = useState([])

  const fetch = async () => {
    setLoading(true)
    const bearerToken = secureLocalStorage.getItem("auth_token")
    console.log("bearerToken", bearerToken)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/allModules`,
        headers: {
          Authorization: "Bearer " + bearerToken,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        const ConvertArray = response.data.data?.map(obj => {
          const isModuleIdOne = obj.module_id === 1
          const mergedObj = {
            ...obj,
            ...(isModuleIdOne
              ? { Global: 1, View: 1, Add: 1, Edit: 1, Delete: 1 }
              : {}),
          }

          mergedObj.submodules = obj.submodules.map(sub => {
            return {
              ...sub,
              ...(isModuleIdOne
                ? { Global: 1, View: 1, Add: 1, Edit: 1, Delete: 1 }
                : {}),
            }
          })
          return mergedObj
          setLoading(false)
        })

        setShowModule(ConvertArray)
        // setShowModule(response.data.data)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const fetch2 = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/locations`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setLocations(response.data.data)
        setPermissionActiveTab({ i: 0, countryId: response?.data.data[0]?.id })
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const [dashboardList, setDashboardList] = useState([])
  const fetch3 = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/dashboardList`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        const options = response.data.data.map(item => ({
          value: item.id,
          label: item.name,
        }))
        setDashboardList(options)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const [isLoading, setLoading] = useState(false)
  const [dashboardElemError, setDashboardElemError] = useState(false)
  const [default_dashboard, setDefaultDashboard] = useState([])
  const [dashboard, setDashboard] = useState([])

  const onAddRoles = async e => {
    e.preventDefault()
    if (roleId !== "" && roleId !== null && roleId !== undefined) {
      var x1 = document.querySelectorAll(".first_global")
      var x2 = document.querySelectorAll(".second_view")
      var x3 = document.querySelectorAll(".third_add")
      var x4 = document.querySelectorAll(".fourth_edit")
      var x5 = document.querySelectorAll(".fifth_delete")
      var role = document.querySelectorAll("#module_id")

      var module_id = []
      for (var i = 0; i < role.length; i++) {
        module_id.push(parseInt(role[i].value))
      }

      var one = 0
      for (var i = 0; i < x1.length; i++) {
        if (x1[i].checked == true) {
          one = one + "1"
        } else {
          one = one + "0"
        }
      }
      var second = 0
      for (var i = 0; i < x2.length; i++) {
        if (x2[i].checked == true) {
          second = second + "1"
        } else {
          second = second + "0"
        }
      }
      var third = 0
      for (var i = 0; i < x3.length; i++) {
        if (x3[i].checked == true) {
          third = third + "1"
        } else {
          third = third + "0"
        }
      }
      var fourth = 0
      for (var i = 0; i < x4.length; i++) {
        if (x4[i].checked == true) {
          fourth = fourth + "1"
        } else {
          fourth = fourth + "0"
        }
      }
      var five = 0
      for (var i = 0; i < x5.length; i++) {
        if (x5[i].checked == true) {
          five = five + "1"
        } else {
          five = five + "0"
        }
      }

      var gloabal = one.substring(1).split("").map(Number)
      var view = second.substring(1).split("").map(Number)
      var add = third.substring(1).split("").map(Number)
      var edit = fourth.substring(1).split("").map(Number)
      var delte = five.substring(1).split("").map(Number)

      const array = [module_id, gloabal, view, add, edit, delte]

      const newArray = []
      const subarrayLength = array[0].length

      for (let i = 0; i < subarrayLength; i++) {
        const subarray = array.map(sub => sub[i])
        newArray.push(subarray)
      }

      const StringArray = newArray.map(subarray => subarray.join(","))

      const result = dashboard.map(obj => obj.value).join(",")

      setLoading(true)
      const formData = new FormData()
      formData.append("role_id", roleId)
      formData.append("country_id", permissionActiveTab.countryId)
      formData.append("permissions", JSON.stringify(StringArray))

      try {
        const response = await axios({
          method: "post",
          url: `${configure.apiUrl}/addPermission`,
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(response => {
          Swal.fire({
            icon: "success",
            text: "Role permissions added successfully",
          }).then(data => {
            navigate(`${configure.appUrl}roles`)
          })
          setLoading(false)
        })
      } catch (err) {
        setLoading(false)
        Swal.fire({
          icon: "error",
          text: "Something Went Wrong",
        })
      }
    } else {
      Swal.fire({
        title: "Define roles before granting permissions",
        icon: "warning",
        confirmButtonText: "Ok",
      }).then(() => {
        setverticalActiveTab("1")
      })
    }
  }
  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }
  // personal details
  const handleSubmit = async e => {
    e.preventDefault()
    if (description !== "") {
      setLoading(true)
      const formData = new FormData()
      formData.append("role_name", role_name)
      formData.append("elements", dashboardElem ? dashboardElem?.map(em => em.value).toString() : "")
      formData.append("kra", description)
      try {
        const response = await axios({
          method: "post",
          url: `${configure.apiUrl}/addRoles`,
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(response => {
          setLoading(false)
          setRoleId(response.data.data)
          Swal.fire({
            icon: "success",
            text: "New role deatils added successfully.",
          }).then(data => {
            toggleVertical("2")
          })
          setLoading(false)
        })
      } catch (err) {
        setLoading(false)
      }
    } else {
      setLoading(false)
      setError(true)
    }
  }

  const handleWarningPopup = ({ roleIdCH, countryId }) => {
    if (roleId !== "" && roleId !== null && roleId !== undefined) {
      Swal.fire({
        title: "Please do save permissson before changing the country.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ok save it",
        customClass: {
          confirmButton: "swal2-confirm-button btn-purple border-0 shadow-none",
          cancelButton: "swal2-cancel-button",
        },
      }).then(async willOkay => {
        console.log(willOkay)
        if (willOkay.isConfirmed) {
          var x1 = document.querySelectorAll(".first_global")
          var x2 = document.querySelectorAll(".second_view")
          var x3 = document.querySelectorAll(".third_add")
          var x4 = document.querySelectorAll(".fourth_edit")
          var x5 = document.querySelectorAll(".fifth_delete")
          var role = document.querySelectorAll("#module_id")

          var module_id = []
          for (var i = 0; i < role.length; i++) {
            module_id.push(parseInt(role[i].value))
          }

          var one = 0
          for (var i = 0; i < x1.length; i++) {
            if (x1[i].checked == true) {
              one = one + "1"
            } else {
              one = one + "0"
            }
          }
          var second = 0
          for (var i = 0; i < x2.length; i++) {
            if (x2[i].checked == true) {
              second = second + "1"
            } else {
              second = second + "0"
            }
          }
          var third = 0
          for (var i = 0; i < x3.length; i++) {
            if (x3[i].checked == true) {
              third = third + "1"
            } else {
              third = third + "0"
            }
          }
          var fourth = 0
          for (var i = 0; i < x4.length; i++) {
            if (x4[i].checked == true) {
              fourth = fourth + "1"
            } else {
              fourth = fourth + "0"
            }
          }
          var five = 0
          for (var i = 0; i < x5.length; i++) {
            if (x5[i].checked == true) {
              five = five + "1"
            } else {
              five = five + "0"
            }
          }

          var gloabal = one.substring(1).split("").map(Number)
          var view = second.substring(1).split("").map(Number)
          var add = third.substring(1).split("").map(Number)
          var edit = fourth.substring(1).split("").map(Number)
          var delte = five.substring(1).split("").map(Number)

          const array = [module_id, gloabal, view, add, edit, delte]

          const newArray = []
          const subarrayLength = array[0].length

          for (let i = 0; i < subarrayLength; i++) {
            const subarray = array.map(sub => sub[i])
            newArray.push(subarray)
          }

          const StringArray = newArray.map(subarray => subarray.join(","))

          const result = dashboard.map(obj => obj.value).join(",")

          setLoading(true)
          const formData = new FormData()
          formData.append("role_id", roleId)
          formData.append("country_id", permissionActiveTab.countryId)
          formData.append("permissions", JSON.stringify(StringArray))

          try {
            const response = await axios({
              method: "post",
              url: `${configure.apiUrl}/addPermission`,
              data: formData,
              headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
              },
            }).then(response => {
              Swal.fire({
                icon: "success",
                text: "Role permissions added successfully.",
              }).then(data => {
                setPermissionActiveTab({
                  i: roleIdCH,
                  countryId: countryId,
                })
                const Dashglobal = document.getElementById("Dashboardglobal")
                const Dashview = document.getElementById("Dashboardview")
                const Dashadd = document.getElementById("Dashboardadd")
                const Dashedit = document.getElementById("Dashboardedit")
                const dashdelete = document.getElementById("Dashboarddelete")

                x1.forEach(checkbox => {
                  if (!Dashglobal.contains(checkbox)) {
                    checkbox.checked = false
                  }
                })
                x2.forEach(checkbox => {
                  if (!Dashview.contains(checkbox)) {
                    checkbox.checked = false
                  }
                })
                x3.forEach(checkbox => {
                  if (!Dashadd.contains(checkbox)) {
                    checkbox.checked = false
                  }
                })
                x4.forEach(checkbox => {
                  if (!Dashedit.contains(checkbox)) {
                    checkbox.checked = false
                  }
                })
                x5.forEach(checkbox => {
                  if (!dashdelete.contains(checkbox)) {
                    checkbox.checked = false
                  }
                })
              })

              setLoading(false)
            })
          } catch (err) {
            setLoading(false)
            Swal.fire({
              icon: "error",
              text: "Something Went Wrong",
            })
          }
        } else {
          // setPermissionActiveTab({
          //   i: i,
          //   countryId: countryId,
          // })
        }
      })
    } else {
      Swal.fire({
        title: "Define Roles Before Granting Permissions",
        icon: "warning",
        confirmButtonText: "Ok",
      }).then(() => {
        setverticalActiveTab("1")
      })
    }
  }
  const fetchRolePermission = async () => {
    const formData = new FormData()
    formData.append("role_id", roleId)
    formData.append("country_id", permissionActiveTab.countryId)
    setLoading(true)
    try {
      await axios({
        method: "post",
        url: `${configure.apiUrl}/showRoles`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      }).then(response => {
        // console.log("showRoles", response)
        // console.log("length", response.data.data.role.permissions.length)
        if (response.data.success) {
          if (response.data.data.role.permissions.length !== 0) {
            const Permissions = response?.data?.data?.role.permissions?.map(
              item => {
                return item.permission
              }
            )
            const permissionsArray = Permissions[0]
            if (permissionsArray !== null || permissionsArray !== undefined) {
              let newArrays
              if (typeof permissionsArray === "string") {
                newArrays = JSON.parse(Permissions[0])?.map(str => {
                  const [moduleId, Global, View, Add, Edit, Delete] =
                    str.split(",")
                  return {
                    moduleId: Number(moduleId),
                    Global: Number(Global),
                    View: Number(View),
                    Add: Number(Add),
                    Edit: Number(Edit),
                    Delete: Number(Delete),
                  }
                })
              } else {
                newArrays = permissionsArray?.map(str => {
                  const [moduleId, Global, View, Add, Edit, Delete] =
                    str.split(",")
                  return {
                    moduleId: Number(moduleId),
                    Global: Number(Global),
                    View: Number(View),
                    Add: Number(Add),
                    Edit: Number(Edit),
                    Delete: Number(Delete),
                  }
                })
              }

              const ConvertArray = response.data.data.modules.map(obj => {
                const matchingObj = newArrays?.find(
                  item => item.moduleId === obj.module_id
                )
                const mergedObj = { ...obj, ...matchingObj }
                mergedObj.submodules = obj.submodules.map(sub => ({
                  ...sub,
                  ...newArrays?.find(item => item.moduleId === sub.module_id),
                }))
                return mergedObj
              })
              // console.log("ConvertArray", ConvertArray)
              setShowModule(ConvertArray)
              setLoading(false)
            } else {
              const ConvertArray = response.data.data.modules.map(obj => {
                const isModuleIdOne = obj.module_id === 1
                const mergedObj = {
                  ...obj,
                  ...(isModuleIdOne
                    ? { Global: 1, View: 1, Add: 1, Edit: 1, Delete: 1 }
                    : {}),
                }

                mergedObj.submodules = obj.submodules.map(sub => {
                  return {
                    ...sub,
                    ...(isModuleIdOne
                      ? { Global: 1, View: 1, Add: 1, Edit: 1, Delete: 1 }
                      : {}),
                  }
                })
                return mergedObj
                setLoading(false)
              })

              setShowModule(ConvertArray)
            }
          } else {
            // console.log("checked")
            fetch()
          }
        } else {
          fetch()
        }
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }
  useEffect(() => {
    if (verticalActiveTab == 2 && roleId == null) {
      fetch()
    }
  }, [permissionActiveTab.countryId, verticalActiveTab])

  useEffect(() => {
    console.log(verticalActiveTab)
    if (verticalActiveTab == 2 && roleId !== null) {
      setShowModule([])
      fetchRolePermission()
    }
  }, [permissionActiveTab.countryId, verticalActiveTab])

  const [openIndex, setOpenIndex] = useState(null)

  const handleToggle = index => {
    setOpenIndex(openIndex === index ? null : index)
  }

  const handleActionClick = ({ e, moduleId, actionType, name, isCheck }) => {
    const globalChecked = document.getElementById(`${moduleId}global`).checked
    const viewChecked = document.getElementById(`${moduleId}view`).checked

    console.log("viewChecked", viewChecked)
    console.log("globalChecked", globalChecked)

    if (viewChecked == true || globalChecked == true) {
      if (isCheck == 3) {
        allAddChecked({
          e: e,
          name: name,
          isCheck: isCheck,
        })
      } else if (isCheck == 4) {
        allUpdateChecked({
          e: e,
          name: name,
          isCheck: isCheck,
        })
      } else if (isCheck == 5) {
        allDeleteChecked({
          e: e,
          name: name,
          isCheck: isCheck,
        })
      }
    } else {
      e.preventDefault()
      Swal.fire({
        icon: "warning",
        title: "Action Restricted",
        text: 'Please ensure that "Global" and "View" checkboxes are checked before proceeding.',
        confirmButtonText: "OK",
      }).then(() => {
        e.target.checked = false
      })
    }
  }

  const handleActionSubClick = ({
    e,
    subModuleId,
    actionType,
    name,
    count,
    globe,
  }) => {
    console.log("subModuleId".subModuleId)
    const globalCheckbox = document.querySelector(`.${subModuleId}global`)
    const viewCheckbox = document.querySelector(`.${subModuleId}view`)
    const globalChecked = globalCheckbox ? globalCheckbox.checked : false
    const viewChecked = viewCheckbox ? viewCheckbox.checked : false

    console.log("viewChecked", viewChecked)
    console.log("globalChecked", globalChecked)

    if (viewChecked == true || globalChecked == true) {
      checkAllSubmodule({
        e: e,
        name: name,
        count: count,
        globe: globe,
      })
    } else {
      e.preventDefault() // Prevent checkbox change
      Swal.fire({
        icon: "warning",
        title: "Action Restricted",
        text: 'Please ensure that "Global" and "View" checkboxes are checked before proceeding.',
        confirmButtonText: "OK",
      }).then(() => {
        e.target.checked = false
      })
    }
  }
  return (
    <React.Fragment>
      <div className="page-content mb-4" id="addRoles">
        {isLoading && <Loader />}
        <Container fluid>
          <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Add Roles"} />
          {/* Input fields */}
          <Row className="gx-4 justify-content-between">
            <Col md="2" className="px-0">
              <Nav
                pills
                className={`side-tab flex-column px-2 py-3 bg-white ${fixedSideTab ? "fixed-side-tab" : ""
                  } h-100`}
              >
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab == 1 ? true : false,
                    })}
                    onClick={() => {
                      toggleVertical("1")
                    }}
                  >
                    Role Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab == 2 ? true : false,
                    })}
                    onClick={() => {
                      toggleVertical("2")
                    }}
                  >
                    Permission
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col md="10 pe-0">
              <div className="card p-4 bg-white">
                {/* fixed buttons */}
                <TabContent
                  activeTab={verticalActiveTab}
                  className="text-muted mt-4 mt-md-0"
                >
                  <TabPane tabId="1">
                    <div className="pb-5">
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div
                            className={`flex-column fixed-buttons pt-2 ${fixedContent ? "d-flex" : "d-none"
                              }`}
                          >
                            <button
                              type="submit"
                              className="btn btn-save border-radius fix-btn btn-label pe-0"
                            >
                              <i className="fas fa-save me-2 label-icon"></i>
                              <span>Save</span>
                            </button>
                            <a
                              type="button"
                              onClick={() => navigate(-1)}
                              className="btn btn-red border-radius fix-btn btn-label pe-0"
                            >
                              <i className="dripicons-cross me-2 icon-center label-icon"></i>
                              <span>Cancel</span>
                            </a>
                          </div>
                          <div className="col-md-9">
                            <div>
                              <h4 className="text-dark above">Role Details</h4>
                            </div>
                          </div>
                          <div className="col-md-3 mb-3 text-md-end">
                            <button
                              type="submit"
                              className="border-radius btn me-md-0 me-2 btn-save btn-label"
                            >
                              <i className="fas fa-save me-2 label-icon"></i>
                              SAVE
                            </button>
                            <button
                              type="button"
                              onClick={() => navigate(-1)}
                              className="border-radius btn ms-md-2 btn-red btn-label"
                            >
                              <i className="dripicons-cross me-2 icon-center label-icon"></i>
                              CANCEL
                            </button>
                          </div>
                        </div>
                        <div className="row ">
                          <div className="col-md-3 mt-2 mb-3">
                            <div className="form-group">
                              <label>
                                Role Name
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Add role"
                                className="form-control form-control-solid form-control-md"
                                name="role_name"
                                value={role_name}
                                required
                                onChange={e => setRoleName(e.target.value)}
                              />
                            </div>
                          </div>

                          <div className="col-12 mt-2 mb-3">
                            <div className="">
                              {/* <label>
                                Dashboard Elements
                                <span className="text-danger">*</span>
                              </label> */}
                              <div className="d-flex justify-space-between">
                                <label htmlFor="">
                                  Dashboard Elements
                                  {/* <span className="text-danger">*</span> */}
                                </label>
                                {/* <span
                                  className="text-danger"
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "500",
                                    paddingTop: "7px",
                                  }}
                                >
                                  {(dashboardElem.length == 0) == error1
                                    ? "Dashboard Elements are required"
                                    : null}
                                </span> */}
                              </div>
                              <Select
                                required
                                placeholder="Select Dashboard Element"
                                className="basic-multi-select"
                                classNamePrefix="border-radius select"
                                isMulti
                                styles={colourStyles}
                                options={DashboardElement}
                                closeMenuOnSelect={false}
                                onChange={selectedOption => {
                                  setDashboardElem(selectedOption)
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="">
                              <div className="d-flex justify-space-between">
                                <label htmlFor="">
                                  Key Responsibility Areas{" "}
                                  <span className="text-danger">*</span>
                                </label>
                              </div>

                              <CKEditor
                                editor={ClassicEditor}
                                data=""
                                onReady={editor => {
                                  // You can store the "editor" and use when it is needed.
                                }}
                                onChange={(event, editor) => {
                                  const data = editor.getData()
                                  setDescription(data)
                                }}
                              />
                              <div>
                                <span
                                  className="text-danger"
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "500",
                                    paddingTop: "7px",
                                  }}
                                >
                                  {(description.length == 0) == error
                                    ? "Key Responsibility Areas are required"
                                    : null}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </TabPane>
                  <TabPane tabId="2">
                    <form onSubmit={onAddRoles}>
                      <div
                        className={`flex-column fixed-buttons pt-2 ${fixedContent ? "d-flex" : "d-none"
                          }`}
                      >
                        <button
                          type="submit"
                          className="btn btn-save border-radius fix-btn btn-label pe-0"
                        >
                          <i className="fas fa-save me-2 label-icon"></i>
                          <span>Save</span>
                        </button>
                        <a
                          onClick={() => navigate(-1)}
                          className="btn btn-red border-radius fix-btn btn-label pe-0"
                        >
                          <i className="dripicons-cross me-2 icon-center label-icon"></i>
                          <span>Cancel</span>
                        </a>
                      </div>
                      <Row className="align-items-end justify-space-between">
                        <div className="d-flex justify-content-between">
                          <div className="d-flex gap-3">
                            {locations?.map((ob, i) => {
                              return (
                                <h4 className="mb-3 text-dark" key={ob.id}>
                                  <input
                                    className="form-check-input input-location"
                                    type="radio"
                                    name="exampleRadios1"
                                    id={`exampleRadios1`}
                                    checked={
                                      permissionActiveTab.i == i ? true : false
                                    }
                                    onClick={() =>
                                      handleWarningPopup({
                                        roleIdCH: i,
                                        countryId: ob.id,
                                      })
                                    }
                                  />
                                  <label
                                    className="form-check-label pl-10"
                                    htmlFor={`exampleRadios`}
                                  >
                                    <img
                                      src={ob.Link}
                                      alt={ob.loaction}
                                      width="28px"
                                      height="18px"
                                      className="ps-1"
                                    />{" "}
                                  </label>
                                  {/* {ob.location} */}
                                </h4>
                              )
                            })}
                          </div>

                          <div className="col-md-3 mb-3 text-md-end">
                            <button className="border-radius btn me-md-0 me-2 btn-save btn-label">
                              <i className="fas fa-save me-2 label-icon"></i>
                              SAVE
                            </button>
                            <button
                              type="button"
                              onClick={() => navigate(-1)}
                              className="border-radius btn ms-md-2 btn-red btn-label"
                            >
                              <i className="dripicons-cross me-2 icon-center label-icon"></i>
                              CANCEL
                            </button>
                          </div>
                        </div>
                      </Row>
                      {warningTab && (
                        <div className="warning-confirm">
                          <div
                            id="myModal-confirm"
                            className="shadow-lg px-3 py-4"
                          >
                            <div className="d-flex flex-column justify-content-center">
                              <div className="text-center mb-3">
                                <img
                                  src={warningIcon}
                                  alt=""
                                  className="img-fluid"
                                  width={50}
                                />
                              </div>
                              <div className="fs-4 text-dark fw-bold text-center mb-3">
                                Please do save permissson before Changing the
                                country.
                              </div>
                              <div className="d-flex justify-content-center gap-3">
                                <button
                                  type="button"
                                  className="swal2-cancel-button btn btn-secondary btn-lg"
                                  aria-label=""
                                >
                                  Cancel
                                </button>
                                <button
                                  type="submit"
                                  className="btn btn-purple btn-lg "
                                  aria-label=""
                                >
                                  Ok save it
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="responsive-table mt-2 overflow-auto">
                        <Table className="table table-bordered m-0 text-center mt-4 role-table">
                          <thead
                            className={`${fixedContent ? "fixed-header pt-1" : ""
                              }`}
                          >
                            <tr>
                              <th className="text-start ps-4">Module</th>
                              <th className="cell-width">
                                Global
                                <a onClick={() => setGlobalModal(true)}>
                                  <i
                                    title="info"
                                    className="bx bx-info-circle ms-1 info-icon "
                                  ></i>
                                </a>
                              </th>
                              <th className="cell-width">
                                View
                                <a onClick={() => setViewModal(true)}>
                                  <i
                                    title="info"
                                    className="bx bx-info-circle ms-1 info-icon"
                                  ></i>
                                </a>
                              </th>
                              <th className="cell-width">
                                Add
                                <a onClick={() => setAddModal(true)}>
                                  <i
                                    title="info"
                                    className="bx bx-info-circle ms-1 info-icon"
                                  ></i>
                                </a>
                              </th>
                              <th className="cell-width">
                                Update
                                <a onClick={() => setEditModal(true)}>
                                  <i
                                    title="info"
                                    className="bx bx-info-circle ms-1 info-icon"
                                  ></i>
                                </a>
                              </th>
                              <th className="cell-width">
                                Delete
                                <a onClick={() => setDeleteModal(true)}>
                                  <i
                                    title="info"
                                    className="bx bx-info-circle ms-1 info-icon"
                                  ></i>
                                </a>
                              </th>
                              <th className="cell-width">More</th>
                            </tr>
                          </thead>
                          <tbody>
                            {show_module.map((module, i) => {
                              const checkboxsGlobal =
                                document.getElementById("Dashboardglobal")
                              const checkboxsView =
                                document.getElementById("Dashboardview")
                              const checkboxsAdd =
                                document.getElementById("Dashboardadd")
                              const checkboxsEdit =
                                document.getElementById("Dashboardedit")
                              const checkboxsDelete =
                                document.getElementById("Dashboarddelete")
                              if (checkboxsGlobal != null) {
                                checkboxsGlobal.setAttribute("checked", "true")
                                checkboxsGlobal.setAttribute("disabled", "true")
                              }
                              if (checkboxsView != null) {
                                checkboxsView.setAttribute("checked", "true")
                                checkboxsView.setAttribute("disabled", "true")
                              }
                              if (checkboxsAdd != null) {
                                checkboxsAdd.setAttribute("checked", "true")
                                checkboxsAdd.setAttribute("disabled", "true")
                              }
                              if (checkboxsEdit != null) {
                                checkboxsEdit.setAttribute("checked", "true")
                                checkboxsEdit.setAttribute("disabled", "true")
                              }
                              if (checkboxsDelete != null) {
                                checkboxsDelete.setAttribute("checked", "true")
                                checkboxsDelete.setAttribute("disabled", "true")
                              }
                              const hasSubmodules = module.submodules.length > 0
                              const collapseId = `panelsStayOpen-collapse${i}`
                              const headerId = `panelsStayOpen-heading${i}`
                              const moduleId = module.module_name.replace(
                                " ",
                                "_"
                              )
                              return (
                                <>
                                  <tr key={i} className="">
                                    <td className="p-0" colSpan={7}>
                                      <div
                                        className="accordion"
                                        id="accordionPanelsStayOpenExample"
                                      >
                                        <div className="accordion-item accordian-box">
                                          <div
                                            className="accordion-header"
                                            id={headerId}
                                          >
                                            <button
                                              className={`accordion-button fw-bold accordian-btn bd-accordian-btn ${openIndex === i
                                                  ? ""
                                                  : "collapsed"
                                                } ${hasSubmodules ? "" : "no-after"
                                                }`}
                                              type="button"
                                              onClick={() => handleToggle(i)}
                                            >
                                              {module.module_name}
                                            </button>

                                            <input
                                              id={`module_id`}
                                              value={module.module_id}
                                              readOnly
                                              className="display-none bd-text-center"
                                            />
                                            <div className="outer-toggle-btns">
                                              <div className="form-switch form-switch-md">
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input first_global"
                                                  id={
                                                    module.module_name.replace(
                                                      " ",
                                                      "_"
                                                    ) + "global"
                                                  }
                                                  defaultChecked={
                                                    module.Global == "1"
                                                  }
                                                  onClick={e => {
                                                    allGlobChecked({
                                                      e: e,
                                                      name: module.module_name,
                                                      isCheck: 1,
                                                    })
                                                  }}
                                                />
                                              </div>
                                              <div className="form-switch form-switch-md">
                                                <input
                                                  type="checkbox"
                                                  className={`form-check-input second_view`}
                                                  id={
                                                    module.module_name.replace(
                                                      " ",
                                                      "_"
                                                    ) + "view"
                                                  }
                                                  defaultChecked={
                                                    module.View == "1"
                                                  }
                                                  onClick={e => {
                                                    allViewChecked({
                                                      e: e,
                                                      name: module.module_name,
                                                      isCheck: 2,
                                                    })
                                                  }}
                                                />
                                              </div>
                                              <div className="form-switch form-switch-md">
                                                <input
                                                  type="checkbox"
                                                  className={`form-check-input third_add ${module.module_id === 1
                                                      ? "d-none"
                                                      : ""
                                                    }`}
                                                  id={
                                                    module.module_name.replace(
                                                      " ",
                                                      "_"
                                                    ) + "add"
                                                  }
                                                  defaultChecked={
                                                    module.Add == "1"
                                                  }
                                                  onClick={e => {
                                                    handleActionClick({
                                                      e: e,
                                                      moduleId: moduleId,
                                                      actionType: "add",
                                                      name: module.module_name,
                                                      isCheck: 3,
                                                    })
                                                    // allAddChecked({
                                                    //   e: e,
                                                    //   name: module.module_name,
                                                    //   isCheck: 3,
                                                    // })
                                                  }}
                                                />
                                              </div>
                                              <div className="form-switch form-switch-md">
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input fourth_edit"
                                                  id={
                                                    module.module_name.replace(
                                                      " ",
                                                      "_"
                                                    ) + "edit"
                                                  }
                                                  defaultChecked={
                                                    module.Edit == "1"
                                                  }
                                                  onClick={e => {
                                                    handleActionClick({
                                                      e: e,
                                                      moduleId: moduleId,
                                                      actionType: "edit",
                                                      name: module.module_name,
                                                      isCheck: 4,
                                                    })
                                                    // allUpdateChecked({
                                                    //   e: e,
                                                    //   name: module.module_name,
                                                    //   isCheck: 4,
                                                    // })
                                                  }}
                                                />
                                              </div>
                                              <div className="form-switch form-switch-md">
                                                <input
                                                  type="checkbox"
                                                  className={`form-check-input fifth_delete ${module.module_id === 1
                                                      ? "d-none"
                                                      : ""
                                                    }`}
                                                  id={
                                                    module.module_name.replace(
                                                      " ",
                                                      "_"
                                                    ) + "delete"
                                                  }
                                                  defaultChecked={
                                                    module.Delete == "1"
                                                  }
                                                  onClick={e => {
                                                    // allDeleteChecked({
                                                    //   e: e,
                                                    //   name: module.module_name,
                                                    //   isCheck: 5,
                                                    // })
                                                    handleActionClick({
                                                      e: e,
                                                      moduleId: moduleId,
                                                      actionType: "delete",
                                                      name: module.module_name,
                                                      isCheck: 5,
                                                    })
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            id={collapseId}
                                            className={`accordion-collapse collapse ${openIndex === i ? "show" : ""
                                              }`}
                                            aria-labelledby={`heading${i}`}
                                          >
                                            <div className="accordion-body p-0 ">
                                              <Table className="mb-0 bd-hover-inner bg-grey">
                                                {module.submodules.map(
                                                  (innerModule, index) => {
                                                    const subModuleId = innerModule?.module_name?.trim()?.replace(/\s+/g, '_')
                                                    if (module.module_name) {
                                                      var one = 0
                                                      var two = 0
                                                      var three = 0
                                                      var four = 0
                                                      var five = 0
                                                      var x1 =
                                                        document.querySelectorAll(
                                                          "#" +
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) +
                                                          "one"
                                                        )
                                                      var x2 =
                                                        document.querySelectorAll(
                                                          "#" +
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) +
                                                          "two"
                                                        )
                                                      var x3 =
                                                        document.querySelectorAll(
                                                          "#" +
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) +
                                                          "three"
                                                        )
                                                      var x4 =
                                                        document.querySelectorAll(
                                                          "#" +
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) +
                                                          "four"
                                                        )
                                                      var x5 =
                                                        document.querySelectorAll(
                                                          "#" +
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) +
                                                          "five"
                                                        )

                                                      for (
                                                        var i = 0;
                                                        i < x1.length;
                                                        i++
                                                      ) {
                                                        if (
                                                          x1[i].checked == true
                                                        ) {
                                                          one = one + "1"
                                                        } else {
                                                          one = one + "0"
                                                        }
                                                      }
                                                      for (
                                                        var i = 0;
                                                        i < x2.length;
                                                        i++
                                                      ) {
                                                        if (
                                                          x2[i].checked == true
                                                        ) {
                                                          two = two + "1"
                                                        } else {
                                                          two = two + "0"
                                                        }
                                                      }
                                                      // Particular Loop for Three
                                                      for (
                                                        var i = 0;
                                                        i < x3.length;
                                                        i++
                                                      ) {
                                                        if (
                                                          x3[i].checked == true
                                                        ) {
                                                          three = three + "1"
                                                        } else {
                                                          three = three + "0"
                                                        }
                                                      }
                                                      // Particular Loop for Four
                                                      for (
                                                        var i = 0;
                                                        i < x4.length;
                                                        i++
                                                      ) {
                                                        if (
                                                          x4[i].checked == true
                                                        ) {
                                                          four = four + "1"
                                                        } else {
                                                          four = four + "0"
                                                        }
                                                      }
                                                      // Particular Loop for Five
                                                      for (
                                                        var i = 0;
                                                        i < x5.length;
                                                        i++
                                                      ) {
                                                        if (
                                                          x5[i].checked == true
                                                        ) {
                                                          five = five + "1"
                                                        } else {
                                                          five = five + "0"
                                                        }
                                                      }

                                                      let percentage
                                                      let Twopercentage
                                                      let Threepercentage
                                                      let Fourpercentage
                                                      let Fivepercentage
                                                      if (one.length > 0) {
                                                        const onesLength = (
                                                          one
                                                            .substring(1)
                                                            .match(/1/g) || []
                                                        ).length
                                                        const towLength = (
                                                          two
                                                            .substring(1)
                                                            .match(/1/g) || []
                                                        ).length
                                                        const threeLength = (
                                                          three
                                                            .substring(1)
                                                            .match(/1/g) || []
                                                        ).length
                                                        const fourLength = (
                                                          four
                                                            .substring(1)
                                                            .match(/1/g) || []
                                                        ).length
                                                        const fiveLength = (
                                                          five
                                                            .substring(1)
                                                            .match(/1/g) || []
                                                        ).length
                                                        percentage =
                                                          (onesLength /
                                                            module.submodules
                                                              .length) *
                                                          100
                                                        Twopercentage =
                                                          (towLength /
                                                            module.submodules
                                                              .length) *
                                                          100
                                                        Threepercentage =
                                                          (threeLength /
                                                            module.submodules
                                                              .length) *
                                                          100
                                                        Fourpercentage =
                                                          (fourLength /
                                                            module.submodules
                                                              .length) *
                                                          100
                                                        Fivepercentage =
                                                          (fiveLength /
                                                            module.submodules
                                                              .length) *
                                                          100
                                                      }

                                                      if (percentage >= 1) {
                                                        const checkbox =
                                                          document.getElementById(
                                                            `${module.module_name.replace(
                                                              " ",
                                                              "_"
                                                            )}global`
                                                          )
                                                        checkbox.defaultChecked = true
                                                      } else if (
                                                        percentage <= 0
                                                      ) {
                                                        const checkbox =
                                                          document.getElementById(
                                                            `${module.module_name.replace(
                                                              " ",
                                                              "_"
                                                            )}global`
                                                          )
                                                        checkbox.defaultChecked = false
                                                      }

                                                      if (Twopercentage >= 1) {
                                                        const checkbox =
                                                          document.getElementById(
                                                            `${module.module_name.replace(
                                                              " ",
                                                              "_"
                                                            ) + "view"
                                                            }`
                                                          )
                                                        checkbox.defaultChecked = true
                                                      } else if (
                                                        Twopercentage <= 0
                                                      ) {
                                                        const checkbox =
                                                          document.getElementById(
                                                            `${module.module_name.replace(
                                                              " ",
                                                              "_"
                                                            ) + "view"
                                                            }`
                                                          )
                                                        checkbox.defaultChecked = false
                                                      }

                                                      if (
                                                        Threepercentage >= 1
                                                      ) {
                                                        const checkbox =
                                                          document.getElementById(
                                                            `${module.module_name.replace(
                                                              " ",
                                                              "_"
                                                            ) + "add"
                                                            }`
                                                          )
                                                        checkbox.defaultChecked = true
                                                      } else if (
                                                        Threepercentage <= 0
                                                      ) {
                                                        const checkbox =
                                                          document.getElementById(
                                                            `${module.module_name.replace(
                                                              " ",
                                                              "_"
                                                            ) + "add"
                                                            }`
                                                          )
                                                        checkbox.defaultChecked = false
                                                      }
                                                      if (Fourpercentage >= 1) {
                                                        const checkbox =
                                                          document.getElementById(
                                                            `${module.module_name.replace(
                                                              " ",
                                                              "_"
                                                            ) + "edit"
                                                            }`
                                                          )
                                                        checkbox.defaultChecked = true
                                                      } else if (
                                                        Fourpercentage <= 0
                                                      ) {
                                                        const checkbox =
                                                          document.getElementById(
                                                            `${module.module_name.replace(
                                                              " ",
                                                              "_"
                                                            ) + "edit"
                                                            }`
                                                          )
                                                        checkbox.defaultChecked = false
                                                      }

                                                      if (Fivepercentage >= 1) {
                                                        const checkbox =
                                                          document.getElementById(
                                                            `${module.module_name.replace(
                                                              " ",
                                                              "_"
                                                            ) + "delete"
                                                            }`
                                                          )
                                                        checkbox.defaultChecked = true
                                                      } else if (
                                                        Fivepercentage <= 0
                                                      ) {
                                                        const checkbox =
                                                          document.getElementById(
                                                            `${module.module_name.replace(
                                                              " ",
                                                              "_"
                                                            ) + "delete"
                                                            }`
                                                          )
                                                        checkbox.defaultChecked = false
                                                      }
                                                    }

                                                    return (
                                                      <tr key={index}>
                                                        <td className="border text-start ps-4 text-black">
                                                          <i
                                                            className="bx bx-chevrons-right"
                                                            style={{
                                                              padding:
                                                                "0px 5px 0px 0px",
                                                            }}
                                                          ></i>
                                                          <input
                                                            id="module_id"
                                                            value={
                                                              innerModule.module_id
                                                            }
                                                            className="display-none"
                                                            readOnly
                                                          />
                                                          {
                                                            innerModule.module_name
                                                          }
                                                        </td>
                                                        <td className="border cell-width ">
                                                          <div className=" form-switch form-switch-md text-center">
                                                            <input
                                                              type="checkbox"
                                                              className={`form-check-input first_global ${module.module_name.replace(
                                                                " ",
                                                                "_"
                                                              ) + "one"
                                                                } ${subModuleId}global`}
                                                              id={
                                                                module.module_name.replace(
                                                                  " ",
                                                                  "_"
                                                                ) + "one"
                                                              }
                                                              defaultChecked={
                                                                innerModule.Global ==
                                                                "1"
                                                              }
                                                              onChange={e => {
                                                                checkAllSubmodule(
                                                                  {
                                                                    e: e,
                                                                    name: module.module_name,
                                                                    count:
                                                                      "one",
                                                                    globe:
                                                                      "global",
                                                                  }
                                                                )
                                                              }}
                                                            />
                                                          </div>
                                                        </td>
                                                        <td className="border cell-width ">
                                                          <div className="form-switch form-switch-md">
                                                            <input
                                                              type="checkbox"
                                                              className={`form-check-input second_view ${module.module_name.replace(
                                                                " ",
                                                                "_"
                                                              ) + "two"
                                                                } ${subModuleId}view`}
                                                              id={
                                                                module.module_name.replace(
                                                                  " ",
                                                                  "_"
                                                                ) + "two"
                                                              }
                                                              defaultChecked={
                                                                innerModule.View ==
                                                                "1"
                                                              }
                                                              onChange={e => {
                                                                checkAllSubmodule(
                                                                  {
                                                                    e: e,
                                                                    name: module.module_name,
                                                                    count:
                                                                      "two",
                                                                    globe:
                                                                      "view",
                                                                  }
                                                                )
                                                              }}
                                                            />
                                                          </div>
                                                        </td>
                                                        <td className="border cell-width">
                                                          <div className="form-switch form-switch-md">
                                                            <input
                                                              type="checkbox"
                                                              className={`form-check-input third_add ${module.module_name.replace(
                                                                " ",
                                                                "_"
                                                              ) + "three"
                                                                } ${subModuleId}`}
                                                              id={
                                                                module.module_name.replace(
                                                                  " ",
                                                                  "_"
                                                                ) + "three"
                                                              }
                                                              defaultChecked={
                                                                innerModule.Add ==
                                                                "1"
                                                              }
                                                              onChange={e => {
                                                                handleActionSubClick(
                                                                  {
                                                                    e: e,
                                                                    subModuleId:
                                                                      subModuleId,
                                                                    actionType:
                                                                      "add",
                                                                    name: module.module_name,
                                                                    count:
                                                                      "three",
                                                                    globe:
                                                                      "add",
                                                                  }
                                                                )
                                                              }}
                                                            />
                                                          </div>
                                                        </td>
                                                        <td className="border cell-width">
                                                          <div className=" form-switch form-switch-md">
                                                            <input
                                                              type="checkbox"
                                                              className={`form-check-input fourth_edit ${module.module_name.replace(
                                                                " ",
                                                                "_"
                                                              ) + "four"
                                                                }`}
                                                              id={
                                                                module.module_name.replace(
                                                                  " ",
                                                                  "_"
                                                                ) + "four"
                                                              }
                                                              defaultChecked={
                                                                innerModule.Edit ==
                                                                "1"
                                                              }
                                                              onChange={e => {
                                                                handleActionSubClick(
                                                                  {
                                                                    e: e,
                                                                    subModuleId:
                                                                      subModuleId,
                                                                    actionType:
                                                                      "edit",
                                                                    name: module.module_name,
                                                                    count:
                                                                      "four",
                                                                    globe:
                                                                      "edit",
                                                                  }
                                                                )
                                                              }}
                                                            />
                                                          </div>
                                                        </td>
                                                        <td className="border cell-width">
                                                          <div className=" form-switch form-switch-md">
                                                            <input
                                                              type="checkbox"
                                                              className={`form-check-input fifth_delete ${module.module_name.replace(
                                                                " ",
                                                                "_"
                                                              ) + "five"
                                                                }`}
                                                              id={
                                                                module.module_name.replace(
                                                                  " ",
                                                                  "_"
                                                                ) + "five"
                                                              }
                                                              defaultChecked={
                                                                innerModule.Delete ==
                                                                "1"
                                                              }
                                                              onChange={e => {
                                                                handleActionSubClick(
                                                                  {
                                                                    e: e,
                                                                    subModuleId:
                                                                      subModuleId,
                                                                    actionType:
                                                                      "delete",
                                                                    name: module.module_name,
                                                                    count:
                                                                      "five",
                                                                    globe:
                                                                      "delete",
                                                                  }
                                                                )
                                                              }}
                                                            />
                                                          </div>
                                                        </td>
                                                        <td className="cell-width"></td>
                                                      </tr>
                                                    )
                                                  }
                                                )}
                                              </Table>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              )
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </form>
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Global Modal */}
      <Modal
        isOpen={global_modal}
        toggle={() => {
          tog_center()
        }}
        className="bd-info-popup"
        centered
      >
        <div className="modal-header bd-head-info">
          <h5 className="modal-title mt-0">
            <i className="dripicons-information bd-transform-icon pr-10 text-red"></i>
            Global Info
          </h5>
        </div>
        <div className="modal-body pb-0">
          <div className="d-flex">
            <div className="col-5 pr-30">
              <div>
                <img src={InfoGif} className="w-100" />
              </div>
            </div>
            <div className="mt-20 ">
              <div>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Cras
                  mattis consectetur purus sit amet fermentum. Cras justo odio,
                  dapibus ac facilisis in, egestas eget quam. Morbi leo risus,
                  porta ac consectetur ac, vestibulum at eros.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <div className="text-center mt-40">
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={() => setGlobalModal(false)}
                  >
                    I Understood
                    <i className="dripicons-thumbs-up pl-10"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* View Modal */}
      <Modal
        isOpen={view_modal}
        toggle={() => {
          tog_center()
        }}
        className="bd-info-popup"
        centered
      >
        <div className="modal-header bd-head-info">
          <h5 className="modal-title mt-0">
            <i className="dripicons-information bd-transform-icon pr-10 text-red"></i>
            View Info
          </h5>
        </div>
        <div className="modal-body pb-0">
          <div className="d-flex">
            <div className="col-5 pr-30">
              <div>
                <img src={InfoGif} className="w-100" />
              </div>
            </div>
            <div className="mt-20 ">
              <div>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Cras
                  mattis consectetur purus sit amet fermentum. Cras justo odio,
                  dapibus ac facilisis in, egestas eget quam. Morbi leo risus,
                  porta ac consectetur ac, vestibulum at eros.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <div className="text-center mt-40">
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={() => setViewModal(false)}
                  >
                    I Understood
                    <i className="dripicons-thumbs-up pl-10"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Add Modal */}
      <Modal
        isOpen={add_modal}
        toggle={() => {
          tog_center()
        }}
        centered
        className="bd-info-popup"
      >
        <div className="modal-header bd-head-info">
          <h5 className="modal-title mt-0">
            <i className="dripicons-information bd-transform-icon pr-10 text-red"></i>
            Add Info
          </h5>
        </div>
        <div className="modal-body pb-0">
          <div className="d-flex">
            <div className="col-5 pr-30">
              <div>
                <img src={InfoGif} className="w-100" />
              </div>
            </div>
            <div className="mt-20 ">
              <div>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Cras
                  mattis consectetur purus sit amet fermentum. Cras justo odio,
                  dapibus ac facilisis in, egestas eget quam. Morbi leo risus,
                  porta ac consectetur ac, vestibulum at eros.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <div className="text-center mt-40">
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={() => setAddModal(false)}
                  >
                    I Understood
                    <i className="dripicons-thumbs-up pl-10"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Edit Modal */}
      <Modal
        isOpen={edit_modal}
        toggle={() => {
          tog_center()
        }}
        centered
        className="bd-info-popup"
      >
        <div className="modal-header bd-head-info">
          <h5 className="modal-title mt-0">
            <i className="dripicons-information bd-transform-icon pr-10 text-red"></i>
            Update Info
          </h5>
        </div>
        <div className="modal-body pb-0">
          <div className="d-flex">
            <div className="col-5 pr-30">
              <div>
                <img src={InfoGif} className="w-100" />
              </div>
            </div>
            <div className="mt-20 ">
              <div>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Cras
                  mattis consectetur purus sit amet fermentum. Cras justo odio,
                  dapibus ac facilisis in, egestas eget quam. Morbi leo risus,
                  porta ac consectetur ac, vestibulum at eros.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <div className="text-center mt-40">
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={() => setEditModal(false)}
                  >
                    I Understood
                    <i className="dripicons-thumbs-up pl-10"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Delete Modal */}
      <Modal
        isOpen={delete_modal}
        toggle={() => {
          tog_center()
        }}
        className="bd-info-popup"
        centered
      >
        <div className="modal-header bd-head-info">
          <h5 className="modal-title mt-0">
            <i className="dripicons-information bd-transform-icon pr-10 text-red"></i>
            Delete Info
          </h5>
        </div>
        <div className="modal-body pb-0">
          <div className="d-flex">
            <div className="col-5 pr-30">
              <div>
                <img src={InfoGif} className="w-100" />
              </div>
            </div>
            <div className="mt-20 ">
              <div>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Cras
                  mattis consectetur purus sit amet fermentum. Cras justo odio,
                  dapibus ac facilisis in, egestas eget quam. Morbi leo risus,
                  porta ac consectetur ac, vestibulum at eros.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <div className="text-center mt-40">
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={() => setDeleteModal(false)}
                  >
                    I Understood
                    <i className="dripicons-thumbs-up pl-10"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default AddRoles
