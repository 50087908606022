import React, { useEffect, useState } from "react";
import { Card, CardBody, Table } from "reactstrap";
import { fetchData } from "utils/common-fetchData";
import Loader from "pages/Separate/Loader";
import { getTimeRemaining } from "utils/common-helpers";

const TimerRow = ({ createdAt, chance }) => {
  const durationInDays = chance; // The duration for the countdown (in this case, 30 days)
  const [timeRemaining, setTimeRemaining] = useState(
    getTimeRemaining(createdAt, durationInDays)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const newTimeRemaining = getTimeRemaining(createdAt, durationInDays);
      if (newTimeRemaining.timeLeft <= 0) {
        clearInterval(interval);
        setTimeRemaining({ ...newTimeRemaining, timeLeft: 0 });
      } else {
        setTimeRemaining(newTimeRemaining);
      }
    }, 1000);

    // Cleanup the interval when the component is unmounted
    return () => clearInterval(interval);
  }, [createdAt, durationInDays]);

  return (
    <td className="text-center text-purple">
      {chance} days -{" "}
      <span className="text-danger">
        ({timeRemaining.days}:{timeRemaining.hours}:{timeRemaining.minutes}:
        {timeRemaining.seconds})
      </span>
    </td>
  );
};

const FlawsGap = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const toggleLoading = (loading) => {
    setLoading(loading);
  };
  useEffect(() => {
    const getFlawsGap = async () => {
      const userId = localStorage.getItem("auth_id");
      const response = await fetchData(
        toggleLoading,
        `improveReport/${userId}`,
        ""
      );
      if (response?.success) {
        setData(response?.data?.filter((obj) => obj?.time != null));
      }
    };
    getFlawsGap();
  }, []);
  return (
    <React.Fragment>
      {loading && <Loader />}
      <div>
        <Card>
          <CardBody>
            <div className="mb-4 d-flex justify-content-between">
              <p className="text-purple fs-5 mb-0 fw-bold">Flaws & Gap </p>
              <div className="text-purple absent-length">{data?.length}</div>
            </div>
            <div className="responsive-table mb-4" id="Fav-lead-table">
              <Table className="table table-bordered mb-0">
                <thead>
                  <tr>
                    <th className="text-center header">Id</th>
                    <th className="header col-10">Reasons</th>
                    <th className="text-center header col-2">Time</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((obj, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>
                          <div>
                            {obj?.title}
                            <div className="ms-4">
                              <i className="bx bx-subdirectory-right fs-5"></i>{" "}
                              {obj?.reasons}
                            </div>
                          </div>
                        </td>
                        {obj?.time != null ? (
                          <TimerRow
                            createdAt={obj.created_at}
                            chance={obj?.time}
                          />
                        ) : (
                          <td className="text-center">
                            {obj?.time || "Not Given Chance"}
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              {data?.length == 0 ? (
                <p className="text-center text-purple fw-bold mt-3">
                  🌟 Smooth sailing! No flows or gaps for you! 🚀
                </p>
              ) : null}
            </div>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default FlawsGap;
