import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import {
    Row
} from "reactstrap";

const ViewZithasTechInvoice = ({ invoiceDetail }) => {
    const csgst = Number(invoiceDetail?.sub_total) * 0.09
    return (
        <React.Fragment>
            <div >
                <Container fluid>
                    <Row className='px-1 justify-content-center mb-5'>
                        <div className="col-md-8">
                            <div className='invoice-page p-4 pb-5 bg-white '>
                                <div className="row ">
                                    <div className="col-4 pe-0">
                                        <div className="invoice-line"></div>
                                    </div>
                                    <div className="col-4">
                                        <p className='fs-4 py-3 text-center bg-white mb-0'>{invoiceDetail?.tax_inv_no != null ? "TAX" : "PERFORMA"} INVOICE</p>
                                    </div>
                                    <div className="col-4 ps-0">
                                        <div className="invoice-line"></div>
                                    </div>
                                </div>

                                <div className="row justify-content-between">
                                    <div className="col-3 ">

                                        <p className='mb-1'>Bill To</p>
                                        <p className='mb-1'>{invoiceDetail?.c_company} ,</p>
                                        <p className='mb-1'>{invoiceDetail?.city}, {invoiceDetail?.state},</p>
                                        <p className='mb-1'>{invoiceDetail?.country} - {invoiceDetail?.c_zipcode} ,</p>
                                        <p className='mb-1'>GST : {invoiceDetail?.cgst}</p>

                                    </div>
                                    <div className="col-5 ps-0">
                                        <table className='table '>
                                            <tbody>
                                                <tr>
                                                    <th className='border border-secondary py-1'>Invoice#</th>
                                                    <td className='border border-secondary py-1'>{invoiceDetail?.invoice_number}</td>
                                                </tr>
                                                <tr>
                                                    <th className='border border-secondary py-1'>Invoice Date</th>
                                                    <td className='border border-secondary py-1'>{invoiceDetail?.invoice_date}</td>
                                                </tr>
                                                <tr>
                                                    <th className='border border-secondary py-1'>Terms</th>
                                                    <td className='border border-secondary py-1'>{invoiceDetail?.terms}</td>
                                                </tr>
                                                <tr>
                                                    <th className='border border-secondary py-1'>Due Date</th>
                                                    <td className='border border-secondary py-1'>{invoiceDetail?.due_date}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className='mt-4'>
                                    <table className='table '>
                                        <thead>
                                            <tr>
                                                <th className='border border-secondary'>#</th>
                                                <th className='border border-secondary'>Item & Description</th>
                                                <th className='border border-secondary'>HSN/SAC</th>
                                                <th className='border border-secondary'>Qty</th>
                                                <th className='border border-secondary'>Rate</th>
                                                <th className='border border-secondary'>Amount </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {invoiceDetail?.items?.map((obj, i) => {
                                                const hex = invoiceDetail?.hex
                                                return (
                                                    <tr key={i}>
                                                        <td className='border border-secondary'>{obj?.id}</td>
                                                        <td className='border border-secondary'>{obj?.service_detail}</td>
                                                        <td className='border border-secondary'>{obj?.hsn}</td>
                                                        <td className='border border-secondary'>{obj?.quantity}</td>
                                                        <td className='border border-secondary'>{obj?.rate}</td>
                                                        <td className='border border-secondary'>
                                                            <span dangerouslySetInnerHTML={{ __html: hex }}></span> {obj?.amount}</td>
                                                    </tr>
                                                )
                                            })}

                                        </tbody>
                                    </table>
                                    <p dangerouslySetInnerHTML={{ __html: invoiceDetail?.customer_note }}></p>
                                </div>

                                <div className='row justify-content-end'>
                                    <div className="col-5">
                                        <div className='d-flex py-2 mt-3 justify-content-between'>
                                            <span>Sub Total</span>
                                            <span><span dangerouslySetInnerHTML={{ __html: invoiceDetail?.hex }}></span> {invoiceDetail?.sub_total}</span>
                                        </div>
                                        <div className='d-flex py-2 justify-content-between'>
                                            <span>Discount</span>
                                            <span>{invoiceDetail?.discount_amount}</span>
                                        </div>
                                        {invoiceDetail?.c_state == "12" ? (
                                            <>
                                                <div className='d-flex pt-2 pb-3 justify-content-between'>
                                                    <span>CGST (9%)</span>
                                                    <span>
                                                        <span dangerouslySetInnerHTML={{ __html: invoiceDetail?.hex }}></span>
                                                        {csgst}
                                                    </span>
                                                </div>
                                                <div className='d-flex pt-2 pb-3 justify-content-between'>
                                                    <span>SGST (9%)</span>
                                                    <span><span dangerouslySetInnerHTML={{ __html: invoiceDetail?.hex }}></span> {csgst}</span>
                                                </div>
                                            </>
                                        ) : (
                                            <div className='d-flex pt-2 pb-3 justify-content-between'>
                                                <span>IGST (18%)</span>
                                                <span><span dangerouslySetInnerHTML={{ __html: invoiceDetail?.hex }}></span> {invoiceDetail?.igst}</span>
                                            </div>

                                        )}

                                        <div className='d-flex pt-2 border-top border-secondary pb-2 justify-content-between'>
                                            <strong> Total (₹)</strong>
                                            <span><span dangerouslySetInnerHTML={{ __html: invoiceDetail?.hex }}></span> {invoiceDetail?.total}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className='mt-4 row pb-5'>
                                    <p>Total in Words : <strong>{invoiceDetail?.total_in_words}</strong></p>
                                </div>

                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default ViewZithasTechInvoice