import moment from "moment"
import React from "react"
import { getModuleicon } from "utils/common-helpers"

export const notificationColumns = (onDeleteNotification) => [
    {
        Header: "ID",
        accessor: "id",
        isInfoIcon: false,
        isShortIcon: true,
        className: "",
    },
    {
        Header: "Date",
        accessor: "date",
        isInfoIcon: false,
        isShortIcon: true,
        className: "col-1",
        Cell: cellprops => {
            return (
                <div className="text-center">
                    {moment(cellprops.row.original.created_at).format('DD-MM-YYYY')}
                </div>
            )
        }
    },
    {
        Header: "Title",
        accessor: "title",
        isInfoIcon: false,
        isShortIcon: true,
        className: "col-10",
        Cell: cellProps => {
            return (
                <div className="d-flex justify-content-start gap-1 notification_box">
                    <div className="module-icon">{getModuleicon((cellProps.row.original?.module)?.toLowerCase())}</div>
                    <div>
                        {cellProps.row.original.title}
                        <div className="ms-4">
                            <i className="bx bx-subdirectory-right fs-5"></i>{" "}
                            {cellProps.row.original.message}
                        </div>
                    </div>

                </div>
            )
        },
    },

    // {
    //     Header: "Action",
    //     accessor: "action",
    //     className: "notification_action",
    //     isInfoIcon: false,
    //     Cell: cellProps => {
    //         return (
    //             <div className="d-flex justify-content-center">
    //                 <a
    //                     title="view lead"
    //                     className="btn btn-sm btn-outline-danger me-1"
    //                     onClick={() => onDeleteNotification(cellProps?.row?.original?.id)}
    //                 >
    //                     <i className="fas fa-trash "></i>
    //                 </a>
    //             </div>
    //         )
    //     },
    // },
]