import NewPagination from 'components/Common/Newpagination'
import React, { useEffect, useMemo, useState } from 'react'
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'
import { Col, Container, Row, Table } from 'reactstrap'
import { ApprovalDigitalColumns } from 'utils/columns/work/DigitalColumns'
import { fetchData, FetchPostData } from 'utils/common-fetchData'
import secureLocalStorage from 'react-secure-storage'
import Loader from 'pages/Separate/Loader'
import { useParams } from 'react-router-dom'
import { calculateCompleteTime } from 'utils/common-helpers'

function TableContainer({
  columns,
  data,
}) {
  const [fixedContent, setFixedContent] = useState(false)

  document.addEventListener("scroll", () => {
    if (window.scrollY > 68) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "id",
            desc: true,
          },
        ],
      },
    },

    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state
  useEffect(() => setPageSize(50), [])






  return (
    <div>
      <div className="col-12 d-flex mb-3">
        <div className="col-6 d-flex justify-content-start align-items-center gap-2">
          <div className="col-2 d-flex justify-content-start align-items-center">
            <select
              className="s me-4 form-select"
              style={{ width: "100px" }}
              value={pageSize}
              onChange={e => setPageSize(e.target.value)}
            >
              {[10, 25, 50, 100].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>

          <div className='col-8 gap-3 d-flex justify-content-start align-items-center'>

            <div className="d-flex gap-2 align-items-center general-box col-md-6">
              <h5 className="text-purple mb-0">Total Hours : </h5>
              <h5 className="text-primary mb-0">
                {calculateCompleteTime(data, "est_hrs")}
              </h5>
            </div>

            <div className="d-flex gap-2 align-items-center general-box col-md-6">
              <h5 className="text-purple mb-0">Used Hours : </h5>
              <h5 className="text-primary mb-0">
                {calculateCompleteTime(data, "dev_hrs")}
              </h5>
            </div>
          </div>
        </div>

        <div className="col-6 d-flex justify-content-end align-items-center">
          <div className="col-8 d-flex justify-content-end align-items-center mt-2">
            <div className="search-box">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className="s form-control rounded "
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="position-relative">
        <Table className="table table-bordered" {...getTableProps()}>
          <thead
            className={`${(fixedContent && page.length >= 10) ? "fixed_approval_process_headers"
              : "fixed-table-header"
              } `}
          >
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => {
                  const classNames = column.className
                  return (
                    <th
                      key={index}
                      className={` col-t-${index + 1
                        } ${classNames} customShortIcon custom-text`}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}

                      {column.isShortIcon && (
                        <span
                          onClick={() =>
                            column.getSortByToggleProps().onClick()
                          }
                          className="ms-2 cursor-pointer customApprovalIcon"
                        >
                          <i
                            title="Ascending"
                            className={`bi bi-arrow-up info-icon ${column.isSorted && !column.isSortedDesc
                              ? "text-dark"
                              : ""
                              }`}
                          ></i>
                          <i
                            title="Descending"
                            className={`bi bi-arrow-down info-icon ${column.isSorted && column.isSortedDesc
                              ? "text-dark"
                              : ""
                              }`}
                          ></i>
                        </span>
                      )}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>

          <tbody className="fix-table-body" {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr
                  {...row.getRowProps()}
                  key={i}
                  className=""
                >
                  {row.cells.map((cell, index) => (
                    <td
                      key={index}
                      className={`t-col-${index + 1} ${cell.column.className}`}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              )
            })}
            {page.length == 0 && (
              <tr style={{ backgroundColor: "unset" }}>
                <td className="text-center" colSpan={10}>
                  No Data Found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <NewPagination
          gotoPage={gotoPage}
          canPreviousPag={canPreviousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          page={page}
          data={data}
        />
      </Row>
    </div>
  )
}


const ApprovalDmStep = ({ currentStep, approvalList }) => {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([])
  const params = useParams();
  const token = secureLocalStorage.getItem('auth_token')
  const toggleLoading = (loading) => {
    setLoading(loading);
  }
  const onEditHrs = async (id, value) => {
    const formEntries = {
      task_id: id,
      type: params?.seo,
      dev_hrs: value
    }
    const res = await FetchPostData(toggleLoading, `updateResourceHrs`, token, formEntries)
    if (res?.data?.success) {
      setData(res?.data?.data?.original?.data)
    }
  }

  const columns1 = useMemo(
    () => ApprovalDigitalColumns(onEditHrs),
    []
  )

  useEffect(() => {
    const getallTask = async () => {
      const res = await fetchData(toggleLoading, `digitalProjectTasks/${params?.id}/${params.seo}`, token)
      if (res?.success) {
        setData(res?.data)
      }
    }
    if (currentStep == 2) {
      if (data?.length == 0) {
        getallTask();
      }
    }
  }, [currentStep])

  useEffect(() => {
    const result = data?.filter((obj) => obj?.dev_hrs == null)?.length
    approvalList(result)
  }, [data])

  return (
    <div>
      {isLoading && <Loader />}
      <div className="">
        <Container fluid>
          <Row>
            <Col lg={12} className="p-0">
              <div className="responsive-table">
                <TableContainer
                  columns={columns1}
                  data={data}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default ApprovalDmStep
