import React, {  useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Container,
  Row,
  Col,
} from "reactstrap"
import ProjectCalendar from "./ProjectCalendar"
const ProjectWeeklyPlan = () => {
  const [isCollapse, setIsCollapse] = useState(true)
  document.title = "Digital Marketing | Zithas Crm"
  return (

    <React.Fragment>
      <div className="page-content mb-4">
        <Container fluid>
          <Breadcrumbs title="Digital Marketing" breadcrumbItem="Project Weekly Plan" />
          <div id="credentialPage">
            <Row
              className={`gx-4 justify-content-between di-flex align-items-top `}
              id="cred"
            >
              <Col
                md={isCollapse ? "12" : "10"}
                className={`z-1 pe-0  ${isCollapse && "width-100 ps-0"
                  }`}
              >
                <div className="card p-4 h-100">
                  <ProjectCalendar />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ProjectWeeklyPlan
