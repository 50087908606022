import React, { useEffect, useState } from "react"
import { Container, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useMemo } from "react"
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table"
import "../../assets/css/Staff/Staff.css"
import classnames from "classnames"
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import EducationView from "./ViewStaff/Education"
import PermissionView from "./ViewStaff/Permisions"
import InfoGif from "../../assets/images/GIF/question-mark.gif"
// common component
import Pagination from "components/Common/Pagination"
import Loader from "pages/Separate/Loader"
import axios from "axios"
import configure from "configure"
import moment from "moment"
import Swal from "sweetalert2"
import AccessDeniedPopup from "routes/AccessDeniedPopup"
import secureLocalStorage from "react-secure-storage"
import PersonalDetails from "./ViewStaff/PersonalDetails"
import ZithasCtc from "./ViewStaff/ZithasCtc"
import WorkExperience from "./ViewStaff/WorkExperience"
import Education from "./ViewStaff/Education"
import Documents from "./ViewStaff/Document"
import GenerateDoc from "./ViewStaff/GenerateDoc"
import ZithasCredential from "./ViewStaff/ZithasCredential"
import NewPagination from "components/Common/Newpagination"
import { fetchData, FetchPostData } from "utils/common-fetchData"
import { staffColumns } from "utils/columns/Human Resource/humanResource"
import { checkPermissionsByModule, getPermissionsByModule } from "utils/common-helpers"
import { useSelector } from "react-redux"
import CompanyCommitment from "./ViewStaff/CompnayCommitment"

function TableContainer({ columns, data, setAccessDenied, setLoading }) {
  const [fixedHeader, setFixedHeader] = useState(false)
  const [fixedContent, setFixedContent] = useState(false)

  document.addEventListener("scroll", () => {
    const scrollThreshold = window.innerHeight * 0.25;
    if (window.scrollY > scrollThreshold) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })
  const navigate = useNavigate()
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      // initialState: {
      //   sortBy: [
      //     {
      //       id: "id",
      //       desc: true,
      //     },
      //   ],
      // },
    },

    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state

  useEffect(() => setPageSize(50), [])

  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedHeader(true)
    } else {
      setFixedHeader(false)
    }
  })

  const [name_modal, setNameModal] = useState(false)
  const [designation_modal, setDesignationModal] = useState(false)
  const [phone_modal, setPhoneModal] = useState(false)
  const [email_modal, setEmailModal] = useState(false)
  const [joining_modal, setJoiningModal] = useState(false)
  const [status_modal, setStatusModal] = useState(false)

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  // Center Modal
  function tog_center() {
    setNameModal(false)
    setDesignationModal(false)
    setPhoneModal(false)
    setEmailModal(false)
    removeBodyCss()
  }

  // Render the UI for your table

  const onAddPermission = async id => {
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("module_id", 66)
    try {
      await axios({
        method: "post",
        url: `${configure.apiUrl}/earth_permission`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        const allEmail = data.map(item => item.email)
        if (res.data.data.india[3] == 1 || res.data.data.uk[3] == 1) {
          navigate(`${configure.appUrl}addstaff`, {
            state: {
              allEmail: allEmail,
            },
          })
          // navigate(`${configure.appUrl}addstaff`)
        } else {
          setAccessDenied(true)
        }
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const onAllClearLogin = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("module_id", 66)
    try {
      await axios({
        method: "post",
        url: `${configure.apiUrl}/earth_permission`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(async res => {
        if (res.data.data.india[4] == 1 || res.data.data.uk[4] == 1) {
          try {
            const response = await axios({
              method: "get",
              url: `${configure.apiUrl}/allLoginClear`,
              headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
              },
            }).then(response => {
              Swal.fire({
                icon: "success",
                text: "All Login clear Successfully.",
              })
              setLoading(false)
            })
          } catch (err) {
            swal({
              icon: "error",
              text: "SomeThing Went Wrong",
            })
            setLoading(false)
          }
        } else {
          setLoading(false)
          setAccessDenied(true)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div>
      {/* fixed buttons */}
      <div
        className={`flex-column fixed-buttons pt-2 ${fixedContent ? "d-flex" : "d-none"
          }`}
      >
        <Link
          onClick={() => onAddPermission()}
          className="btn btn-save  fix-btn btn-label pe-0"
        >
          <i className="fas fa-plus me-2 icon-size label-icon"></i>
          <span>Staff</span>
        </Link>
        <button
          onClick={() => navigate(-1)}
          className="btn btn-red  fix-btn btn-label pe-0"
          type="button"
        >
          <i className="dripicons-cross me-2 icon-center label-icon"></i>
          <span>Cancel</span>
        </button>
      </div>
      <Row className="mb-2">
        <Col md={3}>
          <div>
            <select
              className=" form-select"
              style={{ width: "100px" }}
              value={pageSize}
              onChange={e => setPageSize(e.target.value)}
            >
              {[10, 25, 50, 100].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </Col>
        <Col md={9} className="d-flex justify-content-end">
          <div className="me-4">
            <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className=" form-control rounded h-100"
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
          <div className="text-md-end">
            <Link
              onClick={() => onAddPermission()}
              // to={`${configure.appUrl}addstaff`}
              className=" btn text-white text-uppercase me-2 btn-save btn-label"
            >
              <i className="fas fa-plus me-2 icon-size label-icon"></i>Staff
            </Link>
            <Link
              onClick={() => onAllClearLogin()}
              className=" btn text-white text-uppercase btn-save btn-label"
            >
              <i className="fas fa-recycle me-2 icon-size label-icon"></i>All clear
              login
            </Link>
            <button
              onClick={() => navigate(-1)}
              className=" btn ms-md-2 btn-red btn-label"
              type="button"
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>
              CANCEL
            </button>
          </div>
        </Col>
      </Row>
      <Table className="table table-bordered mt-4 shadow" {...getTableProps()}>
        <thead
          className={`${(fixedHeader && page.length >= 10) ? "fix-staffs-header" : ""} fix-staff-header`}
        >
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <th
                  key={index}
                  className={`t-col-${index + 1} ${column.className} customShortIcon`}
                  {...column.getHeaderProps()}
                >
                  {column.render("Header")}
                  {column.isInfoIcon ? (
                    <a
                      onClick={() => {
                        if (column.Header == "Name") {
                          setNameModal(true)
                        } else if (column.Header == "Designation") {
                          setDesignationModal(true)
                        } else if (column.Header == "Phone no") {
                          setPhoneModal(true)
                        } else if (column.Header == "Email Id") {
                          setEmailModal(true)
                        } else if (column.Header == "Joining Date") {
                          setJoiningModal(true)
                        } else if (column.Header == "Status") {
                          setStatusModal(true)
                        }
                      }}
                    >
                      <i
                        title="info"
                        className="bx bx-info-circle ms-1 info-icon"
                      ></i>
                    </a>
                  ) : null}

                  {column.isShortIcon && (
                    <span
                      onClick={(e) =>
                        column.getSortByToggleProps().onClick(e)
                      }
                      className="ms-2 cursor-pointer"
                    >
                      <i
                        title="Ascending"
                        className={`bi bi-arrow-up info-icon ${column.isSorted &&
                          !column.isSortedDesc
                          ? "text-dark"
                          : ""
                          }`}
                      ></i>
                      <i
                        title="Descending"
                        className={`bi bi-arrow-down info-icon ${column.isSorted && column.isSortedDesc
                          ? "text-dark"
                          : ""
                          }`}
                      ></i>
                    </span>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody className={`fix-staff-header`} {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr className="row-hover" {...row.getRowProps()} key={i}>
                {row.cells.map((cell, index) => {
                  return (
                    <td
                      key={index}
                      className={`t-col-${index + 1}`}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <NewPagination
          gotoPage={gotoPage}
          canPreviousPage={canPreviousPage}
          previousPage={previousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          page={page}
          data={data}
          nextPage={nextPage}
        />
      </Row>

      {/* Name Modal */}
      <Modal
        isOpen={name_modal}
        toggle={() => {
          tog_center()
        }}
        className="bd-info-popup"
        centered
      >
        <div className="modal-header bd-head-info">
          <h5 className="modal-title mt-0">
            <i className="dripicons-information bd-transform-icon pr-10 text-red"></i>
            Staff Name Info
          </h5>
        </div>
        <div className="modal-body pb-0">
          <div className="d-flex">
            <div className="col-5 pr-30">
              <div>
                <img src={InfoGif} className="w-100" />
              </div>
            </div>
            <div className="mt-20 ">
              <div>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Cras
                  mattis consectetur purus sit amet fermentum. Cras justo odio,
                  dapibus ac facilisis in, egestas eget quam. Morbi leo risus,
                  porta ac consectetur ac, vestibulum at eros.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <div className="text-center mt-40">
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={() => setNameModal(false)}
                  >
                    I Understood
                    <i className="dripicons-thumbs-up pl-10"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Designation Modal*/}
      <Modal
        isOpen={designation_modal}
        toggle={() => {
          tog_center()
        }}
        className="bd-info-popup"
        centered
      >
        <div className="modal-header bd-head-info">
          <h5 className="modal-title mt-0">
            <i className="dripicons-information bd-transform-icon pr-10 text-red"></i>
            Designation Info
          </h5>
        </div>
        <div className="modal-body pb-0">
          <div className="d-flex">
            <div className="col-5 pr-30">
              <div>
                <img src={InfoGif} className="w-100" />
              </div>
            </div>
            <div className="mt-20 ">
              <div>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Cras
                  mattis consectetur purus sit amet fermentum. Cras justo odio,
                  dapibus ac facilisis in, egestas eget quam. Morbi leo risus,
                  porta ac consectetur ac, vestibulum at eros.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <div className="text-center mt-40">
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={() => setDesignationModal(false)}
                  >
                    I Understood
                    <i className="dripicons-thumbs-up pl-10"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Phone Number Modal*/}
      <Modal
        isOpen={phone_modal}
        toggle={() => {
          tog_center()
        }}
        className="bd-info-popup"
        centered
      >
        <div className="modal-header bd-head-info">
          <h5 className="modal-title mt-0">
            <i className="dripicons-information bd-transform-icon pr-10 text-red"></i>
            Contact Info
          </h5>
        </div>
        <div className="modal-body pb-0">
          <div className="d-flex">
            <div className="col-5 pr-30">
              <div>
                <img src={InfoGif} className="w-100" />
              </div>
            </div>
            <div className="mt-20 ">
              <div>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Cras
                  mattis consectetur purus sit amet fermentum. Cras justo odio,
                  dapibus ac facilisis in, egestas eget quam. Morbi leo risus,
                  porta ac consectetur ac, vestibulum at eros.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <div className="text-center mt-40">
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={() => setPhoneModal(false)}
                  >
                    I Understood
                    <i className="dripicons-thumbs-up pl-10"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Email Modal*/}
      <Modal
        isOpen={email_modal}
        toggle={() => {
          tog_center()
        }}
        className="bd-info-popup"
        centered
      >
        <div className="modal-header bd-head-info">
          <h5 className="modal-title mt-0">
            <i className="dripicons-information bd-transform-icon pr-10 text-red"></i>
            Email Info
          </h5>
        </div>
        <div className="modal-body pb-0">
          <div className="d-flex">
            <div className="col-5 pr-30">
              <div>
                <img src={InfoGif} className="w-100" />
              </div>
            </div>
            <div className="mt-20 ">
              <div>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Cras
                  mattis consectetur purus sit amet fermentum. Cras justo odio,
                  dapibus ac facilisis in, egestas eget quam. Morbi leo risus,
                  porta ac consectetur ac, vestibulum at eros.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <div className="text-center mt-40">
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={() => setEmailModal(false)}
                  >
                    I Understood
                    <i className="dripicons-thumbs-up pl-10"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Joining Date Modal*/}
      <Modal
        isOpen={joining_modal}
        toggle={() => {
          tog_center()
        }}
        className="bd-info-popup"
        centered
      >
        <div className="modal-header bd-head-info">
          <h5 className="modal-title mt-0">
            <i className="dripicons-information bd-transform-icon pr-10 text-red"></i>
            Joining Date Info
          </h5>
        </div>
        <div className="modal-body pb-0">
          <div className="d-flex">
            <div className="col-5 pr-30">
              <div>
                <img src={InfoGif} className="w-100" />
              </div>
            </div>
            <div className="mt-20 ">
              <div>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Cras
                  mattis consectetur purus sit amet fermentum. Cras justo odio,
                  dapibus ac facilisis in, egestas eget quam. Morbi leo risus,
                  porta ac consectetur ac, vestibulum at eros.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <div className="text-center mt-40">
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={() => setJoiningModal(false)}
                  >
                    I Understood
                    <i className="dripicons-thumbs-up pl-10"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Status Modal*/}
      <Modal
        isOpen={status_modal}
        toggle={() => {
          tog_center()
        }}
        className="bd-info-popup"
        centered
      >
        <div className="modal-header bd-head-info">
          <h5 className="modal-title mt-0">
            <i className="dripicons-information bd-transform-icon pr-10 text-red"></i>
            Joining Date Info
          </h5>
        </div>
        <div className="modal-body pb-0">
          <div className="d-flex">
            <div className="col-5 pr-30">
              <div>
                <img src={InfoGif} className="w-100" />
              </div>
            </div>
            <div className="mt-20 ">
              <div>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Cras
                  mattis consectetur purus sit amet fermentum. Cras justo odio,
                  dapibus ac facilisis in, egestas eget quam. Morbi leo risus,
                  porta ac consectetur ac, vestibulum at eros.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <div className="text-center mt-40">
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={() => setStatusModal(false)}
                  >
                    I Understood
                    <i className="dripicons-thumbs-up pl-10"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

const Staff = () => {
  const [modal_Access, setAccessDenied] = useState(false)
  document.title = "Staff | Zithas Crm"

  const [modal_fullscreen, setmodal_fullscreen] = useState(false)
  const [data, setDatas] = useState([])
  const [isLoading, setLoading] = useState(true);
  const toggleLoading = (loading) => {
    setLoading(loading)
  }


  document.addEventListener("keydown", e => {
    if (e.key === "Escape") setmodal_fullscreen(false)
  })

  const [staff_id, setStaffId] = useState("")
  const navigate = useNavigate();
  const role = useSelector((state) => state?.Roles?.apiData)

  const onEditPermission = async ({ id }) => {
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("module_id", 66)
    try {
      await axios({
        method: "post",
        url: `${configure.apiUrl}/earth_permission`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        if (res.data.data.india[4] == 1 || res.data.data.uk[4] == 1) {
          navigate(`${configure.appUrl}edit-staff/${id}`)
        } else {
          setAccessDenied(true)
        }
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }
  const onDeletePermission = async ({ id }) => {
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("module_id", 66)
    try {
      await axios({
        method: "post",
        url: `${configure.apiUrl}/earth_permission`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        if (res.data.data.india[5] == 1 || res.data.data.uk[5] == 1) {
          Swal.fire({
            title: "Are you sure?",
            text: "You want to Delete this Staff !",
            icon: "warning",
            showCancelButton: true,
          }).then(async willDelete => {
            if (willDelete.isConfirmed) {
              setLoading(true)
              const formData = new FormData()
              formData.append("staff_id", id)
              try {
                const response = await axios({
                  method: "post",
                  url: `${configure.apiUrl}/destroy`,
                  data: formData,
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                  },
                }).then(response => {
                  Swal.fire({
                    icon: "success",
                    text: "Staff Deleted Successfully",
                  }).then(data => {
                    setDatas(response?.data?.data)
                  })
                  setLoading(false)
                })
              } catch (err) {
                swal({
                  icon: "error",
                  text: "SomeThing Went Wrong",
                })
                setLoading(false)
              }
            }
          })
        } else {
          setAccessDenied(true)
        }
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }
  const onClearLogin = async ({ id }) => {
    const permission = await checkPermissionsByModule(role, 66, 4)
    if (permission) {
      try {
        const formDataEntries = {
          staff_id: id,
        }
        const response = await FetchPostData(toggleLoading, "login_clear", "", formDataEntries)
        if (response?.data?.success) {
          Swal.fire({
            icon: "success",
            text: "Staff Login clear Successfully",
          })
        }
      } catch (err) {
        swal({
          icon: "error",
          text: "SomeThing Went Wrong",
        })
      }
    } else {
      setLoading(false)
      setAccessDenied(true)
    }
  }

  const statusChange = async (e, id, status) => {
    setLoading(true)
    const permission = await checkPermissionsByModule(role, 66, 4)
    setLoading(false)
    if (permission) {
      Swal.fire({
        text: `Are you sure you want to ${status == 1 ? "enable" : "disable"
          } this staff?`,
        icon: "warning",
        showCancelButton: true,
      }).then(async Active => {
        if (Active.isConfirmed) {
          setLoading(true)
          try {
            const formDataEntries = {
              staff_id: id,
              status: status
            }
            const response = await FetchPostData(toggleLoading, "staffStatus", "", formDataEntries)
            if (response?.data?.success) {
              Swal.fire({
                text: `Staff status ${status == 1 ? "enable" : "disable"
                  } successfully`,
                icon: "success",
              })
              setLoading(false)
            }
          } catch (error) {
            setLoading(false)
            console.log(error)
          }
        } else {
          e.target.checked = !e.target.checked
        }
      })
    } else {
      setAccessDenied(true)
      e.target.checked = !e.target.checked
    }
    setLoading(false)
  }
  const onViewModal = (modal, staffId) => {
    setmodal_fullscreen(modal);
    setStaffId(staffId);
  }

  // table columns
  const columns = useMemo(
    () => staffColumns(statusChange, onViewModal, onEditPermission, onClearLogin, onDeletePermission),
    []
  )

  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const toggleVertical = tab => {
    setverticalActiveTab(tab)
  }


  useEffect(() => {
    const fetchStaff = async () => {
      const datas = await fetchData(toggleLoading, "staff", secureLocalStorage.getItem("auth_token"));
      setDatas(datas?.data)
    }
    fetchStaff()
  }, [])

  const filterViewStaffData = useMemo(() => {
    return data?.find(item => item.id === staff_id)
  }, [staff_id, data])

  const location = useLocation()

  useEffect(() => {
    const staffId = localStorage.getItem("staffId")
    const roleId = localStorage.getItem("roleId")
    const cleanup = () => {
      if (staffId) {
        localStorage.removeItem("staffId")
      }
      if (roleId) {
        localStorage.removeItem("roleId")
      }
    }
    cleanup()
  }, [location])

  const [staffData, setStaffData] = useState({})
  const getStaffData = (item) => {
    setStaffData(item)
  }

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="page-content" id="staff">
        <Container fluid>
          <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Staff"} />
          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <div className="responsive-table">
                    <TableContainer
                      columns={columns}
                      data={data}
                      setAccessDenied={setAccessDenied}
                      setLoading={setLoading}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* View fullscreen Modal */}
      <Modal
        size="xl"
        isOpen={modal_fullscreen}
        toggle={() => {
          tog_fullscreen()
        }}
        className="modal-fullscreen"
      >
        <div className="modal-header mb-0">
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ width: "100%" }}
          >
            <h5 className="col-6 modal-title mt-0">Staff Details</h5>
            <div className="col-6 mr-0 d-flex justify-content-end align-items-center">
              <img
                src={`${configure.imgUrl}${filterViewStaffData?.avatar}`}
                className="profileImg"
                alt="no img"
              />
              <p className="fw-bold mb-0 ms-2">{filterViewStaffData?.name}</p>
            </div>
          </div>

          <button
            type="button"
            onClick={() => {
              setmodal_fullscreen(false)
              setverticalActiveTab("1")
            }}
            className="ms-4"
            style={{ border: "none", backgroundColor: "transparent" }}
          >
            <span
              aria-hidden="true"
              style={{ color: "#bf1e30", fontSize: "28px", lineHeight: "1px" }}
            >
              {" "}
              &times;{" "}
            </span>
          </button>
        </div>

        <div
          className="modal-body hide-bottom-scrollbar ps-3 pe-0 pt-0"
          id="staff-View"
        >
          <Row className="gx-4 justify-content-between">
            <Col md="2" className="side-tab py-3 h-100 bd-fixed-sidetab">
              <Nav pills className="flex-column">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleVertical("1")
                    }}
                  >
                    Personal Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "2",
                    })}
                    onClick={() => {
                      toggleVertical("2")
                    }}
                  >
                    Zithas CTC
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "3",
                    })}
                    onClick={() => {
                      toggleVertical("3")
                    }}
                  >
                    Work Experience
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "4",
                    })}
                    onClick={() => {
                      toggleVertical("4")
                    }}
                  >
                    Education
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "5",
                    })}
                    onClick={() => {
                      toggleVertical("5")
                    }}
                  >
                    Documents
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "8",
                    })}
                    onClick={() => {
                      toggleVertical("8")
                    }}
                  >
                    Generate Documents
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "6",
                    })}
                    onClick={() => {
                      toggleVertical("6")
                    }}
                  >
                    Zithas Credential
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "9",
                    })}
                    onClick={() => {
                      toggleVertical("9")
                    }}
                  >
                    Company Commitment
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "7",
                    })}
                    onClick={() => {
                      toggleVertical("7")
                    }}
                  >
                    Permission
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col md="10" className="bd-right-side-tab">
              <div className="p-4 h-100 pl-0">
                <TabContent
                  activeTab={verticalActiveTab}
                  className="text-muted mt-4 mt-md-0"
                >
                  <TabPane tabId="1">
                    <PersonalDetails id={filterViewStaffData?.id} verticalActiveTab={verticalActiveTab} staffData={getStaffData} />
                  </TabPane>
                  <TabPane tabId="2">
                    <ZithasCtc id={filterViewStaffData?.id} zithasCTC={staffData} />
                  </TabPane>
                  <TabPane tabId="3">
                    <WorkExperience id={filterViewStaffData?.id} staffData={staffData} verticalActiveTab={verticalActiveTab} />
                  </TabPane>
                  <TabPane tabId="4">
                    <Education id={filterViewStaffData?.id} staffData={staffData} verticalActiveTab={verticalActiveTab} />
                  </TabPane>
                  <TabPane tabId="5">
                    <Documents id={filterViewStaffData?.id} verticalActiveTab={verticalActiveTab} />
                  </TabPane>
                  <TabPane tabId="8">
                    <GenerateDoc id={filterViewStaffData?.id} verticalActiveTab={verticalActiveTab} />
                  </TabPane>
                  <TabPane tabId="6">
                    <ZithasCredential id={filterViewStaffData?.id} />
                  </TabPane>

                  <TabPane tabId="7">
                    <PermissionView
                      id={filterViewStaffData?.id}
                      verticalActiveTab={verticalActiveTab}
                    />
                  </TabPane>

                  <TabPane tabId="9">
                    <CompanyCommitment id={filterViewStaffData?.id} staffData={staffData} />
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>

      {/* Access Denied */}

      <AccessDeniedPopup
        modal_Access={modal_Access}
        setAccessDenied={setAccessDenied}
      />
    </React.Fragment>
  )
}

export default Staff
