import React, { useEffect, useState } from "react"
import { Card, CardBody, Table } from "reactstrap"
import { fetchData, FetchPostData } from "utils/common-fetchData"
import zithas from "../../../assets/images/main-zithas.png"
import { Tooltip } from "antd"
import Loader from "pages/Separate/Loader"
import configure from "configure"
import secureLocalStorage from "react-secure-storage"
import Swal from "sweetalert2"

const LoginClear = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const toggleLoading = loading => {
    setLoading(loading)
  }
  useEffect(() => {
    const getLoginClear = async () => {
      const response = await fetchData(toggleLoading, "dashboardData/3", "")
      if (response?.success) {
        console.log(response)
        setData(response?.data?.timer_user)
      }
    }
    getLoginClear()
  }, [])



  const onClearLogin = async id => {
    const auth = secureLocalStorage.getItem("auth_token")
    const formEntries = {
      staff_id:id
    }
    const res = await FetchPostData(toggleLoading, `login_clear`, auth, formEntries)
    if (res?.data?.success) {
      Swal.fire({
        icon: "success",
        text: "Login cleared successfully",
      })
    }
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div >
        <Card>
          <CardBody>
            <div className="mb-4 d-flex justify-content-between">
              <p className="text-purple fs-5 mb-0 fw-bold">Clear Login </p>
              <div className="text-purple absent-length">{data?.length}</div>
            </div>
            <div className="responsive-table mb-4" >
              <Table className="table table-bordered mb-0">
                <thead>
                  <tr>
                    <th className="header col-2">Id</th>
                    <th className="header col-5">Users</th>
                    <th className="text-center header col-2">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((obj, i) => {
                    return (
                      <tr key={i}>
                        <td>{obj?.id}</td>
                        <td>
                          <Tooltip color={"#752b97"} title={obj?.name}>
                            <img
                              src={`${
                                obj?.avatar != null
                                  ? `${configure.imgUrl}${obj?.avatar}`
                                  : zithas
                              } `}
                              alt="user Image"
                              className="img-fluid cursor-pointer object-fit-cover"
                              style={{
                                borderRadius: "50px",
                                width: "30px",
                                height: "30px",
                              }}
                              title={obj?.name}
                            />
                          </Tooltip>
                        </td>

                        <td className="text-center">
                          <a
                            title="Clear Login"
                            className="btn btn-sm btn-outline-secondary"
                            onClick={() => onClearLogin(obj?.id)}
                          >
                            <i className="fa fa-history   "></i>
                          </a>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}

export default LoginClear
