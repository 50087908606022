import React, { useState } from 'react'
import { Card, CardBody, Input, Label, Row } from 'reactstrap'
import Select from "react-select"
import { useSelector } from 'react-redux'
import DemoSalary from "../../assets/pdf/demoPDF.pdf"
const AuditReport = () => {
    const getLocation = useSelector((state) => state?.location?.locations)
    const [audit_type, setAuditType] = useState(null);
    const [generate, setGenerate] = useState(false)
    const [formRows, setFormRows] = useState([{}])

    const onAddFormRow = () => {
        setFormRows([...formRows, {}])
    }

    const onDeleteFormRow = index => {
        const newInputs = [...formRows]
        newInputs.splice(index, 1)
        setFormRows(newInputs)
    }
    const auditType = [
        { label: "Lead", value: "lead" },
        { label: "Project", value: "project" },
    ]
    const leadOption = [
        {
            value: 53,
            label: "Global Biotech"
        },
        {
            value: 55,
            label: "Manav Infotech"
        },
        {
            value: 57,
            label: "Zydus Ltd"
        },
        {
            value: 59,
            label: "sunpharma"
        },
        {
            value: 60,
            label: "TLC"
        },
        {
            value: 70,
            label: "Zithas Tech."
        },

    ]
    const projects = [
        {
            value: 25,
            label: "test111"
        },
        {
            value: 26,
            label: "Test-project 99"
        },
        {
            value: 28,
            label: "Test-project-19"
        }
    ]
    return (
        <div>
            <div className='ms-3'>
                <div>
                    <form className='px-2 py-2 '>
                        <Row className="mt-3 py-3 audit_box">
                            <div className='d-flex flex-column'>
                                <h5 className='mb-3'>Generate Audit Report</h5>
                                <div className='d-flex justify-content-start align-items-center gap-3'>
                                    <div className="mb-3 col-md-3">
                                        <Label htmlFor="formrow-firstname-Input">
                                            Location <span className="text-danger">*</span>
                                        </Label>
                                        <Select
                                            placeholder="Select Location"
                                            className="basic-multi-select"
                                            classNamePrefix=" select"
                                            options={getLocation}
                                        />
                                    </div>
                                    <div className="mb-3 col-md-3">
                                        <Label htmlFor="formrow-firstname-Input">
                                            Audit Type <span className="text-danger">*</span>
                                        </Label>
                                        <Select
                                            placeholder="Select Audit Type"
                                            className="basic-multi-select"
                                            classNamePrefix=" select"
                                            options={auditType}
                                            onChange={(e) => setAuditType(e)}
                                        />
                                    </div>
                                    <div className="mb-3 col-md-3">
                                        {audit_type != null ? (
                                            <>
                                                <Label htmlFor="formrow-firstname-Input">
                                                    {audit_type?.label} <span className="text-danger">*</span>
                                                </Label>
                                                <Select
                                                    placeholder="Select Audit Type"
                                                    className="basic-multi-select"
                                                    classNamePrefix=" select"
                                                    options={audit_type?.value == "lead" ? leadOption : projects}
                                                />
                                            </>
                                        ) : null}
                                    </div>
                                    <div className="col-md-3" style={{marginTop:"10px"}}>
                                        <a className="s btn text-white btn-save me-0  btn-label " onClick={() => setGenerate(true)}><i className="mdi mdi-file-chart me-2 icon-size label-icon"></i>Create Report</a>
                                    </div>
                                </div>

                            </div>

                        {
                            generate ? (
                                <div className='col-12 d-flex mt-4 pe-5'>
                                    <div className='col-6 d-flex flex-column'>
                                        {/* <h5>Created Report</h5> */}
                                        <div className='mt-2 text-primary fs-6'>
                                            <i className='bi bi-arrow-right text-primary me-2'></i>
                                            Website Link : <a className='text-purple ms-3'>https://www.zithas.com</a>
                                            <a><i className='ms-2 bi bi-clipboard'></i></a>                               
                                        </div>
                                    </div>
                                </div>
                            ) : ""
                        }

                        </Row>
                    </form>
                    {generate ? (
                        <div className='mt-3 activity_box'>
                            {/* <div className='col-12 d-flex'>
                                <div className='col-6 d-flex flex-column'>
                                    <h5>Created Report</h5>
                                    <div className='mt-2 text-primary fs-6'>
                                        <i className='bi bi-arrow-right text-primary me-2'></i>
                                        Website Link : <a className='text-purple ms-3'>https://www.zithas.com</a>
                                        <a><i className='ms-2 bi bi-clipboard'></i></a>                               
                                    </div>
                                </div>
                                <div className='col-6 text-end'>
                                    <a className="s btn text-white btn-save me-0 btn-label ms-4" onClick={() => setGenerate(true)}><i className="mdi mdi-content-save me-2 icon-size label-icon"></i>Save</a>
                                  
                                    <a
                                        className="s btn text-white me-2 ms-3 btn-info btn-label"
                                        title="Download"
                                        href={DemoSalary}
                                        target="_blank"
                                        rel="noreferrer"
                                        download
                                    >
                                        <i className="mdi mdi-download me-2 icon-size label-icon"></i>Download Report


                                    </a>
                                </div>
                               
                            </div> */}
                          
                            
                                <div className='d-flex justify-content-between align-items-center'>
                                    <h4 className=''>Activities</h4>
                                    <div className='col-6 text-end pe-3'>
                                        <a className="s btn text-white btn-save me-0 btn-label ms-4" onClick={() => setGenerate(true)}><i className="mdi mdi-content-save me-2 icon-size label-icon"></i>Save</a>
                                    
                                        <a
                                            className="s btn text-white me-2 ms-3 btn-info btn-label"
                                            title="Download"
                                            href={DemoSalary}
                                            target="_blank"
                                            rel="noreferrer"
                                            download
                                        >
                                            <i className="mdi mdi-download me-2 icon-size label-icon"></i>Download Report


                                        </a>
                                    </div>
                                </div>
                                <div className='col-12 d-flex flex-column justify-content-start align-items-center gap-3 my-4'>
                                    {/* <div className='col-12 d-flex justify-content-start align-items-center gap-3'>
                                        <div>
                                            <i className="bi bi-arrow-right"></i>

                                        </div>
                                        <div className='col-12 mt-1 d-flex justify-content-start align-items-center'>
                                            <h5 className='col-6  text-purple'>Starting Developemnt as per requirement Project Plan and Resource Allocation.</h5>
                                            <h5 className='ml-100 text-purple col-6'>Type : <span className='text-primary'>Input Field </span> </h5>
                                        </div>                 
                                    </div> */}
                                    <div className='col-12 d-flex flex-column'>
                                        {/* <div className='col-12 d-flex flex-column gap-3'>
                                            <input type='text' className = "form-control" placeholder='Enter Activity Name'></input>
                                            <input type='text' className = "form-control" placeholder='Enter Activity Name'></input>
                                            <input type='text' className = "form-control" placeholder='Enter Activity Name'></input>
                                        </div> */}
                                        <div className='col-12'>
                                            <table className="table table-bordered table-responsive table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>Activity Name</th>
                                                        <th>Input field 1</th>
                                                        <th>Input field 2</th>
                                                        <th>Input field 3</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className='acti_name'>Starting Developemnt as per requirement Project Plan and Resource Allocation.</td>
                                                        <td className='acti_inp1'><input type='text' className='form-control' placeholder='input field 1'></input></td>
                                                        <td  className='acti_inp1'><input type='text' className='form-control' placeholder='input field 2'></input></td>
                                                        <td  className='acti_inp1'><input type='text' className='form-control' placeholder='input field 3'></input></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12 d-flex flex-column justify-content-start align-items-center gap-3 my-4'>
                                    <div className='col-12 d-flex justify-content-start align-items-center gap-3'>
                                        <div>
                                            <i className="bi bi-arrow-right"></i>

                                        </div>
                                        <div className='col-12 mt-1 d-flex justify-content-start align-items-center'>
                                            <h5 className='col-6  text-purple'>Add Help module to the client customer and add video in that section.</h5>
                                            {/* <h5 className='ml-100 text-purple col-6'>Type : <span className='text-primary'>Text Area </span> </h5> */}
                                        </div>                 
                                    </div>
                                    <div className='col-12 d-flex flex-column'>
                                        <div className='col-12 d-flex flex-column gap-3'>
                                            <textarea cols="30" rows="7" placeholder='Enter Activity Name' className='form-control'></textarea>                                  
                                        </div>
                                    </div>

                                </div>

                                <div className='col-12 d-flex flex-column justify-content-start align-items-center gap-3 my-4'>
                                    <div className='col-12 d-flex justify-content-start align-items-center gap-3'>
                                        <div>
                                            <i className="bi bi-arrow-right"></i>

                                        </div>
                                        <div className='col-12 mt-1 d-flex justify-content-start align-items-center'>
                                            <h5 className='col-6 text-purple'>Design the timer change section from header for work module.</h5>
                                            {/* <h5 className='ml-100 text-purple col-6'>Type : <span className='text-primary'>Multiple </span> </h5> */}
                                        </div>                 
                                    </div>
                                    
                                    <div className='col-12 d-flex'>
                                        
                                        {/* <div className="contact-form d-flex flex-column col-md-12 p-0 outline-secondary setting_package_box"> */}
                                        <div className="contact-form d-flex flex-column col-md-12 p-0 outline-secondary">
                                            {/* <div className="col-12 d-flex justify-content-between align-items-center gap-3 mb-3">
                                                <div className='col-10'>
                                                    <input type='text' className='form-control'placeholder='Enter Activity'></input>                                   
                                                </div>

                                                <div className='col-2'>
                                                <button className="btn btn-purple btn-label me-2"
                                                    onClick={e => {
                                                    e.preventDefault()
                                                        onAddFormRow()
                                                    }}>
                                                    <i className="fas fa-plus me-2 icon-size label-icon"></i>
                                                        Add More
                                                </button>

                                                </div>
                                            </div> */}
                                            <div className="col-12 d-flex justify-content-start align-items-center my-2">
                                                    <div className="contact-column edit-contact-column mb-md-0 col-11">
                                                        <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="formrow-firstname-Input"
                                                        placeholder="Enter Activity Name"
                                                        name="task_name"
                                                        // value={person.task_name}
                                                        // onChange={e => handleInputChange(key, e)}
                                                        />
                                                    </div>

                                                    <div className="col-1 contact-btns d-flex justify-content-start align-items-end mb-md-0 mt-0">
                                                        <button
                                                        onClick={e => {
                                                            e.preventDefault()
                                                            onAddFormRow()
                                                        }}
                                                        className={`btn btn-save`}
                                                        title='Add More'
                                                        >
                                                        {/* <i className={`fas fa-plus me-0`}> Add More</i> */}
                                                        <i className="fas fa-plus me-2 icon-size label-icon"></i>
                                                        Add More
                                                        </button>
                                                    </div>
                                                    </div>
                                                {formRows?.map((person, key) => {
                                                return (
                                                <div key={key} className="">
                                                    <div className="col-12 d-flex justify-content-start align-items-center my-2">
                                                    <div className="contact-column edit-contact-column mb-md-0 col-11">
                                                        <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="formrow-firstname-Input"
                                                        placeholder="Enter Activity Name"
                                                        name="task_name"
                                                        // value={person.task_name}
                                                        // onChange={e => handleInputChange(key, e)}
                                                        />
                                                    </div>

                                                    <div className="col-1 contact-btns d-flex justify-content-start align-items-end mb-md-0 mt-0">
                                                        <button
                                                        onClick={e => {
                                                            e.preventDefault()
                                                            onDeleteFormRow(key)
                                                        }}
                                                        className={`btn btn-red`}
                                                        >
                                                        <i className={`fas fa-trash-alt me-0`}></i>
                                                        </button>
                                                    </div>
                                                    </div>
                                                    {key !== formRows.length - 1 ? (
                                                    <div className=""></div>
                                                    ) : null}
                                                </div>
                                                )
                                            })}

                                            </div>
                                    </div>

                                    <div className='col-12 mt-4 text-end'>
                                        
                                        {/* <a className="s btn text-white me-0 btn-purple btn-label"><i className="mdi mdi-file-chart me-2 icon-size label-icon"></i>Generate Report</a> */}

                                        {/* <button
                                            // onClick={handleCancel}
                                            type="button"
                                            // onClick={()=> setEditStatus(false)}
                                            className=" btn ms-md-2 btn-red btn-label"
                                        >
                                            <i className="dripicons-cross me-2 icon-center label-icon"></i>
                                            CANCEL
                                        </button> */}
                                    
                                    </div>
                                </div>
                        </div>
                    ) : null}

                </div>
            </div>
        </div>
    )
}

export default AuditReport
