import React from "react"
import indiaFlag from "../../../assets/images/flags/india.png"
import ukFlag from "../../../assets/images/flags/uk.png"
export const settingsColumns = (onDeleteActivity, onEditActivity) => [
    {
        Header: "ID",
        accessor: "id",
        isShortIcon: true,
        className: "activity_id_width",
    },
    {
        Header: "Activity Name",
        accessor: "activity",
        isShortIcon: true,
        className: "activity_name",
    },
    {
        Header: "Est. Time",
        accessor: "est_time",
        isShortIcon: true,
        className: "estimate_time",

    },
    {
        Header: "Action",
        accessor: "action",
        className: "activity_action_width",
        Cell: cellProps => {
            return (
                <div>
                    <a
                        onClick={() => {
                            onEditActivity(cellProps.row.original.id, cellProps.row.original)
                        }}
                        className="btn btn-sm me-1 btn-outline-secondary"
                    >
                        <i className="fas fa-pencil-alt"></i>
                    </a>
                    <a onClick={() => onDeleteActivity(cellProps.row.original.id)} className="btn btn-sm  btn-danger">
                        <i className="fas fa-trash-alt"></i>
                    </a>
                </div>
            )
        },
    },
]

export const packageColumns = (onDeletePackage, editPackage) => [
    {
        Header: "ID",
        accessor: "id",
        isShortIcon: true,
        className: "package_id_width",
    },
    {
        Header: "Country",
        accessor: "country",
        isShortIcon: true,
        className: "country_width",
        Cell: cellProps => {
            const location = cellProps?.row?.original?.country
            return (
                <div>
                    <img
                        src={location == "1" ? indiaFlag : ukFlag}
                        value={location}
                        className="location-img"
                        alt="flag"
                    />
                </div>
            )
        },
    },
    {
        Header: "Package Name",
        accessor: "name",
        isShortIcon: true,
        className: "package_name",
    },
    {
        Header: "Activity Count",
        accessor: "activity_count",
        isShortIcon: true,
        className: "activity_count",
        Cell: cellProps => {
            const count = cellProps?.row?.original?.quantity != undefined ? cellProps?.row?.original?.quantity?.split(',') : []
            return (
                <div>{count?.length}</div>
            )
        }
    },
    {
        Header: "Total Est Hours",
        accessor: "total_est_time",
        isShortIcon: true,
        className: "estimated_hourse",
    },
    {
        Header: "Action",
        accessor: "action",
        className: "action_width",
        Cell: cellProps => {
            return (
                <div>
                    <a
                        onClick={() => {
                            editPackage(cellProps?.row?.original)
                        }}
                        className="btn btn-sm me-1 btn-outline-secondary"
                    >
                        <i className="fas fa-pencil-alt"></i>
                    </a>
                    <a onClick={() => onDeletePackage(cellProps?.row?.original?.id)} className="btn btn-sm  btn-danger">
                        <i className="fas fa-trash-alt"></i>
                    </a>
                </div>
            )
        },
    },
]