import React, { useState } from "react"
import { Form, Input, Row } from "reactstrap"
import Select from "react-select"
import { RelatedToOption, StaffOption } from "utils/common-options"
import { useSelector } from "react-redux"
import { FetchPostData } from "utils/common-fetchData"
import Loader from "pages/Separate/Loader"
import secureLocalStorage from "react-secure-storage"
import Swal from "sweetalert2"

const FloatingBtn = () => {
  const [notesSidebar, setNotesSidebar] = useState(false)
  const [loading, setLoading] = useState(false)
  const [related, setRelated] = useState([])
  const [relatedto_option, setRelatedToOption] = useState([])
  const [relatedto, setRelatedTo] = useState([])
    const [formRows, setFormRows] = useState([{}])
  const generalData = useSelector(state => state?.GeneralData?.generalData)
  const [relatedIdOption, setRelatedIdOption] = useState([])
  const [staffId, setStaffId] = useState(null)
  const [relatedId, setRelatedId] = useState(null)
  const [file, setFile] = useState("")

  const onAddFormRow = () => {
    setFormRows([...formRows, {}])
  }

  const onDeleteFormRow = index => {
    const newInputs = [...formRows]
    newInputs.splice(index, 1)
    setFormRows(newInputs)
  }

  const handleInputChange = (index, e) => {
    const { name, value } = e.target
    const updatedFormRows = [...formRows]
    updatedFormRows[index] = {
      ...updatedFormRows[index],
      [name]: value,
    }
    setFormRows(updatedFormRows)
  }
  const toggleLoading = loading => {
    setLoading(loading)
  }

  const handleAddReport = async e => {
    e.preventDefault()
    if (staffId != null && staffId?.length != 0) {
      const formEntries = {
        user_id: localStorage.getItem('auth_id'),
        staff_id: staffId?.value,
        related: related?.value,
        related_to: relatedto?.value,
        related_id: relatedId?.value,
        reason: formRows?.map(row => row?.incident)?.join("#@"),
        title: formRows?.map(row => row?.title)?.join("#@"),
        file: file,
      }
      const token = secureLocalStorage.getItem("auth_token")
      const res = await FetchPostData(
        toggleLoading,
        "addImproveReport",
        token,
        formEntries
      )
      if (res?.data?.success) {
        Swal.fire({
          icon: "success",
          text: "Flaws & Gap Added Successfully",
        })
        setNotesSidebar(false)
        setStaffId([])
        setRelated([])
        setRelatedTo([])
        setFormRows([{incident:"", title:""}])
        setRelatedId([])
        setNotesSidebar(false)
        setRelatedIdOption([])
        setRelatedToOption([])
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "Please Select Staff",
      })
    }
  }

  return (
    <div>
      {loading && <Loader />}
      <div>
        <div>
          <div className="floating-btn">
            <button
              className="bd-report-float btn btn-red"
              onClick={() => setNotesSidebar(!notesSidebar)}
            >
              Flaws & Gap
            </button>
          </div>

          {/* Add Notes Sidebar */}
          <div
            className={`notes-sidebar ${
              notesSidebar ? "show-notes-sidebar report-width" : ""
            }`}
          >
            <form className="" onSubmit={handleAddReport}>
              <Row className="align-items-center ">
                <div className="col-12 d-flex justify-content-between">
                  <h5>Add Staff Flaws & Gap</h5>
                  <div className="text-end">
                    <button className="btn btn-purple  me-2">
                      <i className="fas fa-plus me-2"></i>Submit
                    </button>
                    <a
                      onClick={() => setNotesSidebar(false)}
                      className="btn btn-red text-uppercase "
                    >
                      <i className="dripicons-cross me-1 icon-center"></i>Close
                    </a>
                  </div>
                </div>
                <div className="col-md-3 mt-4">
                  <label htmlFor="">Staff <span className="text-danger">*</span></label>
                  <Select
                    placeholder="Select"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    options={generalData?.staff}
                    value={staffId}
                    onChange={e => setStaffId(e)}
                  />
                </div>
                <div className="col-md-3 mt-4">
                  <label htmlFor="">Related</label>
                  <Select
                    placeholder="Select"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    options={RelatedToOption}
                    value={related}
                    onChange={e => {
                      setRelated(e)
                      setRelatedToOption(e?.related)
                      setRelatedTo([])
                      setRelatedIdOption([])
                      setRelatedId([])
                    }}
                  />
                </div>
                {relatedto_option?.length != 0 && (
                  <div className="col-md-3 mt-4">
                    <label htmlFor="">{related?.label}</label>
                    <Select
                      placeholder="Select"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      options={relatedto_option}
                      value={relatedto}
                      onChange={selectedOption => {
                        setRelatedTo(selectedOption)
                        setRelatedId([])
                        setRelatedIdOption(
                          selectedOption?.value == "lead"
                            ? generalData?.leads
                            : selectedOption?.value == "proposal"
                            ? generalData?.proposal
                            : selectedOption?.value == "digital-project"
                            ? generalData?.digital_projects
                            : selectedOption?.value == "development-project"
                            ? generalData?.projects
                            : selectedOption?.value == "introducer"
                            ? generalData.introducer
                            : selectedOption?.value == "staff"
                            ? generalData.staff
                            : []
                        )
                      }}
                    />
                  </div>
                )}
                {relatedIdOption?.length != 0 && (
                  <div className="col-md-3 mt-4">
                    <label htmlFor="">{relatedto?.label}</label>
                    <Select
                      placeholder="Select"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      options={relatedIdOption}
                      value={relatedId}
                      onChange={e => setRelatedId(e)}
                    />
                  </div>
                )}

                <div className="mt-4 report-height">
                  <label htmlFor="">Reasons <span className="text-danger">*</span></label>
                  {formRows?.map((person, key) => {
                    return (
                      <div key={key} className={`${key == 0 ? "" : "mt-3"} `}>
                        <div className="col-12 d-flex justify-content-start align-items-center my-2">
                          <div className="contact-column edit-contact-column mb-md-0 w-100">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Enter Title"
                              name="title"
                              value={person.title}
                              required
                              onChange={e => handleInputChange(key, e)}
                            />
                          </div>
                          <div className="contact-btns d-flex justify-content-end align-items-end mb-md-0 mt-0">
                            <button
                              onClick={e => {
                                e.preventDefault()
                                if (key == 0) {
                                  onAddFormRow()
                                } else {
                                  onDeleteFormRow()
                                }
                              }}
                              className={`btn ${
                                key == 0 ? "btn-purple" : "btn-red"
                              }`}
                            >
                              <i
                                className={`fas ${
                                  key == 0 ? "fa-plus" : "fa-trash-alt"
                                } me-0`}
                              ></i>
                            </button>
                          </div>
                        </div>
                        <div className="contact-column edit-contact-column mb-md-0 mt-2 w-100">
                          <textarea
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter Incident"
                            name="incident"
                            required
                            value={person.incident} // Bind value to state
                            onChange={e => handleInputChange(key, e)}
                          />
                        </div>
                      </div>
                    )
                  })}
                </div>

                <div className="col-md-6 mt-4">
                  <label htmlFor="">Upload File</label>
                  <Input
                    type="file"
                    className="form-control"
                    id="formrow-firstname-Input"
                    placeholder="Enter Reasons"
                    name="task_name"
                    onChange={e => setFile(e.target.files)}
                  />
                </div>
              </Row>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FloatingBtn
