import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import {
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import editEventImg from "../../../assets/images/Digital Marketing/editEventImg.jpg"
import sideImg from "../../../assets/images/profile.png"
import "@fullcalendar/bootstrap/main.css";
import "../../../assets/css/Work/digitalMarketing.css"
import secureLocalStorage from "react-secure-storage";
import { FetchPostData } from "utils/common-fetchData";
import moment from "moment";
import Loader from "pages/Separate/Loader";
import { addTimes, calculateQtyWithinTime, calculateTotalTime, FilteredArray2, getDayType, getWeekStartAndEnd, updateSpecificDate, updateTimeForDate } from "utils/common-helpers";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import configure from "configure";

const CompleteSprintCalendar = ({ resource, key }) => {
  document.title = "Future Plan | Zithas Crm";
  const [event, setEvent] = useState({});
  const [allActivities, setAllActivities] = useState([])
  const [activityName, setActiviyName] = useState('');
  const navigate = useNavigate();


  document.addEventListener("keydown", e => {
    if (e.key == "Escape") {
      if (modal) {
        setModal(false)
      }
    }
  })

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [eventsList, setEvents] = useState([]);
  const [modalData, setModalData] = useState({})
  const [eventData, setEventData] = useState({});
  const [qty, setQty] = useState('');
  const [addModal, setAddModal] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  useEffect(() => {
    new Draggable(document.getElementById("external-events"), {
      itemSelector: ".external-event",
    });
  }, []);


  const handleEventClick = arg => {
    const event = arg.event;
    setEvent({
      id: event.id,
      title: event.title,
      title_category: event.title_category,
      start: event.start,
      className: event.classNames,
      category: event.classNames[0],
      event_category: event.classNames[0],
    });
    setIsEdit(true);
  };


  const onDrag = event => {
    event.preventDefault();
  };


  const onDrop = async (event) => {
    const { date, draggedEl } = event;
    const categoryId = draggedEl.id;
    const quantity = draggedEl.getAttribute('data-quantity');
    const estTime = draggedEl.getAttribute('data-est');
    const taskId = draggedEl.getAttribute('data-id');
    const projectId = draggedEl.getAttribute('data-project-id');
    const activiytId = draggedEl.getAttribute('data-activity-id');
    const type = draggedEl.getAttribute('data-type');

    if (!draggedEl || !draggedEl.classList.contains('external-event')) {
      return;
    }

    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    const currentTime = new Date();

    const modifiedDate = new Date(
      year,
      month,
      day,
      currentTime.getHours(),
      currentTime.getMinutes(),
      currentTime.getSeconds()
    );
    const mainDate = moment(date).format('YYYY-MM-DD')
    const time = customDateHtml?.find((obj) => obj?.date == mainDate)?.time

    const newEvent = {
      title: draggedEl.innerText || draggedEl.getAttribute('title'),
      start: modifiedDate,
      className: draggedEl.className,
      id: categoryId,
      quantity: quantity,
      est_time: estTime,
      actual_time: calculateTotalTime(estTime, quantity),
      occupied: time

    };
    const formEntries = {
      task_id: taskId,
      staff_id: localStorage.getItem('auth_id'),
      project_id: projectId,
      activity_id: activiytId,
      type: type,
      quantity: quantity,
      start_date: mainDate,
      user_id: localStorage.getItem('auth_id'),
      className: draggedEl.className,
    }
    setActiviyName(draggedEl.innerText || draggedEl.getAttribute('title'))
    setQty(quantity)
    setModalData(formEntries)
    setEventData(newEvent)
    setAddModal(true)
  };

  const [customDateHtml, setCustomDateHtml] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [mainAllActivity, setMainAllActivity] = useState([])
  const token = secureLocalStorage.getItem('auth_id')
  const [sprintStatus, setSprintStatus] = useState(false)
  const toggleLoading = (load) => {
    setLoading(load);
  }
  const getTaskData = async (info) => {
    setCustomDateHtml([])
    const formEntries = {
      user_id: localStorage.getItem('auth_id'),
      start_date: moment(info.view.currentStart)?.format('YYYY-MM-DD'),
      end_date: moment(info.view.currentEnd)?.format('YYYY-MM-DD'),
      sprint_start_date: getWeekStartAndEnd()?.startOfWeek
    }
    const res = await FetchPostData(toggleLoading, 'pendingDigitalActivityTasks', token, formEntries);
    if (res?.data?.success) {
      const details = res?.data?.data
      setSprintStatus(details?.sprint_status)
      setCustomDateHtml(details?.availability?.original.data)
      setAllActivities(FilteredArray2(details?.pending_tasks, details?.updated_pending_task?.original?.data))
      setMainAllActivity(details?.pending_tasks)
      setEvents(details?.updated_pending_task?.original?.data?.map((obj) => ({
        title: obj.name,
        start: obj?.date,
        id: obj.id,
        task_id: obj.id,
        activity_id: obj?.activity_id,
        quantity: obj?.quantity,
        est_time: obj?.est_hrs,
        actual_time: calculateTotalTime(obj?.est_hrs, obj?.quantity),
        className: obj?.className,
        staff_id: obj?.staff_id,
      })))

    }
  }
  useEffect(() => {
  }, [startDate])


  const handleAddActivity = async (e) => {
    e.preventDefault();
    if (qty != 0) {
      const formEntries = { ...modalData, quantity: qty, task_type: 2, est_hrs: calculateTotalTime(eventData?.est_time || "00:00", qty) }
      const res = await FetchPostData(toggleLoading, "updateTaskDate", token, formEntries)
      if (res?.data?.success) {
        const detail = res?.data?.data?.original?.data
        setCustomDateHtml(updateTimeForDate(customDateHtml, modalData?.start_date, eventData?.actual_time))
        setAllActivities(FilteredArray2(mainAllActivity, detail))
        setEvents(detail?.map((obj) => ({
          title: obj.name,
          start: obj?.date,
          id: obj.id,
          task_id: obj.id,
          activity_id: obj?.activity_id,
          quantity: obj?.quantity,
          est_time: obj?.est_hrs,
          actual_time: calculateTotalTime(obj?.est_hrs, obj?.quantity),
          className: obj?.className,
          staff_id: obj?.staff_id
        })))
        setAddModal(false)
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "⚠️ Quantity cannot be zero! Please update it. ✨"
      })
    }
  }


  const onCompleteSprint = async (e) => {
    e.preventDefault()
    if (sprintStatus) {
      Swal.fire({
        icon: "success",
        title: "🎉 Hurrah! You're Up to Date with Your Sprint!"
      })
    } else {
      Swal.fire({
        icon: "warning",
        title: "Are you sure ?",
        text: "You want to Complete your Sprint !",
        showCancelButton: true,
        confirmButtonText: "Yes, complete it!"
      }).then(async (result) => {
        if (result.isConfirmed) {
          const formEntries = {
            user_id: localStorage.getItem('auth_id'),
            start_date: getWeekStartAndEnd()?.startOfWeek,
            end_date: getWeekStartAndEnd()?.endOfWeek
          }
          const res = await FetchPostData(toggleLoading, "completeSprint", token, formEntries)
          if (res?.data?.success) {
            Swal.fire({
              icon: "success",
              title: "Sprint completed successfully!"
            }).then(() => {
              navigate(`${configure.appUrl}dashboard`)
            })
          }
        }
      })
    }
  }



  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="">
        <Row>
          <Col className="col-12">
            <div className="col-12 d-flex justify-content-end align-items-center">
              <button className=" btn text-white me-0 btn-success btn-label ms-2 sprint-buttns"
                disabled={allActivities?.length != 0}
                onClick={onCompleteSprint}
              >
                <i className="bx bx-run me-2 icon-size label-icon"></i>
                Complete Sprint
              </button>
            </div>
            <Row>
              <Col lg={4}>
                <Card>
                  <div className="pt-0" >
                    <div id="external-events" className="mt-0" style={{ height: "860px", overflowY: "scroll", scrollbarWidth: "thin", scrollbarColor: "#8796ce #f0f0f0" }}>
                      <br />
                      <p className="text-muted ps-2">
                        Drag and drop your activity
                      </p>
                      {allActivities &&
                        allActivities.map((category, i) => {
                          const outputString = category?.project_name.split(" ").map(word => word[0]).join("");
                          return (
                            <div className="d-flex justify-space-between align-items-center" key={i}>
                              <div
                                className={`bg-danger external-event fc-event text-white w-100 d-flex justify-space-between align-items-center`}
                                key={"cat-" + i}
                                draggable
                                onDrag={event => onDrag(event, category)}
                                data-id={category?.id}
                                data-project-id={category?.project_id}
                                data-activity-id={category?.activity_id}
                                data-quantity={category?.quantity}
                                data-est={category?.est_time}
                                data-type={category?.type}
                              >
                                <span>
                                  <i className="mdi mdi-checkbox-blank-circle font-size-11 me-2" />
                                  <span title={category.project_name}>{outputString}</span>  - {category.name}
                                </span>

                                <span className="badge bg-light text-dark ms-2">{category.quantity}</span>
                              </div>
                            </div>
                          )
                        }
                        )}
                      {allActivities?.length == 0 ? (
                        <p className="text-center text-purple fw-bold">🎉 All pending tasks have been planned for future! <br /> 🌟 You can now proceed to complete your sprint. 🚀</p>
                      ) : null}
                    </div>
                  </div>
                </Card>
              </Col>
              <Col lg={8}>
                <FullCalendar
                  key="calendar-key"
                  plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin]}
                  initialView="dayGridWeek"
                  slotDuration={"00:15:00"}
                  handleWindowResize={true}
                  themeSystem="bootstrap"
                  weekends={true}
                  headerToolbar={{
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,dayGridWeek,dayGridDay",
                  }}
                  events={eventsList}
                  eventContent={(arg) => {
                    const { event } = arg;
                    return (
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <div
                          className="text-start "
                          style={{
                            wordWrap: "break-word",
                            wordBreak: "break-word",
                            whiteSpace: "normal",
                            maxWidth: "100%",
                          }}
                        >
                          {event.title} - <span className="badge bg-light text-dark ms-2">{event?.extendedProps?.quantity}</span>
                        </div>
                      </div>
                    );
                  }}
                  dayCellContent={(cell) => {
                    const { date } = cell;
                    const time = customDateHtml?.find((obj) => obj?.date === moment(date).format('YYYY-MM-DD'))?.time || "00:00";
                    return (
                      <div className="fc-day-cell-content">
                        <div className="time-header">{time} hrs</div>
                      </div>
                    );
                  }}
                  editable={true}
                  droppable={true}
                  selectable={true}
                  eventClick={handleEventClick}
                  drop={onDrop}
                  datesSet={getTaskData}
                />


                <Modal
                  isOpen={addModal}
                  size="lg"
                  centered
                >
                  <ModalHeader toggle={() => setAddModal(false)} tag="h5" className="py-3 px-4 border-bottom-0">
                    Add Activity
                  </ModalHeader>
                  <ModalBody className="p-4">
                    <Form
                      onSubmit={(e) => {
                        handleAddActivity(e)
                      }}
                    >
                      <Row>
                        <div className="col-md-5 d-flex justify-content-end align-items-center ps-3">
                          <div className="text-center">
                            <img
                              src={editEventImg}
                              alt="Addepe"
                              style={{ width: "90%" }}
                            />
                          </div>
                        </div>
                        <div className="col-7 d-flex flex-column justify-content-center align-items-start pl-0">
                          <div className="d-flex gap-2 col-md-12 mb-5">
                            <a className="text-purple pt-2 px-2 rounded work-status cr-status text-center col-md-4">
                              <h5>Est Time</h5>
                              <h5>{eventData?.est_time} Hrs </h5>
                            </a>
                            <a className="text-purple pt-2 px-2 rounded work-status cr-status text-center col-md-4">
                              <h5>Engaged Time</h5>
                              <h5>{eventData?.occupied} Hrs</h5>
                            </a>
                            <a className="text-purple pt-2 px-2 rounded work-status cr-status text-center col-md-4">
                              <h5>Time Taken</h5>
                              <h5>
                                {addTimes(calculateTotalTime(eventData?.est_time || "00:00", qty), eventData?.occupied || "00:00")} Hrs</h5>
                            </a>
                          </div>
                          <div className="mb-3 col-12">
                            <Label className="form-label">Activity Name</Label>
                            <Input
                              disabled
                              name="title"
                              type="text"
                              value={activityName?.replace(/\d+/g, "")}
                            />
                          </div>
                          <div className="row col-md-12 align-items-end">
                            <div className="col-4">
                              <Label className="form-label">Total Quantity</Label>
                              <Input
                                type="text"
                                className="form-control"
                                placeholder="Enter Quantity"
                                value={eventData?.quantity}
                                disabled

                              />
                            </div>
                            <div className="col-4">
                              <Label className="form-label">Quantity</Label>
                              <Input
                                type="text"
                                className="form-control"
                                placeholder="Enter Quantity"
                                value={qty}
                                onChange={(e) => setQty(e.target.value)}
                              // onBlur={handleBlur}
                              />
                            </div>
                          </div>
                        </div>
                      </Row>
                      <Row className="">
                        <Col className="col-12 text-end">
                          <button type="submit" className="btn btn-success" id="btn-save-event">
                            Save
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </ModalBody>
                </Modal>
              </Col>
            </Row>
          </Col>
        </Row>

      </div>
    </React.Fragment>
  );
};

CompleteSprintCalendar.propTypes = {
  className: PropTypes.string,
};

export default CompleteSprintCalendar;
