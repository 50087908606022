import React, { useState, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Table, Modal } from "reactstrap";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
// assets
import Loader from "pages/Separate/Loader";
import NewPagination from "components/Common/Newpagination";
import ReactSelect from "react-select";
import "../../../assets/css/dashboard.css";
import { fetchData, FetchPostData } from "utils/common-fetchData";
import secureLocalStorage from "react-secure-storage";
import {
  checkPermissionsByModule,
  filterActivityDate,
  transformGeneralData,
} from "utils/common-helpers";
import AccessDeniedPopup from "routes/AccessDeniedPopup";
import { MyActivityColumns } from "utils/columns/ElementsColumns/MyActivityColumns";
import "../../../assets/css/dashboard.css";
import EditTasks from "pages/Dashboard/GeneralTask/EditTasks";
import Swal from "sweetalert2";

function TableContainer({ filterStatus, columns, members, toggleLoading }) {
  const [type, setType] = useState(false);
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [data, setData] = useState([]);
  const [allDatas, setAllDatas] = useState([]);
  const [allData, setAllData] = useState([]);
  const [memberId, setMemberId] = useState([]);
  const [fixedHeader, setFixedHeader] = useState(false);
  const [allActivity, setAllActivity] = useState(false);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "enddate",
            desc: true,
          },
        ],
      },
    },

    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { pageIndex, pageSize, globalFilter } = state;

  useEffect(() => {
    setPageSize(10);
  }, []);
  // Render the UI for your table

  const colourStyles = {
    menuList: (styles) => ({
      ...styles,
      background: "white",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 50,
    }),
  };

  const filterData = async (e) => {
    e.preventDefault();
    if (memberId?.length != 0) {
      const auth = sessionStorage.getItem("auth_token");
      const formData = {
        user_id: localStorage.getItem("auth_id"),
        staff_id: memberId?.value,
        all_activity: allActivity ? 1 : 0,
      };
      const response = await FetchPostData(
        toggleLoading,
        "teamsActivity",
        auth,
        formData
      );
      if (response?.status == 200) {
        const tasks = response?.data?.tasks?.map((obj) => ({
          ...obj,
          type: 1,
        }));
        const follows = response?.data?.followups?.map((obj) => ({
          ...obj,
          type: 2,
        }));
        const mainData = [...tasks, ...follows];
        const filteredArray = mainData.filter((item) => item.todays == date);
        const filteredData = mainData.filter((item) => {
          return (
            item.status != "complete" ||
            (item.status == "complete" && item.todays == date)
          );
        });
        if (allActivity) {
          setData(filteredData);
          setAllDatas(filteredData);
          setAllData(filteredData);
        } else {
          setData(filteredArray);
          setAllDatas(filteredArray);
          setAllData(filteredData);
        }
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "Please select all mandatory field.",
      });
    }
  };

  return (
    <div>
      <Row>
        <Col xl="12">
          <div className="card-body">
            <div className="d-flex justify-content-between mb-2">
              <div className="mb-3 card-title">Teams Activities</div>
              <div className=" d-flex gap-3   justify-content-center align-items-center mt-1 ms-3 mb-2 me-2">
                <p className="text-gray mt-0 mb-0">All Activity</p>
                <div className="form-switch form-switch-md ps-4">
                  <input
                    title="Intial Days"
                    type="checkbox"
                    className="form-check-input"
                    id="customSwitchsizemd"
                    defaultChecked={allActivity}
                    onChange={(e) => {
                      setAllActivity(!allActivity);
                    }}
                    style={{
                      width: "40px",
                      height: "20px",
                      transform: "scale(0.8)",
                    }}
                  />
                </div>
              </div>
            </div>
            <Row className="mb-2 justify-content-between">
              <div className="mb-3 col-7 col-md-7 mb-md-0 d-flex ">
                <div className="col-1 me-md-5">
                  <select
                    className="border-radius form-select"
                    style={{ width: "100px" }}
                    value={pageSize}
                    onChange={(e) => setPageSize(e.target.value)}
                  >
                    {[10, 25, 50, 100].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-md-11 col-lg-12  d-flex gap-2 align-items-center ms-md-2">
                  <div className="col-4 cusrtom_search_box">
                    <div className="search-box me-xxl-2 my-xxl-0 d-inline-block w-100">
                      <div className="d-flex">
                        <div className="position-relative">
                          <label
                            htmlFor="search-bar-0"
                            className="search-label w-100 mb-0"
                          >
                            <span id="search-bar-0-label" className="sr-only">
                              Search this table
                            </span>
                            <input
                              onChange={(e) => setGlobalFilter(e.target.value)}
                              id="search-bar-0"
                              type="text"
                              className="border-radius form-control rounded h-100 w-100"
                              placeholder={`Search...`}
                              value={globalFilter || ""}
                            />
                          </label>
                          <i className="bx bx-search-alt search-icon"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <a
                    className={`d-flex gap-2 align-items-center justify-content-center general-box taskbox ${
                      type == "task" ? "bg-purple " : ""
                    }`}
                    onClick={() => {
                      if (type == "task") {
                        setType("");
                        setData(allDatas);
                      } else {
                        setType("task");
                        setData(allDatas?.filter((obj) => obj?.type == 1));
                      }
                    }}
                  >
                    <h5 className="text-purple mb-0">Tasks : </h5>
                    <h5 className="text-primary mb-0">
                      {data?.filter((obj) => obj?.type == 1)?.length}
                    </h5>
                  </a>
                  <a
                    className={`d-flex gap-2 align-items-center justify-content-center general-box followupBox ${
                      type == "follow" ? "bg-purple " : ""
                    }`}
                    onClick={() => {
                      if (type == "follow") {
                        setType("");
                        setData(allDatas);
                      } else {
                        setType("follow");
                        setData(data?.filter((obj) => obj?.type == 2));
                      }
                    }}
                  >
                    <h5 className="text-purple mb-0">Follow Ups : </h5>
                    <h5 className="text-primary mb-0">
                      {data?.filter((obj) => obj?.type == 2)?.length}
                    </h5>
                  </a>
                  <a
                    className={`d-flex gap-2 align-items-center general-box justify-content-center newBox bg-light-red cursor-pointer ${
                      type == "new" ? "bg-purple" : ""
                    }`}
                    onClick={(e) => {
                      if (type == "new") {
                        setType("");
                        setData(allDatas);
                      } else {
                        setType("new");
                        setData(data?.filter((obj) => obj?.enddate == null));
                      }
                    }}
                  >
                    <h5 className="text-danger mb-0">New : </h5>
                    <h5 className="text-danger mb-0">
                      {data?.filter((obj) => obj?.enddate == null)?.length}
                    </h5>
                  </a>
                  <a
                    className={` d-flex gap-2 align-items-center justify-content-center general-box todaysBox ${
                      type == "todays activity" || filterStatus
                        ? "bg-purple "
                        : ""
                    }`}
                    onClick={() => {
                      setType("todays activity");
                      setData(allDatas);
                    }}
                  >
                    <h5 className="text-purple mb-0">Today's Plan :</h5>
                    <h5 className="text-primary mb-0">{allDatas?.length}</h5>
                  </a>
                </div>
              </div>

              <form
                className="col-5 col-md-4 d-flex justify-content-end align-items-center"
                onSubmit={filterData}
              >
                <div className="col-12 d-flex justify-content-end align-items-center gap-2">
                  {allActivity ? null : (
                    <>
                      <div className="customDate_box">
                        <input
                          type="date"
                          className="form-control"
                          value={date}
                          max={new Date().toISOString().split("T")[0]}
                          onChange={(e) => setDate(e.target.value)}
                          required
                        />
                      </div>
                    </>
                  )}

                  <div className="col-4 col-md-5">
                    <ReactSelect
                      placeholder="Member"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      styles={colourStyles}
                      options={members}
                      value={memberId}
                      onChange={(e) => setMemberId(e)}
                    />
                  </div>
                  <button
                    className="btn btn-sm  me-1 bg-purple text-white"
                    style={{ borderRadius: "5px" }}
                  >
                    <i className="bi bi-funnel-fill fs-5"></i>
                  </button>
                </div>
              </form>
            </Row>

            <div className="position-relative table-responsive">
              {data.length === 0 ? (
                <h5 className="text-center py-4 text-dark">
                  Choose a Staff Member to Explore their Activities!
                </h5>
              ) : (
                <Table
                  className="table table-bordered mt-4"
                  {...getTableProps()}
                >
                  <thead
                    className={`${
                      fixedHeader && page.length >= 5
                        ? "fixed_view_payment_table"
                        : ""
                    }`}
                  >
                    {headerGroups.map((headerGroup, i) => (
                      <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column, index) => (
                          <th
                            key={index}
                            className={`t-col-${index + 1} ${column.className}`}
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                          >
                            <div className="d-flex align-items-center">
                              <span className="flex-grow-1 text-center header-text">
                                {column.render("Header")}
                              </span>
                              {column.isShortIcon && (
                                <span
                                  onClick={() =>
                                    column.getSortByToggleProps().onClick()
                                  }
                                  className="d-flex align-items-center ms-2"
                                >
                                  <i
                                    title="Ascending"
                                    className={`bi bi-arrow-up info-icon ${
                                      column.isSorted && !column.isSortedDesc
                                        ? "text-dark"
                                        : ""
                                    }`}
                                  ></i>
                                  <i
                                    title="Descending"
                                    className={`bi bi-arrow-down info-icon ${
                                      column.isSorted && column.isSortedDesc
                                        ? "text-dark"
                                        : ""
                                    }`}
                                  ></i>
                                </span>
                              )}
                            </div>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody
                    className="fix-introducer-header"
                    {...getTableBodyProps()}
                  >
                    {page.map((row, i) => {
                      prepareRow(row);
                      const isCompleted = row?.original?.status == "complete";
                      return (
                        <tr
                          className={`row-hover ${
                            row?.original?.type == 2
                              ? "bg-orange-task"
                              : isCompleted
                              ? "bg-green"
                              : ""
                          } `}
                          {...row.getRowProps()}
                          key={i}
                        >
                          {row.cells.map((cell, index) => {
                            return (
                              <td
                                key={index}
                                className={`t-col-${index + 1} ${
                                  cell.column.className
                                }`}
                                {...cell.getCellProps()}
                              >
                                {/* Check if row is completed to apply strikethrough */}
                                {isCompleted ? (
                                  <span
                                    style={{ textDecoration: "line-through" }}
                                  >
                                    {cell.render("Cell")}
                                  </span>
                                ) : (
                                  cell.render("Cell")
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}
            </div>

            <Row className="justify-content-md-end justify-content-center align-items-center">
              <NewPagination
                gotoPage={gotoPage}
                canPreviousPage={canPreviousPage}
                previousPage={previousPage}
                canNextPage={canNextPage}
                pageIndex={pageIndex}
                pageOptions={pageOptions}
                pageSize={pageSize}
                page={page}
                data={data}
                nextPage={nextPage}
              />
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}

const ActivityComponent = () => {
  const [data, setData] = useState([]);
  const [members, setMembers] = useState(null);
  const [filterStatus, setFilterStatus] = useState(false);
  const [modal_Access, setAccessDenied] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [generalData, setGeneralData] = useState({});
  const [editGeneralTask, setEditGeneralTask] = useState(false);
  const [alldependency, setAllDependency] = useState({});
  const [tasks, setTask] = useState([]);
  const toggleLoading = (loading) => {
    setLoading(loading);
  };

  useEffect(() => {
    const fetchAccessData = async () => {
      const auth = sessionStorage.getItem("auth_token");
      const formDataEntries = {
        user_id: localStorage.getItem("auth_id"),
      };
      const datas = await FetchPostData(
        toggleLoading,
        "/actitvityAccess",
        auth,
        formDataEntries
      );
      if (datas?.data?.success) {
        if (datas?.data?.data?.length != 0) {
          const options = datas?.data?.data[0]?.members_data?.map((obj) => ({
            value: obj.id,
            label: obj.name,
          }));
          setMembers(options);
          const res = await fetchData(toggleLoading, "generalData", auth);
          setAllDependency(transformGeneralData(res?.data));
          setTask(
            res?.data?.subtasks?.map((obj) => ({
              id: obj.task,
              related: obj.related,
              subtasks: obj.subtasks,
              value: obj.task,
              label: obj.task,
            }))
          );
        } else {
          setMembers([]);
        }
      }
    };
    fetchAccessData();
  }, []);
  const onManagePlan = async (id, status, type) => {
    const formDatas = {
      user_id: localStorage.getItem("auth_id"),
      role_id: secureLocalStorage.getItem("auth_role"),
      task_id: id,
      status: status,
      type: type,
    };
    const response = await FetchPostData(
      toggleLoading,
      "manageDayPlan",
      secureLocalStorage?.getItem("auth_token"),
      formDatas
    );
  };

  const onViewTask = (task) => {
    setGeneralData(task);
    setEditGeneralTask(true);
  };
  const onCloseEditModal = () => {
    setEditGeneralTask(false);
  };

  const columns = useMemo(
    () => MyActivityColumns(onViewTask, onManagePlan),
    [data]
  );

  return (
    <React.Fragment>
      {members?.length != 0 && members != null ? (
        <>
          {isLoading && <Loader />}
          <div className="">
            <div>
              <Row>
                <Col lg="12">
                  <Card>
                    <div className="responsive-table">
                      <TableContainer
                        filterStatus={filterStatus}
                        setFilterStatus={setFilterStatus}
                        columns={columns}
                        data={[]}
                        members={members}
                        toggleLoading={toggleLoading}
                      />
                    </div>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </>
      ) : null}

      <Modal
        size="xl"
        isOpen={editGeneralTask}
        toggle={() => {
          tog_fullscreen();
        }}
        className="modal-fullscreen"
      >
        <EditTasks
          generalData={generalData}
          tasks={tasks}
          toggleLoading={toggleLoading}
          alldependency={alldependency}
          onCloseEditModal={onCloseEditModal}
          modalType={"view"}
        />
      </Modal>

      <AccessDeniedPopup
        modal_Access={modal_Access}
        setAccessDenied={setAccessDenied}
      />
    </React.Fragment>
  );
};

export default ActivityComponent;
