import html2canvas from "html2canvas";
import moment from "moment";
import React from "react";

export const getPermissionsByModule = (data, moduleId) => {
  const result = {};

  data.forEach(item => {
    const permissions = JSON.parse(item.permission);

    // Find the specific permission entry for the given module_id
    const permissionEntry = permissions.find(p => {
      if (typeof p === 'string') {
        const parts = p.split(',');
        return parts[0] === moduleId.toString();
      }
      return false;
    });

    const countryKey = item.country_id === 1 ? 'india' : 'uk'; // Map country_id to country name
    if (permissionEntry) {
      // Convert the permission string to an array of numbers
      const permissionArray = permissionEntry.split(',').map(Number);
      result[countryKey] = permissionArray;
    }
  });

  return result;
};


// export const checkPermissionsByModule = (data, moduleId, roleIndex) => {
//   const permission = getPermissionsByModule(data, moduleId)
//   return permission?.india[roleIndex] == 1 || (permission?.uk !== undefined && permission?.uk[roleIndex] == 1)
// };

export const checkPermissionsByModule = (data, moduleId, roleIndex) => {
  const permission = getPermissionsByModule(data, moduleId);
  const indiaPermission = Array.isArray(permission?.india) && permission.india[roleIndex] === 1;
  const ukPermission = Array.isArray(permission?.uk) && permission.uk[roleIndex] === 1;

  return indiaPermission || ukPermission;
};


export const transformGeneralData = (data) => {
  const transformArray = (arr, key, label, additionalField = null) => {
    return arr.map(item => {
      const transformedItem = {
        value: item[key],
        label: item[label],
      };

      // If an additionalField is provided, add it to the transformed item
      if (additionalField && item[additionalField]) {
        transformedItem[additionalField] = item[additionalField];
      }

      return transformedItem;
    });
  };

  return {
    digital_projects: transformArray(data.digital_projects, 'id', 'project_name'),
    introducer: transformArray(data.introducer, 'id', 'company'),
    leads: transformArray(data.leads, 'id', 'company', 'lead_belongs_to'),
    proposal: transformArray(data.proposal, 'id', 'proposalsubtitle'),
    projects: transformArray(data.projects, 'id', 'project_name'),
    users: transformArray(data.users, 'id', 'name'),
    staff: transformArray(data.users, 'id', 'name')
  };
};


export const sumTimes = (timeArray, key) => {
  const totalMinutes = timeArray?.reduce((total, item) => {
    const timeString = item[key];
    if (!timeString) return total;
    const [hours, minutes] = timeString.split(':').map(Number);
    if (!isNaN(hours) && !isNaN(minutes)) {
      return total + (hours * 60) + minutes;
    }
    return total;
  }, 0) || 0;

  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
};


export const groupAndMerge = (arrays) => {
  const grouped = {}
  arrays.forEach(item => {
    const { id, related_id, related_name, related_to } = item
    const key = `${related_id}`
    if (!grouped[key]) {
      grouped[key] = {
        id,
        related_id,
        list: [],
        related_name,
        related_to
      }
    }
    grouped[key].list.push(item)
  })

  const output = Object.values(grouped)
  return output
}

export const filterData = (dataArray, filters) => {
  return dataArray.filter(item => {
    const matchesLocation = filters.location ? filters.location == item.location : true;

    const matchesAssignee = filters.assigned?.length
      ? filters.assigned.some(assignee => item.assigned?.split(',').includes(assignee.toString()))
      : true;

    const matchesStatus = filters.status?.length
      ? filters.status.includes(item.status?.trim())
      : true;

    const matchesRelated = filters.related?.length
      ? filters.related.includes(item.related?.trim())
      : true;

    return (
      matchesLocation &&
      matchesAssignee &&
      matchesStatus &&
      matchesRelated
    );
  });
};

export const filterActivityDate = (value, allData) => {
  const currentDate = moment().format("YYYY-MM-DD")
  let tempData;
  if (value == "current") {
    const filterData = allData.filter(item => {
      return item.enddate == currentDate
    })
    tempData = filterData
  } else if (value == "past") {
    const filterData = allData.filter(item => {
      return item.enddate < currentDate
    })
    tempData = filterData
  } else if (value == "future") {
    const filterData = allData.filter(item => {
      return item.enddate > currentDate
    })
    tempData = filterData
  } else if (value == "all") {
    tempData = allData
  }

  return tempData
  // const shortedData = tempData.sort(
  //   (a, b) => new Date(a.date_time) - new Date(b.date_time)
  // )
  // setFollowUp(shortedData)
}

export const customGlobalFilter = (rows, columnIds, filterValue) => {
  return rows.filter(row => {
    const rowData = row.original;
    // Iterate over all fields in row.original to check for a match
    for (const key in rowData) {
      if (
        rowData[key] &&
        rowData[key]
          .toString()
          .toLowerCase()
          .includes(filterValue.toLowerCase())
      ) {
        return true; // If any field matches, keep this row
      }
    }
    return false; // Otherwise, filter it out
  });
};

export const captureScreenshot = (modalRef) => {
  if (modalRef.current) {
    const modalElement = modalRef.current;

    // Save the current styles that have !important (so we can restore them)
    const originalMaxHeight = window.getComputedStyle(modalElement).maxHeight;
    const originalOverflowY = window.getComputedStyle(modalElement).overflowY;

    // Temporarily apply styles to remove the max-height and allow full content capture
    modalElement.style.maxHeight = 'none'; // Remove max-height restriction
    modalElement.style.overflowY = 'visible'; // Allow overflow

    // Ensure any scrollable content is fully captured by html2canvas
    html2canvas(modalElement, {
      useCORS: true,  // Handle cross-origin resources
      allowTaint: true,  // Allow tainted content (useful for external resources)
      scrollX: 0,        // Adjust for horizontal scrolling (if any)
      scrollY: -window.scrollY,  // Adjust for vertical scroll position
      width: modalElement.scrollWidth,  // Full width of the modal content (including overflow)
      height: modalElement.scrollHeight, // Full height of the modal content (including overflow)
    }).then((canvas) => {
      const imageUrl = canvas.toDataURL("image/png");

      // Create a download link and simulate a click to download the image
      const link = document.createElement("a");
      link.href = imageUrl;
      link.download = "screenshot.png";
      link.click();

      // Restore the original styles after capturing the screenshot
      modalElement.style.maxHeight = originalMaxHeight;
      modalElement.style.overflowY = originalOverflowY;
    });
  }

};

export const getUpdatedEntries = (array, changearray) => {
  return changearray.filter(change => {
    const id = change.split(',')[0];
    const original = array.find(item => item.split(',')[0] === id);
    return original !== change;
  });
}
export function convertSecondsToTime(seconds) {
  // Calculate hours, minutes, and remaining seconds
  const hours = Math.floor(seconds / 3600); // 1 hour = 3600 seconds
  const minutes = Math.floor((seconds % 3600) / 60); // 1 minute = 60 seconds
  const remainingSeconds = seconds % 60; // Remaining seconds

  // Pad with leading zeros to ensure two digits for minutes and seconds
  const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;

  return formattedTime;
}

export const filterDiscrepanciesData = (data, filters) => {
  return data.filter(item => {
    const { fromDate, toDate, staffId, reported, chance, solved } = filters;

    // Filter by fromDate and toDate (if they are provided)
    const isDateValid =
      (!fromDate || fromDate === "" || new Date(item.created_at) >= new Date(fromDate)) &&
      (!toDate || toDate === "" || new Date(item.created_at) <= new Date(toDate));

    // Filter by staffId (if provided)
    const isStaffValid = !staffId || item.staff_id == staffId?.value;

    // Filter by reportedBy (if provided)
    const isReportedByValid = !reported || item.added_by == reported?.value;;

    // Filter by chance (if provided)
    const isChanceValid = chance === undefined || (chance === false ? item.time == null : item.time != null);

    // Filter by solved (if provided)
    const isSolvedValid = solved === undefined || (solved === true ? item.status == 1 : item.status != 1);

    // Dynamically apply filters based on the provided fields
    const filtersApplied =
      (fromDate || toDate ? isDateValid : true) &&
      (staffId ? isStaffValid : true) &&
      (reported ? isReportedByValid : true) &&
      (chance !== undefined ? isChanceValid : true) &&
      (solved !== undefined ? isSolvedValid : true);

    return filtersApplied;
  });
};

export function getTimeRemaining(createdAt, durationInDays) {
  const targetDate = new Date(createdAt).getTime() + durationInDays * 24 * 60 * 60 * 1000;  // Add 30 days to createdAt
  const currentTime = new Date().getTime();
  const timeLeft = targetDate - currentTime;

  // Calculate days, hours, minutes, and seconds
  const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

  return { days, hours, minutes, seconds, timeLeft };
}

export function shiftIdToEnd(array) {
  return array.map(item => {
    // Separate the components with id 1 from others
    const nonIdOneComponents = item.component.filter(c => c.id != 10);
    const idOneComponents = item.component.filter(c => c.id == 10);

    // Concatenate non-id1 components followed by the id1 components at the end
    return {
      ...item,
      component: [...nonIdOneComponents, ...idOneComponents]
    };
  });
}

export function getModuleicon(module){
  switch (module) {
    case 'sales':
      return <i className="bx bx-tone text-primary cursor-pointer" title={module}></i>
      case 'work':
        return <i className="bx bx-task text-primary cursor-pointer" title={module}></i>
      case 'finance':
        return <i className="bi bi-bank  text-primary cursor-pointer" title={module}></i>
      case 'projects':
    default: return null
  }
}

export function filterNotificationData(array, fromDate, toDate, moduleId) {
  const fromDates = fromDate ? new Date(fromDate) : null;
  const toDates = toDate ? new Date(toDate) : null;
  return array.filter(item => {
    const itemDate = new Date(item.created_at);
    console.log(itemDate)
    const moduleMatch = moduleId?.length != 0 ? item.module?.toLowerCase() == moduleId.value.toLowerCase() : true;
    const dateMatch = (!fromDates || itemDate >= fromDates) && (!toDates || itemDate <= toDates);
    return moduleMatch && dateMatch;
  });
}


// Digital Marketing


export function addTimes(time1, time2) {
  function convertToMinutes(timeStr) {
    if (!timeStr || typeof timeStr !== "string") {
      throw new Error("Invalid time string format");
    }

    const [hours, minutes] = timeStr.split(":").map(Number);

    // Validate if hours and minutes are numbers
    if (isNaN(hours) || isNaN(minutes)) {
      throw new Error("Invalid time string format");
    }

    return (hours * 60) + minutes;
  }
  const time1InMinutes = convertToMinutes(time1);
  const time2InMinutes = convertToMinutes(time2);
  const totalMinutes = time1InMinutes + time2InMinutes;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  const resultTime = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
  return resultTime;
}

export function calculateQtyWithinTime(engagedTime, estimatedTimePerQty, totalQty, availableTime) {
  // Convert time strings to minutes
  const convertToMinutes = (timeStr) => {
    const [hours, minutes] = timeStr.split(':').map(Number);
    return hours * 60 + minutes;
  };

  // Convert engaged time and estimated time per quantity to minutes
  const engagedTimeInMinutes = convertToMinutes(engagedTime);
  const estimatedTimeInMinutes = convertToMinutes(estimatedTimePerQty);
  const availableTimeInMinutes = convertToMinutes("08:00");

  // Calculate the total time available for the activity
  const totalAvailableTime = availableTimeInMinutes - engagedTimeInMinutes;

  // Calculate the maximum quantity that can be completed
  const maxQty = Math.floor(totalAvailableTime / estimatedTimeInMinutes);

  // Return the minimum of maxQty and totalQty
  return Math.min(maxQty, totalQty);
}


export function calculateTotalTime(est, qty) {
  const [hours, minutes] = est?.split(':')?.map(Number);
  const totalMinutes = (hours * 60 + minutes) * qty;
  const resultHours = Math.floor(totalMinutes / 60);
  const resultMinutes = totalMinutes % 60;
  return `${String(resultHours)?.padStart(2, '0')}:${String(resultMinutes)?.padStart(2, '0')}`;
}

export function calculateCompleteTime(array, field = 'est_hrs') {
  let totalHours = 0;
  let totalMinutes = 0;

  array.forEach(item => {
    const time = item[field]?.split(':')?.map(Number);
    if (time) {
      const [hours, minutes] = time;
      totalHours += hours;
      totalMinutes += minutes;
    }
  });
  const extraHours = Math.floor(totalMinutes / 60);
  totalMinutes = totalMinutes % 60;
  totalHours += extraHours;
  return `${String(totalHours).padStart(2, '0')} hours ${String(totalMinutes).padStart(2, '0')} Min`;
}

export function calculateRemainingTimes(engagedTime, estimatedTimePerQty, totalQty, availableTime) {
  const convertToMinutes = (timeStr) => {
    const [hours, minutes] = timeStr.split(':').map(Number);
    return hours * 60 + minutes;
  };
  const engagedTimeInMinutes = convertToMinutes(engagedTime);
  const estimatedTimeInMinutes = convertToMinutes(estimatedTimePerQty);
  const availableTimeInMinutes = convertToMinutes(availableTime);
  const totalTimeRequired = totalQty * estimatedTimeInMinutes;
  const remainingTime = totalTimeRequired - engagedTimeInMinutes;
  if (remainingTime <= 0) {
    return "00:00";
  }

  const timeToComplete = Math.min(remainingTime, availableTimeInMinutes);

  // Convert the remaining time back to HH:MM format
  const convertToTimeString = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`;
  };

  // Return the remaining time in HH:MM format
  return convertToTimeString(timeToComplete);
}

export function updateSpecificDate(array, est, dateToUpdate) {
  // Split est into hours and minutes
  const [addHours, addMinutes] = est.split(':').map(Number);
  // Check if the date exists in the array
  if (array[dateToUpdate]) {
    const match = array[dateToUpdate].match(/>(\d{2}):(\d{2}) hrs</); // Extract current time
    if (match) {
      let [currentHours, currentMinutes] = match.slice(1).map(Number);

      // Add the time
      currentMinutes += addMinutes;
      currentHours += addHours + Math.floor(currentMinutes / 60);
      currentMinutes %= 60;
      currentHours %= 24; // Optional: Wrap around if hours exceed 24

      // Update the specific date with the new time
      const newTime = `${String(currentHours).padStart(2, '0')}:${String(currentMinutes).padStart(2, '0')} hrs`;
      array[dateToUpdate] = array[dateToUpdate].replace(/>\d{2}:\d{2} hrs</, `>${newTime}<`);
    }
  }

  return array;
}

export function FilteredArray(array1, array2) {
  return array1
    .map(item1 => {
      const match = array2?.filter(item2 => item2?.activity_id === item1?.id);
      if (match && match.length > 0) {
        const totalAssigned = match.reduce((acc, curr) => acc + (parseInt(curr.quantity) || 0), 0);
        const newQuantity = parseInt(item1.quantity) - totalAssigned;

        return {
          ...item1,
          quantity: newQuantity > 0 ? newQuantity : 0
        };
      }
      return item1;
    })
    .filter(item => parseInt(item.quantity) > 0);
}

export function FilteredArray2(array1, array2) {
  return array1
    .map(item1 => {
      const match = array2?.filter(item2 => item2?.activity_id === item1?.activity_id);
      if (match && match.length > 0) {
        const totalAssigned = match.reduce((acc, curr) => acc + (parseInt(curr.quantity) || 0), 0);
        const newQuantity = parseInt(item1.quantity) - totalAssigned;

        return {
          ...item1,
          quantity: newQuantity > 0 ? newQuantity : 0
        };
      }
      return item1;
    })
    .filter(item => parseInt(item.quantity) > 0);
}


export const updateArrayQuantities = (array1, array2) => {
  const convertToMinutes = (timeStr) => {
    const [hours, minutes] = timeStr.split(':').map(Number);
    return hours * 60 + minutes;
  };
  const minutesToTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`;
  };


  return array1.map(item1 => {
    let totalDevHrsInMinutes = convertToMinutes(item1.dev_hrs);
    let totalQuantity = item1.quantity;
    array2.forEach(item2 => {
      if (item2.activity_id === item1.activity_id) {
        totalQuantity -= item2.quantity;
        totalDevHrsInMinutes -= convertToMinutes(item2.dev_hrs);
      }
    });
    if (totalQuantity > 0) {
      const newDevHrs = minutesToTime(totalDevHrsInMinutes);
      return { ...item1, quantity: totalQuantity, dev_hrs: newDevHrs };
    }
    return null;
  })
    .filter(item => item !== null);
};

export const convertToMinutes = (timeStr) => {
  const [hours, minutes] = timeStr.split(':').map(Number);
  return hours * 60 + minutes;
};

// Helper function to convert minutes back to HH:mm format
export const minutesToTime = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`;
};

export const calculateTimeOrQuantity = (totalTime, qty, newTimePerQty = null) => {
  const totalMinutes = convertToMinutes(totalTime);

  // If new time per quantity is provided, recalculate the quantity
  if (newTimePerQty) {
    const newTimePerQtyMinutes = convertToMinutes(newTimePerQty);
    const newQuantity = Math.floor(totalMinutes / newTimePerQtyMinutes); // Round down to nearest integer
    return newQuantity;
  }

  // Otherwise, calculate the time per quantity
  const timePerQtyMinutes = totalMinutes / qty;
  return minutesToTime(timePerQtyMinutes);
};


export function updateTimeForDate(array, targetDate, additionalTime) {
  const [addHours, addMinutes] = additionalTime.split(":").map(Number);
  return array.map((item) => {
    if (item.date == targetDate) {
      const [hours, minutes] = item.time.split(":").map(Number);
      const totalMinutes = hours * 60 + minutes + addHours * 60 + addMinutes;
      const newHours = Math.floor(totalMinutes / 60).toString().padStart(2, "0");
      const newMinutes = (totalMinutes % 60).toString().padStart(2, "0");

      return { ...item, time: `${newHours}:${newMinutes}` };
    }
    return item;
  });
}

export function getWeekStartAndEnd() {
  const today = new Date();
  const day = today.getDay(); 
  
  let startOfWeek;
  let endOfWeek;

  // If today is Friday or Saturday, calculate the current week's start and end
  if (day === 5 || day === 6) {
      startOfWeek = new Date(today);
      startOfWeek.setDate(today.getDate() - (day - 5)); // Move back to Monday
      endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6); // Move forward to Sunday
  } 
  // If today is Monday, Tuesday, Wednesday, or Thursday, calculate the last week's start and end
  else {
      startOfWeek = new Date(today);
      startOfWeek.setDate(today.getDate() - (day + 2)); // Move back to the previous week's Monday
      endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6); // Move forward to Sunday
  }

  return {
      startOfWeek: startOfWeek.toISOString().split('T')[0], // Format as YYYY-MM-DD
      endOfWeek: endOfWeek.toISOString().split('T')[0] // Format as YYYY-MM-DD
  };
}

export function calculateEndDate(startDate, durationInMonths) {
  const start = new Date(startDate); // Convert startDate to a Date object
  const end = new Date(start); // Clone the start date
  end.setMonth(end.getMonth() + Number(durationInMonths));
  return end.toISOString().split("T")[0]; // Format as YYYY-MM-DD
}

export const toolbarOptions = [
  [{ header: [1, 2, 3, false] }], // Headers dropdown
  ["bold", "italic", "underline", "strike"], // Formatting options
  [{ color: [] }, { background: [] }], // Text color and background color
  [{ list: "ordered" }, { list: "bullet" }], // Ordered and unordered lists
  [{ script: "sub" }, { script: "super" }], // Subscript / superscript
  [{ indent: "-1" }, { indent: "+1" }], // Indent
  [{ align: [] }], // Text alignment
  ["link", "image", "video"], // Add links, images, and videos
  ["clean"], // Clear formatting
];

export const getEncryptedData = (normalText) => {
  if (normalText) {
    try {
      const encrypted = CryptoJS.AES.encrypt(
        JSON.stringify(normalText),
        'MADSFARZITAPDKSJFKS'
      ).toString();

      const urlSafeEncrypted = encrypted
        .replace(/\//g, '_') // Replace / with _

      return urlSafeEncrypted;
    } catch (error) {
      // Handle any encryption errors
      console.error("Error during encryption", error);
    }
  }
};
export const getDecryptedData = (encryptedText) => {
  if (encryptedText) {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedText, 'MADSFARZITAPDKSJFKS');
      const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      return (data);

    } catch (error) {
      // console.log("dec Invalid Key")
      return encryptedText
    }
  } else {
    return encryptedText
  }
};