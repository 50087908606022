import Pagi2 from "components/Common/Pagi2"
import Loader from "pages/Separate/Loader"
import React, { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Table,
  Form,
  Modal,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import configure from "configure"
// import auditRole from "../../assets/images/audit-log-roles.avif"
import auditRole from "../../assets/images/role-3.avif"
import "../../assets/css/Audit-log/roles.css"
import userImg from "../../assets/images/users/avatar-1.jpg"
import Draggable, { DraggableCore } from "react-draggable"
import { ResizableBox } from "react-resizable"
import { ModuleOptions, StaffOption } from "utils/common-options"
import Select from "react-select"
import { notificationColumns } from "utils/columns/Navbar/notification-columns"
import { fetchData } from "utils/common-fetchData"
import secureLocalStorage from "react-secure-storage"
import Swal from "sweetalert2"
import { filterNotificationData } from "utils/common-helpers"

const NotificationPage = () => {
  const [fixedContent, setFixedContent] = useState(false)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [formPopup, setFormPopup] = useState(false)
  const [allData, setAllData] = useState([])
  const [boxWidth, setBoxWidth] = useState(0)

  document.title = "Notifications | Zithas Technologies"

  useEffect(() => {
    const updateWidth = () => {
      const viewportWidth = window.innerWidth
      setBoxWidth(viewportWidth * 0.75)
    }
    updateWidth()
    window.addEventListener("resize", updateWidth)
    return () => window.removeEventListener("resize", updateWidth)
  }, [])

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 120) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 50,
    }),
    menu: (provided, state) => ({
      ...provided,
      top: "auto",
      bottom: "100%",
    }),
  }
  const token = secureLocalStorage.getItem('auth_token')
  const toggleLoading = (loading) => {
    setLoading(loading)
  }
  const getData = async () => {
    const user = localStorage.getItem('auth_id')
    const res = await fetchData(toggleLoading, `notifications/${user}`, token)
    if (res?.success) {
      setData(res?.data);
      setAllData(res?.data);
    }
  }


  useEffect(() => {
    getData()
  }, [])

  const columns = useMemo(
    () => notificationColumns(),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "id",
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state
  useEffect(() => setPageSize(50), [])
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [moduleId, setModuleId] = useState([])
  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Notifications"} />
          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <div className="responsive-table">
                    <div>
                      {/* fixed buttons */}
                      <div
                        className={`flex-column fixed-buttons pt-2 ${fixedContent ? "d-flex" : "d-none"
                          }`}
                      >
                        <button
                          onClick={() => navigate(-1)}
                          className="btn btn-red  fix-btn btn-label"
                        >
                          <i className="dripicons-cross me-2 icon-center label-icon"></i>
                          <span>Cancel</span>
                        </button>
                      </div>
                      <Row className="mb-2">
                        <Col className="col-2">
                          <select
                            className=" me-4 form-select"
                            style={{ width: "100px" }}
                            value={pageSize}
                            onChange={e => setPageSize(e.target.value)}
                          >
                            {[10, 25, 50, 100].map(pageSize => (
                              <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                              </option>
                            ))}
                          </select>
                        </Col>
                        <Col className="col-10 d-flex justify-content-end">
                          <div className="me-4">
                            <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
                              <div className="position-relative">
                                <label
                                  htmlFor="search-bar-0"
                                  className="search-label"
                                >
                                  <span
                                    id="search-bar-0-label"
                                    className="sr-only"
                                  >
                                    Search this table
                                  </span>
                                  <input
                                    onChange={e =>
                                      setGlobalFilter(e.target.value)
                                    }
                                    id="search-bar-0"
                                    type="text"
                                    className=" form-control rounded h-100"
                                    placeholder={`Search...`}
                                    value={globalFilter || ""}
                                  />
                                </label>
                                <i className="bx bx-search-alt search-icon"></i>
                              </div>
                            </div>
                          </div>
                          <div className="me-2">
                            <button
                              onClick={() => setFormPopup(!formPopup)}
                              className="btn btn-outline-secondary filter-btn me-3"
                            >
                              <i className="fas fa-filter"></i>
                            </button>
                          </div>

                          <div className="text-md-end">
                            <button
                              onClick={() => navigate(-1)}
                              className=" btn btn-red btn-label"
                            >
                              <i className="dripicons-cross me-2 icon-center lab label-icon"></i>
                              CANCEL
                            </button>
                          </div>
                        </Col>
                      </Row>
                      <Row>

                        <div className="col-12">
                          <div className="position-relative shadow">

                            <Table className="table table-bordered mt-4" {...getTableProps()}>
                              <thead
                                className={`${fixedContent && page.length >= 10
                                  ? "fix-notification-headers"
                                  : ""
                                  } fix-staff-headers`}
                              >
                                {headerGroups.map((headerGroup, i) => (
                                  <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column, index) => (
                                      <th
                                        key={index}
                                        className={`t-col-${index + 1} ${column.className}`}
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                      >
                                        <div className="d-flex align-items-center">
                                          <span className="flex-grow-1 text-center header-text">
                                            {column.render("Header")}
                                          </span>
                                          {column.isShortIcon && (
                                            <span
                                              onClick={() =>
                                                column.getSortByToggleProps().onClick()
                                              }
                                              className="d-flex align-items-center ms-2"
                                            >
                                              <i
                                                title="Ascending"
                                                className={`bi bi-arrow-up info-icon ${column.isSorted && !column.isSortedDesc
                                                  ? "text-dark"
                                                  : ""
                                                  }`}
                                              ></i>
                                              <i
                                                title="Descending"
                                                className={`bi bi-arrow-down info-icon ${column.isSorted && column.isSortedDesc
                                                  ? "text-dark"
                                                  : ""
                                                  }`}
                                              ></i>
                                            </span>
                                          )}
                                        </div>
                                      </th>
                                    ))}
                                  </tr>
                                ))}
                              </thead>

                              <tbody className="fix-introducer-header" {...getTableBodyProps()}>
                                {page.map((row, i) => {
                                  prepareRow(row)
                                  return (
                                    <tr className="row-hover" {...row.getRowProps()} key={i}>
                                      {row.cells.map((cell, index) => {
                                        return (
                                          <td
                                            key={index}
                                            className={`t-col-${index + 1} ${cell.column.className
                                              }`}
                                            {...cell.getCellProps()}
                                          >
                                            {cell.render("Cell")}
                                          </td>
                                        )
                                      })}
                                    </tr>
                                  )
                                })}
                                {page.length == 0 && (
                                  <tr className="text-center">
                                    <td colSpan={8}>No Data Found</td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </Row>
                      {/* Filter Popup  */}
                      {formPopup ? (
                        <Draggable>
                          <ResizableBox
                            width={boxWidth}
                            style={{
                              position: "fixed",
                              top: "28%",
                              right: "2%",
                              zIndex: "1055",
                              backgroundColor: "#fff",
                            }}
                          >
                            <div>
                              <form onSubmit={(e) => {
                                e.preventDefault();
                                setData(filterNotificationData(allData, fromDate, toDate, moduleId))
                              }}>
                                <Row className="align-items-end">
                                  <Row
                                    className="d-flex flex-wrap pe-0 me-0 row"
                                  >
                                    <div className="col-7 d-flex justify-content-start align-items-center">
                                      <div className="col-4 mb-2 px-0 ps-1">
                                        <label htmlFor="startDate">
                                          From Date
                                        </label>
                                        <input
                                          type="date"
                                          id="startDate"
                                          className="form-control"
                                          value={fromDate}
                                          onChange={(e) => setFromDate(e.target.value)}
                                        />
                                      </div>

                                      <div className="col-4 mb-2 px-0 ps-2">
                                        <label htmlFor="endDate">To Date</label>
                                        <input
                                          type="date"
                                          id="endDate"
                                          className="form-control"
                                          value={toDate}
                                          onChange={(e) => setToDate(e.target.value)}
                                        />
                                      </div>

                                      <div className="col-5 mb-2 px-0 ps-2">
                                        <label htmlFor="">Module</label>
                                        <Select
                                          placeholder="Select Module"
                                          className="basic-multi-select"
                                          classNamePrefix=" select"
                                          styles={colourStyles}
                                          options={ModuleOptions}
                                          value={moduleId}
                                          onChange={(e) => setModuleId(e)}
                                        />
                                      </div>

                                    </div>

                                    <div className="col-2 col-md-5 mb-2 d-flex flex-wrap justify-content-end">
                                      <div className="d-flex align-item-center mt-3">
                                        {/* <label htmlFor="">Clear Filters</label> */}
                                        <div className="">
                                          <button className="btn  btn-purple mr-10 my-1 btn-label">
                                            <i className="mdi mdi-filter me-1 label-icon"></i>{" "}
                                            Filter
                                          </button>
                                          <a
                                            className="btn  btn-danger mr-10 my-1"
                                            style={{
                                              fontSize: "13px",
                                              backgroundColor: "#fed36d",
                                              borderColor: "#fed36d",
                                              color: "black",
                                            }}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setFromDate('')
                                              setToDate('')
                                              setModuleId([])
                                              setData(allData)
                                            }}
                                          >
                                            Clear Filters
                                          </a>

                                          <a
                                            className="btn  btn-red my-1 btn-label"
                                            onClick={() => setFormPopup(false)}
                                          >
                                            <i className="dripicons-cross me-1 label-icon"></i>{" "}
                                            Close
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </Row>
                                </Row>
                              </form>
                            </div>
                          </ResizableBox>
                        </Draggable>
                      ) : null}

                      {/* Pagination */}
                      <Row className="justify-content-md-end justify-content-center align-items-center">
                        <Pagi2
                          gotoPage={gotoPage}
                          previousPage={previousPage}
                          canPreviousPage={canPreviousPage}
                          nextPage={nextPage}
                          canNextPage={canNextPage}
                          pageIndex={pageIndex}
                          pageOptions={pageOptions}
                        />
                      </Row>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default NotificationPage
