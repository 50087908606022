import React, { useEffect } from 'react';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js'; import { sendNotification } from './common-notification';


const NotificationComponent = ({ userId }) => {
    useEffect(() => {
        // Set up Echo to listen for events
        window.Pusher = Pusher;
        const echo = new Echo({
            broadcaster: 'pusher',
            key: 'adcc6be71edac68899eb',
            cluster: 'ap2',  // Replace with your cluster
            forceTLS: true,
        });

        const channel = echo.channel(`notifications.${userId}`);
        channel.listen('.new-notification', (event) => {
            const { title, message } = event?.notification;
            // Call your custom function to display the notification
            sendNotification(title, message);
        });

        // Cleanup when component is unmounted
        return () => {
            echo.leave(`notifications.${userId}`);  // Unsubscribe when component is unmounted
        };
    }, [userId]);

    return null;
};

export default NotificationComponent;


