import React, { useEffect, useState } from "react"
import Loader from "pages/Separate/Loader"
import "../../../../assets/css/Sales/Sales.css"
import moment from "moment"
import { FetchPostData } from "utils/common-fetchData"
import secureLocalStorage from "react-secure-storage"
import configure from "configure"

const DmViewNotes = ({ verticalActiveTab, projectDetail }) => {
  const [loading, setLoading] = useState(false)
  const [data, setDatas] = useState([])
  const authToken = secureLocalStorage.getItem("auth_token")
  const toggleLoading = loading => {
    setLoading(loading)
  }

  useEffect(() => {
    const fetchNotes = async () => {
      const formEntries = {
        project_id: projectDetail?.id,
        user_id: localStorage.getItem("auth_id"),
      }
      const response = await FetchPostData(
        toggleLoading,
        "digitalProjectNotes",
        authToken,
        formEntries
      )
      if (response?.data?.success) {
        setDatas(response.data.data?.notes)
      }
    }
    if (verticalActiveTab == "4") {
      if(data?.length == 0){
        fetchNotes()
      }
    }
  }, [verticalActiveTab])

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="mb-3">
        <div className="px-3 py-4 bg-white">
          <div className="mt-4">
            <ul className="verti-timeline list-unstyled notes-box">
              {data.map(note => (
                <li className="event-list position-relative" key={note.id}>
                  <div className="event-timeline-dot">
                    <img
                      src={`${configure.imgUrl}${note?.avatar}`}
                      className="userImg me-3 "
                      alt=""
                    />
                  </div>
                  <div className="d-flex">
                    <div className="flex-grow-1 ms-4 note py-2 rounded ps-3 pr-10">
                      <div className=" d-flex justify-content-between ">
                        <div>
                          <p className="text-muted ">
                            <h6 className="d-inline me-2">By {note?.name}</h6>{" "}
                            At {note?.created_at}
                            <span className="pl-15">
                              Got in touch with this Project at{" "}
                              {moment(note?.date).format("DD-MM-YYYY HH:mm:ss")}
                            </span>
                          </p>
                        </div>
                        <div className="me-4">
                          <a className="btn btn-sm edit-delete-btn text-success me-2">
                            <i
                              className={` ${
                                note.pinned == 1
                                  ? "bi bi-pin-angle-fill"
                                  : " bi bi-pin-angle"
                              }`}
                              title="Pin Note"
                            ></i>
                          </a>
                        </div>
                      </div>
                      <i className="bi bi-triangle-fill note-icon"></i>

                      <div className="Features">
                        <p
                          className="mb-2"
                          dangerouslySetInnerHTML={{
                            __html: note.decription,
                          }}
                        ></p>
                        <div />
                        <div>
                          <div>
                            Attachment:{" "}
                            {note?.file != null ? (
                              <>
                                {JSON.parse(note?.file)?.map((obj, i) => {
                                  return (
                                    <a
                                      target="_blank"
                                      rel="noreferrer"
                                      className="activity-log-btn text-info"
                                      href={obj}
                                      key={i}
                                    >
                                      <i className="fas fa-eye pr-10"></i>View
                                    </a>
                                  )
                                })}
                              </>
                            ) : (
                              <small className="text-dark">
                                No Files Available
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default DmViewNotes
