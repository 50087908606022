import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import { withTranslation } from "react-i18next";
import { fetchData } from "utils/common-fetchData";
import { Spin } from "antd";
import configure from "configure";
import { getModuleicon } from "utils/common-helpers";

const NotificationDropdown = props => {
  const [menu, setMenu] = useState(false);
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const toggleLoading = (loading) => {
    setLoading(loading)
  }
  useEffect(() => {
    const getData = async () => {
      const user = localStorage.getItem('auth_id')
      const res = await fetchData(toggleLoading, `notificationsList/${user}`, '')
      if (res?.success) {
        setData(res?.data)
      }
    }
    if (data?.length == 0) {
      getData()
    }
  }, [])

  const onViewNotification = async (id) => {
    const userId = localStorage.getItem('auth_id')
    const res = await fetchData(toggleLoading, `chanageNotificationStatus/${id}/${userId}`, '');
    if (res?.success) {
      setData(res?.data)
    }
  }

  const onClearAll = async () => {
    const userId = localStorage.getItem('auth_id')
    const res = await fetchData(toggleLoading, `clearAllNotifications/${userId}`, '');
    if (res?.success) {
      setData(res?.data)
    }
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell bx-tada" />
          <span className="badge bg-danger rounded-pill">{data?.length}</span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
              <div className="col-auto">
              {loading ? <Spin /> : <a className="fs-6" onClick={onClearAll}>Clear All</a>}
              </div>

              <div className="mt-2">
                {data?.map((obj, i) => {
                  return (
                    <p className={`noti_1 border-botom d-flex justify-content-start align-items-center gap-2 mb-1 cursor-pointer ${i % 2 == 0 ? "noti_1" : "noti_2"}`} key={i} onClick={() => onViewNotification(obj?.id)}>
                      {getModuleicon((obj?.module)?.toLowerCase())}
                      {obj?.title}
                    </p>
                  )
                })}
                {data?.length == 0 ? (
                  <p className="text-purple text-center mt-3"> 🎉 All Quiet for Now 🚀</p>
                ) : null}
              </div>
            </Row>
          </div>
          <div className="p-2 border-top d-grid">
            <Link className="btn btn-sm btn-link font-size-14 text-center" to={`${configure.appUrl}notifications`}>
              <i className="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">{props.t("View More..")}</span>
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any
};