import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import "../../../assets/css/Staff/Staff.css"
import "../../../assets/css/Credential/Credential.css"
import "../../../assets/css/Scollbar.css"
import "../../../assets/css/Work/projects.css"
import {
  Container,
  Row,
  Col,
} from "reactstrap"

import SeoIndex from "./SEO/SeoIndex"

const DigitalMarketingIndex = () => {
  document.title = "Digital Marketing | Zithas Crm"
  return (
    <React.Fragment>
      <div className="page-content mb-4">
        <Container fluid>
          <Breadcrumbs title="Work" breadcrumbItem="Digital Marketing" />
          <div id="credentialPage">
            <Row
              className={`row `}
              id="cred"
            >
              <Col
                md={12}
                className={`z-1 p-0`}
              >
                <div className="card h-100">
                  <SeoIndex />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DigitalMarketingIndex
