import moment from "moment"
import React from "react";
import sideImg from "../../../assets/images/profile.png"
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import configure from "configure";
import { Tooltip } from "antd";

export const ReportColumns = (onGiveChance, onDeleteReport, onEditReport, onCompleteIssue) => [
    {
        Header: "Employee",
        accessor: "employee_code",
        isInfoIcon: false,
        isShortIcon: true,
        className: "employee",
        Cell: cellProps => {
            return (
                <div className="text-center d-flex gap-2 flex-wrap justify-content-center align-items-center">
                    <Tooltip color={"#752b97"} title={cellProps?.row?.original?.staff_name}>
                        <img
                            src={`${cellProps?.row?.original?.staff_avatar != null ? `${configure.imgUrl}${cellProps?.row?.original?.staff_avatar}` : `${sideImg}`} `}
                            alt=""
                            className="img-fluid assignee-img"
                            title="User Name"
                        />
                    </Tooltip>
                </div>
            )
        }

    },
    {
        Header: "Reason",
        accessor: "reason",
        isInfoIcon: true,
        isShortIcon: true,
        className: "report-reason",
        Cell: cellProps => {
            const data = cellProps?.row?.original
            return (
                <div>
                    {data?.title}
                    <div className="ms-4">
                        <i className="bx bx-subdirectory-right fs-5"></i> {data?.reasons}
                    </div>
                </div>

            )
        }
    },
    {
        Header: "Related",
        accessor: "related",
        isInfoIcon: true,
        isShortIcon: true,
        className: "report-option",
        Cell: cellProps => {
            return (
                <div className="text-capitalize">{cellProps?.row?.original?.related}</div>
            )
        }
    },
    {
        Header: "Reported By",
        accessor: "reported_by",
        isInfoIcon: true,
        isShortIcon: true,
        className: "report-by",
    },
    {
        Header: "Reported At",
        accessor: "created_at",
        isInfoIcon: false,
        isShortIcon: true,
        className: "employee_date_of_joining",
        Cell: cellProps => {
            return (
                <span>
                    {moment(cellProps.row.original.created_at).format(
                        "DD-MM-YYYY"
                    )}
                </span>
            )
        },
    },
    {
        Header: "Action",
        accessor: "action",
        Cell: cellProps => {
            return (
                <div className="d-flex justify-content-center">
                    <a
                        className="btn btn-sm action-btn btn-outline-secondary me-1"
                        onClick={() => onGiveChance(cellProps.row?.original?.id)}
                    >
                        <i className="fas fa-hourglass-half "></i>
                    </a>
                    <a
                        onClick={() => onCompleteIssue(cellProps.row?.original?.id)}
                        className="btn action-btn btn-sm me-1 btn-outline-secondary"
                    >
                        <i className="fas fa-check"></i>
                    </a>
                    <UncontrolledDropdown
                        className="dropdown"
                        style={{ width: "29px" }}
                    >
                        <DropdownToggle
                            className="btn btn-sm btn-outline-secondary w-100"
                            color="white"
                        >
                            <i className="mdi mdi-dots-horizontal"></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                            <a
                                className="dropdown-item"
                                title="Clear Login"
                                onClick={() => onEditReport(cellProps.row?.original?.id,  cellProps.row?.original)}
                            >
                                <i className="fa fa-pen pr-10"></i> Edit Discrepancies
                            </a>
                            <a
                                className="dropdown-item"
                                title="delete"
                                onClick={() => onDeleteReport(cellProps.row?.original?.id)}
                            >
                                <i className="fas fa-trash-alt pr-10 text-danger"></i>
                                Remove from list
                            </a>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
            )
        },
    },
]