import soundUrl from "../assets/images/notification-tune.wav"
export const sendNotification = (title, message) => {
    // Check if the Notification API is supported by the browser
    if (!("Notification" in window)) {
        console.log("This browser does not support notifications.");
        return;
    }

    // Check current notification permission
    if (Notification.permission === "granted") {
        // Play the custom sound
        const audio = new Audio(soundUrl); // URL of your custom sound
        audio.play();

        // Send the web notification
        new Notification(title, {
            body: message,
            icon: "https://ztpl.net/mars-react/favicon.png", // Optional icon
        });
    } else if (Notification.permission !== "denied") {
        Notification.requestPermission().then(permission => {
            if (permission === "granted") {
                // Play the custom sound
                const audio = new Audio(soundUrl); // URL of your custom sound
                audio.play();

                // Send the web notification
                new Notification(title, {
                    body: message,
                    icon: "https://ztpl.net/mars-react/favicon.png", // Optional icon
                });
            } else {
                console.log("Notification permission denied.");
            }
        });
    } else {
        console.log("Notification permission denied.");
    }
};
