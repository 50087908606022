import Breadcrumb from "components/Common/Breadcrumb"
import NewPagination from "components/Common/Newpagination"
import React, { useEffect, useMemo, useState } from "react"
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table"
import { Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, Table } from "reactstrap"
import { DigitalProjectsColumns } from "utils/columns/work/DigitalColumns"
import SimpleBar from "simplebar-react"
import { DigitalServiceOptions } from "utils/common-options"
import classNames from "classnames"
import secureLocalStorage from "react-secure-storage"
import { fetchData } from "utils/common-fetchData"
import Loader from "pages/Separate/Loader"

function TableContainer({ columns, data, isPaused }) {
  const [fixedContent, setFixedContent] = useState(false)
  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "id",
            desc: true,
          },
        ],
      },
    },

    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state

  useEffect(() => setPageSize(50), [])
  return (
    <div>
      <Row>
        <div className="col-md-12 my-2 d-flex">
          <div className="col-md-3 d-flex justify-content-start align-items-center">
            <select
              className="s me-4 form-select"
              style={{ width: "100px" }}
              value={pageSize}
              onChange={e => setPageSize(e.target.value)}
            >
              {[10, 25, 50, 100].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-9 d-flex justify-content-end align-items-center">
            <div className="search-box">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className="s form-control rounded "
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
        </div>
      </Row>
      <div className="position-relative">
        <Table className="table table-bordered" {...getTableProps()}>
          <thead
            className={`${fixedContent ? "fix-fixed-project-header" : "fix-table-headers"
              } `}
          >
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => {
                  const classNames = column.className
                  return (
                    <th
                      key={index}
                      className={` col-t-${index + 1} ${classNames}`}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <div className="col-12 p-0 d-flex flex-wrap">
                        <div
                          className={`${index == 0 ? "col-6" : "col-10"
                            } d-flex ${index == 0
                              ? "justify-content-end"
                              : "justify-content-start"
                            }`}
                        >
                          {column.render("Header")}
                        </div>
                        <div
                          className={`${index == 0 ? "col-6" : "col-2"
                            } d-flex justify-content-end`}
                        >
                          {column.isShortIcon ? (
                            <span
                              onClick={() =>
                                column.getSortByToggleProps().onClick()
                              }
                              style={{
                                position: index != 0 ? "" : "",
                                right: index != 0 ? "0" : "",
                              }}
                              className="d-flex"
                            >
                              <i
                                title="Ascending"
                                className={`bi bi-arrow-up ms-1 info-icon ${column.isSorted && !column.isSortedDesc
                                  ? "text-dark"
                                  : ""
                                  }`}
                              ></i>
                              <i
                                title="Descending"
                                className={`bi bi-arrow-down info-icon ${column.isSorted && column.isSortedDesc
                                  ? "text-dark"
                                  : ""
                                  }`}
                              ></i>
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>

          <tbody className="fix-table-body" {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr
                  {...row.getRowProps()}
                  key={i}
                  className={isPaused ? "bg-primary" : ""}
                >
                  {row.cells.map((cell, index) => (
                    <td
                      key={index}
                      className={`t-col-${index + 1} ${cell.column.className}`}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              )
            })}
            {page.length == 0 && (
              <tr style={{ backgroundColor: "unset" }}>
                <td className="text-center" colSpan={10}>
                  No Data Found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <NewPagination
          gotoPage={gotoPage}
          canPreviousPag={canPreviousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          page={page}
          data={data}
        />
      </Row>
    </div>
  )
}

const DigitalPlanIndex = () => {
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const columns1 = useMemo(() => DigitalProjectsColumns(verticalActiveTab), [verticalActiveTab]);
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const [isIndex, setIsIndex] = useState(false)
  const handleHover = () => {
    setIsIndex(true)
  }
  const handleLeave = () => {
    setIsIndex(false)
  }
  const toggleLoading = (loading) => {
    setLoading(loading)
  }
  const authToken = secureLocalStorage.getItem('auth_token')
  useEffect(() => {
    const getProjectList = async () => {
      const res = await fetchData(toggleLoading, `digitalProjectByType/${verticalActiveTab}`, authToken);
      if(res?.success){
        setData(res?.data)
      }
    }
    getProjectList();
  }, [verticalActiveTab])

  return (
    <div>
      {loading && <Loader />}
      <React.Fragment>
        <div className="page-content mb-4">
          <Container fluid>
            <Breadcrumb title="Work" breadcrumbItem="Digital Marketing" />
            <div id="credentialPage">
              <Row >
                <div
                  className={`px-0 sidebar-containerr `}
                  style={isIndex ? { zIndex: "2" } : {}}
                >
                  <div className="position-relative">
                    <div>
                      <SimpleBar
                        style={{ height: "70vh" }}
                      >
                        <Nav
                          pills
                          className={`credtabFix-ul  flex-column px-1 py-1 h-100s`}

                        >
                          {DigitalServiceOptions?.map((obj, i) => {
                            return (
                              <NavItem
                                className={classNames({
                                  "d-flex flex-column mb-2 dm-planing-h": true,
                                  active: verticalActiveTab == obj.id,
                                })}
                                key={i}
                              >
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classNames({
                                    "text-center crendential": true,
                                    active: verticalActiveTab == obj.id,
                                  })}
                                  onClick={() => {
                                    setverticalActiveTab(obj.id)
                                  }}
                                  onMouseEnter={handleHover}
                                  onMouseLeave={handleLeave}
                                >
                                  {obj?.text}
                                  <span className="link_name border-start border-white border-2">
                                    {obj?.name}
                                  </span>
                                </NavLink>
                              </NavItem>
                            )
                          })}
                        </Nav>
                      </SimpleBar>
                    </div>
                  </div>
                </div>
                <Col md={"11"}
                  className={`z-1 pe-0 credential-table width-94 ps-0`}>
                  <Card>
                    <CardBody>
                      <div className="responsive-table">
                        <TableContainer columns={columns1} data={data} />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </div>
  )
}

export default DigitalPlanIndex
