import React, { useEffect, useState } from "react"
import { Container, Table } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { json, useNavigate } from "react-router-dom"
import { Row, Form } from "reactstrap"
import Select from "react-select"
import indiaFlag from "../../../assets/images/flags/india.png"
import UkFlag from "../../../assets/images/flags/uk.png"

// assets
import "../../../assets/css/Sales/Sales.css"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import configure from "configure"

const AddProposal = () => {
  const terms = []

  const [fixButtons, setFixButtons] = useState(false)
  const [testingRows, setTestingRows] = useState([{}])
  const [breakdownRows, setBreakdownRows] = useState([{}])
  const [termsRows, setTermsRows] = useState([
    {
      no: "1",
      service: "Project duration",
      currency: "₹",
      total: "",
    },
    {
      no: "2",
      service: "(Service Name)",
      currency: "₹",
      total: "",
    },
    {
      no: "3",
      service: "Domain, Hosting, and SSL (Annual Charges)",
      currency: "₹",
      total: "",
    },
  ])

  // For Client information
  const [relatedClient, setRelatedClient] = useState([])
  const navigate = useNavigate()

  document.title = "Add General Proposal | Zithas Crm"

  // dynamic Testing form add-remove
  const onAddTestingRow = () => {
    setTestingRows([...testingRows, {}])
  }
  const onDeleteTestingRow = index => {
    const newInputs = [...testingRows]
    newInputs.splice(index, 1)
    setTestingRows(newInputs)
  }

  // dynamic breakdownRows Form add-remove
  const onAddBreakdownRow = () => {
    setBreakdownRows([...breakdownRows, {}])
  }
  const onDeleteBreakdownRow = index => {
    const newInputs = [...breakdownRows]
    newInputs.splice(index, 1)
    setBreakdownRows(newInputs)
  }

  // dynamic Terms and agreement Form add-remove
  const onAddTermsRow = () => {
    const modifiedRows = [...termsRows]
    modifiedRows.push({ id: modifiedRows.length + 1 })
    setTermsRows(modifiedRows)
  }
  const onDeleteTermsRow = id => {
    var modifiedRows = [...termsRows]
    modifiedRows = modifiedRows.filter(x => x["id"] !== id)
    setTermsRows(modifiedRows)
  }

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 50,
    }),
  }

  const related_option = [
    { label: "Lead", value: "lead" },
    { label: "Customer", value: "customer" },
    { label: "Introducer", value: "introducer" },
  ]

  //   All useState
  const [isLoading, setLoading] = useState([])
  const [location_option, setLocationOption] = useState([])
  const [status_option, setStatusOption] = useState([])
  const [assign_option, setAssignOption] = useState([])
  const [currency_option, setCurrencyOption] = useState([])
  const [location, setLocation] = useState([])
  const [proposal_title, setProposalTitle] = useState("")
  const [proposal_subtitle, setProposalSubTitle] = useState("")
  const [status, setStatus] = useState([])
  const [currency, setCurrency] = useState([])
  const [date, setDate] = useState("")
  const [till_date, setTillDate] = useState("")
  const [related, setRelated] = useState("")
  const [lead_option, setLeadOption] = useState([])
  const [company_name, setCompany_name] = useState("")
  const [address, setAddress] = useState("")
  const [website, setWebsite] = useState("")
  const [person_name, setPersonName] = useState("")
  const [contact_name_options, setContactPersionOptions] = useState([])
  const [person_email, setPersonEmail] = useState("")
  const [person_phone, setPersonPhone] = useState("")
  const [provider_company, setProviderCompany] = useState("")
  const [provider_address, setProviderAddress] = useState("")
  const [provider_website, setProviderWebsite] = useState("")
  const [provider_person, setProviderPerson] = useState("")
  const [provider_phone, setProviderPhone] = useState("")
  const [provider_email, setProviderEmail] = useState("")
  const [assign, setAssign] = useState([])
  const [deliverables, setDeliverables] = useState([])
  const [cost_break, setCostBreak] = useState([])
  const [terms_row, setTermsRow] = useState([])
  const [conclusion_email, setConclusionEmail] = useState("")
  const [conclusion_phone, setConclusionPhone] = useState("")
  const [source_id, setSourceId] = useState([])

  useEffect(() => {
    fetchLocation()
    fetchCurrency()
    onStatus()
  }, [])

  const fetchLocation = async () => {
    setLoading(true)
    try {
      await axios({
        method: "get",
        url: `${configure.apiUrl}/locations`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setLocationOption(
          res.data.data.map(obj => ({
            label: obj.location,
            value: obj.id,
          }))
        )
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const onChangeCountry = async e => {
    if (e.target.value == 1) {
      setProviderCompany("Zithas Technologies Pvt. Ltd.")
      // setProviderAddress("Vadodara, Gujarat, India")
      setProviderWebsite("www.zithas.com")
    } else if (e.target.value == 2) {
      setProviderCompany("Zithas Technologies Ltd.")
      // setProviderAddress("Bristol, United Kingdom")
      setProviderWebsite("www.zithas.co.uk")
    }

    setLoading(true)
    setLocation(e.target.value)
    const fromData = new FormData()
    fromData.append("country_id", e.target.value)
    try {
      await axios({
        method: "post",
        url: `${configure.apiUrl}/lead_belong`,
        data: fromData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setAssignOption(
          res.data.data.assigned?.map(item => ({
            value: item.id,
            label: item.name,
          }))
        )
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const fetchCurrency = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/currency`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setCurrencyOption(
          response.data.data.currency.map(item => ({
            label: item.currency,
            value: item.id,
            symbol: item.symbol,
          }))
        )
      })
    } catch (err) {
      console.log(err)
    }
  }

  const onChangeRelated = async e => {
    setRelatedClient(e)
    setLoading(true)
    setPersonEmail("")
    setPersonName("")
    setPersonPhone("")
    setSourceId([])
    setCompany_name("")
    setWebsite("")
    setAddress("")
    const fromData = new FormData()
    fromData.append("related", e.value)
    fromData.append("location", location)
    try {
      await axios({
        method: "post",
        url: `${configure.apiUrl}/related`,
        data: fromData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setLoading(false)

        // console.log("Related", res)

        if (res.data.success === true) {
          if (e.value == "introducer") {
            setLeadOption(
              res.data.data.map(obj => ({
                value: obj.id,
                label: obj.company,
                address: obj.address,
                details: obj.person_details,
                website: obj.website,
              }))
            )
          } else {
            setLeadOption(
              res.data.data.map(obj => ({
                value: obj.id,
                label:
                  obj.company +
                  " - " +
                  (obj.person_details[0]
                    ? obj.person_details[0]?.person_name
                    : "NA") + " - " + obj?.id,
                companyName: obj.company,
                address: obj.address,
                details: obj.person_details,
                website: obj.website,
              }))
            )
          }
        }
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const onClientInfo = e => {
    setSourceId(e)
    setCompany_name(e?.companyName)
    if (relatedClient?.value == "lead") {
      setContactPersionOptions(
        e.details.map(person => ({ label: person.person_name, value: person }))
      )
      // setPersonEmail(e.details[0]?.person_email)
      // setPersonName(e.details[0]?.person_name)
      // setPersonPhone(e.person_phone)
      setAddress(e.address)
      setWebsite(e.website)
    } else if (relatedClient?.value == "customer") {
      setContactPersionOptions(
        e.details.map(person => ({ label: person.person_name, value: person }))
      )

      setPersonEmail(e.details[0]?.email)
      setPersonName(e.details[0]?.person_name)
      setPersonPhone(e.details[0]?.phone)
      setAddress(e.address)
      setWebsite(e.website)
    }
  }

  const onStaffDetail = async e => {
    setProviderPerson(e.label)
    setAssign(e)
    setLoading(true)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/show/${e.value}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        // console.log("staff", response)

        setProviderPhone(response.data.data.staff.phone_number)
        setProviderEmail(response.data.data.staff.email)

        let city, state, country

        Object.keys(response.data.data.staff).forEach(obj => {
          switch (obj) {
            case "ctname":
              city =
                response.data.data.staff[obj] != null
                  ? response.data.data.staff[obj]
                  : "-"
              break
            case "sname":
              state =
                response.data.data.staff[obj] != null
                  ? response.data.data.staff[obj]
                  : "-"
              break
            case "cname":
              country =
                response.data.data.staff[obj] != null
                  ? response.data.data.staff[obj]
                  : "-"
              break
          }
        })

        setProviderAddress(`${city}, ${state}, ${country}`)

        setConclusionEmail(response.data.data.staff.email)
        setConclusionPhone(response.data.data.staff.phone_number)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const onStatus = async e => {
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/proposal_status`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setStatusOption(
          response.data.data.map(obj => ({ value: obj.id, label: obj.name }))
        )
      })
    } catch (err) {
      console.log(err)
    }
  }

  // Deliverables & testing Dynamic Row
  const onSrNo = (e, key) => {
    let values = [...testingRows]
    values[key][e.target.name] = e.target.value
    setDeliverables(values)
  }
  const onDescription = (e, key) => {
    let values = [...testingRows]
    values[key][e.target.name] = e.target.value
    setDeliverables(values)
  }
  const onTime = (e, key) => {
    let values = [...testingRows]
    values[key][e.target.name] = e.target.value
    setDeliverables(values)
  }

  // Cost Breakdown Dynamic Row
  const onSrnoBreak = (e, key) => {
    let values = [...breakdownRows]
    values[key][e.target.name] = e.target.value
    setCostBreak(values)
  }
  const onDescriptionCost = (e, key) => {
    let values = [...breakdownRows]
    values[key][e.target.name] = e.target.value
    setCostBreak(values)
  }
  const onTimeCost = (e, key) => {
    let values = [...breakdownRows]
    values[key][e.target.name] = e.target.value
    setCostBreak(values)
  }

  // Terms & Condition Dynamic Row

  const onSrNoTerms = (e, key) => {
    let values = [...termsRows]
    values[key][e.target.name] = e.target.value
    setTermsRow(values)
  }
  const onParticular = (e, key) => {
    let values = [...termsRows]
    values[key][e.target.name] = e.target.value
    setTermsRow(values)
  }
  const onCurrency = (e, key) => {
    let values = [...termsRows]
    values[key][e.target.name] = e.target.value
    setTermsRow(values)
  }
  const onTotal = (e, key) => {
    let values = [...termsRows]
    values[key][e.target.name] = e.target.value
    setTermsRow(values)
  }

  const onAddProposal = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("proposaltitle", proposal_title)
    formData.append("proposalsubtitle", proposal_subtitle)
    formData.append("currency", currency?.value)
    formData.append("date", date)
    formData.append("opentill", till_date)
    formData.append("related", relatedClient?.value)
    formData.append("companyname", company_name)
    formData.append(
      "contactname",
      person_name?.label ? person_name?.label : person_name
    )
    formData.append("address", address)
    formData.append("clientcontact", person_phone)
    formData.append("email", person_email)
    formData.append("website", website)
    formData.append("assigned", assign?.value)
    formData.append("company2", provider_company)
    formData.append("contactnumber", provider_phone)
    formData.append("address1", provider_address)
    formData.append("providermail", provider_email)
    formData.append("contact", provider_person)
    formData.append("website1", provider_website)
    formData.append("deliverytesting", JSON.stringify(deliverables))
    formData.append("terms", JSON.stringify(terms_row))
    formData.append("costbreakdown", JSON.stringify(cost_break))
    formData.append("location", location)
    formData.append("status", status?.value)
    formData.append("conclusion_email", conclusion_email)
    formData.append("conclusion_contact", conclusion_phone)
    formData.append("source_name", source_id?.value)
    formData.append("type", "General")

    // console.log(person_name)

    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/add_proposal`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setLoading(false)
        swal({
          icon: "success",
          text: "Proposal Added Succesfully",
        }).then(data => {
          navigate(`${configure.appUrl}sales/proposal`)
        })
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="page-content mb-3">
        <Container fluid>
          <Breadcrumbs
            title={"Dashboard"}
            breadcrumbItem={"Add General Proposal"}
          />
          <Row className="px-1">
            <Form onSubmit={onAddProposal}>
              {/* Fixed Buttons */}
              <div
                className={`flex-column fixed-buttons pt-2 ${
                  fixButtons ? "d-flex" : "d-none"
                }`}
              >
                <button className="btn btn-purple  fix-btn btn-label pe-0">
                  <i className="fas fa-save me-2 label-icon"></i>
                  <span>Save</span>
                </button>
                <a
                  onClick={() => navigate(-1)}
                  className="btn btn-red  fix-btn btn-label pe-0"
                >
                  <i className="dripicons-cross me-2 icon-center label-icon"></i>
                  <span>Cancel</span>
                </a>
              </div>
              <Row className="bg-white px-2 justify-content-between py-4 shadow mb-3">
                <div className="col-md-3 mb-md-0 mb-3">
                  <label htmlFor="">Location </label>
                  {/* <Select
                    placeholder="Select Location"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    styles={colourStyles}
                    options={location_option}
                    onChange={onChangeCountry}
                  /> */}
                  <div className="row " style={{ marginLeft: "0px" }}>
                    {location_option.map((item, i) => {
                      return (
                        <div className="form-check mb-2 col-md-4" key={i}>
                          <input
                            className="form-check-input input-location"
                            type="radio"
                            name="exampleRadios"
                            id={`exampleRadios${i}`}
                            value={item.value}
                            onChange={onChangeCountry}
                          />
                          <label
                            className="form-check-label pl-10"
                            htmlFor={`exampleRadios${i}`}
                          >
                            {item.value == 1 ? (
                              <img
                                src={indiaFlag}
                                alt="no img"
                                width="28"
                                height="18"
                                style={{ marginTop: "2px" }}
                              />
                            ) : (
                              <img
                                src={UkFlag}
                                alt="no img"
                                width="28"
                                height="18"
                                style={{ marginTop: "2px" }}
                              />
                            )}
                          </label>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className="col-md-6 mb-3 text-end">
                  <button className=" btn ms-md-2 btn-save btn-label">
                    <i className="fas fa-save me-1 label-icon"></i> save
                  </button>
                  <button
                    onClick={e => {
                      e.preventDefault()
                      navigate(-1)
                    }}
                    className=" btn ms-md-2 btn-red btn-label"
                  >
                    <i className="dripicons-cross me-2 icon-center label-icon"></i>
                    CANCEL
                  </button>
                </div>
              </Row>

              {/* Service  info */}
              <Row className="bg-white px-2 py-4 shadow mb-3">
                <h5 className="mb-3">Service Information</h5>

                <div className="col-md-6 mt-2 mb-3">
                  <label htmlFor="">Proposal Title</label>
                  <input
                    type="text"
                    placeholder="Enter Proposal Title"
                    className="form-control "
                    value={proposal_title}
                    onChange={e => {
                      setProposalTitle(e.target.value)

                      let values = [...termsRows]
                      values[1]["service"] = e.target.value
                      setTermsRow(values)
                    }}
                  />
                </div>
                <div className="col-md-6 mt-2 mb-3">
                  <label htmlFor="">Proposal Subtitle</label>
                  <input
                    type="text"
                    placeholder="Enter Proposal Subtitle"
                    className="form-control "
                    value={proposal_subtitle}
                    onChange={e => setProposalSubTitle(e.target.value)}
                  />
                </div>
                <div className="col-md-3 mb-3 mb-md-0">
                  <label htmlFor="">Status</label>
                  <Select
                    placeholder="Select Status"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    styles={colourStyles}
                    options={status_option}
                    value={status}
                    onChange={e => setStatus(e)}
                  />
                </div>
                <div className="col-md-3 mb-3 mb-md-0">
                  <label htmlFor="">Currency</label>
                  <Select
                    placeholder="Select Currency"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    styles={colourStyles}
                    options={currency_option}
                    value={currency}
                    onChange={e => setCurrency(e)}
                  />
                </div>
                <div className="col-md-2 mb-3 mb-md-0">
                  <label htmlFor="">Date</label>
                  <input
                    type="date"
                    className="form-control "
                    onChange={e => setDate(e.target.value)}
                  />
                </div>

                <div className="col-md-2 mb-3 mb-md-0">
                  <label htmlFor="">Open Till</label>
                  <input
                    type="date"
                    className="form-control "
                    onChange={e => setTillDate(e.target.value)}
                  />
                </div>
                <div className="col-md-2 mb-3 mb-md-0">
                  <label htmlFor="">Related </label>
                  <Select
                    placeholder="Select Related"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    styles={colourStyles}
                    options={related_option}
                    onChange={e => onChangeRelated(e)}
                  />
                </div>
              </Row>

              <Row>
                <div className="col-md-6 pe-md-4">
                  <Row className="bg-white px-2 py-4 shadow mb-3">
                    <h5 className="mb-3">Client Information</h5>

                    {relatedClient?.length != 0 ? (
                      <div className="col-md-4 mt-2 mb-3">
                        <label htmlFor="">{relatedClient?.label}</label>
                        <Select
                          placeholder="Select"
                          className="basic-multi-select"
                          classNamePrefix=" select"
                          styles={colourStyles}
                          options={lead_option}
                          value={source_id}
                          onChange={e => onClientInfo(e)}
                        />
                      </div>
                    ) : null}

                    <div className="col-md-4 mt-2 mb-3">
                      <label htmlFor="">Company Name</label>
                      <input
                        type="text"
                        placeholder="Enter Company Name"
                        className="form-control "
                        value={company_name}
                        onChange={e => setCompany_name(e.target.value)}
                      />
                    </div>
                    {relatedClient?.label == "Introducer" ? (
                      <div className="col-md-4 mt-2 mb-3">
                        <label htmlFor="">Contact Person</label>
                        <input
                          type="text"
                          placeholder="Enter Contact Person"
                          className="form-control "
                          value={person_name}
                          onChange={e => setPersonName(e.target.value)}
                        />
                      </div>
                    ) : (
                      <div className="col-md-4 mt-2 mb-3">
                        <label htmlFor="">Contact Person</label>
                        <Select
                          placeholder="Select"
                          className="basic-multi-select"
                          classNamePrefix=" select"
                          styles={colourStyles}
                          options={contact_name_options}
                          value={person_name}
                          onChange={e => {
                            setPersonName(e)
                            console.log(e)
                            setPersonEmail(e.value.person_email)
                            setPersonPhone(e.value.person_phone)
                          }}
                        />
                      </div>
                    )}

                    <div className="col-md-12 mb-3">
                      <label htmlFor="">Address</label>
                      <input
                        type="text"
                        placeholder="Enter Address"
                        className="form-control "
                        value={address}
                        onChange={e => setAddress(e.target.value)}
                      />
                    </div>
                    <div className="col-md-4 mb-3 mb-md-0">
                      <label htmlFor="">Contact</label>
                      <input
                        type="text"
                        placeholder="Enter Contact"
                        className="form-control "
                        value={person_phone}
                        onChange={e => setPersonPhone(e.target.value)}
                      />
                    </div>
                    <div className="col-md-4 mb-3 mb-md-0">
                      <label htmlFor="">Email Id</label>
                      <input
                        type="email"
                        placeholder="Enter Email Id"
                        className="form-control "
                        value={person_email}
                        onChange={e => setPersonEmail(e.target.value)}
                      />
                    </div>
                    <div className="col-md-4 mb-3 mb-md-0">
                      <label htmlFor="">Website</label>
                      <input
                        type="text"
                        placeholder="Enter URL"
                        className="form-control "
                        value={website}
                        onChange={e => setWebsite(e.target.value)}
                      />
                    </div>
                  </Row>
                </div>
                <div className="col-md-6 ps-md-3">
                  {/* Provider  info */}
                  <Row className="bg-white px-2 py-4 shadow mb-3">
                    <h5 className="mb-3">Provider Information</h5>

                    <div className="col-md-4 mt-2 mb-3">
                      <label htmlFor="">Assigned</label>
                      <Select
                        placeholder="Select Status"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        styles={colourStyles}
                        options={assign_option}
                        onChange={e => onStaffDetail(e)}
                      />
                    </div>
                    <div className="col-md-4 mt-2 mb-3">
                      <label htmlFor="">Contact Person</label>
                      <input
                        type="text"
                        placeholder="Enter Contact Person"
                        className="form-control "
                        value={provider_person}
                        onChange={e => setProviderPerson(e.target.value)}
                      />
                    </div>
                    <div className="col-md-4 mt-2 mb-3">
                      <label htmlFor="">Contact</label>
                      <input
                        type="text"
                        placeholder="Enter Contact"
                        className="form-control "
                        value={provider_phone}
                        onChange={e => setProviderPhone(e.target.value)}
                      />
                    </div>
                    <div className="col-md-4 mb-3 ">
                      <label htmlFor="">Email Id</label>
                      <input
                        type="email"
                        placeholder="Enter Email Id"
                        className="form-control "
                        value={provider_email}
                        onChange={e => setProviderEmail(e.target.value)}
                      />
                    </div>
                    {location?.length != 0 ? (
                      <>
                        <div className="col-md-8 mb-3 ">
                          <label htmlFor="">Address</label>
                          <input
                            type="text"
                            value={provider_address}
                            onChange={e => setProviderAddress(e.target.value)}
                            placeholder="Enter Address"
                            className="form-control "
                          />
                        </div>
                        <div className="col-md-6 mb-3 mb-md-0">
                          <label htmlFor="">Company Name</label>
                          <input
                            type="text"
                            value={provider_company}
                            onChange={e => setProviderCompany(e.target.value)}
                            placeholder="Enter Company Name"
                            className="form-control "
                          />
                        </div>
                        <div className="col-md-6 mb-3 mb-md-0">
                          <label htmlFor="">Website</label>
                          <input
                            type="text"
                            value={provider_website}
                            onChange={e => setProviderWebsite(e.target.value)}
                            placeholder="Enter Website"
                            className="form-control "
                          />
                        </div>
                      </>
                    ) : null}
                  </Row>
                </div>
              </Row>

              {/* Deliverables & testing info */}
              <Row className="bg-white px-2 py-4 shadow mb-3">
                <div className="d-flex justify-content-between">
                  <h5 className="mb-3">Deliverables & testing</h5>
                  <a
                    onClick={() => onAddTestingRow()}
                    className="btn btn-purple  btn-label"
                  >
                    <i className="fas fa-plus me-2 icon-size label-icon"></i>{" "}
                    Add New Row
                  </a>
                </div>
                <div className="responsive-table">
                  <Table className="table table-bordered mb-0 table-striped mt-3 table-hover">
                    <thead className="">
                      <tr>
                        <th>Sr No.</th>
                        <th>Milestone Description</th>
                        <th>Estimated Time (In Weeks)</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(testingRows || []).map((testingRow, key) => (
                        <tr key={key}>
                          <td className="col-1">
                            <input
                              type="number"
                              className="form-control "
                              name="sr.no"
                              onChange={e => onSrNo(e, key)}
                            />
                          </td>
                          <td className="col-8">
                            <input
                              type="text"
                              placeholder="Enter Description"
                              className="form-control "
                              name="milestone"
                              onChange={e => onDescription(e, key)}
                            />
                          </td>
                          <td className="col-2">
                            <input
                              type="text"
                              placeholder="Enter Estimated Time"
                              className="form-control "
                              name="estimatedtime"
                              onChange={e => onTime(e, key)}
                            />
                          </td>
                          <td className="col-1 text-center">
                            {key !== 0 ? (
                              <a
                                onClick={() => onDeleteTestingRow(key)}
                                className="btn btn-danger"
                              >
                                <i className="fas fa-trash-alt"></i>
                              </a>
                            ) : (
                              "--"
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Row>

              {/* Terms and agreement */}
              <Row className="bg-white px-2 py-4 shadow mb-3">
                <div className="d-flex justify-content-between">
                  <h5 className="mb-3">Terms and agreement</h5>
                </div>
                <div className="responsive-table">
                  <Table className="table table-bordered mb-0 table-striped mt-3 table-hover">
                    <thead className="">
                      <tr>
                        <th>Sr No.</th>
                        <th>Particular</th>
                        <th>Currency</th>
                        <th>Total</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(termsRows || []).map((termsRow, key) => {
                        return (
                          <tr key={key}>
                            <td className="col-1">
                              <input
                                type="number"
                                className="form-control "
                                name="no"
                                value={termsRow.no}
                                onChange={e => onSrNoTerms(e, key)}
                              />
                            </td>
                            <td className="col-7">
                              <input
                                type="text"
                                placeholder="Enter Description"
                                className="form-control "
                                name="service"
                                value={termsRow.service}
                                onChange={e => onParticular(e, key)}
                              />
                            </td>
                            <td className="col-1">
                              <input
                                type="text"
                                className="form-control "
                                placeholder="$"
                                name="currency"
                                value={currency?.symbol}
                                onChange={e => onCurrency(e, key)}
                                readOnly
                              />
                            </td>
                            <td className="col-2">
                              <input
                                type="text"
                                placeholder={
                                  key == 0
                                    ? "Estimated Time"
                                    : "Enter Total Cost"
                                }
                                className="form-control "
                                name="total"
                                value={termsRow.total}
                                onChange={e => onTotal(e, key)}
                              />
                            </td>
                            <td className="col-1 text-center">
                              {key == 0 ? (
                                <a
                                  onClick={() => onAddTermsRow()}
                                  className="btn btn-purple"
                                >
                                  <i className="fas fa-plus"></i>
                                </a>
                              ) : null}
                              {key >= 3 ? (
                                <a
                                  onClick={() => onDeleteTermsRow(termsRow.id)}
                                  className="btn btn-danger"
                                >
                                  <i className="fas fa-trash-alt"></i>
                                </a>
                              ) : key != 0 ? (
                                "--"
                              ) : null}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div>
              </Row>

              {/* Cost Breakdown info */}
              <Row className="bg-white px-2 py-4 shadow mb-3">
                <div className="d-flex justify-content-between">
                  <h5 className="mb-3">Cost Breakdown</h5>
                  <a
                    onClick={() => onAddBreakdownRow()}
                    className="btn btn-purple btn-label"
                  >
                    <i className="fas fa-plus me-2 icon-size label-icon"></i>{" "}
                    Add New Row
                  </a>
                </div>
                <div className="responsive-table">
                  <Table className="table table-bordered mb-0 table-striped mt-3 table-hover">
                    <thead className="">
                      <tr>
                        <th>Sr No.</th>
                        <th>Description</th>
                        <th>Total Cost</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(breakdownRows || []).map((breakdownRow, key) => (
                        <tr key={key}>
                          <td className="col-1">
                            <input
                              type="number"
                              className="form-control "
                              name="sr_no"
                              onChange={e => onSrnoBreak(e, key)}
                            />
                          </td>
                          <td className="col-8">
                            <input
                              type="text"
                              placeholder="Enter Description"
                              name="description"
                              onChange={e => onDescriptionCost(e, key)}
                              className="form-control "
                            />
                          </td>
                          <td className="col-2">
                            <input
                              type="text"
                              placeholder="Enter Total Cost"
                              className="form-control "
                              name="totalcost"
                              onChange={e => onTimeCost(e, key)}
                            />
                          </td>
                          <td className="col-1 text-center">
                            {key !== 0 ? (
                              <a
                                onClick={() => onDeleteBreakdownRow(key)}
                                className="btn btn-outline-danger"
                              >
                                <i className="fas fa-trash-alt"></i>
                              </a>
                            ) : (
                              "--"
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Row>

              {/* Conclusion*/}
              <Row className="bg-white px-2 py-4 shadow mb-3">
                <h5 className="mb-3">Conclusion</h5>

                <div className="col-md-3 mt-2 mb-3 mb-md-0">
                  <label htmlFor="">Email Id</label>
                  <input
                    type="email"
                    placeholder="Enter Email Id"
                    className="form-control "
                    value={conclusion_email}
                    onChange={e => setConclusionEmail(e.target.value)}
                  />
                </div>
                <div className="col-md-3 mt-2 mb-3 mb-md-0">
                  <label htmlFor="">Phone Number</label>
                  <input
                    type="text"
                    placeholder="Enter Phone no."
                    className="form-control "
                    value={conclusion_phone}
                    onChange={e => setConclusionPhone(e.target.value)}
                  />
                </div>
              </Row>
            </Form>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddProposal
