import React, { useEffect, useState } from "react";
import { Card, CardBody, Table } from "reactstrap";
import { fetchData } from "utils/common-fetchData";
import secureLocalStorage from "react-secure-storage";
import Loader from "pages/Separate/Loader";
import configure from "configure";
import { Link } from "react-router-dom";
import { Tooltip } from "antd";

const FlowsList = () => {
  const token = secureLocalStorage.getItem("auth_token");
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const toggleLoading = (load) => {
    setLoading(load);
  };
  useEffect(() => {
    const getData = async () => {
      const res = await fetchData(toggleLoading, "improveReportData", token);
      if (res?.success) {
        setData(res?.data);
      }
    };
    getData();
  }, []);

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div>
        <Card>
          <CardBody>
            <div className="mb-4 d-flex justify-content-between">
              <p className="text-purple fs-5 mb-0 fw-bold">Critical F&Gs</p>
              <div className="text-purple absent-length">{data?.length}</div>
            </div>
            <div id="FlowsNGaps-table" className="responsive-table mb-4">
              <Table className="table table-bordered mb-0">
                <thead>
                  <tr>
                    <th className="header text-center">Staff</th>
                    <th className="text-center header col-2">Count</th>
                    <th className="text-center header col-3">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((obj, i) => {
                    return (
                      <tr key={i}>
                        <td className="d-flex align-items-center justify-content-center">
                          <Tooltip color={"#752b97"} title={obj?.alias_name}>
                            <span>
                              <img
                                src={`${configure.imgUrl}${obj?.avatar}`}
                                alt="avatar"
                                className="img-fluid me-2"
                                style={{
                                  width: "30px",
                                  borderRadius: "100%",
                                }}
                              />
                            </span>
                          </Tooltip>
                        </td>
                        <td className="text-center">{obj?.report_count}</td>
                        <td className="text-center">
                          <Link
                            to={`${configure.appUrl}flaws-gap`}
                            title=""
                            className="btn btn-sm btn-outline-secondary me-1"
                          >
                            <i className="bi bi-arrow-right"></i>
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default FlowsList;
