import React, { useState } from "react"
import PropTypes from "prop-types"

import { connect } from "react-redux"

import { Link, useLocation, useParams } from "react-router-dom"

// Redux Store
import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions"
// reactstrap
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import zithasLogo from "../../assets/images/zithas-logo.png"

// import Css
import "../../assets/css/Layout/Header.css"
//i18n
import { withTranslation } from "react-i18next"
import configure from "configure"
import TimerChange from "./Timer/TimerChange"

const Header = props => {
  const [isSearch, setSearch] = useState(false)
  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  const calenderUrl = useLocation()
  const calender3 = calenderUrl.pathname
  const usepara = useParams()
  return (
    <React.Fragment>
      <header id="page-topbar" className="Header">
        <div
          className={`navbar-header px-0 ${
            calender3 === `${configure.appUrl}calender` ||
            calender3 === `${configure.appUrl}calender/${usepara.id}`
              ? "height-50"
              : ""
          }`}
        >
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to={`${configure.appUrl}`} className="logo logo-light">
                <span>
                  <img
                    src={zithasLogo}
                    alt="zithas logo"
                    className="zithas-logo"
                    height="48"
                  />
                </span>
              </Link>
            </div>

            <button
              type="button"
              className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
              data-toggle="collapse"
              onClick={() => {
                props.toggleLeftmenu(!props.leftMenu)
              }}
              data-target="#topnav-menu-content"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>
          <TimerChange />
          <div className="d-flex">
            {/* Knowledge Base */}
            <div className="d-flex align-items-center me-3">
              <Link
                className="nav-link dropdown-toggle arrow-none"
                to={`${configure.appUrl}knowledgebase`}
                target="_blank"
                rel="noreferrer"
              >
                <i className="bi bi-book-fill text-white"></i>
                <span className="text-white">&nbsp; Knowledge Base</span>
              </Link>
            </div>

            <div className="d-flex align-items-center">
              <Link
                className="nav-link dropdown-toggle arrow-none"
                to={`${configure.appUrl}calender`}
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-calendar text-white"></i>
                <span className="text-white">&nbsp; Calander</span>
              </Link>
            </div>

            <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                type="button"
                className="btn header-item noti-icon "
                id="page-header-search-dropdown"
                onClick={() => setSearch(!isSearch)}
              >
                <i className="mdi mdi-magnify" />
              </button>
              <div
                className={
                  isSearch
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={props.t("Search") + "..."}
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div></div>

            <ProfileMenu />
            <NotificationDropdown />
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout
  return { layoutType, showRightSidebar, leftMenu }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(Header))
