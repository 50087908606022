import React from "react"
import DMTaskTimer from "pages/WorkModule/Digital Marketing/Task Switch/DMTaskTimer"
import secureLocalStorage from "react-secure-storage"

const TimerChange = () => {
  return (
    <div>
      <div className="d-flex gap-2 text-white align-items-center">
        {secureLocalStorage.getItem('auth_role') == 15 ? (
          <DMTaskTimer />
        ) : null}
      </div>
    </div>
  )
}

export default TimerChange
