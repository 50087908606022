import React, { useEffect, useRef, useState } from "react"
import { Container } from "reactstrap"
import { useNavigate, useParams } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Row } from "reactstrap"

// images
import zithasLogo from "../../assets/images/zithas-dark-logo.png"
import sign from "../../assets/images/zithas-signature.png"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import moment from "moment"
import configure from "configure"

const ViewApproveDoc = () => {
  const [fixedRightSec, setFixedRightSec] = useState(false)

  document.addEventListener("scroll", () => {
    if (window.scrollY > 48) {
      setFixedRightSec(true)
    } else {
      setFixedRightSec(false)
    }
  })

  const printContract = () => {
    let printContents = document.getElementById("zithasInvoicePrint").innerHTML
    let originalContents = document.body.innerHTML
    document.body.innerHTML = printContents
    window.print()
    document.body.innerHTML = originalContents
  }

  const [isLoading, setLoading] = useState(false)
  const [contract_type, setContractType] = useState([])
  const [contract_value, setContractValue] = useState("")
  const [description, setDescription] = useState("")
  const [start_date, setStartDate] = useState("")
  const [end_date, setEndDate] = useState("")
  const [note, setNote] = useState("")
  const [date, setDate] = useState("")
  const [sign_date, setSignDate] = useState("")
  const [downLink, setDownLink] = useState("")
  const [signature, setSignature] = useState("")
  const [contact, setContact] = useState("")

  const params = useParams()

  const pdfRef = useRef()
  const fetchData = async () => {
    setLoading(true)
    try {
      const staffId = localStorage.getItem("auth_id")
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/viewGenerateLetters/${params?.id}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        setLoading(false)
        const filterData = response?.data?.data
        const templateData = filterData[0]?.new_template
        setDownLink(filterData[0]?.signature)
        setDescription(templateData)
        setSignDate(filterData[0]?.sign_date)
        setDate(filterData[0]?.created_at)
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }
  useEffect(() => {
    fetchData()
  }, [])

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="page-content mb-4">
        <Container fluid>
          <Breadcrumbs title={"Dashboard"} breadcrumbItem={"View Document"} />
          <Row>
            <div className="col-md-12 ps-0">
              <div
                id="zithasInvoicePrint"
                ref={pdfRef}
                className="bg-white p-3 shadow document"
              >
                <div className="pb-2 text-center">
                  <img src={zithasLogo} height="60" alt="zithasLogo" />
                </div>
                <div>
                  <div>
                    <div
                      className="Features document-content"
                      dangerouslySetInnerHTML={{
                        __html: description
                          // ?.replaceAll("{", "")
                          // .replaceAll("}", "")
                          ,
                      }}
                    />
                  </div>

                  <h5 className="fw-bold text-decoration-underline">
                    Date : {moment(date).format("DD-MM-YYYY")}
                  </h5>
                  <Row className="mt-4 justify-space-between">
                    <div className="col-4">
                      {signature == null ? (
                        <div className="sign-box">Signature</div>
                      ) : (
                        <img src={sign} height="50" className="mb-2" />
                      )}
                      <h5>Zithas Signature</h5>
                    </div>

                    <div className="col-4">
                      {signature != null ? (
                        <>
                          <img src={signature} height="50" className="mb-2" />
                          <h5>{contact != "undefined" && contact} Signature</h5>
                        </>
                      ) : null}
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ViewApproveDoc
