import moment from "moment"
import React from "react"

export const leaveColumns = (onLeaveDetail) => [
    {
        Header: "ID",
        accessor: "id",
        isInfoIcon: false,
        isShortIcon: true,
        className: "text-center lemp-id",
    },
    {
        Header: "Employee Name",
        accessor: "name",
        isInfoIcon: false,
        isShortIcon: true,
        className: "col-md-3",
    },
    {
        Header: "Applied On",
        accessor: "created_at",
        isInfoIcon: false,
        isShortIcon: true,
        className: "text-center lapplied_on_box",
        Cell: cellProps => {
            return (
                <div>{moment(cellProps?.row?.original.created_at).format('DD-MM-YYYY')}</div>
            )
        }
    },
    {
        Header: "Reason",
        accessor: "leave_reason",
        isInfoIcon: false,
        isShortIcon: true,
        className: "lreason_box text-start",
    },
    {
        Header: "End Date",
        accessor: "end_date",
        isInfoIcon: false,
        isShortIcon: true,
        className: "lemployee_code-box text-center",
    },
    {
        Header: "Total Days",
        accessor: "leave_days",
        isInfoIcon: false,
        isShortIcon: true,
        className: "lemployee_code-box text-center",
    },

    {
        Header: "Action",
        accessor: "action",
        Cell: cellProps => {
            return (
                <div className="d-flex">
                    <a
                        title="View"
                        className="btn btn-sm btn-outline-secondary me-1"
                        onClick={() => onLeaveDetail(cellProps?.row?.original?.id, cellProps?.row?.original?.employee,cellProps?.row?.original)}
                    >
                        <i className="fas fa-eye "></i>
                    </a>
                </div>
            )
        },
    },

]
