import React, { useEffect, useState } from "react"
import { Container } from "reactstrap"
import { useNavigate } from "react-router-dom"
import { Row, Form } from "reactstrap"
import Select from "react-select"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import Loader from "pages/Separate/Loader"
import ReactSelect from "react-select"
import secureLocalStorage from "react-secure-storage"
import { DigitalServiceOptionsDrop, ResourceType } from "utils/common-options"
import { FetchPostData } from "utils/common-fetchData"
import { calculateEndDate } from "utils/common-helpers"

const DmViewBasicProjectDetails = ({
  verticalActiveTab,
  projectDetail,
  getBasicDetail,
}) => {
  const [isLoading, setLoading] = useState([])
  const [location_option, setLocationOption] = useState([])
  const [fixButtons, setFixButtons] = useState(false)
  const [customerOptions, setCustomerOptions] = useState([])
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [duration, setDuration] = useState([])
  const [allKeyIndustryOption, setAllKeyIndustryOption] = useState([])
  // Api Integration
  const [projectBelong, setProjectBelong] = useState(null)
  const [projectName, setProjectName] = useState("")
  const [projectType, setProjectType] = useState(null)
  const [projectSource, setProjectSource] = useState(null)
  const [projectReferenceOption, setProjectReferenceOption] = useState([])
  const [projectReferenceOptions, setProjectReferenceOptions] = useState([])
  const [projectCustomer, setProjectCustomer] = useState(null)
  const [projectResourceType, setProjectResourceType] = useState(null)
  const [projectIndustry, setProjectIndustry] = useState(null)
  const [projectDescription, setProjectDescription] = useState(null)
  const [projectKeyManager, setProjectKeyManager] = useState(null)
  const [projectKeyManagerOptions, setProjectKeyManagerOptions] = useState([])
  const [SEOMembers, setSEOMembers] = useState([])
  const [SMMMembers, setSMMMembers] = useState([])
  const [PPCMembers, setPPCMembers] = useState([])
  const [EMMembers, setEMMembers] = useState([])
  const [seoPackage, setSeoPackage] = useState([])
  const [smmPackage, setSmmPackage] = useState([])
  const [ppcPackage, setPpcPackage] = useState([])
  const [emPackage, setEmPackage] = useState([])
  const [serviceDeveloperOption, setServiceDeveloperOption] = useState([])

  const Duration_Options = [
    { label: "1 Month", value: "1" },
    { label: "2 Month", value: "2" },
    { label: "3 Month", value: "3" },
    { label: "6 Month", value: "6" },
  ]
  // For Client information

  document.title = "Add Project | Zithas Crm"

  useEffect(() => {
    if (verticalActiveTab == "1") {
      fetchGeneralData()
    }
  }, [verticalActiveTab])
  const toggleLoading = loading => {
    setLoading(loading)
  }

  const fetchGeneralData = async () => {
    const auth_token = secureLocalStorage.getItem("auth_token")
    const response = await FetchPostData(
      toggleLoading,
      "digitalProjectData",
      auth_token,
      ""
    )
    if (response?.data?.success) {
      const {
        location,
        source,
        customer,
        introducer,
        industry,
        keymanager,
        developers,
        packages,
      } = response.data.data
      const mapOptions = (data, valueKey, labelKey) =>
        data?.map(obj => ({
          value: obj[valueKey],
          label: obj[labelKey],
        }))
    

      setLocationOption(mapOptions(location, "id", "location"))
      const SourceOption = source?.map(obj => ({
        value: obj.id,
        label: obj.leadname,
        customerOption: mapOptions(customer, "id", "company"),
        introducerOption: mapOptions(introducer, "id", "company"),
      }))

      setCustomerOptions(mapOptions(customer, "id", "company"))
      setAllKeyIndustryOption(mapOptions(industry, "id", "name"))
      setProjectKeyManagerOptions(mapOptions(keymanager, "id", "name"))
      setServiceDeveloperOption(mapOptions(developers, "id", "name"))
      const seoPackage = packages
        ?.filter(obj => obj?.service == 1)
        ?.map(obj => ({
          value: obj.id,
          label: obj.name,
          activities: obj?.activities,
          quantity: obj?.quantity,
        }))
      const ssmPackage = packages
        ?.filter(obj => obj?.service == 2)
        ?.map(obj => ({
          value: obj.id,
          label: obj.name,
          activities: obj?.activities,
          quantity: obj?.quantity,
        }))
      const ppcPackage = packages
        ?.filter(obj => obj?.service == 3)
        ?.map(obj => ({
          value: obj.id,
          label: obj.name,
          activities: obj?.activities,
          quantity: obj?.quantity,
        }))
      const emPackage = packages
        ?.filter(obj => obj?.service == 4)
        ?.map(obj => ({
          value: obj.id,
          label: obj.name,
          activities: obj?.activities,
          quantity: obj?.quantity,
        }))

      const datas = projectDetail
      setProjectDescription(datas?.description)
      setStartDate(datas?.start_date)
      setEndDate(calculateEndDate(datas?.start_date, datas?.duration))
      setProjectBelong(
        mapOptions(location, "id", "location")?.find(
          obj => obj?.value == datas?.location
        )
      )
      setProjectName(datas?.project_name)
      const services = datas?.project_type?.split(",")
      setProjectType(
        DigitalServiceOptionsDrop?.filter(obj => services?.includes(obj.value))
      )
      const sourceDetail = SourceOption?.find(
        obj => obj?.value == datas?.source
      )
      setProjectSource(sourceDetail)
      const SourceRefer =
        sourceDetail?.label?.toLowerCase() == "customer"
          ? sourceDetail?.customerOption
          : sourceDetail?.label?.toLowerCase() == "introducer"
          ? sourceDetail?.introducerOption
          : []
      setProjectReferenceOptions(SourceRefer)
      setProjectReferenceOption(
        SourceRefer?.find(obj => obj?.value == datas?.source_id)
      )
      setProjectResourceType(
        ResourceType?.find(obj => obj?.value == datas?.resource_type)
      )
      setProjectCustomer(
        mapOptions(customer, "id", "company")?.find(
          obj => obj?.value == datas?.customer_id
        )
      )
      setProjectIndustry(
        mapOptions(industry, "id", "name")?.find(
          obj => obj?.value == datas?.industry
        )
      )
      setProjectKeyManager(
        mapOptions(keymanager, "id", "name")?.find(
          obj => obj?.value == datas?.kam
        )
      )
      setDuration(Duration_Options?.find(obj => obj?.value == datas?.duration))
      setSeoPackage(
        seoPackage?.find(obj => obj?.value == datas?.seo_package) || []
      )
      setSmmPackage(
        smmPackage?.find(obj => obj?.value == datas?.smm_package) || []
      )
      setPpcPackage(
        ppcPackage?.find(obj => obj?.value == datas?.ppc_package) || []
      )
      setEmPackage(
        emPackage?.find(obj => obj?.value == datas?.em_package) || []
      )
      const alldevelopers = mapOptions(developers, "id", "name")
      setSEOMembers(
        alldevelopers?.filter(obj =>
          datas?.seo_assignee?.split(",")?.includes(obj?.value?.toString())
        )
      )
      setSMMMembers(
        alldevelopers?.filter(obj =>
          datas?.smm_assignee?.split(",")?.includes(obj?.value?.toString())
        )
      )
      setPPCMembers(
        alldevelopers?.filter(obj =>
          datas?.ppc_assignee?.split(",")?.includes(obj?.value?.toString())
        )
      )
      setEMMembers(
        alldevelopers?.filter(obj =>
          datas?.em_assignee?.split(",")?.includes(obj?.value?.toString())
        )
      )
    }
  }

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className=" mb-3">
        <Row className="bg-white py-4 shadow mb-3 px-2">
          <div className="col-md-2 mt-2 mb-3">
            <label htmlFor="">
              Project Belongs To <span className="text-danger">*</span>
            </label>
            <Select
              placeholder="Project Belongs To"
              className="basic-multi-select"
              classNamePrefix=" select"
              options={location_option}
              value={projectBelong}
              isDisabled
            />
          </div>

          <div className="col-md-6 mt-2 mb-3">
            <label htmlFor="">
              Project Name <span className="text-danger">*</span>
            </label>
            <input
              required
              type="text"
              placeholder="Enter Project Name"
              className="form-control "
              value={projectName}
              disabled
            />
          </div>

          <div className="col-md-4 mt-2 mb-3">
            <label htmlFor="">
              Project Type <span className="text-danger">*</span>
            </label>
            <Select
              placeholder="Select Project Type"
              className="basic-multi-select"
              classNamePrefix=" select"
              value={projectType}
              isMulti
              isDisabled
            />
          </div>

          <div className="col-md-12 mb-3 d-flex justify-content-start align-items-center gap-4">
            <div className="col-md-3 mt-2 mb-3 mb-md-0">
              <label htmlFor="">
                Source <span className="text-danger">*</span>
              </label>
              <Select
                placeholder="Select Source"
                className="basic-multi-select"
                classNamePrefix=" select"
                value={projectSource}
                isDisabled
              />
            </div>
            <div className="col-md-3 mb-3 mt-2 mb-md-0">
              <label htmlFor="">Select {projectSource?.label}</label>
              <Select
                placeholder={`Select`}
                className="basic-multi-select"
                classNamePrefix=" select"
                value={projectReferenceOption}
                isDisabled
              />
            </div>
            <div className="col-md-3 mt-2 mb-3 mb-md-0">
              <label htmlFor="">
                Resource Type <span className="text-danger">*</span>
              </label>
              <Select
                placeholder="Select Resource Type"
                className="basic-multi-select"
                classNamePrefix=" select"
                value={projectResourceType}
                isDisabled
              />
            </div>
          </div>

          <div className="col-md-12 mb-3 d-flex justify-content-start align-items-center gap-4">
            <div className="col-md-3 mb-3 mt-2 mb-md-0">
              <label htmlFor="">
                Customer <span className="text-danger">*</span>
              </label>
              <Select
                placeholder="Select Customer"
                className="basic-multi-select"
                classNamePrefix=" select"
                value={projectCustomer}
                isDisabled
              />
            </div>

            <div className="col-md-3 mt-2 mb-3 mb-md-0">
              <label htmlFor="">
                {" "}
                Project Industry <span className="text-danger">*</span>
              </label>
              <Select
                placeholder="Select Industry"
                className="basic-multi-select"
                classNamePrefix=" select"
                value={projectIndustry}
                isDisabled
              />
            </div>
          </div>

          <div className="col-12">
            <label htmlFor="">Description</label>
            <CKEditor
              editor={ClassicEditor}
              data={projectDescription}
              onReady={editor => {}}
              onChange={(event, editor) => {
                const data = editor.getData()
                setProjectDescription(data)
              }}
              disabled={true}
            />
          </div>
        </Row>
        <Row className="px-0">
          <div className="col-md-6 pe-md-4">
            <Row className="bg-white px-2 py-4 shadow mb-3">
              <h5 className="mb-3">Members</h5>
              <div className="col-md-8 mt-2 mb-3">
                <label htmlFor="">Key Account Manager</label>
                <Select
                  placeholder="Select Key Account Manager"
                  className="basic-multi-select"
                  classNamePrefix=" select"
                  value={projectKeyManager}
                  isDisabled
                />
              </div>

              <div className="col-12 mt-2 mb-3 d-flex gap-2 flex-wrap">
                {projectType?.find(obj => obj?.value == "1") && (
                  <div className="col-12">
                    <label htmlFor="">SEO</label>
                    <ReactSelect
                      placeholder="Select Members"
                      className="basic-multi-select "
                      classNamePrefix="select"
                      isMulti="true"
                      value={SEOMembers}
                      isDisabled
                    />
                  </div>
                )}
                {projectType?.find(obj => obj?.value == "2") && (
                  <div className="col-12">
                    <label htmlFor="">SMM</label>
                    <ReactSelect
                      placeholder="Select Members"
                      className="basic-multi-select "
                      classNamePrefix="select"
                      isMulti
                      value={SMMMembers}
                      isDisabled
                    />
                  </div>
                )}
                {projectType?.find(obj => obj?.value == "3") && (
                  <div className="col-12">
                    <label htmlFor="">PPC</label>
                    <ReactSelect
                      placeholder="Select Members"
                      className="basic-multi-select "
                      classNamePrefix="select"
                      isMulti
                      value={PPCMembers}
                      isDisabled
                    />
                  </div>
                )}
                {projectType?.find(obj => obj?.value == "4") && (
                  <div className="col-12">
                    <label htmlFor="">EM</label>
                    <ReactSelect
                      placeholder="Select Members"
                      className="basic-multi-select "
                      classNamePrefix="select"
                      isMulti
                      value={EMMembers}
                      isDisabled
                    />
                  </div>
                )}
              </div>
            </Row>
          </div>

          <div className="col-md-6 ps-md-3">
            <Row className="bg-white px-2 py-4 shadow mb-3">
              <h5 className="mb-3 mt-4">Estimatation</h5>
              <div className="col-md-6 mt-2 mb-3">
                <label htmlFor="">
                  Start Date <span className="text-danger">*</span>
                </label>
                <input
                  required
                  type="date"
                  className="form-control "
                  value={startDate}
                  disabled
                />
              </div>
              <div className="col-md-6 mt-2 mb-3">
                <label htmlFor="">
                  Report Date<span className="text-danger">*</span>
                </label>
                <input
                  required
                  type="date"
                  className="form-control "
                  value={startDate}
                  disabled
                />
              </div>
              <div className="col-md-6 mt-2 mb-3 ">
                <label htmlFor="">Duration </label>
                <Select
                  placeholder="Select Duration"
                  className="overflow-visible"
                  classNamePrefix="select"
                  value={duration}
                  isDisabled
                />
              </div>
              <div className="col-md-6 mt-2 mb-3 ">
                <label htmlFor="">End Date</label>
                <input
                  required
                  type="date"
                  className="form-control "
                  disabled
                  value={endDate}
                />
              </div>
              <div className="col-12 mt-2 mb-3 d-flex justify-content-start align-items-center flex-wrap gap-2">
                {projectType?.find(obj => obj?.value == "1") && (
                  <div className="col-12 ">
                    <label htmlFor="">SEO</label>
                    <ReactSelect
                      placeholder="Select SEO Package"
                      className="basic-multi-select "
                      classNamePrefix="select"
                      value={seoPackage}
                      isDisabled
                    />
                  </div>
                )}
                {projectType?.find(obj => obj?.value == "2") && (
                  <div className="col-12">
                    <label htmlFor="">SMM</label>
                    <ReactSelect
                      placeholder="Select SMM Package"
                      className="basic-multi-select "
                      classNamePrefix="select"
                      value={smmPackage}
                      isDisabled
                    />
                  </div>
                )}
                {projectType?.find(obj => obj?.value == "3") && (
                  <div className="col-12 ">
                    <label htmlFor="">PPC</label>
                    <ReactSelect
                      placeholder="Select PPC Package"
                      className="basic-multi-select "
                      classNamePrefix="select"
                      value={ppcPackage}
                      isDisabled
                    />
                  </div>
                )}
                {projectType?.find(obj => obj?.value == "4") && (
                  <div className="col-12">
                    <label htmlFor="">EM</label>
                    <ReactSelect
                      placeholder="Select EM Package"
                      className="basic-multi-select "
                      classNamePrefix="select"
                      value={emPackage}
                      isDisabled
                    />
                  </div>
                )}
              </div>
            </Row>
          </div>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default DmViewBasicProjectDetails
