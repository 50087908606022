import React, { Fragment, useEffect, useState } from "react"
import {
  Container,
  DropdownMenu,
  DropdownToggle,
  ModalBody,
  UncontrolledDropdown,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import { useMemo } from "react"
import { useTable, usePagination, useGlobalFilter } from "react-table"
import InfoGif from "../../../assets/images/GIF/question-mark.gif"
import AccessDenied from "../../../assets/images/dribbble_1.gif"
import mauticImg from "../../../assets/images/Mautic_Logo_LB.png"
import "../../../assets/css/Work/support.css"
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Badge,
  Modal,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import Select from "react-select"
import sideImg from "../../../assets/images/profile-img.png"

// assets
import "../../../assets/css/Sales/Sales.css"
import "../../../assets/css/Sales/Lead.css"

// common component
import Pagination from "components/Common/Pagination"
import ViewProfile from "./ViewLead/ViewProfile"
import ViewActivity from "./ViewLead/ViewActivity"
import ViewNotes from "./ViewLead/ViewNotes"
import Loader from "pages/Separate/Loader"
// Hover card
import axios from "axios"
import ViewFollowUp from "./ViewLead/ViewFollowUp"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import Dropzone from "react-dropzone"
import swal from "sweetalert"
import moment from "moment"
import configure from "configure"
import Swal from "sweetalert2"
import AccessDeniedPopup from "routes/AccessDeniedPopup"
import AddMeeting from "./AddMeeting"
import Draggable, { DraggableCore } from "react-draggable"
import { Resizable, ResizableBox } from "react-resizable"
import { fetchData } from "utils/common-fetchData"
// import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels"

const Lead = () => {
  const [modal_fullscreen, setmodal_fullscreen] = useState(false)
  const [viewId, setViewId] = useState("")
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const [data, setDatas] = useState([])
  const [displayData, setDisplayData] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [country, setCountry] = useState("")
  const [source, setSource] = useState([])
  const [status, setStatus] = useState([])
  const [assign, setAssign] = useState([])
  const [type, setType] = useState([])
  const [industry, setIndustry] = useState([])
  const [location, setLocation] = useState([])
  const [sourceIn_option, setInSource] = useState([])
  const [sourceuk_option, setUkSource] = useState([])
  const [userIn_option, setUserIn] = useState([])
  const [useruk_option, setUserUk] = useState([])
  const [alluser_option, setAllUsers] = useState([])
  const [allsource_option, setAllSource] = useState([])
  const [status_option, setStatusOption] = useState([])
  const [industry_option, setIndustryOption] = useState([])
  const [convertBtn, setConvertBtn] = useState(false)

  // const handleDoubleClick = (event, editor) => {
  //   console.log("check")
  //   const selection = editor.model.document.selection
  //   if (selection) {
  //     editor.model.change(writer => {
  //       writer.setSelection(
  //         selection.getFirstPosition(),
  //         selection.getLastPosition()
  //       )
  //     })
  //   }
  // }

  // const handleDoubleClick = (editor) => {
  //   // Access the model and view
  //   const model = editor.model;
  //   const view = editor.editing.view;
  //   // Handle the double-click event
  //   view.document.on('mousedown', (event, data) => {
  //     if (data.domTarget && data.domTarget.nodeType === 3) { // If text node is clicked
  //       // Start selecting text
  //       model.change(writer => {
  //         const range = model.createRange(
  //           model.createPositionAt(data.domTarget, 0),
  //           model.createPositionAt(data.domTarget, data.domTarget.textContent.length)
  //         );
  //         writer.setSelection(range);
  //       });
  //     }
  //   });
  // };

  useEffect(() => {
    fetch()
    FilterData()
  }, [])

  useEffect(() => {
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("module_id", 17)

    axios({
      method: "post",
      url: `${configure.apiUrl}/user_permission`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    })
      .then(res => {
        if (res.data.data.india[1] == 1 && res.data.data.uk[1] == 1) {
          setConvertBtn(true)
        } else {
          setConvertBtn(false)
        }
      })
      .catch(err => console.log(err))
  }, [])

  const type_option = [
    { label: "Hot", value: "1" },
    { label: "Cold", value: "2" },
    { label: "Warm", value: "3" },
    { label: "Normal", value: "4" },
  ]

  const fetch = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("location", "")
    formData.append("asigned", "")
    formData.append("status", "")
    formData.append("source", "")
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/lead`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      setDisplayData(response.data.data)
      setDatas(response.data.data)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const [disable, setDiable] = useState(false)

  const FilterData = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/filter_data`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(async response => {
        setStatusOption(
          response.data.data.lead_status.map(obj => ({
            value: obj.id,
            label: obj.name,
          }))
        )
        setInSource(
          response.data.data.india_source.map(obj => ({
            value: obj.id,
            label: obj.leadname,
          }))
        )
        setUkSource(
          response.data.data.uk_source.map(obj => ({
            value: obj.id,
            label: obj.leadname,
          }))
        )
        setUserIn(
          response.data.data.india_user.map(obj => ({
            value: obj.id,
            label: obj.name,
          }))
        )
        setUserUk(
          response.data.data.uk_user.map(obj => ({
            value: obj.id,
            label: obj.name,
          }))
        )

        const users = [
          ...response.data.data.uk_user,
          ...response.data.data.india_user,
        ]
        const source = [
          ...response.data.data.uk_source,
          ...response.data.data.india_source,
        ]
        const jsonObject = users.map(JSON.stringify)
        const uniqueSet = new Set(jsonObject)
        const uniqueArray = Array.from(uniqueSet).map(JSON.parse)
        setAllUsers(
          uniqueArray.map(obj => ({
            value: obj.id,
            label: obj.name,
          }))
        )

        const jsonObject1 = source.map(JSON.stringify)
        const uniqueSet1 = new Set(jsonObject1)
        const uniqueArray1 = Array.from(uniqueSet1).map(JSON.parse)
        setAllSource(
          uniqueArray1.map(obj => ({
            value: obj.id,
            label: obj.leadname,
          }))
        )
        setIndustryOption(
          response.data.data.industry?.map(obj => ({
            value: obj.id,
            label: obj.name,
          }))
        )

        const formData2 = new FormData()
        formData2.append("user_id", localStorage.getItem("auth_id"))
        const response2 = await axios({
          method: "post",
          url: `${configure.apiUrl}/show_lead_filter`,
          data: formData2,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(res => {
          if (res.data.success == false) {
            setDiable(true)
          }
          const datas = res.data.data[0]

          if (datas.location == "1") {
            setCountry({ label: "India", value: 1 })
          } else if (datas.location == "2") {
            setCountry({ label: "United Kingdom", value: 2 })
          }
          const sourceIds = datas?.source?.split(",").map(Number)
          const statusIds = datas?.status?.split(",").map(Number)
          const assignIds = datas?.assigned?.split(",").map(Number)
          const typeIds = datas?.lead_type?.split(",")
          const industryIds = datas?.industry?.split(",").map(Number)
          setSource(
            uniqueArray1
              ?.filter(item => sourceIds?.includes(item.id))
              ?.map(obj => ({
                value: obj.id,
                label: obj.leadname,
              }))
          )
          setStatus(
            response.data.data?.lead_status
              ?.filter(item => statusIds?.includes(item.id))
              ?.map(obj => ({
                value: obj.id,
                label: obj.name,
              }))
          )
          setAssign(
            uniqueArray
              ?.filter(item => assignIds?.includes(item.id))
              ?.map(obj => ({
                value: obj.id,
                label: obj.name,
              }))
          )
          setType(type_option?.filter(item => typeIds?.includes(item.value)))
          setIndustry(
            response.data.data?.industry
              ?.filter(item => industryIds?.includes(item.id))
              ?.map(obj => ({
                value: obj.id,
                label: obj.name,
              }))
          )
        })

        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  const [lead_data, setLeadData] = useState([])
  const [note_data, setNoteData] = useState([])
  const [log_data, setLogData] = useState([])
  const [follow_data, setFollowData] = useState([])
  const [isFavorite, setIsFavorite] = useState(true)
  const [isFavoriteId, setIsFavoriteId] = useState(0)
  const [name_modal, setNameModal] = useState(false)
  //code for fetching lead data

  //meta title
  document.title = "Leads | Zithas Technologies"

  document.addEventListener("keydown", e => {
    if (e.key === "Escape") setmodal_fullscreen(false)
  })

  const [view_assign, setViewAssign] = useState([])
  const [created_at, setCreatedAt] = useState("")

  const toggleFavorite = async ({ leadId, fav }) => {
    swal({
      text: `${fav == 0
        ? "Are you sure you want to favorite this lead?"
        : "Are you sure you want to unfavorite this lead?"
        }`,
      icon: "warning",
      buttons: true,
    }).then(async willOkay => {
      if (willOkay) {
        console.log(leadId, fav)
        setIsFavorite(!isFavorite) // Toggle favorite status
        const favValue = fav ? 0 : 1 || fav ? 1 : 0
        setIsFavoriteId(fav)
        const formData = new FormData()
        formData.append("lead_id", leadId)
        formData.append("fav", favValue)
        formData.append("user_id", localStorage.getItem("auth_id"))
        formData.append("location", "")
        formData.append("asigned", "")
        formData.append("status", "")
        formData.append("source", "")
        setLoading(true)
        try {
          const response = await axios({
            method: "post",
            data: formData,
            url: `${configure.apiUrl}/isFavourite`,
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          })
          if (response) {
            // console.log("isFavourite", response)
            setDatas(response?.data?.data)
            setLoading(false)
            // swal({
            //   text: "FollowUp is completed",
            //   icon: "success",
            // }).then(() => {
            //   setFollowUp(response.data.data)
            // })
          }
          setLoading(false)
        } catch (error) {
          setLoading(false)
          console.log(error)
        }
      }
    })
  }
  // table columns
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        isInfoIcon: false,
        className: "text-center",
        Cell: cellProps => {
          // console.log(cellProps.row.original)
          return (
            <div className="d-flex align-items-center gap-2">
              {/* <i className="bi bi-star-fill text-purple"></i> */}
              <button
                className="btn border-0 p-0"
                onClick={() =>
                  toggleFavorite({
                    leadId: cellProps.row.original.id,
                    fav: cellProps.row.original.is_favourite,
                  })
                }
              >
                <i
                  className={`bi fav-btn ${cellProps.row.original.is_favourite == 1
                    ? "bi-star-fill text-star"
                    : "bi-star"
                    }`}
                // onClick={() => addToFavorite(cellProps.row.original.id)}
                ></i>
              </button>
              <span>{cellProps.row.original.id}</span>
            </div>
          )
        },
      },
      {
        Header: "",
        accessor: "assigned",
        Cell: cellProps => {
          return (
            <div className="text-center d-flex align-items-center">
              <div className="profileImg-box">
                <img
                  src={`https://mars.zithas.com/application/public/${cellProps.row.original.asigned_avatar}`}
                  alt=""
                  className="assigned-img mx-1"
                  height="20"
                  width="20"
                />
                <div className="profileImg-detail">
                  <div className="overflow-hidden mb-0 card">
                    <div className="bg-primary bg-soft">
                      <div className="row">
                        <div className="col-8">
                          <div className="text-primary text-start p-3">
                            <h5 className="text-primary ">
                              {cellProps.row.original.asigned}
                            </h5>
                            <p style={{ width: "200px" }}>
                              {cellProps.row.original.assign_designation}
                            </p>
                          </div>
                        </div>
                        <div className="align-self-end col-4">
                          <img src={sideImg} alt="" className="img-fluid" />
                        </div>
                      </div>
                    </div>
                    <div className="pt-0 mb-0 card-body">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="avatar-md profile-user-wid mb-2">
                            <img
                              src={`https://mars.zithas.com/application/public/${cellProps.row.original.asigned_avatar}`}
                              alt=""
                              style={{ width: "100%", height: "100%" }}
                              className="img-thumbnail rounded-circle"
                            />
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className=" text-start">
                            <div className="row">
                              <div className="pe-1 col-5">
                                <h5 className="font-size-12 mb-1">Join Date</h5>
                                <p className=" font-size-12 text-muted mb-0">
                                  {cellProps.row.original.join_date}
                                </p>
                              </div>
                              <div className="ps-0 col-7">
                                <h5 className="font-size-12 mb-1">Email</h5>
                                <p className=" font-size-12 text-muted mb-0">
                                  {cellProps.row.original.assign_email}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {cellProps.row.original.lead_belongs_to == 1 ? (
                <img
                  src="https://mars.ztpl.net/assets/images/india.png"
                  className=" mx-1 location-img"
                  alt="flag"
                  height="15"
                  width="20"
                />
              ) : cellProps.row.original.lead_belongs_to == 2 ? (
                <img
                  src="https://mars.ztpl.net/assets/images/uk.png"
                  className=" mx-1 location-img"
                  alt="flag"
                  height="15"
                  width="20"
                />
              ) : null}
            </div>
          )
        },
        isInfoIcon: true,
      },
      {
        Header: "Company",
        accessor: "company",
        isInfoIcon: true,
        className: "cursor-pointer",
        Cell: cellProps => {
          return (
            <div className="d-flex justify-space-between align-items-center">
              <div>
                {cellProps.row.original.company !== null
                  ? cellProps.row.original.company
                  : "-"}
              </div>
              <div>
                {cellProps.row.original.lead_type == "Hot" ? (
                  <i
                    className="bx bxs-hot"
                    style={{
                      fontSize: "18px",
                      lineHeight: "0",
                      color: "#FF5733",
                    }}
                  ></i>
                ) : cellProps.row.original.lead_type == "Warm" ? (
                  <i
                    className="bx bxs-flame"
                    style={{
                      fontSize: "20px",
                      lineHeight: "0",
                      color: "#fdab99",
                    }}
                  ></i>
                ) : cellProps.row.original.lead_type == "Cold" ? (
                  <i
                    className="bx bxs-sun"
                    style={{
                      fontSize: "20px",
                      lineHeight: "0",
                      color: "#4ab3ff",
                    }}
                  ></i>
                ) : null}
              </div>
            </div>
          )
        },
      },
      {
        Header: "Name",
        accessor: "person_details",
        isInfoIcon: true,
        Cell: cellProps => {
          const personal = JSON.parse(cellProps.row.original.person_details)
          let PersonName
          if (personal != null && personal?.length == 0) {
            PersonName = "-"
          } else if (personal != null) {
            PersonName = personal[0]?.person_name
          }
          return <div>{PersonName ? PersonName : "-"}</div>
        },
      },
      {
        Header: "Email ID",
        accessor: "email",
        isInfoIcon: true,
        Cell: cellProps => {
          const alldata = cellProps.row.original
          return (
            <div>
              {alldata?.person_details
                ? JSON.parse(alldata.person_details)[0]?.person_email
                  ? JSON.parse(alldata.person_details)[0].person_email
                  : alldata?.email != null
                    ? alldata?.email
                    : "-"
                : alldata?.email != null
                  ? alldata?.email
                  : "-"}
            </div>
          )
          // const personal = JSON.parse(cellProps.row.original.person_details)
          // let PersonEmail
          // if (personal != null && personal?.length == 0) {
          //   PersonEmail = cellProps.row.original.email
          // } else if (personal != null) {
          //   PersonEmail = personal[0]?.person_email
          // }
          // return <div>{PersonEmail ? PersonEmail : "-"}</div>
        },
      },
      {
        Header: "Phone",
        accessor: "phone",
        isInfoIcon: true,
        Cell: cellProps => {
          const alldata = cellProps.row.original
          return (
            <div>
              {alldata?.person_details
                ? JSON.parse(alldata.person_details)[0]?.person_phone
                  ? JSON.parse(alldata.person_details)[0].person_phone
                  : alldata?.phonenumber != null
                    ? alldata?.phonenumber
                    : "-"
                : alldata?.phonenumber != null
                  ? alldata?.phonenumber
                  : "-"}
            </div>
          )

          // const personal = JSON.parse(cellProps.row.original.person_details)
          // let PersonPhone
          // if (personal != null && personal?.length == 0) {
          //   PersonPhone = cellProps.row.original.phonenumber
          // } else if (personal != null) {
          //   PersonPhone = personal[0]?.person_phone
          // }
          // return <div>{PersonPhone ? PersonPhone : "-"}</div>
        },
      },
      {
        Header: "Days",
        accessor: "diff",
        isInfoIcon: true,
        className: "text-center",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: cellProps => {
          return (
            <Badge className={cellProps.row.original.status_color}>
              {cellProps.row.original.sname}
            </Badge>
          )
        },
        isInfoIcon: true,
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: cellProps => {
          const onDeletePermission = async () => {
            setLoading(true)
            const formData = new FormData()
            formData.append("user_id", localStorage.getItem("auth_id"))
            formData.append("module_id", 17)
            try {
              await axios({
                method: "post",
                url: `${configure.apiUrl}/user_permission`,
                data: formData,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              }).then(res => {
                if (res.data.data.india[5] == 1 || res.data.data.uk[5] == 1) {
                  onDeleteLead()
                } else {
                  setAccessDenied(true)
                }
                setLoading(false)
              })
            } catch (error) {
              console.log(error)
              setLoading(false)
            }
          }

          const onDeleteLead = e => {
            swal({
              title: "Are you sure?",
              text: "You want to Delete this Lead !",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then(async willDelete => {
              if (willDelete) {
                setLoading(true)
                const formData = new FormData()
                formData.append("lead_id", cellProps.row.original.id)
                formData.append("user_id", localStorage.getItem("auth_id"))
                try {
                  const response = await axios({
                    method: "post",
                    url: `${configure.apiUrl}/delete_lead`,
                    data: formData,
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "multipart/form-data",
                    },
                  }).then(response => {
                    swal({
                      icon: "success",
                      text: "Lead Deleted Successfully",
                    }).then(data => {
                      setDatas(response.data.data)
                    })
                    setLoading(false)
                  })
                } catch (err) {
                  swal({
                    icon: "error",
                    text: "SomeThing Went Wrong",
                  })
                  setLoading(false)
                }
              }
            })
          }

          const fetch2 = async id => {
            setCreatedAt(cellProps.row.original.created_at)
            setViewId(id)
            setLoading(true)
            setmodal_fullscreen(true)
            const formData = new FormData()
            formData.append("lead_id", cellProps.row.original.id)
            formData.append("user_id", localStorage.getItem("auth_id"))
            try {
              const response = await axios({
                method: "post",
                url: `${configure.apiUrl}/all_lead_data`,
                data: formData,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              }).then(response => {
                setLeadData(response.data.data.lead)
                setNoteData(response.data.data.lead_note)
                setLogData(response.data.data.lead_activity?.reverse())
                setFollowData(response.data.data.lead_followup_data)
                setViewAssign(
                  response.data.data.assigned?.map(obj => ({
                    value: obj.id,
                    label: obj.name,
                  }))
                )

                setLoading(false)
              })
            } catch (err) {
              console.log(err)
              setLoading(false)
            }
          }

          const onConvert = async e => {
            setLoading(true)
            const formData = new FormData()
            formData.append("lead_id", cellProps.row.original.id)
            try {
              const response = await axios({
                method: "post",
                url: `${configure.apiUrl}/convert_to_customer`,
                data: formData,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              }).then(response => {
                swal({
                  icon: "success",
                  text: "Converted Successfully",
                }).then(data => {
                  navigate(
                    `${configure.appUrl}sales/edit-customer/${response.data.data.id}`
                  )
                })

                setLoading(false)
              })
            } catch (err) {
              console.log(err)
              setLoading(false)
            }
          }

          const onEditPermission = async ({ id, isEditType }) => {
            setLoading(true)
            const formData = new FormData()
            formData.append("user_id", localStorage.getItem("auth_id"))
            formData.append("module_id", 17)
            try {
              await axios({
                method: "post",
                url: `${configure.apiUrl}/user_permission`,
                data: formData,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              }).then(res => {
                if (res.data.data.india[4] == 1 || res.data.data.uk[4] == 1) {
                  if (isEditType === 1) {
                    navigate(`${configure.appUrl}sales/edit-lead/${id}`)
                  } else {
                    onConvert()
                  }
                } else {
                  setAccessDenied(true)
                }
                setLoading(false)
              })
            } catch (error) {
              console.log(error)
              setLoading(false)
            }
          }

          return (
            <div className="d-flex">
              <a
                title="add note"
                className="btn btn-sm btn-outline-secondary me-1"
                onClick={() => {
                  fetchCurrency(cellProps.cell?.row?.original.id, cellProps.cell?.row?.original)
                  setLeadId(cellProps.cell?.row?.original.id)
                }}
              >
                <i className="fas fa-file-alt "></i>
              </a>

              <a
                title="view lead"
                className="btn btn-sm btn-outline-secondary me-1"
                onClick={() => fetch2(cellProps.cell?.row?.original.id)}
              >
                <i className="fas fa-eye "></i>
              </a>
              <UncontrolledDropdown
                className="dropdown"
                style={{ width: "29px" }}
              >
                <DropdownToggle
                  className="btn btn-sm btn-outline-secondary w-100"
                  color="white"
                >
                  <i className="mdi mdi-dots-horizontal"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <Link
                    onClick={() =>
                      onEditPermission({
                        id: cellProps.cell.row.original.id,
                        isEditType: 1,
                      })
                    }
                    // to={`${configure.appUrl}sales/edit-lead/${cellProps.cell?.row?.original.id}`}
                    title="edit lead"
                    className="dropdown-item"
                  >
                    <i className="fas fa-pencil-alt pr-10"></i> Edit Lead
                  </Link>

                  {convertBtn ? (
                    <a
                      title="view lead"
                      className="dropdown-item"
                      // onClick={onConvert}
                      onClick={() =>
                        onEditPermission({
                          id: cellProps.row.original.id,
                          isEditType: 2,
                        })
                      }
                    >
                      <i className="fas fa-exchange-alt text-success pr-10"></i>{" "}
                      Convert to Customer
                    </a>
                  ) : null}
                  <a
                    onClick={() => onDeletePermission()}
                    className="dropdown-item"
                    title="delete"
                  >
                    <i className="fas fa-trash-alt pr-10 text-danger"></i>{" "}
                    Delete Lead
                  </a>
                  <a
                    className="dropdown-item"
                    title="Create a meeting"
                    // onClick={() => {
                    //   redirectCalender()
                    // }}
                    href={`${configure.appUrl}calender/lead-${cellProps.cell.row.original.id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-users me-2"></i>
                    Add Meeting
                  </a>
                </DropdownMenu>
              </UncontrolledDropdown>
              {cellProps.row.original.mautic == 1 &&
                cellProps.row.original.is_valid == 1 && (
                  <div className="d-flex justify-content-center ps-1 align-items-center">
                    <img
                      src={mauticImg}
                      width={23}
                      height={23}
                      alt="mautic"
                      className="img-fluid"
                    />
                  </div>
                )}
            </div>
          )
        },
      },
    ],
    [convertBtn]
  )

  const [formPopup, setFormPopup] = useState(false)
  const [fixedContent, setFixedContent] = useState(false)

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
      },
    },
    useGlobalFilter,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state

  useEffect(() => setPageSize(50), [])

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 1200,
    }),
  }

  useEffect(() => {
    fetchCountry()
  }, [])

  const fetchCountry = async () => {
    setLoading(true)
    try {
      await axios({
        method: "get",
        url: `${configure.apiUrl}/locations`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setLocation(
          res.data.data.map(obj => ({
            label: obj.location,
            value: obj.id,
          }))
        )
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const onFilter = async e => {
    e.preventDefault()
    setFormPopup(false)
    setLoading(true)
    const Assign = assign.map(obj => obj.value).join(",")
    const Status = status.map(obj => obj.value).join(",")
    const Source = source.map(obj => obj.value).join(",")
    const Type = type.map(obj => obj.label).join(",")
    const Industry = industry.map(obj => obj.value).join(",")
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    if (country.value != undefined) {
      formData.append("location", country.value)
    } else {
      formData.append("location", "")
    }
    formData.append("asigned", Assign)
    formData.append("status", Status)
    formData.append("source", Source)
    formData.append("lead_type", Type)
    formData.append("industry", Industry)

    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/lead`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setDatas(response.data.data)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const [title, setTitle] = useState("")

  const info = [
    {
      title: "Assigned",
      mean: " <p> <strong>Meaning :</strong> In the Mars System's Lead Module, the <strong>Assigned</strong> column designates the specific team member or individual responsible for overseeing and taking action on a lead or potential business opportunity. </p>",
      purpose:
        " <p> <strong>Purpose :</strong> The purpose of the <strong>Assigned</strong> column in the Lead Module is to assign clear accountability for each lead. By identifying who is responsible for nurturing and converting leads into customers, it streamlines lead management, ensures follow-up, and enhances the chances of successful lead conversion. This fosters a more efficient and organized approach to lead handling within the Mars System.</p>",
    },
    {
      title: "Company",
      mean: "<p><strong>Meaning :</strong>  Within the Mars System's Lead Module, the <strong>Company</strong> column refers to the name of the organization or business entity associated with a lead or potential opportunity</p>",
      purpose:
        "<p> <strong>Purpose :</strong> The purpose of the <strong>Company</strong> column is to provide crucial information about the lead's affiliation. It helps in categorizing leads by their company, allowing sales and marketing teams to tailor their strategies and messaging accordingly. This column streamlines lead segmentation and ensures that leads from the same company are managed cohesively, enhancing the effectiveness of lead conversion efforts.</p>",
    },
    {
      title: "Name",
      mean: "<p><strong>Meaning :</strong>  In the Mars System's Lead Module, the <strong>Name</strong> column typically represents the full name of an individual associated with a lead or potential business opportunity.. </p>",
      purpose:
        "</strong> <p> <strong>Purpose :</strong> The purpose of the <strong>Name</strong> column is to provide specific information about the person linked to the lead. It allows for personalized communication and relationship-building with leads. By knowing the names of potential clients or contacts, the Mars System's users can establish a more personal and targeted approach to lead engagement, which can significantly improve lead conversion rates and customer satisfaction</p>",
    },
    {
      title: "Email ID",
      mean: "<p><strong>Meaning :</strong>  Within the Mars System's Lead Module, the <strong>Email ID</strong> column stores the email addresses of individuals or contacts associated with leads or potential business opportunities</p>",
      purpose:
        "<p><strong>Purpose :</strong> The purpose of the <strong>Email ID</strong> column is to serve as a primary means of communication with leads. Email addresses are crucial for sending follow-up messages, marketing materials, and other relevant information. This column ensures that users have a direct and reliable channel to reach out to leads, fostering efficient communication and nurturing relationships to move leads further down the sales funnel.</p>",
    },
    {
      title: "Phone",
      mean: "<p><strong>Meaning :</strong>  In the Mars System's Lead Module, the <strong>Phone</strong> column contains the contact phone numbers of individuals or organizations associated with leads or potential business opportunities</p>",
      purpose:
        "<p><strong>Purpose :</strong> The purpose of the <strong>Phone</strong> column is to provide an additional means of direct communication with leads. Phone numbers enable users to engage with leads through voice calls, which can be especially effective for personalized interactions, follow-ups, and addressing specific inquiries. This column enhances the versatility of communication channels, allowing for more comprehensive lead engagement and relationship building.</p>",
    },
    {
      title: "Days",
      mean: " <p><strong>Meaning :</strong>  In the Mars System's Lead Module, the <strong>Days</strong> column signifies the number of days that have elapsed since the lead's last update or entry into the system.</p>",
      purpose:
        "<p><strong>Purpose :</strong> The purpose of the <strong>Days</strong> column is to provide a time-based reference for lead activity. It helps users understand the recency of lead updates, ensuring that leads are regularly reviewed and actioned upon. This column aids in prioritizing leads that require immediate attention, as leads with longer durations since their last update may need follow-ups or reevaluation. It promotes timely and effective lead management within the Mars System.</p>",
    },
    {
      title: "Status",
      mean: "<p><strong>Meaning :</strong>  In the Mars System's Lead Module, the <strong>Status</strong> column indicates the current stage or status of a lead's progression within the lead management process</p>",
      purpose:
        "<p><strong>Purpose :</strong>  The purpose of the  <strong>Status</strong> column is to provide a clear overview of where each lead stands in the sales or conversion pipeline. It allows users to track and manage leads efficiently by categorizing them into stages such as  <strong>New </strong> ,  <strong>Contacted </strong>,  <strong>Qualified </strong>,  <strong>Negotiating </strong>, or  <strong>Closed</strong>. This status tracking facilitates better lead prioritization, workflow management, and decision-making, helping teams focus their efforts on leads at the appropriate stage of the sales cycle.</p>",
    },
  ]

  const [modal_Access, setAccessDenied] = useState(false)

  // Quick Note Functionality

  const [currency_option, setCurrencyOption] = useState([])
  const [lead_id, setLeadId] = useState("")
  const [allNotes, setAllNotes] = useState([])
  const [leadDetail, setLeadDetail] = useState({})
  const toggleLoading = (loading) => {
    setLoading(loading)
  }

  const fetchCurrency = async (id, obj) => {
    setNotesSidebar(true);
    const personal = JSON?.parse(obj?.person_details)
    let PersonName
    if (personal != null && personal?.length == 0) {
      PersonName = "-"
    } else if (personal != null) {
      PersonName = personal[0]?.person_name
    }
    const newObj = { ...obj, personName: PersonName }
    setLeadDetail(newObj)
    const response = await fetchData(toggleLoading, `leadNotesData/${id}`, '')
    if (response?.success) {
      setCurrencyOption(
        response.data.currency.map(item => ({
          label: item.currency,
          value: item.id,
        }))
      )
      setAllNotes(response.data.notes)

    }
  }

  const [selectedFiles1, setselectedFiles1] = useState([])

  function handleAcceptedFiles1(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles1(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const [notesSidebar, setNotesSidebar] = useState(false)

  const [currency, setCurrency] = useState({
    label: null,
    value: null,
  })
  const curentDateTime = moment().format("YYYY-MM-DDTHH:mm")
  const [notedate, setNotedate] = useState(curentDateTime)
  const [amount, setAmount] = useState("")
  const [ckedata, setCkedata] = useState("")
  const [noDrag, setNoDrag] = useState(false)
  const [noteForm, setNoteForm] = useState(false)
  const [boxWidth, setBoxWidth] = useState(0)
  const [boxHeight, setBoxHeight] = useState(0)

  useEffect(() => {
    const updateWidth = () => {
      const viewportWidth = window.innerWidth
      setBoxWidth(viewportWidth * 0.95)
    }
    const updateHeight = () => {
      const viewpoirtHeight = window.innerHeight
      setBoxHeight(viewpoirtHeight * 0.95)
    }
    updateHeight()
    updateWidth()
    window.addEventListener("resize", updateWidth, updateHeight)
    return () => window.removeEventListener("resize", updateWidth, updateHeight)
  }, [])

  const addNotes = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append("lead_id", lead_id)
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("currency", currency.value)
    formData.append("date", notedate)
    formData.append("amount", amount)
    formData.append("description", ckedata)
    for (let i = 0; i < selectedFiles1.length; i++) {
      formData.append("files[]", selectedFiles1[i])
    }
    try {
      const response = await axios({
        method: "post",
        // url: `https://api.zithas.com/api/add_lead_note`,
        url: `${configure.apiUrl}/add_lead_note`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setLoading(false)
        Swal.fire({
          icon: "success",
          text: "Note Added Successfully",
        }).then(data => {
          setCkedata("")
          // setNotedate("")
          setAmount("")
          setNotesSidebar(false)
        })
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const onSaveFilter = async e => {
    setLoading(true)
    const Assign = assign?.map(obj => obj.value).join(",")
    const Status = status?.map(obj => obj.value).join(",")
    const Source = source?.map(obj => obj.value).join(",")
    const Type = type?.map(obj => obj.value).join(",")
    const Industry = industry?.map(obj => obj.value).join(",")
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("location", country?.value)
    formData.append("assigned", Assign)
    formData.append("status", Status)
    formData.append("source", Source)
    formData.append("lead_type", Type)
    formData.append("industry", Industry)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/save_lead_filter`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        swal({
          icon: "success",
          text: "Filters Saved Successfully",
        })
        setDiable(!disable)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  console.log("noDrag", noDrag)

  const onClearFilter = async e => {
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/clear_lead_filter`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        swal({
          icon: "success",
          text: "Filters Cleared Successfully",
        }).then(data => {
          setCountry("")
          setSource([])
          setStatus([])
          setAssign([])
          setType([])
          setIndustry([])
          setDiable(!disable)
        })
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const timeDifference = new Date() - new Date(created_at)
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24)

  const onAddPermission = async id => {
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("module_id", 17)
    try {
      await axios({
        method: "post",
        url: `${configure.apiUrl}/user_permission`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        if (res.data.data.india[3] == 1 || res.data.data.uk[3] == 1) {
          navigate(`${configure.appUrl}sales/add-lead`)
        } else {
          setAccessDenied(true)
        }
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Lead"} />
          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <div className="responsive-table">
                    <div>
                      <div
                        className={`flex-column fixed-buttons pt-2 ${fixedContent ? "d-flex" : "d-none"
                          }`}
                      >
                        <Link
                          onClick={() => onAddPermission()}
                          className="btn btn-purple  fix-btn btn-label pe-0"
                        >
                          <i className="fas fa-plus me-2 icon-size label-icon"></i>
                          <span>Lead</span>
                        </Link>
                        <button
                          onClick={() => navigate(-1)}
                          className="btn btn-red  fix-btn btn-label pe-0"
                        >
                          <i className="dripicons-cross me-2 icon-center label-icon"></i>
                          <span>Cancel</span>
                        </button>
                      </div>
                      <Row className="mb-2">
                        <Col>
                          <div className="d-flex">
                            <select
                              className=" form-select"
                              style={{ width: "100px" }}
                              value={pageSize}
                              onChange={e => setPageSize(e.target.value)}
                            >
                              {[10, 25, 50, 100].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                  Show {pageSize}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col>
                        <Col className="d-flex justify-content-end">
                          <div className="me-4">
                            <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
                              <div className="position-relative">
                                <label
                                  htmlFor="search-bar-0"
                                  className="search-label"
                                >
                                  <span
                                    id="search-bar-0-label"
                                    className="sr-only"
                                  >
                                    Search this table
                                  </span>
                                  <input
                                    onChange={e =>
                                      setGlobalFilter(e.target.value)
                                    }
                                    id="search-bar-0"
                                    type="text"
                                    className=" form-control rounded h-100"
                                    placeholder={` Search...`}
                                    value={globalFilter || ""}
                                  />
                                </label>
                                <i className="bx bx-search-alt search-icon"></i>
                              </div>
                            </div>
                          </div>
                          <div>
                            <button
                              onClick={() => setFormPopup(!formPopup)}
                              className="btn btn-outline-secondary  me-3"
                            >
                              <i className="fas fa-filter"></i>
                            </button>
                          </div>
                          <div className="text-md-end">
                            <Link
                              id="add"
                              onClick={() => onAddPermission()}
                              className=" btn text-white me-2 btn-save btn-label"
                            >
                              <i className="fas fa-plus me-2 icon-size label-icon"></i>
                              Lead
                            </Link>
                            <button
                              onClick={() => navigate(-1)}
                              className=" btn btn-red  btn-label"
                            >
                              <i className="dripicons-cross me-2 icon-center label-icon"></i>
                              CANCEL
                            </button>
                          </div>
                        </Col>
                      </Row>
                      <div className="position-relative">
                        <Table
                          className="table table-bordered mt-4"
                          {...getTableProps()}
                        >
                          <thead
                            className={`${fixedContent ? "fix-header" : ""
                              } fix-lead-header`}
                          >
                            {headerGroups.map((headerGroup, i) => (
                              <tr
                                key={i}
                                {...headerGroup.getHeaderGroupProps()}
                              >
                                {headerGroup.headers.map((column, index) => (
                                  <th
                                    key={index}
                                    className={`t-col-${index + 1} ${column.className
                                      }`}
                                    {...column.getHeaderProps()}
                                  >
                                    {column.render("Header")}
                                    {column.isInfoIcon ? (
                                      <a
                                        onClick={() => {
                                          setNameModal(true)
                                          setTitle(column.Header)
                                        }}
                                      >
                                        <i
                                          title="info"
                                          className={`bx bx-info-circle ms-1 info-icon assign-${index}`}
                                        ></i>
                                      </a>
                                    ) : null}
                                  </th>
                                ))}
                              </tr>
                            ))}
                          </thead>

                          <tbody
                            className="fix-lead-header"
                            {...getTableBodyProps()}
                          >
                            {page.map((row, i) => {
                              prepareRow(row)
                              let backColor
                              if (row.original.lead_type == "Hot") {
                                backColor = "bd-hot"
                              }
                              return (
                                <tr
                                  className={`row-hover ${backColor}`}
                                  {...row.getRowProps()}
                                  key={i}
                                >
                                  {row.cells.map((cell, index) => {
                                    return (
                                      <td
                                        key={index}
                                        onClick={() =>
                                          cell.column.Header === "Company"
                                            ? navigate(
                                              `${configure.appUrl}sales/edit-lead/${cell.row.allCells[0].value}`
                                            )
                                            : ""
                                        }
                                        className={`t-col-${index + 1} ${cell.column.className
                                          }`}
                                        {...cell.getCellProps()}
                                      >
                                        {cell.render("Cell")}
                                      </td>
                                    )
                                  })}
                                </tr>
                              )
                            })}
                            {page.length == 0 ? (
                              <tr>
                                <td className="text-center" colSpan={10}>
                                  No Leads Available
                                </td>
                              </tr>
                            ) : null}
                          </tbody>
                        </Table>

                        {formPopup ? (
                          <div className="popup-form p-4">
                            <Form onSubmit={onFilter}>
                              <Row className="align-items-end">
                                <Row
                                  className="d-flex pe-0 me-0"
                                  style={{ width: "100%" }}
                                >
                                  <div className="col-md-3 mb-2">
                                    <label htmlFor="">Location</label>
                                    <Select
                                      placeholder="Select Location"
                                      className="basic-multi-select"
                                      classNamePrefix=" select"
                                      onChange={e => setCountry(e)}
                                      value={country}
                                      styles={colourStyles}
                                      options={location}
                                    />
                                  </div>
                                  <div className="col-md-3 mb-2">
                                    <label htmlFor="">Source</label>
                                    {country.value == "2" ? (
                                      <Select
                                        placeholder="Select Source"
                                        className="basic-multi-select"
                                        classNamePrefix=" select"
                                        styles={colourStyles}
                                        options={sourceuk_option}
                                        value={source}
                                        closeMenuOnSelect={false}
                                        isMulti
                                        onChange={e => setSource(e)}
                                      />
                                    ) : country.value == "1" ? (
                                      <Select
                                        placeholder="Select Source"
                                        className="basic-multi-select"
                                        classNamePrefix=" select"
                                        styles={colourStyles}
                                        options={sourceIn_option}
                                        value={source}
                                        closeMenuOnSelect={false}
                                        isMulti
                                        onChange={e => setSource(e)}
                                      />
                                    ) : (
                                      <Select
                                        placeholder="Select Source"
                                        className="basic-multi-select"
                                        classNamePrefix=" select"
                                        styles={colourStyles}
                                        options={allsource_option}
                                        value={source}
                                        closeMenuOnSelect={false}
                                        isMulti
                                        onChange={e => setSource(e)}
                                      />
                                    )}
                                  </div>
                                  <div className="col-md-3 mb-2">
                                    <label htmlFor="">Status</label>
                                    <Select
                                      placeholder="Select Status"
                                      className="basic-multi-select"
                                      classNamePrefix=" select"
                                      styles={colourStyles}
                                      options={status_option}
                                      value={status}
                                      closeMenuOnSelect={false}
                                      isMulti
                                      onChange={e => setStatus(e)}
                                    />
                                  </div>
                                  <div className="col-md-3 mb-2">
                                    <label htmlFor="">Assigned</label>
                                    {country.value == "2" ? (
                                      <Select
                                        placeholder="Select Assigned"
                                        className="basic-multi-select"
                                        classNamePrefix=" select"
                                        styles={colourStyles}
                                        options={useruk_option}
                                        value={assign}
                                        closeMenuOnSelect={false}
                                        isMulti
                                        onChange={e => setAssign(e)}
                                      />
                                    ) : country.value == "1" ? (
                                      <Select
                                        placeholder="Select Assigned"
                                        className="basic-multi-select"
                                        classNamePrefix=" select"
                                        styles={colourStyles}
                                        options={userIn_option}
                                        value={assign}
                                        closeMenuOnSelect={false}
                                        isMulti
                                        onChange={e => setAssign(e)}
                                      />
                                    ) : (
                                      <Select
                                        placeholder="Select Assigned"
                                        className="basic-multi-select"
                                        classNamePrefix=" select"
                                        styles={colourStyles}
                                        options={alluser_option}
                                        value={assign}
                                        closeMenuOnSelect={false}
                                        isMulti
                                        onChange={e => setAssign(e)}
                                      />
                                    )}
                                  </div>
                                  <div className="col-md-3 mt-2">
                                    <label htmlFor="">Lead Type</label>
                                    <Select
                                      placeholder="Select Status"
                                      className="basic-multi-select"
                                      classNamePrefix=" select"
                                      styles={colourStyles}
                                      options={type_option}
                                      closeMenuOnSelect={false}
                                      value={type}
                                      onChange={e => setType(e)}
                                      isMulti
                                    />
                                  </div>
                                  <div className="col-md-3 mt-2">
                                    <label htmlFor="">Industry</label>
                                    <Select
                                      placeholder="Select Status"
                                      className="basic-multi-select"
                                      classNamePrefix=" select"
                                      styles={colourStyles}
                                      options={industry_option}
                                      closeMenuOnSelect={false}
                                      value={industry}
                                      onChange={e => setIndustry(e)}
                                      isMulti
                                    />
                                  </div>
                                  <div className="col-md-3 mt-2">
                                    <label htmlFor="">
                                      Save and Clear Filters
                                    </label>
                                    <div>
                                      <a
                                        className="btn  btn-primary mr-10"
                                        onClick={onSaveFilter}
                                        style={{
                                          fontSize: "11px",
                                          backgroundColor: "#93a6ff",
                                          borderColor: "#93a6ff",
                                          color: "black",
                                        }}
                                      >
                                        Save Filters
                                      </a>
                                      <a
                                        className="btn  btn-danger"
                                        onClick={onClearFilter}
                                        style={{
                                          fontSize: "11px",
                                          backgroundColor: "#fed36d",
                                          borderColor: "#fed36d",
                                          color: "black",
                                        }}
                                      >
                                        Clear Filters
                                      </a>
                                    </div>
                                  </div>
                                  <div className="d-flex col-md-3 align-items-end justify-content-end">
                                    <div className="mr-10">
                                      <button className="btn  btn-purple">
                                        <i className="fas fa-filter search-icon me-1 icon-center"></i>{" "}
                                        Filter
                                      </button>
                                    </div>
                                    <div className="">
                                      <a
                                        className="btn  btn-red"
                                        onClick={e => {
                                          e.preventDefault()
                                          setDatas(displayData)
                                          setFormPopup(!formPopup)
                                        }}
                                      >
                                        <i className="dripicons-cross me-1 icon-center"></i>{" "}
                                        Close
                                      </a>
                                    </div>
                                  </div>
                                </Row>
                              </Row>
                            </Form>
                            <i className="bi bi-triangle-fill pop-icon"></i>
                          </div>
                        ) : null}
                      </div>
                      <Row className="justify-content-md-end justify-content-center align-items-center">
                        <Pagination
                          gotoPage={gotoPage}
                          previousPage={previousPage}
                          canPreviousPage={canPreviousPage}
                          nextPage={nextPage}
                          canNextPage={canNextPage}
                          pageIndex={pageIndex}
                          pageOptions={pageOptions}
                        />
                      </Row>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* View Modal */}
      <Modal size="xl" isOpen={modal_fullscreen} className="modal-fullscreen">
        <div className="modal-header mb-0">
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ width: "100%" }}
          >
            <div className="col-6 d-flex align-items-center">
              <h5 className=" modal-title mt-0">Lead Details</h5>
              <span
                className="ml-70 pl-50"
                style={{ fontSize: "16px", color: "black" }}
              >
                Created On : {moment(created_at).format("DD-MM-YYYY")} (
                {parseFloat(daysDifference).toFixed(0)})
              </span>
            </div>
          </div>

          <button
            type="button"
            onClick={() => {
              setmodal_fullscreen(false)
            }}
            className="ms-4"
            style={{ border: "none", backgroundColor: "transparent" }}
          >
            <span
              aria-hidden="true"
              style={{ color: "#bf1e30", fontSize: "28px", lineHeight: "1px" }}
            >
              {" "}
              &times;{" "}
            </span>
          </button>
        </div>

        <div className="modal-body hide-bottom-scrollbar ps-3 pe-0 pt-0">
          <Row className="gx-4 justify-content-between">
            <Col md="2" className="side-tab h-100 py-3 bd-fixed-sidetab">
              <Nav pills className="flex-column">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleVertical("1")
                    }}
                  >
                    Profile
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "2",
                    })}
                    onClick={() => {
                      toggleVertical("2")
                    }}
                  >
                    Notes
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "4",
                    })}
                    onClick={() => {
                      toggleVertical("4")
                    }}
                  >
                    Follow Up
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "3",
                    })}
                    onClick={() => {
                      toggleVertical("3")
                    }}
                  >
                    Activity Log
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col md="10" className="bd-right-side-tab">
              <div className="px-4 h-100 pl-0">
                <TabContent
                  activeTab={verticalActiveTab}
                  className="text-muted mt-4 mt-md-0"
                >
                  <TabPane tabId="1">
                    <ViewProfile viewData={lead_data[0]} />
                  </TabPane>
                  <TabPane tabId="2">
                    <ViewNotes
                      notesData={note_data}
                      view_assign={view_assign}
                      viewId={viewId}
                      verticalActiveTab={verticalActiveTab}
                    />
                  </TabPane>
                  <TabPane tabId="3">
                    <ViewActivity logData={log_data} />
                  </TabPane>
                  <TabPane tabId="4">
                    <ViewFollowUp
                      followData={follow_data}
                      view_assign={view_assign}
                    />
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal
        isOpen={name_modal}
        toggle={() => {
          tog_center()
        }}
        className="bd-info-popup"
        centered
      >
        <div className="modal-header bd-head-info">
          <h5 className="modal-title mt-0">
            <i className="dripicons-information bd-transform-icon pr-10 text-red"></i>
            {title}
          </h5>
        </div>
        <div className="modal-body pb-0">
          <div className="d-flex">
            <div className="col-5 pr-30">
              <div>
                <img src={InfoGif} className="w-100" />
              </div>
            </div>
            <div className="mt-20 ">
              <div>
                {info.map(item => {
                  if (item.title == title) {
                    return (
                      <>
                        <span>
                          <span
                            dangerouslySetInnerHTML={{ __html: item.mean }}
                          ></span>
                        </span>
                        <div
                          dangerouslySetInnerHTML={{ __html: item.purpose }}
                        ></div>
                      </>
                    )
                  }
                })}
                <div></div>
                <div className="text-center mt-70">
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={() => setNameModal(false)}
                  >
                    I Understood
                    <i className="dripicons-thumbs-up pl-10"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <AccessDeniedPopup
        modal_Access={modal_Access}
        setAccessDenied={setAccessDenied}
      />

      {/* Quick Note Add */}
      {/* <div
              className={`d-none notes-sidebar ${
                notesSidebar ? "show-notes-sidebar" : ""
              }`}
            ></div> */}
      <div
        className={`note-sticky-form ${notesSidebar ? "d-block" : "d-none"}`}
      >
        <Draggable
          handle={`${noDrag ? ".modal-header" : ""}`}
          cancel={`${noDrag ? ".modal-body" : ""}`}
        >
          <ResizableBox
            width={boxWidth}
            height={boxHeight}
            style={{
              position: "fixed",
              top: "4%",
              left: "3%",
              zIndex: "1055",
              backgroundColor: "#fff",
            }}
          >
            <div className="px-3 d-flex align-items-center justify-content-between">
              <h5 className="mt-0">Add Note </h5>
              <Col className="company-info px-2 shadow mb-3 me-0 d-flex justify-content-center align-items-center">
                <div className="d-flex justify-content-start align-items-center py-2 pt-3 gap-4">
                  <div className="">
                    <h5>
                      <i className="bi bi-buildings-fill text-purple"></i>{" "}
                      <span className="border-end border-3 border-dark pe-4 text-primary">
                        {leadDetail?.company}
                      </span>
                    </h5>
                  </div>
                  <div>
                    <h5>
                      <i className="bi bi-person-fill text-purple"></i>
                      <span className="border-end border-3 border-dark pe-4 text-primary">
                        {" "}
                        {leadDetail?.personName}
                      </span>   {" "}
                    </h5>
                  </div>
                  <div className="">
                    <h5>
                      <i className="bi bi-telephone-fill text-purple"></i>
                      <span className="border-end border-3 border-dark pe-4 text-primary">
                        {" "}
                        {leadDetail?.phonenumber}
                      </span>   {" "}
                    </h5>
                  </div>
                  <div>
                    <h5>
                      <i className="bi bi-envelope-fill text-purple"></i>{" "}
                      <span className="border-3 border-dark pe-4 text-primary">
                        {" "}
                        {leadDetail?.email}
                      </span>{" "}
                    </h5>
                  </div>
                </div>
              </Col>
              <button
                type="button"
                onClick={() => {
                  setNotesSidebar(false)
                }}
                className="btn-modal-close"
              >
                <span aria-hidden="true" className="btn-modal-span">
                  &times;
                </span>
              </button>
            </div>
            <div className="px-3 pt-2 bg-white">
              <div className="row">
                <div className="col-md-6">
                  <h5 className="text-dark">Notes History</h5>
                  <div className="mt-4" style={{ maxHeight: "400px", overflowY: "auto", scrollbarWidth: "thin" }}>
                    <ul className="verti-timeline list-unstyled notes-box">
                      {allNotes?.map((note, i) => {
                        return (
                          <li className="event-list position-relative" key={i}>
                            <div className="event-timeline-dot connected-dot">
                              <img
                                src={`${configure.imgUrl}${note?.addedby_avatar}`}
                                className="msgUserImg me-3"
                                alt="User Avatar"
                              />
                            </div>
                            <div className="d-flex">

                              <div className="flex-grow-1 ms-4 note py-2 rounded ps-3 pr-10">
                                <div className="d-flex justify-content-start align-items-center">
                                  <div>
                                    <p className="text-muted ">
                                      <h6 className="d-inline me-2">
                                        By {note.added_by}
                                      </h6>{" "}
                                      {moment(note.created_at).format(
                                        "DD-MM-YYYY hh:mm:ss"
                                      )}
                                      <span className="pl-15">
                                        Got in touch with this lead at{" "}
                                        {moment(note.date).format(
                                          "DD-MM-YYYY hh:mm a"
                                        )}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <i className="bi bi-triangle-fill note-icon"></i>
                                <div
                                  className="Features"
                                  dangerouslySetInnerHTML={{
                                    __html: note.description,
                                  }}
                                />
                                <p className="mb-2">
                                  Amount : {note.currency_symbol} {note.amount}{" "}
                                </p>
                                {note.link != null ? (
                                  <p className="mb-1">
                                    Attachment :{" "}
                                    <a
                                      target="_blank "
                                      className="btn btn-sm edit-delete-btn text-secondary ml-10"
                                      rel="noreferrer"
                                      type="button"
                                      href={note.link}
                                    >
                                      <i className="fas fa-eye"></i> View
                                    </a>
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end flex-column gap-4">
                    <Form onSubmit={addNotes}>
                      <Row className="align-items-center justify-content-between">
                        <div className="col-12 d-flex justify-content-between">
                          <h5 className="text-dark">Add New Note</h5>
                          <div className="text-end">
                            <a
                              className="btn btn-save  me-2 btn-label"
                              type="button"
                              href={`${configure.appUrl}calender/lead-${lead_id}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i className="fa fa-users me-2 label-icon"></i>
                              Add Meeting
                            </a>
                            <button className="btn btn-save  me-2 btn-label">
                              <i className="fas fa-plus me-2 label-icon"></i>Add Note
                            </button>
                            {/* <a
                        onClick={() => {
                          setNotesSidebar(false)
                        }}
                        className="btn btn-red text-uppercase  btn-label"
                      >
                        <i className="dripicons-cross me-1 icon-center label-icon"></i>
                        Close
                      </a> */}
                          </div>
                        </div>
                        <div className="" style={{ width: "98%" }}>
                          <label htmlFor="">Description</label>
                          <div
                            className="no-drag"
                            onDoubleClick={() => setNoDrag(true)}
                            onMouseLeave={() => setNoDrag(false)}
                            onMouseEnter={() => setNoDrag(true)}
                          >
                            <CKEditor
                              editor={ClassicEditor}
                              data={ckedata}
                              onReady={editor => { }}
                              onChange={(event, editor) => {
                                const data = editor.getData()
                                setCkedata(data)
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 mt-3 px-0 ps-2">
                          <label htmlFor="">Currency</label>
                          <Select
                            onChange={e =>
                              setCurrency({
                                label: e.label,
                                value: e.value,
                              })
                            }
                            placeholder="Select Currency"
                            className="basic-multi-select"
                            classNamePrefix=" select"
                            styles={colourStyles}
                            options={currency_option}
                            menuPlacement="top"
                          />
                        </div>
                        <div className="col-md-3 mt-3  px-0 ps-2">
                          <label htmlFor="">Amount</label>
                          <input
                            type="number"
                            value={amount}
                            onChange={e => setAmount(e.target.value)}
                            placeholder="Enter Amount"
                            className="form-control "
                          />
                        </div>
                        <div className="col-md-5 mt-3 notes pe-4">
                          <label htmlFor="touch">
                            Got in touch with this lead <span className="text-danger"> *</span>
                          </label>
                          <input
                            type="datetime-local"
                            value={notedate}
                            onChange={e => setNotedate(e.target.value)}
                            required
                            className="form-control "
                          />
                        </div>

                        <div className="col-md-6 mt-3">
                          <label htmlFor="">Attachment</label>
                          <Dropzone
                            onDrop={acceptedFiles => {
                              handleAcceptedFiles1(acceptedFiles)
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div
                                className="dz-message needsclick p-0"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="d-flex px-2 custom-file-upload align-items-center">
                                  <div className="">
                                    <i className="display-6 text-muted bx bxs-cloud-upload" />
                                  </div>
                                  <h5 className="mb-0 ms-2">Upload Files</h5>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        </div>
                        <div
                          className={`col-md-5 mt-3 dropzone-previews ${selectedFiles1.length > 0 ? "d-block" : "d-none"
                            }`}
                          id="file-previews"
                        >
                          <label className="mb-1">Uploaded Files</label>
                          <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                            <div className="p-1">
                              <Row className="align-items-center justify-content-evenly">
                                {selectedFiles1.map((f, i) => {
                                  return (
                                    <Col key={i} className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                  )
                                })}
                              </Row>
                            </div>
                          </Card>
                        </div>

                      </Row>
                    </Form>

                  </div>
                </div>
              </div>
            </div>
          </ResizableBox>
        </Draggable>
      </div>
    </React.Fragment>
  )
}

export default Lead
