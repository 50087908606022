import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Modal } from "reactstrap"

// assets
import "../../../../assets/css/Sales/Sales.css"
import moment from "moment"
import Draggable from "react-draggable"
import { ResizableBox } from "react-resizable"
import { fetchData } from "utils/common-fetchData"
import Loader from "pages/Separate/Loader"
import secureLocalStorage from "react-secure-storage"
import { useParams } from "react-router-dom"

const DmViewActivityLogs = ({ verticalActiveTab, type, projectDetail }) => {
  const [old_data, setOldData] = useState({})
  const [new_data, setNewData] = useState({})
  const [viewData, setViewData] = useState(false)

  const labelobj = Object.keys(old_data)
  const oldValue = Object.values(old_data)
  const newValue = Object.values(new_data)
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const params = useParams()
  const toggleLoading = load => {
    setLoading(load)
  }
  const token = secureLocalStorage.getItem("auth_token")
  useEffect(() => {
    const getActivity = async () => {
      const res = await fetchData(
        toggleLoading,
        `getDigitalActivityLogs/${
          type == "edit" ? projectDetail?.id : params?.id
        }`,
        token
      )
      if (res?.success) {
        console.log(res)
        setData(res?.data)
      }
    }
    if (verticalActiveTab == 5) {
      getActivity()
    }
  }, [verticalActiveTab])

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="mb-3">
        <div>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between mb-4">
                    <h5 className="text-dark">My Activity</h5>
                  </div>
                  <div>
                    <ul className="verti-timeline list-unstyled">
                      {data.map((status, key) => (
                        <li key={key} className="event-list position-relative">
                          <div className="event-timeline-dot">
                            <i
                              className={`fas fa-arrow-alt-circle-right timeline-icon fs-4 bx-fade-right`}
                            />
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div className="d-flex align-items-center">
                                <div>
                                  <h5>{status.description}</h5>
                                  <p className="text-muted">
                                    At{" "}
                                    {moment(status.created_at).format(
                                      "DD-MM-YYYY"
                                    )}{" "}
                                    By {status.alias_name}
                                  </p>
                                </div>
                                {status.new_data != null ? (
                                  <div className="ml-30">
                                    <a
                                      className="btn btn-sm activity-log-btn text-info"
                                      onClick={() => {
                                        setOldData(JSON.parse(status.old_data))
                                        setNewData(JSON.parse(status.new_data))
                                        setViewData(true)
                                      }}
                                    >
                                      <i className="fas fa-eye"></i> View
                                    </a>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <p className="timing">
                              {moment(status.created_at)
                                .format("DD-MM-YYYY hh:mm:ss")
                                .slice(10)}
                            </p>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <div className={`note-sticky-form ${viewData ? "d-block" : "d-none"}`}>
        <Draggable>
          <ResizableBox
            width={900}
            style={{
              position: "fixed",
              top: "25%",
              right: "21%",
              zIndex: "1055",
              backgroundColor: "#fff",
            }}
          >
            <div className="modal-content" role="document">
              <div className="support-logsmodal-header">
                <h5 className="modal-title mt-0">Log Details</h5>
                <button
                  type="button"
                  onClick={() => {
                    setViewData(false)
                  }}
                  className="btn-modal-close"
                >
                  <span aria-hidden="true" className="btn-modal-span">
                    &times;
                  </span>
                </button>
              </div>
              <div className="support-logsmodal-body">
                <div className="table-responsive">
                  <table className="table mb-0 table">
                    <thead className="table-light">
                      <tr>
                        <th>Fields</th>
                        <th>Old Data</th>
                        <th>New Data</th>
                      </tr>
                    </thead>
                    <tbody>
                      {labelobj?.map((items, index) => {
                        return (
                          <tr key={index}>
                            <th scope="row">{items}</th>
                            <td>
                              <div
                                className="no-padding-desc"
                                dangerouslySetInnerHTML={{
                                  __html: oldValue[index],
                                }}
                              />
                            </td>
                            <td>
                              <div
                                className="no-padding-desc"
                                dangerouslySetInnerHTML={{
                                  __html: newValue[index],
                                }}
                              />
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </ResizableBox>
        </Draggable>
      </div>
    </React.Fragment>
  )
}

export default DmViewActivityLogs
