import React, { useEffect, useState } from "react"
import "../../../assets/css/Staff/Staff.css"
import "../../../assets/css/Credential/Credential.css"
import "../../../assets/css/Scollbar.css"
import "../../../assets/css/Work/projects.css"
import {
  Container,
  Table,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Collapse,
} from "reactstrap"
import classnames from "classnames"
import SimpleBar from "simplebar-react"
import PackageDm from "./PackageDm"
import { DigitalServiceOptions } from "utils/common-options"

const PackageDmIndex = ({ activity, country, packages }) => {
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const [isCollapse, setIsCollapse] = useState(true)
  const [allPackages, setAllPackages] = useState([])

  document.title = "Digital Marketing | Zithas Crm"
  useEffect(() => {
    setAllPackages(packages)
  }, [packages])

  const [fixedSideTab, setFixedSideTab] = useState(false)
  const [isIndex, setIsIndex] = useState(false)
  const handleHover = () => {
    setIsIndex(true)
  }
  const handleLeave = () => {
    setIsIndex(false)
  }

  document.addEventListener("scroll", () => {
    if (window.scrollY > 68) {
      setFixedSideTab(true)
    } else {
      setFixedSideTab(false)
    }
  })
  const updateData = (data) => {
    setAllPackages(data)
  }

  return (
    <React.Fragment>
      <div className=" mb-4">
        <Container fluid>
          <div id="credentialPage">
            <Row
              className={`justify-content-between di-flex align-items-top `}
              id="cred"
            >
              <div
                className={`px-0 digital_marketing_sidebar ${fixedSideTab && "credtabFix"
                  }`}
                style={isIndex ? { zIndex: "999" } : { zIndex: '1' }}
              >
                <div className="position-relative">
                  <div>
                    <SimpleBar
                      style={{ height: "70vh" }}
                      className={`${fixedSideTab && "fix-simplebar"}`}
                    >
                      <Nav
                        pills
                        className={`credtabFix-ul dmPackageNav ${fixedSideTab && ""
                          }  flex-column px-0 py-0 h-100`}

                      >
                        {DigitalServiceOptions?.map((obj, i) => {
                          return (
                            <NavItem
                              className={classnames({
                                "d-flex flex-column": true,
                                active: verticalActiveTab == obj?.id,
                              })}
                              style={{ height: "45px" }}
                              key={i}
                            >
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  "text-center crendential": true,
                                  active: verticalActiveTab == obj?.id,
                                })}
                                onClick={() => {
                                  setverticalActiveTab(obj?.id)
                                }}
                                onMouseEnter={handleHover}
                                onMouseLeave={handleLeave}
                              >
                                {obj?.text}
                                <span className="link_name border-start border-white border-2">
                                  {obj?.name}
                                </span>
                              </NavLink>
                            </NavItem>
                          )
                        })}

                      </Nav>
                    </SimpleBar>
                  </div>
                </div>
              </div>
              <Col
                md={isCollapse ? "11" : "10"}
                className={` pe-0 credential-table ${isCollapse && "width-94 ps-0"
                  }`}
                  style={{zIndex:"2"}}
              >
                <div className="card p-4 h-100">
                  <TabContent
                    activeTab={verticalActiveTab}
                    className="text-muted mt-4 mt-md-0"
                  >
                    {DigitalServiceOptions?.map((obj) => {
                      return (
                        <TabPane tabId={obj?.id} key={obj?.id}>
                          <PackageDm activity={activity} packages={allPackages} country={country} verticalActiveTab={verticalActiveTab}
                            updateData={updateData} />
                        </TabPane>
                      )
                    })}
                  </TabContent>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PackageDmIndex