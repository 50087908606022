import PropTypes from "prop-types"
import React, { useState } from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  Label,
} from "reactstrap"

//redux
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.svg"

// import css
import "../../assets/css/Login/Login.css"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import configure from "configure"
import secureLocalStorage from "react-secure-storage"
import { getLocation, loginUser, setApiData } from "store/actions"
import { useDispatch } from "react-redux"
import { setElementData } from "store/elements/actions"

const Login = props => {
  //meta title
  const dispatch = useDispatch();
  document.title = "Login | Zithas Technologies"
  const [error2, setError2] = useState(false)
  const navigate = useNavigate()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [isLoading, setLoading] = useState(false)

  const onHandleLogin = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append("email", email)
    formData.append("password", password)
    formData.append("type", `${configure.type}`)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/login`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        console.log(response)
        if (response.data.success == true) {
          // Old Method
          localStorage.setItem("auth_name", response.data.data.log_name)
          localStorage.setItem("auth_role", response.data.data.roleid)
          localStorage.setItem("auth_avt", response.data.data.avatar)
          localStorage.setItem("auth_email", response.data.data.email)

          // New Updated Method
          localStorage.setItem("auth_id", response.data.data.user_id)
          secureLocalStorage.setItem("auth_email", response.data.data.email)
          secureLocalStorage.setItem("auth_avt", response.data.data.avatar)
          secureLocalStorage.setItem("auth_name", response.data.data.log_name)
          secureLocalStorage.setItem("auth_role", response.data.data.roleid)
          secureLocalStorage.setItem("auth_token", response.data.data.token)
          const authUser = {
            id: response.data.data.user_id,
            name: response.data.data.log_name,
            avatar: response.data.data.avatar,
            email: response.data.data.email,
            role: response.data.data.roleid,
            token: response.data.data.token,
          }
          navigate(`${configure.appUrl}dashboard`)
          dispatch(setApiData(response?.data?.data?.permission))
          dispatch(setElementData(response.data.data?.components))
          dispatch(getLocation(response?.data?.data?.location?.map((obj) => ({
            value: obj.id,
            label: obj.location,
            img: obj.Link
          }))))
          dispatch(loginUser(authUser))
          navigate(`${configure.appUrl}dashboard`)
        } else {
          setError2(true)
        }
        setLoading(false)
      })
    } catch (err) {
      setError2(true)
      console.log(err)
      setLoading(false)
    }
  }

  const [passwordType, setPasswordType] = useState("password")

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return
    }
    setPasswordType("password")
  }

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="login-page">
        <div className="text-end pt-3 pe-5  d-none d-sm-block">
          <a
            href="https://www.zithas.com"
            target="_blank"
            rel="noreferrer"
            className="text-white"
          >
            <i className="bx bx-home h2" />
          </a>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col xs={7}>
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Welcome Back !</h5>
                          <p>Sign in to continue to zithas.</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link
                        to={`${configure.appUrl}`}
                        className="logo-light-element"
                      >
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      <Form
                        className="form-horizontal"
                        onSubmit={onHandleLogin}
                      >
                        {error2 ? (
                          <Alert color="danger">
                            Username and password are invalid. Please enter
                            correct username and password
                          </Alert>
                        ) : null}

                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={e => setEmail(e.target.value)}
                          />
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Password</Label>
                          <div className="bd-pass">
                            <Input
                              name="password"
                              type={passwordType}
                              placeholder="Enter Password"
                              onChange={e => setPassword(e.target.value)}
                            />
                            <a
                              className="bd-pass-icon"
                              onClick={togglePassword}
                            >
                              {passwordType === "password" ? (
                                <i className="fas fa-eye-slash "></i>
                              ) : (
                                <i className="fas fa-eye "></i>
                              )}
                            </a>
                          </div>
                        </div>

                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customControlInline"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customControlInline"
                          >
                            Remember me
                          </label>
                        </div>

                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            Log In
                          </button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
