import React, { useEffect, useState } from "react"
// import zithasLogo from "../../assets/images/zithas-dark-logo.png"
import "../../assets/css/Profile/Profile.css"
import { Link } from "react-router-dom"
import ViewDocument from "./ViewDocument"
import configure from "configure"
import { Table, Row, Col, Input, Modal, Form, Label } from "reactstrap"
import axios from "axios"
import Swal from "sweetalert2"
import microsoftPdf from "../../assets/pdf/microsoft_365.pdf"

const Document = ({ setIsLoading }) => {
  const [docData, setDocData] = useState([])
  const [signDocument, setSignDocument] = useState(true)
  const [downloadDocument, setDownloadDocument] = useState(true)

  const [microsoftIntegration, setMicrosoftIntegration] = useState(false)
  const [islinkCreated, setislinkCreated] = useState(false)
  const [linkGen, setLinkGen] = useState("")
  // const [linkValue, setLinkValue] = useState(false);
  const [tokenGenerate, setTokenGenerate] = useState(false)
  const [tokenValue, setTokenValue] = useState(false)
  const [token, setToken] = useState("NA")

  const handleLinkCreate = e => {
    e.preventDefault()
    setislinkCreated(true)
  }

  const handleTokenCreate = e => {
    e.preventDefault()
    setTokenValue(true)

    setTimeout(() => {
      setMicrosoftIntegration(false)
    }, 5000)
  }

  // const handleDeleteToken = e => {
  //   e.preventDefault()
  //   setTokenValue(false)
  // }
  // get documents

  const fetchData = async () => {
    setIsLoading(true)
    try {
      const staffId = localStorage.getItem("auth_id")
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/viewApproveLetter/${staffId}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        setIsLoading(false)
        console.log(response)
        setDocData(response.data.data)
      }
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }
  useEffect(() => {
    fetchData()
  }, [])

  // microsoft integration

  const [client_id, setClient_id] = useState("")
  const [select_value, setSelect_value] = useState("")
  const [secret_id, setSecret_id] = useState("")
  const [tennant_id, setTennant_id] = useState("")
  const [microsoft_type, setMicrosoft_type] = useState("")
  const [isToken, setIsToken] = useState(false)
  const [isAccess, setIsaccess] = useState(true)
  const [isRefresh, setIsRefresh] = useState(false)
  const [isDelete, setIsDelete] = useState(false)

  const handleAccessToken = async e => {
    e.preventDefault()
    setIsLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("client_id", client_id)
    formData.append("secret_value", select_value)
    formData.append("secret_id", secret_id)
    formData.append("tenant_id", tennant_id)
    try {
      try {
        const response = await axios({
          method: "post",
          url: `${configure.apiUrl}/addTeamsCredentials`,
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        console.log("addStaffDocument", response)
        if (response) {
          console.log(response)
          setIsLoading(false)

          Swal.fire({
            icon: "success",
            text: "Link Generated Successfully",
          }).then(() => {
            setIsaccess(false)
            setIsDelete(true)
            setLinkGen(response.data.data.link)
            setislinkCreated(true)
          })
        }
      } catch (err) {
        setIsLoading(false)
        console.log(err)
      }
    } catch (error) {}
  }
  const findToken = async () => {
    setIsLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    // formData.append("user_id", localStorage.getItem("auth_id"))
    try {
      const response = await axios({
        data: formData,
        method: "post",
        url: `${configure.apiUrl}/teamsCredentials`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        setIsLoading(false)
        console.log("teamsCredentials", response)
        console.log("teamsCredentials", response.data)
        if (response.data.data.credentials.length != 0) {
          console.log("checkedddd")
          if (response.data.data.credentials[0]?.secret_id.length != 0) {
            setislinkCreated(true)
            setLinkGen(response.data.data?.link)
            setIsDelete(true)
            setClient_id(response.data.data.credentials[0]?.client_id)
            setSelect_value(response.data.data.credentials[0]?.secret_value)
            setSecret_id(response.data.data.credentials[0]?.secret_id)
            setTennant_id(response.data.data.credentials[0]?.tenant_id)
            setIsaccess(false)
          } else {
            // setIsaccess(true)
            setislinkCreated(false)
            setLinkGen("")
          }
          if (response.data.data.credentials[0]?.token) {
            setTokenGenerate(true)
            setToken(response.data.data?.credentials[0]?.token)
          }
          if (response.data.data.credentials[0]?.refresh_token !== null) {
            setIsRefresh(true)
          }
        }
        // if (
        //   response.data.data.credentials.length != 0 &&
        //   response.data.data.credentials[0]?.secret_id.length != 0
        // ) {
        // }

        // console.log(response.data.data.link)
        // const shortData = response.data.data.credentials[0]
        // console.log("shortData", shortData)

        // if (
        //   response.data.data.credentials.length == 0 &&
        //   response.data.data.credentials[0]?.secret_id.length == 0
        // ) {
        //   setIsaccess(true)
        //   setislinkCreated(false)
        //   setLinkGen("")
        // }

        // if (
        //   response.data.data.credentials.length != 0 &&
        //   response.data.data.credentials[0]?.refresh_token !== null
        // ) {
        //   setIsRefresh(true)
        // }
      }
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }
  useEffect(() => {
    findToken()
  }, [microsoftIntegration])
  console.log("tennant_id", tennant_id)

  const handleRefreshToken = async () => {
    setIsLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    try {
      const response = await axios({
        data: formData,
        method: "post",
        url: `${configure.apiUrl}/retrieveToken`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        console.log(response)
        Swal.fire({
          icon: "success",
          text: "Token Refreshed successfully !",
        })
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }
  const handleDeleteToken = async () => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure ?",
      text: "You want to delete this token.",
      showCancelButton: true,
    }).then(async willDelete => {
      if (willDelete.isConfirmed) {
        setIsLoading(true)
        const formData = new FormData()
        formData.append("user_id", localStorage.getItem("auth_id"))
        try {
          const response = await axios({
            data: formData,
            method: "post",
            url: `${configure.apiUrl}/deleteToken`,
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          })
          if (response) {
            Swal.fire({
              icon: "success",
              text: "Token deleted successfully",
            }).then(() => {
              setIsDelete(false)
              setTokenGenerate(false)
              setIsaccess(true)
              setislinkCreated(false)
              setLinkGen("")
              setClient_id("")
              setSelect_value("")
              setSecret_id("")
              setTennant_id("")
            })
            console.log("deleteToken", response)
            setIsLoading(false)
          }
        } catch (error) {
          setIsLoading(false)
          console.log(error)
        }
      }
    })
  }
  return (
    <div>
      <div className="document-heading shadow">
        {/* <h5 className="mb-3 shadow border border-2 p-3 px-4 fw-bold">Documents</h5> */}
        <h6 className="m-b-20 p-b-5 b-b-default f-w-600 fs-3 p-3 px-4">
          Documents
        </h6>
      </div>

      {docData.map(item => {
        return (
          <div
            key={item.id}
            className="mb-3 shadow border border-3 common-card"
          >
            <div className="bg-white p-4  ">
              {/* <h5 className="mb-4 text-dark">ZTPL Credential</h5> */}
              <div className="row">
                {/* <div className="col-md-4 mb-3 mb-md-0 border-end border-2  ">
              <label htmlFor="" className="docTitle">Document Name</label>             
            </div> */}
                <div className="col-md-8 mb-md-0 border-end border-2">
                  <input
                    type="email"
                    placeholder="Enter Email Id"
                    className="form-control formInput "
                    id=""
                    value={item.requirement_type}
                    disabled
                  />
                </div>
                <div className="col-md-4 gap-3 align-self-center d-flex justify-content-start">
                  {/* <a className="btn btn-sm action-btn btn-outline-secondary me-1">
                <i className="fa fa-download text-purple pr-4"></i>
              </a> */}
                  {item.approve == 0 && (
                    <div className="text-success text-center w-100 fw-bold">
                      Wait for Approvel
                    </div>
                  )}
                  {item.approve == 1 && item.status == 0 && (
                    <div className="d-flex justify-content-center w-100">
                      <Link
                        to={`${configure.appUrl}editdocument/${item.id}`}
                        title="edit"
                        className="btn btn-sm btn-outline-secondary me-1"
                      >
                        <i className="fa fa-pen text-purple pr-2"></i>
                      </Link>
                    </div>
                  )}
                  {item.status == 1 && item.approve == 1 && (
                    <div className="d-flex justify-content-center w-100">
                      {" "}
                      <Link
                        to={`${configure.appUrl}viewdocument/${item.id}`}
                        title="View"
                        className="btn btn-sm btn-outline-secondary me-1"
                        // onClick={setSignDocument(!signDocument)}
                      >
                        {signDocument ? (
                          <i className="fa fa-eye text-purple pr-2"></i>
                        ) : (
                          <i className="fa fa-pen text-purple pr-2"></i>
                        )}
                      </Link>
                      <a
                        href={item.signature}
                        target="_blank"
                        rel="noreferrer"
                        title="download"
                        className="btn btn-sm btn-outline-secondary me-1"
                      >
                        <i className="fa fa-download text-purple pr-2"></i>
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      })}
      {docData.length == 0 && (
        <div className="d-flex pb-3 justify-content-center">
          <span>No Documents Found</span>
        </div>
      )}

      {/* // Microsoft Integration Section */}
      <div className="card border border-2 ">
        <div className="card-body">
          <h4 className="card-title mb-4 fs-3">Microsoft Integration</h4>
          <div className="col-sm-12 d-flex justify-space-between align-item-center">
            <h5 className="mb-0 align-center link-name">Calender</h5>
            <div className="d-flex justify-conent-center gap-1">
              <button
                className="btn text-white link-gerate-btn fw-600"
                onClick={() => setMicrosoftIntegration(!microsoftIntegration)}
              >
                Access Token
              </button>
              {isRefresh && (
                <button
                  onClick={handleRefreshToken}
                  className="btn text-white link-gerate-btn fw-600"
                >
                  <i className="bi bi-arrow-clockwise  label-icon"></i>
                </button>
              )}
              {isDelete && (
                <button
                  className="btn text-white bg-danger fw-600 "
                  onClick={handleDeleteToken}
                >
                  <i className="fas fa-trash  label-icon text-center"></i>
                </button>
              )}
            </div>
          </div>
          <h6 className="m-b-20 m-t-40 p-b-5 b-b-default f-w-600"></h6>
        </div>
      </div>

      {/* //  Microsoft Integration Section Modal  */}
      <Modal isOpen={microsoftIntegration} size="lg" className="" centered>
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            Microsoft Teams Calendar Access Token
          </h5>
          <button
            type="button"
            onClick={() => {
              setMicrosoftIntegration(false)
              setTokenValue(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <Form className="row" onSubmit={handleAccessToken}>
            <div className="mb-3 col-md-6">
              <Label htmlFor="formrow-firstname-Input">
                Client ID <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                required
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter Client ID"
                value={client_id}
                onChange={e => setClient_id(e.target.value)}
              />
            </div>
            <div className="mb-3 col-md-6">
              <div className="text-end pe-1">
                <Label
                  htmlFor="formrow-firstname-Input"
                  className="text-danger"
                >
                  Download and Follow Steps to get details required
                </Label>
              </div>
              <div className="d-flex justify-content-end">
                <a
                  href={microsoftPdf}
                  // href="https://zithastechnologiespvtltd695-my.sharepoint.com/:b:/g/personal/jack_zithas_com/EaDYXk_nyRBIp0d4BNbWPBcBqYhysaW0onv7rDVGVpc-qA?e=tNw4wt"
                  download
                  target="_blank"
                  rel="noreferrer"
                  className=" btn btn-save btn-label "
                >
                  <i className="fas fa-download me-2 label-icon"></i>
                  Document
                </a>
              </div>
            </div>
            <div className="mb-3 col-md-6">
              <Label htmlFor="formrow-firstname-Input">
                Secret Value <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter Secret Value"
                value={select_value}
                required
                onChange={e => setSelect_value(e.target.value)}
              />
            </div>
            <div className="mb-3 col-md-6">
              <Label htmlFor="formrow-firstname-Input">
                Secret ID <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                required
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter Secret ID"
                value={secret_id}
                onChange={e => setSecret_id(e.target.value)}
              />
            </div>
            <div className="mb-3 col-md-6">
              <Label htmlFor="formrow-firstname-Input">
                Tennant ID <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                required
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter Tennant ID"
                value={tennant_id}
                onChange={e => setTennant_id(e.target.value)}
              />
            </div>
            {isAccess && (
              <div className="mt-2 mb-3">
                <button
                  type="submit"
                  className=" btn btn-save btn-label"
                  // onClick={handleLinkCreate}
                >
                  <i className="fas fa-plus me-2 label-icon"></i>
                  Generate Link
                </button>
              </div>
            )}
            {/* )} */}
            {islinkCreated && (
              <div className="mb-3 mt-2 w-100 d-flex">
                <p
                  style={{ cursor: "pointer" }}
                  onClick={() => setTokenGenerate(true)}
                >
                  <span>{linkGen}</span>
                </p>
              </div>
            )}
            {tokenGenerate ? (
              <div className=" mb-3 d-flex flex-column  align-item-start gap-3">
                <div className="mb-3 col-md-12">
                  <p className="overflow-hidden tokenValue-profile">
                    {tokenValue ? token : "NA"}
                  </p>
                </div>
                <div className="mb-3 col-md-3 w-100 d-flex justify-content-end align-item- center">
                  <button
                    className=" btn text-white bg-danger"
                    onClick={handleTokenCreate}
                  >
                    Token Generated
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
          </Form>
        </div>
      </Modal>
    </div>
  )
}

export default Document
