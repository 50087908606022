import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import {
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import editEventImg from "../../../assets/images/Digital Marketing/editEventImg.jpg"
import sideImg from "../../../assets/images/profile.png"
import Select from "react-select"
//css
import "@fullcalendar/bootstrap/main.css";
import "../../../assets/css/Work/digitalMarketing.css"
import { fetchData, FetchPostData } from "utils/common-fetchData";
import secureLocalStorage from "react-secure-storage";
import { useParams } from "react-router-dom";
import Loader from "pages/Separate/Loader";
import { calculateQtyWithinTime, calculateTimeOrQuantity, calculateTotalTime, convertToMinutes, updateArrayQuantities, updateSpecificDate } from "utils/common-helpers";
import Swal from "sweetalert2";
import moment from "moment";
import MomentInput from 'react-moment-input';

const FinalPlanDays = ({ key, finalList }) => {
  // meta title
  document.title = "Project Planning | Zithas Crm";
  const [event, setEvent] = useState({});
  const [allEvents, setAllEvents] = useState([]);
  const [activities, setActivities] = useState([])
  const [allActivities, setAllActivities] = useState([])
  const [qty, setQty] = useState('');
  document.addEventListener("keydown", e => {
    if (e.key == "Escape") {
      if (modal) {
        setModal(false)
      }
    }
  })


  const [modal, setModal] = useState(false);
  const [viewData, setViewData] = useState(false);
  const [selectedDay, setSelectedDay] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [eventsList2, setEvents] = useState([]);
  const [modalData, setModalData] = useState({})
  const [eventData, setEventData] = useState({});
  const [extendedData, setExtendedData] = useState({});



  useEffect(() => {
    new Draggable(document.getElementById("external-events"), {
      itemSelector: ".external-event",
    });
  }, []);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setEvent(null);
    } else {
      setModal(true);
    }
  };


  const handleDateClick2 = arg => {
    const clickedElement = arg.dayEl;
    if (clickedElement.querySelector('.resource-hours')) {
      return;
    }
    const date = arg["date"];
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    const currectDate = new Date();
    const currentHour = currectDate.getHours();
    const currentMin = currectDate.getMinutes();
    const currentSec = currectDate.getSeconds();
    const modifiedDate = new Date(
      year,
      month,
      day,
      currentHour,
      currentMin,
      currentSec
    );
    const modifiedData = { ...arg, date: modifiedDate };

    setSelectedDay(modifiedData);
    toggle();
  };

  const handleEventClick = arg => {
    const event = arg.event;
    const data = event?._def?.extendedProps;
    setActiviyName(event?.title)
    setQty(data?.quantity)
    const dat = moment(event.start).format('YYYY-MM-DD');
    const htmlString = customDateHtml[dat];
    const timeMatch = htmlString ? htmlString.match(/(\d{2}:\d{2})/) : null;
    const time = timeMatch ? timeMatch[0] : "00:00";
    setPlanTime(data?.est_hrs)
    setExtendedData({
      ...data,
      occupied: time
    })
    setIsEdit(true);
  };
  const onDrag = event => {
    event.preventDefault();
  };


  const onDrop = event => {
    const { date, draggedEl } = event;
    const categoryId = draggedEl.id;
    const quantity = draggedEl.getAttribute('data-quantity');
    const estTime = draggedEl.getAttribute('data-est');
    const estOneTime = draggedEl.getAttribute('data-time');
    const actualOneTime = draggedEl.getAttribute('data-taskTime');
    const activityId = draggedEl.getAttribute('data-activityId');


    if (!draggedEl || !draggedEl.classList.contains('external-event')) {
      return;
    }

    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    const currentTime = new Date();

    const modifiedDate = new Date(
      year,
      month,
      day,
      currentTime.getHours(),
      currentTime.getMinutes(),
      currentTime.getSeconds()
    );
    const mainDate = moment(date).format('YYYY-MM-DD')
    const htmlString = customDateHtml[mainDate];
    const time = htmlString?.match(/>(.*?)\s*hrs/)[1];
    const newEvent = {
      title: draggedEl.innerText || draggedEl.getAttribute('title'),
      start: modifiedDate,
      className: draggedEl.className,
      id: categoryId,
      quantity: quantity,
      est_time: estTime,
      actual_time: calculateTotalTime(estTime, quantity),
      occupied: time,
      estOneTime: estOneTime,
      actualEst: actualOneTime,
      activityId: activityId
    };

    if (staffId?.length != 0) {
      const formEntries = {
        staff_id: staffId?.value,
        project_id: params?.id,
        activity_id: activityId,
        type: params?.seo,
        quantity: quantity,
        start_date: mainDate,
        user_id: localStorage.getItem('auth_id'),
        className: draggedEl.className,

      }
      setActiviyName(draggedEl.innerText || draggedEl.getAttribute('title'))
      const calculateQty = calculateQtyWithinTime(time, actualOneTime, quantity)
      setQty(calculateQtyWithinTime(time, actualOneTime, quantity))
      setPlanTime(calculateTotalTime(actualOneTime, calculateQty))
      setModalData(formEntries)
      setEventData(newEvent)
      setModal(true)
    } else {
      Swal.fire({
        icon: "warning",
        title: "Please Select Staff"
      })
    }
  };



  const handleDatesSet = (arg) => {
    const dateCells = document.querySelectorAll('.fc-daygrid-day');
    dateCells.forEach((cell) => {
      const date = cell.getAttribute('data-date');
      if (customDateHtml[date]) {
        if (!cell.querySelector('.resource-hours')) {
          const div = document.createElement('div');
          div.innerHTML = customDateHtml[date];
          cell.appendChild(div);
        }
      }
    });

  };


  const viewActivity = [
    {
      id: 1,
      activity: "Link relevant pages to each other using appropriate anchor text.",
      hrs: "02:00"
    },
    {
      id: 2,
      activity: "Link relevant pages to each other using appropriate anchor text.",
      hrs: "01:00"
    },
    {
      id: 3,
      activity: "Link relevant pages to each other using appropriate anchor text.",
      hrs: "0:15"
    },
  ]

  // Api integration for calendar

  const token = secureLocalStorage.getItem('auth_token')
  const params = useParams();
  const [isLoading, setLoading] = useState(false)
  const [assigneOption, setAssigneOption] = useState([])
  const [staffId, setStaffId] = useState([])
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [customDateHtml, setCustomDateHtml] = useState({})
  const [activityName, setActiviyName] = useState('');
  const [changeKey, setChangeKey] = useState(false)
  const [planTime, setPlanTime] = useState('')
  const toggleLoading = (loading) => {
    setLoading(loading)
  }

  useEffect(() => {
    const getActivity = async () => {
      const res = await fetchData(toggleLoading, `updatedProjectTasks/${params?.id}/${params?.seo}`, token);
      if (res?.success) {
        finalList(updateArrayQuantities(res?.data?.tasks, res?.data?.plan_tasks)?.length)
        setActivities(updateArrayQuantities(res?.data?.tasks, res?.data?.plan_tasks))
        setAllActivities(res?.data?.tasks)
        setStartDate(res?.data?.start_date)
        setEndDate(res?.data?.end_date)
        setAssigneOption(res?.data?.members?.map((obj) => ({
          value: obj.id,
          label: obj.alias_name
        })))
        setAllEvents(res?.data?.plan_tasks?.map((obj) => ({
          ...obj,
          title: obj?.name
        })))
      }
    }
    getActivity();
  }, [])

  const onGetAvailability = async (e) => {
    setStaffId(e)
    const formEntries = {
      user_id: e?.value,
      start_date: startDate,
      end_date: endDate,
    }
    const res = await FetchPostData(toggleLoading, "userAvailability", token, formEntries);
    if (res?.data?.success) {
      const output = res?.data?.data?.reduce((acc, { date, time }, index) => {
        acc[date] = `<div class="resource-hours" id="${index + 1}">${time} hrs</div>`;
        return acc;
      }, {});
      setCustomDateHtml(output)
      setChangeKey(!changeKey)
      setEvents(allEvents?.filter((obj) => obj?.staff_id == e.value))
    }
  }

  const handleAddActivity = async (e) => {
    e.preventDefault();
    if (qty != 0) {
      const formEntries = { ...modalData, quantity: qty, est_hrs: planTime, task_type: 2, task_id: eventData?.id }
      const res = await FetchPostData(toggleLoading, "addDigitalProjectTask", token, formEntries)
      if (res?.data?.success) {
        setEvents((prevEvents) => [...prevEvents, eventData]);
        setCustomDateHtml(updateSpecificDate(customDateHtml, planTime, modalData?.start_date))
        setActivities(updateArrayQuantities(allActivities, res?.data?.data?.original?.data))
        setChangeKey(!changeKey)
        setModal(false)
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "⚠️ Quantity cannot be zero! Please update it. ✨"
      })
    }
  }


  const eventDidMount = (info) => {
    const { el, event } = info;
    el.setAttribute('data-est', event.extendedProps.est_time);
    el.setAttribute('data-quantity', event.extendedProps.quantity);
    el.setAttribute('id', event.extendedProps.id);
    el.setAttribute('type', 'event-edit');
    el.setAttribute('title', event?._def?.title);
  };



  return (
    <React.Fragment>
      {isLoading && (<Loader />)}
      <div className="">
        <div className="d-flex justify-content-end mb-3">
          <div className="col-3">
            <Select
              placeholder="Select Member"
              className="basic-multi-select"
              classNamePrefix="select"
              value={staffId}
              options={assigneOption}
              onChange={(e) => onGetAvailability(e)}
            />
          </div>
        </div>
        <Row>
          <Col className="col-12">
            <Row>
              <Col lg={4}>
                <Card>
                  <CardBody className="pt-0" >
                    <div id="external-events" className="mt-0" style={{ height: "860px", overflowY: "scroll", scrollbarWidth: "thin", scrollbarColor: "#8796ce #f0f0f0" }}>
                      <br />
                      <p className="text-muted ps-2">
                        Drag and drop your activity or click in the calendar
                      </p>
                      {activities &&
                        activities.map((category, i) => {
                          const minutesToTime = (minutes) => {
                            const hours = Math.floor(minutes / 60);
                            const mins = Math.round(minutes % 60);  // Round to nearest minute
                            return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`;
                          };

                          const calculateTimePerQty = (totalTime, qty) => {
                            const totalMinutes = convertToMinutes(totalTime);
                            const timePerQtyMinutes = totalMinutes / qty;
                            return minutesToTime(timePerQtyMinutes);
                          };
                          return (
                            <div className="d-flex justify-space-between align-items-center" key={i} >
                              <div
                                className={`${category?.className}`}
                                id={category?.id}
                                data-quantity={category?.quantity}
                                data-est={category?.dev_hrs}
                                data-activityId={category?.activity_id}
                                draggable
                                data-time={category?.est_time}
                                onDrag={event => onDrag(event, category)}
                                data-taskTime={calculateTimePerQty(category?.dev_hrs, category?.quantity)}
                              >
                                <span>
                                  <i className="mdi mdi-checkbox-blank-circle font-size-11 me-2" />
                                  {category.name}
                                </span>
                                <div className="">
                                  <span className="badge bg-light text-dark ms-2"> {category.dev_hrs}</span>
                                  <span className="badge bg-light text-dark ms-2"> {category.quantity}</span>
                                </div>
                              </div>
                            </div>
                          )
                        }
                        )}
                      {activities?.length == 0 ? (
                        <p className="text-purple fw-bold text-center mt-3"> 🎉 All tasks have been managed on time! ⏳ Great work! <br />Let's move to Final step </p>
                      ) : null}
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col lg={8}>
                <FullCalendar
                  key={changeKey}
                  plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin]}
                  slotDuration={"00:15:00"}
                  handleWindowResize={true}
                  themeSystem="bootstrap"
                  headerToolbar={{
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,dayGridWeek,dayGridDay",
                  }}
                  events={eventsList2}
                  eventDidMount={eventDidMount}
                  editable={true}
                  droppable={true}
                  selectable={true}
                  dateClick={handleDateClick2}
                  eventClick={handleEventClick}
                  drop={onDrop}
                  datesSet={handleDatesSet}
                  dayCellClassNames={() => [staffId?.length == 0 ? 'fc-day-other' : '']}
                />

                <Modal
                  isOpen={modal}
                  size="lg"
                  centered
                >
                  <ModalHeader toggle={toggle} tag="h5" className="py-3 px-4 border-bottom-0">
                    {isEdit ? "Edit Activity" : "Add Activity"}
                  </ModalHeader>
                  <ModalBody className="p-4">
                    <Form
                      onSubmit={(e) => {
                        handleAddActivity(e)
                      }}
                    >
                      <Row>
                        <div className="col-md-5 d-flex justify-content-end align-items-center ps-3">
                          <div className="text-center">
                            <img
                              src={editEventImg}
                              alt="Addepe"
                              style={{ width: "90%" }}
                            />
                          </div>
                        </div>
                        <div className="col-7 d-flex flex-column justify-content-center align-items-start">
                          <div className="d-flex gap-2 col-md-12 mb-5">
                            <a className="text-purple pt-2 px-2 rounded work-status cr-status text-center col-md-4">
                              <h5>Est Time</h5>
                              <h5>{eventData?.estOneTime} Hrs </h5>
                            </a>
                            <a className="text-purple pt-2 px-2 rounded work-status cr-status text-center col-md-4">
                              <h5>Engaged Time</h5>
                              <h5>{eventData?.occupied} Hrs</h5>
                            </a>
                          </div>
                          <div className="mb-3 col-12">
                            <Label className="form-label">Activity Name</Label>
                            <Input
                              disabled
                              name="title"
                              type="text"
                              value={activityName?.replace(/\d+/g, "")}
                            />
                          </div>
                          <div className="row col-md-12">
                            <div className="col-4">
                              <Label className="form-label">Quantity</Label>
                              <Input
                                type="text"
                                className="form-control"
                                placeholder="Enter Quantity"
                                value={qty}
                                onChange={(e) => setQty(e.target.value)}
                                disabled
                              />
                            </div>
                            <div className="col-4">
                              <Label className="form-label">Time</Label>
                              <MomentInput
                                format="HH:mm"
                                options={true}
                                readOnly={false}
                                icon={false}
                                inputClassName="form-control"
                                value={moment(planTime, 'HH:mm') || 0}
                                onChange={(date) => {
                                  setPlanTime(date?._i)

                                }}
                                onClose={(e) => {
                                  setQty(calculateTimeOrQuantity(e?._i, qty, eventData?.actualEst))
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </Row>

                      <Row className="">
                        <Col className="col-12 text-end">
                          <button type="submit" className="btn btn-success" id="btn-save-event">
                            Save
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </ModalBody>
                </Modal>

                <Modal
                  isOpen={isEdit}
                  size="lg"
                  centered
                >
                  <ModalHeader toggle={() => setIsEdit(false)} tag="h5" className="py-3 px-4 border-bottom-0">
                    Edit Activity
                  </ModalHeader>
                  <ModalBody className="p-4">
                    <Form
                      onSubmit={(e) => {
                        // handleAddActivity(e)
                      }}
                    >
                      <Row>
                        <div className="col-md-5 d-flex justify-content-end align-items-center ps-3">
                          <div className="text-center">
                            <img
                              src={editEventImg}
                              alt="Addepe"
                              style={{ width: "90%" }}
                            />
                          </div>
                        </div>
                        <div className="col-7 d-flex flex-column justify-content-center align-items-start">
                          <div className="d-flex gap-2 col-md-12 mb-5">
                            <a className="text-purple pt-2 px-2 rounded work-status cr-status text-center col-md-4">
                              <h5>Est Time</h5>
                              <h5>{extendedData?.est_time} Hrs </h5>
                            </a>
                            <a className="text-purple pt-2 px-2 rounded work-status cr-status text-center col-md-4">
                              <h5>Engaged Time</h5>
                              <h5>{extendedData?.occupied} Hrs</h5>
                            </a>
                          </div>
                          <div className="mb-3 col-12">
                            <Label className="form-label">Activity Name</Label>
                            <Input
                              disabled
                              name="title"
                              type="text"
                              value={activityName?.replace(/\d+/g, "")}
                            />
                          </div>
                          <div className="row col-md-12">
                            <div className="col-4">
                              <Label className="form-label">Quantity</Label>
                              <Input
                                type="text"
                                className="form-control"
                                placeholder="Enter Quantity"
                                value={qty}
                                onChange={(e) => setQty(e.target.value)}
                                disabled
                              />
                            </div>
                            <div className="col-4">
                              <Label className="form-label">Time</Label>
                              <MomentInput
                                format="HH:mm"
                                options={true}
                                readOnly={false}
                                icon={false}
                                inputClassName="form-control"
                                value={moment(planTime, 'HH:mm') || 0}
                                onChange={(date) => {
                                  setPlanTime(date?._i)
                                }}
                                onClose={(e) => {
                                  setQty(calculateTimeOrQuantity(e?._i, qty, eventData?.actualEst))
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </Row>

                      <Row className="">
                        <Col className="col-12 text-end">
                          <button type="submit" className="btn btn-success" id="btn-save-event">
                            Save
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </ModalBody>
                </Modal>


                <Modal size="xl" centered isOpen={viewData} className="activity-log">
                  <div className="modal-header">
                    <h5 className="modal-title mt-0">Activity Details</h5>
                    <button
                      type="button"
                      onClick={() => {
                        setViewData(false)
                      }}
                      className="btn-modal-close"
                    >
                      <span aria-hidden="true" className="btn-modal-span">
                        &times;
                      </span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <Row>
                      <div className="col-md-3 d-flex justify-content-end align-items-center ps-3">
                        <div className="text-center">
                          <img
                            src={editEventImg}
                            alt="Addepe"
                            style={{ width: "90%" }}
                          />
                        </div>
                      </div>
                      <div className="col-md-9">
                        <Table className="table table-bordered mb-0">
                          <thead
                            className="bg-white"
                            style={{ position: "sticky", top: 0 }}
                          >
                            <tr>
                              <th className="text-center header">Sr. No</th>
                              <th className="header">Activity</th>
                              <th className="text-center header">Assignee</th>
                              <th className="text-center header">Est. Hrs</th>
                            </tr>
                          </thead>
                          <tbody>
                            {viewActivity?.map((obj, i) => {
                              return (
                                <tr key={i}>
                                  <td>{obj.id}</td>
                                  <td>{obj?.activity}</td>
                                  <td>
                                    <img
                                      src={sideImg}
                                      alt=""
                                      className="img-fluid"
                                      width='30'
                                      style={{ borderRadius: "100%" }}
                                    />
                                  </td>
                                  <td>{obj?.hrs}</td>
                                </tr>
                              )
                            })}

                          </tbody>
                        </Table>
                      </div>
                    </Row>
                  </div>
                </Modal>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

FinalPlanDays.propTypes = {
  className: PropTypes.string,
};

export default FinalPlanDays;
